import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Container,
  Drawer,
  styled as muiStyled,
  Typography,
  useTheme,
} from '@mui/material'
import { IconChecks, IconCopy, IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import { WEB_NAV_HEIGHT } from '../../../../helpers/static-constants'

export const GroupMainContainer = muiStyled('div')({
  display: 'flex',
})

export const GroupEditMemberListMainContainer = muiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const GroupEditMemberListInfoContainer = muiStyled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
})

export const GroupEditMemberListInfoInnerContainer = muiStyled('div')({
  paddingLeft: '10px',
})

export const GroupEditMemberListChipContainer = muiStyled('div')({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  paddingRight: '10px',
})

export const GroupEditMemberListAvatar = muiStyled(Avatar)(() => {
  return {
    height: '37px',
    width: '37px',
  }
})

export const GroupEditMemberListChip = muiStyled(Chip)(() => {
  const theme = useTheme()

  return {
    height: '24px',
    borderRadius: '6px',
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    fontSize: '12px',
  }
})

export const GroupContainer = styled(Container).attrs(() => {
  return {
    component: 'main',
  }
})(() => ({}))

export const GroupTypography = styled(Typography).attrs(() => {
  return {
    variant: 'subtitle1',
    mb: '6px',
    mt: '16px',
  }
})(() => ({}))

export const GroupCopyLinkWrapperBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    bgcolor: theme.palette.grey[100],
    py: '8px',
    px: '10px',
    mt: '16px',
    borderRadius: '8px',
    sx: {
      cursor: 'pointer',
    },
    border: `1px solid ${theme.palette.grey[200]}`,
  }
})(() => ({}))

export const GroupCopyLinkIconChecks = styled(IconChecks).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(() => ({}))

export const GroupCopyLinkIconCopy = styled(IconCopy).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(() => ({}))

export const GroupCopyLinkTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle2',
    pl: '8px',
    color: theme.palette.grey[600],
  }
})(() => ({}))

export const GroupMemberListWrapperBox = styled(Box).attrs(() => {
  return {
    my: '16px',
    maxHeight: '260px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    sx: {
      overflowY: 'auto',
    },
  }
})(() => ({}))

export const GroupMemberListNameTypography = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const GroupMemberListEmailTypography = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle2',
    color: theme.palette.grey[500],
  }
})(() => ({}))

export const GroupMemberListCircularProgress = styled(CircularProgress).attrs(
  () => {
    return {
      color: 'inherit',
      size: 18,
    }
  },
)(() => ({}))

export const GroupMemberListIconX = styled(IconX).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 18,
    color: theme.palette.grey[500],
    cursor: 'pointer',
  }
})(() => ({}))

export const GroupDrawer = styled(Drawer).attrs(() => {
  return {
    anchor: 'right',
    translate: 'yes',
    transitionDuration: 600,
    sx: {
      flexShrink: 0,
      width: { xs: '100vw', md: 392 },
      '& .MuiDrawer-paper': {
        width: { xs: '100vw', md: 392 },
        marginTop: {
          xs: `0px`,
          md: `${WEB_NAV_HEIGHT}px`,
        },
      },
    },
  }
})(() => ({}))
