import React, { useEffect, useState } from 'react'
import BasicModal from '../../../../components/modal/BasicModal'
import {
  SocialBasicModalContainer,
  SocialInputLabel,
  SocialUserEmailSearchContainer,
} from '../styled/Social.styled'
import {
  OnboardRequestInnerGridContainer,
  OnboardRequestTextField,
} from '../../onboardPages/styled/OnboardRequest.styled'
import SocialUserEmailSearch from '../../../../components/social/SocialUserEmailSearch'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Group, Member } from '../../../../types/groupTypes'
import { createGroup, updateGroup } from '../../../../store/socialSlice'
import { useAppDispatch } from '../../../../store'
import { Box } from '@mui/material'

interface GroupAddEditDialogProps {
  groupAction: 'edit' | 'add'
  selectedGroup?: Group | null
  openModel: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const GroupAddEditDialog: React.FC<GroupAddEditDialogProps> = ({
  groupAction = 'add',
  selectedGroup,
  openModel,
  setIsModalOpen,
}) => {
  const [emailList, setEmailList] = useState<Member[]>([])
  const [isEmailValid, setIsEmailValid] = useState(false)
  //   const [isModalOpen, setIsModalOpen] = useState(false)
  const [defaultGroup, setDefaultGroup] = useState<Group>({})
  const [createGroupLoading, setCreateGroupLoading] = useState(false)
  const dispatch = useAppDispatch()

  const formSchema = z.object({
    groupName: z.string().min(1, { message: ' ' }),
    location: z.string().min(1, { message: ' ' }),
    searchTerm: z.string().min(1, { message: ' ' }),
  })
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      groupName: defaultGroup?.name ?? '',
      location: defaultGroup?.location ?? '',
      searchTerm: defaultGroup?.searchTerm ?? '',
    },
  })
  useEffect(() => {
    if (emailList.length && isEmailValid) {
      setIsEmailValid(false)
    }
  }, [emailList])
  useEffect(() => {
    if (selectedGroup) {
      setDefaultGroup(selectedGroup)
      reset({
        groupName: selectedGroup.name ?? '',
        location: selectedGroup.location ?? '',
        searchTerm: selectedGroup.searchTerm ?? '',
      })
    }
  }, [selectedGroup, reset])

  const getPayload = async (): Promise<void> => {
    if (!emailList.length || groupAction === 'edit') {
      setIsEmailValid(true)
    }
    await handleSubmit(
      async (data: z.infer<typeof formSchema>): Promise<void> => {
        if ((!isEmailValid && emailList.length) || groupAction === 'edit') {
          setCreateGroupLoading(true)

          if (groupAction === 'edit') {
            await dispatch(
              updateGroup({
                id: defaultGroup?.id ?? '',
                name: data.groupName || '',
                searchTerm: data.searchTerm || '',
                location: data.location || '',
              }),
            )
            setIsModalOpen(false)
          } else {
            const payload = {
              name: data.groupName,
              members: emailList,
              location: data.location,
              searchTerm: data.searchTerm,
            }
            const result = await dispatch(createGroup(payload))
            if (createGroup.fulfilled.match(result)) {
              setIsModalOpen(false)
              reset({})
              setEmailList([])
            }
          }

          setCreateGroupLoading(false)
        }
      },
    )()
  }

  const onCloseModal = (): void => {
    reset({})
    setIsEmailValid(false)
    setIsModalOpen(false)
  }

  return (
    <BasicModal
      open={openModel}
      onCancel={onCloseModal}
      onConfirm={() => {
        void getPayload()
      }}
      title={groupAction === 'add' ? 'Create New Group' : 'Edit Group'}
      cancelButtonName="Cancel"
      buttonName={groupAction === 'add' ? 'Create' : 'Update'}
      handleClose={onCloseModal}
      cancelIcon
      modelWidth="444px"
      isLoader={createGroupLoading}
    >
      <SocialBasicModalContainer>
        <OnboardRequestInnerGridContainer>
          <div>
            <SocialInputLabel htmlFor="lastName">Group Name</SocialInputLabel>
            <OnboardRequestTextField
              id="groupName"
              {...register('groupName')}
              error={!!errors.groupName}
              helperText={errors.groupName ? errors.groupName.message : ' '}
            />
          </div>
        </OnboardRequestInnerGridContainer>

        {groupAction === 'add' && (
          <Box>
            <SocialInputLabel>Invite Users by Email</SocialInputLabel>
            <SocialUserEmailSearchContainer>
              <SocialUserEmailSearch
                memberList={emailList}
                setMemberList={setEmailList}
                isEmailValid={isEmailValid}
              />
            </SocialUserEmailSearchContainer>
          </Box>
        )}

        <OnboardRequestInnerGridContainer>
          <div>
            <SocialInputLabel htmlFor="location">
              Location Name
            </SocialInputLabel>
            <OnboardRequestTextField
              id="location"
              {...register('location')}
              error={!!errors.location}
              helperText={errors.location ? errors.location.message : ' '}
            />
          </div>
        </OnboardRequestInnerGridContainer>
        <OnboardRequestInnerGridContainer>
          <div>
            <SocialInputLabel htmlFor="searchTerm">
              Search Term
            </SocialInputLabel>
            <OnboardRequestTextField
              id="searchTerm"
              {...register('searchTerm')}
              error={!!errors.searchTerm}
              helperText={errors.searchTerm ? errors.searchTerm.message : ' '}
            />
          </div>
        </OnboardRequestInnerGridContainer>
      </SocialBasicModalContainer>
    </BasicModal>
  )
}

export default GroupAddEditDialog
