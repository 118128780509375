import { Container, Typography, useTheme } from '@mui/material'
import { IconChevronLeft } from '@tabler/icons-react'
import styled from 'styled-components'

export const RestaurantDetailMuiContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: { flex: 1 },
}))(() => ({}))

export const RestaurantDetailRestaurantContainer = styled('div').attrs(
  () => ({}),
)(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '12px 0px',
  margin: '20px 0px',
}))

export const RestaurantDetailRestaurantIconChevronLeft = styled(
  IconChevronLeft,
).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.light,
    stroke: 2,
    size: 18,
  }
})(() => ({}))

export const RestaurantDetailRestaurantTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'subtitle1',
      component: 'span',
      fontWeight: 600,
      sx: { textTransform: 'capitalize', cursor: 'pointer' },
      color: theme.palette.primary.light,
    }
  },
)(() => ({}))
