import { Box, Typography, useTheme } from '@mui/material'
import { IconDotsVertical, IconPencil } from '@tabler/icons-react'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'
import { MOBILE_APP_HEIGHT } from '../../../../helpers/static-constants'
import { useDevice } from '../../../../hooks/useDevice'

export const ProfileHeaderContainer = styled('div').attrs(() => ({}))(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '40px',
  paddingBottom: '40px',
}))

export const ProfileHeaderTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'h3',
    lineHeight: '44px',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const ProfileHeaderRightContainer = styled('div').attrs(() => ({}))(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
)

export const ProfileHeaderCustomButtonCancel = styled(CustomButton).attrs(
  () => {
    const theme = useTheme()
    return {
      sx: {
        backgroundColor: 'white',
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
        marginRight: '12px',
        py: '8px',
      },
    }
  },
)(() => ({}))

export const ProfileHeaderCustomButtonUpdate = styled(CustomButton).attrs(
  ({ disabled }) => {
    const theme = useTheme()
    return {
      sx: {
        backgroundColor: disabled
          ? theme.palette.grey[100]
          : theme.palette.primary.light,
        borderColor: disabled
          ? theme.palette.grey[300]
          : theme.palette.primary.light,
        color: disabled ? theme.palette.primary.light : 'white',
        py: '8px',
      },
    }
  },
)(() => ({}))

export const ProfileHeaderCustomButtonEdit = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      color: 'white',
      border: 'none',
      py: '8px',
    },
  }
})(() => ({}))

export const ProfileHeaderIconDotsVertical = styled(IconDotsVertical).attrs(
  () => ({ size: 16, stroke: 2 }),
)(() => ({}))

export const ProfileHeaderWebIconPencil = styled(IconPencil).attrs(() => ({
  size: 18,
  stroke: 2,
}))(() => ({}))

export const ProfileHeaderWebEditButtonIconPencil = styled(IconPencil).attrs(() => ({
  size: 16,
  stroke: 2,
}))(() => ({}))

export const ProfileHeaderIconPencil = styled(IconPencil).attrs(() => ({
  size: 16,
  stroke: 2,
}))(() => ({}))

export const ProfileHeaderButtonWrapper = styled(Box).attrs(() => {
  const theme = useTheme()
  const { isHybrid } = useDevice()
  return {
    display: 'flex',
    alignItems: 'center',
    sx: {
      position: 'fixed',
      bottom: isHybrid
        ? `calc(${MOBILE_APP_HEIGHT}px + env(safe-area-inset-bottom))`
        : 0,
      right: 0,
      p: '8px',
      backgroundColor: theme.palette.background.paper,
      zIndex: 100,
      width: '100%',
    },
  }
})(() => ({}))
