import {
  Box,
  CardContent,
  Rating,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import React from 'react'
import { IconStarFilled } from '@tabler/icons-react'

export const RestaurantDishesCardMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'relative',
    },
  }
})(() => ({}))

interface RestaurantDishesCardWrapperCardProp {
  isLoading: boolean
}

export const RestaurantDishesCardWrapperCard = styled(
  ({ isLoading, ...rest }: RestaurantDishesCardWrapperCardProp & any) => (
    <Box {...rest} />
  ),
).attrs<RestaurantDishesCardWrapperCardProp>(({ isLoading }) => {
  const theme = useTheme()
  return {
    sx: {
      width: '100%',
      display: 'flex',
      border: 'none',
      boxShadow: 'none',
      p: 1,
      cursor: 'pointer',
      borderRadius: '16px',
      '&.MuiCard-root:hover': {
        backgroundColor: theme.palette.grey[50],
      },
      filter: isLoading ? 'blur(2px)' : 'none',
    },
  }
})<RestaurantDishesCardWrapperCardProp>(({ theme }) => ({}))

export const RestaurantDishesCardInnerContainer = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'relative',
      minWidth: { xs: '124px', md: '216px' },
      maxWidth: { xs: '130px', md: '220px' },
      height: { xs: '96px', md: '136px' },
    },
  }
})(() => ({}))

export const RestaurantDishesCardSkeleton = styled(Skeleton).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'rectangular',
    sx: {
      height: '100%',
      width: '100%',
      borderRadius: '16px',
      border: `2px solid ${theme.palette.grey[200]}`,
      boxShadow: '0px 0px 2px 0px #1A1A1A40',
    },
  }
})(() => ({}))

export const RestaurantDishesCardMediaWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      position: 'absolute',
      top: '6px',
      left: '6px',
      zIndex: 100,
    },
  }
})(() => ({}))

export const RestaurantDishesCardContent = styled(CardContent).attrs(() => {
  return {
    sx: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      p: '0px 0px 0px 16px',
      paddingBottom: '0px !important',
    },
  }
})(() => ({}))

export const RestaurantDishesCardContentTitleTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body2',
    color: theme.palette.grey[800],
    textTransform: 'capitalize',
  }
})(() => ({}))

export const RestaurantDishesCardContentDescriptionTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const RestaurantDishesCardContentWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
})(() => ({}))

export const RestaurantDishesCardContentWrapperPriceTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      variant: 'body2',
      color: theme.palette.grey[800],
    },
  }
})(() => ({}))

export const RestaurantDishesCardContentRating = styled(Rating).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      '& .MuiRating-icon': {
        margin: '0 2px',
      },
      '& .MuiRating-iconFilled': {
        color: theme.palette.warning.light,
      },
      '& .MuiRating-iconHover': {
        color: theme.palette.warning[300],
      },
    },
  }
})(() => ({}))

export const RestaurantDishesCardContentRatingIconStarFilled = styled(
  IconStarFilled,
).attrs(() => {
  return {
    size: 20,
  }
})(() => ({}))

export const RestaurantDishesCardContentRatingIconStarFilledEmpty = styled(
  IconStarFilled,
).attrs(() => {
  const theme = useTheme()
  return {
    size: 20,
    color: theme.palette.grey[200],
  }
})(() => ({}))

export const RestaurantDishesCardCircularProgressWrapper = styled(Box).attrs(
  () => {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      transition: 'transform 0.3s ease-in-out',
    }
  },
)(() => ({}))
