import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  recoverUserAccount,
  recoverUserBlockedAccount,
  selectIsLoading,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../store/authSlice'
import { MuiAlert, MuiSnackbar } from '../styled/global.styled'
import {
  RecoverBlockedAccountCustomButton,
  RecoverBlockedAccountDescriptionTypography,
  RecoverBlockedAccountInnerContainer,
  RecoverBlockedAccountMainContainer,
  RecoverBlockedAccountTitleTypography,
} from './styled/RecoverBlockedAccount.styled'

const RecoverBlockedAccount: React.FC = () => {
  const dispatch = useAppDispatch()
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const isLoading = useAppSelector(selectIsLoading)
  const [closeState, setCloseState] = useState(false)

  const handleRequestUnblock = async (): Promise<void> => {
    const result = await dispatch(recoverUserBlockedAccount())
    if (recoverUserAccount.fulfilled.match(result)) {
      setCloseState(true)
    }
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <RecoverBlockedAccountMainContainer>
      <Helmet>
        <title>Resactly | Recover Your Account</title>
        <meta
          name="description"
          content="Need help accessing your Resactly account? Use our account recovery page to reset your password or retrieve your account information and get back to discovering top dining spots."
        />
      </Helmet>
      <div>
        <RecoverBlockedAccountTitleTypography>
          Recover Your Account
        </RecoverBlockedAccountTitleTypography>
        <RecoverBlockedAccountDescriptionTypography>
          Your account is Blocked, you must raise unblock request
        </RecoverBlockedAccountDescriptionTypography>
        <RecoverBlockedAccountInnerContainer>
          {closeState ? (
            'Unblock request sent. We will notify you when the request is reviewed.'
          ) : (
            <RecoverBlockedAccountCustomButton
              onClick={() => {
                void handleRequestUnblock()
              }}
              title={isLoading ? '' : 'Request Unblock'}
              icon={
                isLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null
              }
            />
          )}
        </RecoverBlockedAccountInnerContainer>
      </div>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </RecoverBlockedAccountMainContainer>
  )
}

export default RecoverBlockedAccount
