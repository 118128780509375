import { Box, Modal, Typography, useTheme } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import React from 'react'
import RoundedButton from './ui/RoundedButton'

interface FooterDisclaimerModelProps {
  open: boolean
  handleClose: () => void
}

const FooterDisclaimerModel: React.FC<FooterDisclaimerModelProps> = ({
  open,
  handleClose,
}) => {
  const theme = useTheme()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '16px',
          p: { xs: '16px', md: '24px' },
          width: { xs: 'calc(100% - 20px)', md: '430px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: '20px',
          }}
        >
          <Typography variant="h3">Disclaimer</Typography>
          <RoundedButton
            br="50%"
            borderColor={theme.palette.grey[300]}
            diameter={34}
            onClick={handleClose}
          >
            <IconX
              stroke={2}
              size={18}
              color={theme.palette.grey[800]}
              style={{
                cursor: 'pointer',
              }}
            />
          </RoundedButton>
        </Box>
        <Typography variant="subtitle1" component="p">
          The information provided on this platform is for general informational
          purposes only. While we strive to ensure accuracy, we do not guarantee
          that the information is complete, reliable, or free from errors. Users
          are responsible for verifying all ingredients, allergens, and dietary
          restrictions directly with the restaurant. We disclaim any liability
          for any adverse effects, injuries, or damages resulting from the use
          of this information.
        </Typography>
        <Typography variant="subtitle1" component="p" mt="10px">
          By using this platform, you acknowledge and agree to assume full
          responsibility for any decisions made based on the information
          provided.
        </Typography>
      </Box>
    </Modal>
  )
}

export default FooterDisclaimerModel
