import React, { FC } from 'react'

import { Avatar, Box, Drawer, Link, Typography, useTheme } from '@mui/material'
import {
  StyledCustomButton,
  StyledCustomOutlineButton,
} from './styled/MobileDrawer.styled'
import { IconChevronRight } from '@tabler/icons-react'
import { selectEater } from '../../store/profileSlice'
import { selectUserInfo } from '../../store/authSlice'
import { useAppSelector } from '../../store'
import { useAuth0 } from '@auth0/auth0-react'
import useRudderStackAnalytics from '../../useRudderAnalytics'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import { NavLink, useNavigate } from 'react-router-dom'
import { MOBILE_NAV_HEIGHT } from '../../helpers/static-constants'
import { ROUTES } from '../../helpers/routes-helper'
interface CustomDrawerProps {
  open: boolean
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
  routeList: NavList[]
}

interface NavList {
  name: string
  path: string
}

interface INavbar {
  name: string
  path: string
}

const CustomDrawer: FC<CustomDrawerProps> = ({
  open,
  toggleDrawer,
  routeList,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const eater = useAppSelector(selectEater)
  const userInfo = useAppSelector(selectUserInfo)
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()

  const { isAuthenticated } = useAuth0()
  const { logoutAuthUser, loginAuthUser } = useAuthHelper()

  const onNextRoute = (route: INavbar): void => {
    toggleDrawer(false)
  }

  const login = async (): Promise<void> => {
    loginAuthUser()
    toggleDrawer(false)
  }

  const logoutUser = (): void => {
    if(analytics && isAnalyticsReady){
      analytics?.identify('', { isLoggedIn: false })
      analytics?.reset()
    }
    navigate(ROUTES.HOME)
    logoutAuthUser()
    toggleDrawer(false)
  }

  return (
    <Drawer
      transitionDuration={300}
      translate="yes"
      sx={{
        width: 224,
        '& .MuiDrawer-paper': {
          width: 224,
          marginTop: `${MOBILE_NAV_HEIGHT}px`,
          border: `1px solid ${theme.palette.grey[300]}`,
          boxShadow:
            '0px 8px 10px -5px rgba(0,0,0,0.2),-1px 26px 10px 2px rgba(0,0,0,0.14),0px 11px 11px 5px rgba(0,0,0,0.12)',
          zIndex: 10,
        },

        '& .MuiModal-backdrop': {
          backgroundColor: 'transparent',
        },
      }}
      open={open}
      anchor="right"
      onClose={() => toggleDrawer(false)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        height="calc(100% - 100px)"
        alignItems="stretch"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="stretch"
          flexDirection="column"
        >
          {routeList.map((item, index) => {
            return (
              <Link
                onClick={() => onNextRoute(item)}
                key={index}
                component={NavLink}
                to={item?.path}
                variant="subtitle1"
                underline="none"
                sx={{
                  paddingY: '16px',
                  paddingLeft: '24px',
                  cursor: 'pointer',

                  '&:hover': {
                    color: theme.palette.secondary.main,
                  },
                  '&.active': {
                    color: theme.palette.secondary.main,
                    fontWeight: 600,
                    backgroundColor: theme.palette.secondary[50],
                  },
                }}
              >
                <Box display={'flex'}>
                  <Typography
                    noWrap
                    component="p"
                    variant="body2"
                    fontWeight={600}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Link>
            )
          })}
        </Box>
        <Box px="16px">
          {/* <StyledCustomOutlineButton
            title="Logout"
            onClick={logoutUser}
            endIcon={<IconChevronRight size={16} stroke={2} />}
          /> */}
          {!isAuthenticated ? (
            <StyledCustomButton
              title="Login"
              onClick={() => {
                void login()
              }}
            />
          ) : (
            <>
              <Box display="flex" justifyContent="start" alignItems="center">
                <Avatar
                  src={
                    eater?.imageUrl ??
                    userInfo?.picture ??
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                  }
                  sx={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                  }}
                />
                <Box pl="10px">
                  <Typography
                    noWrap
                    variant="subtitle1"
                    maxWidth="150px"
                    color={theme.palette.grey[800]}
                  >
                    {`${eater?.firstName ?? ''} ${eater?.lastName ?? ''}`}
                  </Typography>
                  <Typography
                    noWrap
                    variant="subtitle2"
                    maxWidth="150px"
                    color={theme.palette.grey[500]}
                  >
                    {eater?.email ?? ''}
                  </Typography>
                </Box>
              </Box>
              <StyledCustomOutlineButton
                title="Logout"
                onClick={logoutUser}
                endIcon={<IconChevronRight size={16} stroke={2} />}
              />
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

export default CustomDrawer
