import { Box, TextField, useTheme } from '@mui/material'
import styled from 'styled-components'
export const OnboardSocialWarper = styled(Box).attrs(() => ({
  component: 'form',
  sx: {
    '& .MuiTextField-root': { my: 1 },
    marginRight: { xs: 'auto', md: '0px' },
    marginLeft: { xs: 'auto', md: '0px' },
    marginTop: { xs: '10px', md: '80px' },
    maxWidth: '600px',
  },
  noValidate: true,
}))(({ theme }) => ({}))

export const OnboardSocialTextField = styled(TextField).attrs(() => ({
  type: 'text',
  variant: 'outlined',
  fullWidth: true,
  size: 'small',
}))(() => {
  const theme = useTheme()

  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[300],
      },
    },
  }
})
