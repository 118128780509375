import { Menu, useTheme } from '@mui/material'
import { IconShare } from '@tabler/icons-react'
import { FacebookIcon, XIcon } from 'react-share'
import styled from 'styled-components'

export const RestaurantShareIconShare = styled(IconShare).attrs(() => {
  const theme = useTheme()
  return {
    size: 18,
    color: theme.palette.grey[800],
    stroke: 2,
  }
})(() => ({}))

export const RestaurantShareFacebookIcon = styled(FacebookIcon).attrs(() => {
  return {
    size: 32,
    round: true,
  }
})(() => ({}))

export const RestaurantShareXIcon = styled(XIcon).attrs(() => {
  return {
    size: 32,
    round: true,
  }
})(() => ({}))

export const RestaurantShareMenu = styled(Menu).attrs(() => {
  return {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    MenuListProps: {
      'aria-labelledby': 'basic-button',
    },
    sx: {
      '& .MuiPaper-root': {
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        borderRadius: '10px',
      },
      p: 1,
    },
  }
})(() => ({}))
