import { useMemo } from 'react'

interface AuthConfig {
  scope: string
  domain: string
  clientId: string
  auth0Api: string
  callbackUri: string
}

// Custom hook to get the authentication configuration
export const useAuthConfig = (
  isHybrid: boolean,
  isAndroid: boolean,
): AuthConfig => {
  const scope = 'openid profile email'

  // Read environment variables
  const appId =
    (isAndroid
      ? process.env.REACT_APP_ANDROID_APP_ID
      : process.env.REACT_APP_IOS_APP_ID) ?? ''
  const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? ''
  const auth0Api = process.env.REACT_APP_AUTH0_API ?? ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''

  // Memoize the configuration to avoid unnecessary recalculations
  const callbackUri = useMemo(() => {
    // Determine callback URI based on platform
    return isHybrid
      ? `${appId}://${domain}/capacitor/${appId}/callback`
      : window.location.origin // `${appId}://${domain}/capacitor/${appId}/callback` // window.location.origin
  }, [isHybrid, isAndroid]) // Dependency array ensures recalculation only when `isHybrid` changes

  // Return the configuration
  return {
    scope,
    domain,
    clientId,
    auth0Api,
    callbackUri,
  }
}
