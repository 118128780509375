import { useAuth0 } from '@auth0/auth0-react'
import { CardMedia, CircularProgress, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IRestaurantDishRatingReview } from '../../pages/eater/restaurant/components/ReviewsTab'
import { IChipData } from '../../types'
import {
  RestaurantDishesCardCircularProgressWrapper,
  RestaurantDishesCardContent,
  RestaurantDishesCardContentDescriptionTypography,
  RestaurantDishesCardContentRating,
  RestaurantDishesCardContentRatingIconStarFilled,
  RestaurantDishesCardContentRatingIconStarFilledEmpty,
  RestaurantDishesCardContentTitleTypography,
  RestaurantDishesCardContentWrapper,
  RestaurantDishesCardContentWrapperPriceTypography,
  RestaurantDishesCardInnerContainer,
  RestaurantDishesCardMainContainer,
  RestaurantDishesCardMediaWrapper,
  RestaurantDishesCardSkeleton,
  RestaurantDishesCardWrapperCard,
} from '../styled/restaurant/RestaurantDishesCard.styled'
import CustomButton from '../ui/CustomButton'
import TagChip from '../ui/TagChip'

interface FoodCardProps {
  image: string
  title: string
  description: string
  isEditable?: boolean
  price?: number
  rating?: number
  isButton?: boolean
  id: string
  onRemove?: () => void
  onChangeRating?: (item: IRestaurantDishRatingReview) => void
  tagChipData?: IChipData[]
  isLoading?: boolean
}

const RestaurantDishesCard: React.FC<FoodCardProps> = ({
  image,
  rating,
  description,
  title,
  isEditable = false,
  isButton = false,
  price,
  id,
  onRemove,
  onChangeRating,
  tagChipData,
  isLoading,
}) => {
  const theme = useTheme()
  const { isAuthenticated } = useAuth0()
  const [loaded, setLoaded] = useState(false)
  const [newRating, setNewRating] = React.useState<number | null>(rating ?? 0)

  useEffect(() => {
    if (rating) {
      setNewRating(rating)
    }
  }, [rating])

  const handleRemove = (): void => {
    if (onRemove && !isLoading) {
      onRemove()
    }
  }

  const onRating = (rating: number | null): void => {
    setNewRating(rating)
    if (onChangeRating)
      onChangeRating({ id, starCount: rating !== null ? rating : 0 })
  }

  return (
    <RestaurantDishesCardMainContainer>
      <RestaurantDishesCardWrapperCard isLoading={isLoading}>
        <RestaurantDishesCardInnerContainer>
          {!loaded && <RestaurantDishesCardSkeleton />}
          <CardMedia
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '16px',
              objectFit: 'cover',
              border: `2px solid ${theme.palette.grey[200]}`,
              boxShadow: '0px 0px 2px 0px #1A1A1A40',
              display: loaded ? 'block' : 'none',
            }}
            component="img"
            loading="eager"
            image={image}
            alt={`resactly-${title}-Dish`}
            onLoad={() => setLoaded(true)}
          />
          <RestaurantDishesCardMediaWrapper>
            {tagChipData && (
              <TagChip
                data={tagChipData}
                bgColor="liteOrange"
                textBlur={!isAuthenticated}
              />
            )}
          </RestaurantDishesCardMediaWrapper>
        </RestaurantDishesCardInnerContainer>

        <RestaurantDishesCardContent>
          <div>
            <RestaurantDishesCardContentTitleTypography>
              {title.toLocaleLowerCase()}
            </RestaurantDishesCardContentTitleTypography>
            <RestaurantDishesCardContentDescriptionTypography>
              {description}
            </RestaurantDishesCardContentDescriptionTypography>
          </div>
          <RestaurantDishesCardContentWrapper>
            <>
              {Number(price) > 0 && (
                <RestaurantDishesCardContentWrapperPriceTypography>
                  ${price}
                </RestaurantDishesCardContentWrapperPriceTypography>
              )}
              {typeof rating === 'number' && (
                <RestaurantDishesCardContentRating
                  name={isEditable ? 'simple-controlled' : 'read-only'}
                  value={newRating}
                  readOnly={!isLoading ? !isEditable : true}
                  onChange={(event, newValue) => {
                    onRating(newValue)
                  }}
                  icon={<RestaurantDishesCardContentRatingIconStarFilled />}
                  emptyIcon={
                    <RestaurantDishesCardContentRatingIconStarFilledEmpty />
                  }
                />
              )}
              {isEditable && isButton && (
                <CustomButton
                  title="Remove"
                  backgroundColor={theme.palette.common.white}
                  textColor={theme.palette.primary.light}
                  hoverColor={theme.palette.primary.light}
                  sx={{
                    borderColor: theme.palette.grey[300],
                    borderRadius: '10px',
                  }}
                  onClick={handleRemove}
                />
              )}
            </>
          </RestaurantDishesCardContentWrapper>
        </RestaurantDishesCardContent>
      </RestaurantDishesCardWrapperCard>
      {isLoading && (
        <RestaurantDishesCardCircularProgressWrapper>
          <CircularProgress color="inherit" />
        </RestaurantDishesCardCircularProgressWrapper>
      )}
    </RestaurantDishesCardMainContainer>
  )
}

export default RestaurantDishesCard
