import { Box, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import OnboardDialog from './OnboardDialog'
import OnboardSearchBox from './OnboardSearch'
import {
  OnboardTitle,
  OnboardingInnerWrapper,
  OnboardingItemListChip,
  OnboardingMoreItemListChip,
  OnboardingSearchContainer,
  OnboardingSearchInnerContainer,
  OnboardingWrapper,
} from './styled/OnboardPreferenceOptions.styled'

interface OnboardPreferenceOptionsProps {
  title?: string
  chipType?: 'green' | 'red'
  itemList: string[]
  selectedItems: string[]
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  oppositeItems: string[]
  searchIndexKey: string
  isPreferenceEdit: boolean
  searchField?: string
  testId?: string
  alignItems?: 'center' | 'right' | 'left'
}

interface IActiveChipStyle {
  color: string
  borderColor: string
  backgroundColor: string
}

const OnboardPreferenceOptions: React.FC<OnboardPreferenceOptionsProps> = ({
  title,
  chipType = 'green',
  itemList,
  selectedItems,
  setSelectedItems,
  oppositeItems,
  searchIndexKey,
  isPreferenceEdit,
  searchField = 'value',
  alignItems = 'center',
}: OnboardPreferenceOptionsProps): JSX.Element => {
  const theme = useTheme()
  const activeChipStyle = getActiveChipStyle(chipType)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [suggestedWord, setSuggestedWord] = useState('')
  const [allItemList, setAllItemList] = useState<string[]>([])

  const handleItemToggle = useCallback(
    (item: string): void => {
      setSelectedItems((prevState) =>
        prevState.includes(item)
          ? prevState.filter((i) => i !== item)
          : [...prevState, item],
      )
    },
    [setSelectedItems],
  )

  useEffect(() => {
    if (suggestedWord.length > 0) {
      if (!oppositeItems.includes(suggestedWord))
        handleItemToggle(suggestedWord)
      if (!allItemList.includes(suggestedWord))
        setAllItemList((prevState) => {
          return [suggestedWord, ...prevState]
        })
      setSuggestedWord('')
    }
  }, [suggestedWord])

  useEffect(() => {
    setAllItemList(
      Array.from(new Set([...selectedItems, ...itemList])).filter(
        (e) => e.length > 0,
      ),
    )
  }, [itemList, isPreferenceEdit])

  const isItemSelected = useCallback(
    (item: string): boolean => selectedItems.includes(item),
    [selectedItems],
  )

  const toggleDialog = useCallback(
    (): void => setIsDialogOpen((prevState) => !prevState),
    [],
  )

  const setSuggestedSearch = useCallback(
    (item: string): void => setSuggestedWord(item.toLocaleLowerCase()),
    [],
  )

  const renderItem = useCallback(
    (item: string, index: number) => {
      return (
        <OnboardingInnerWrapper key={index}>
          <Box component="div" sx={{ alignItems: 'start' }}>
            <OnboardingItemListChip
              sx={{
                paddingX: '8px',
                color: isItemSelected(item)
                  ? activeChipStyle.color
                  : theme.palette.grey[800],
                borderColor: isItemSelected(item)
                  ? activeChipStyle.borderColor
                  : theme.palette.grey[300],
                backgroundColor: isItemSelected(item)
                  ? activeChipStyle.backgroundColor
                  : theme.palette.background.paper,
                fontSize: '14px',
              }}
              {...(!isPreferenceEdit && {
                onClick: () => handleItemToggle(item),
              })}
              disabled={oppositeItems.includes(item)}
              label={item}
              style={{
                fontFamily: 'Inter',
                textTransform: 'capitalize',
              }}
            />
          </Box>
        </OnboardingInnerWrapper>
      )
    },
    [activeChipStyle, handleItemToggle, isItemSelected, oppositeItems],
  )

  return (
    <Box
      component="div"
      display={'flex'}
      flexDirection={'column'}
      alignItems={
        alignItems === 'left'
          ? 'start'
          : alignItems === 'right'
          ? 'end'
          : 'center'
      }
      style={{
        alignItems: `${
          alignItems === 'left'
            ? 'start'
            : alignItems === 'right'
            ? 'end'
            : 'center'
        } !important`,
      }}
    >
      <OnboardTitle>{title}</OnboardTitle>

      {!isPreferenceEdit && (
        <OnboardingSearchContainer>
          <OnboardingSearchInnerContainer>
            <OnboardSearchBox
              indexKey={searchIndexKey}
              key={`${itemList.length} ${title ?? ''}`}
              setWord={setSuggestedSearch}
              searchFieldName={searchField}
            />
          </OnboardingSearchInnerContainer>
        </OnboardingSearchContainer>
      )}
      {!isPreferenceEdit && (
        <OnboardingWrapper
          px={`${alignItems === 'left' ? '0px' : '5px'}`}
          justifyContent={
            alignItems === 'left'
              ? 'start'
              : alignItems === 'right'
              ? 'end'
              : 'center'
          }
        >
          {allItemList.slice(0, 10).map(renderItem)}
          {allItemList.length > 10 && (
            <>
              <OnboardingInnerWrapper>
                <Box>
                  <OnboardingMoreItemListChip
                    label={`+ ${allItemList.length - 10} more`}
                    onClick={toggleDialog}
                    style={{
                      fontFamily: 'Inter',
                    }}
                  />
                </Box>
              </OnboardingInnerWrapper>
            </>
          )}
        </OnboardingWrapper>
      )}
      {isPreferenceEdit && (
        <OnboardingWrapper
          justifyContent={
            alignItems === 'left'
              ? 'start'
              : alignItems === 'right'
              ? 'end'
              : 'center'
          }
          pt={1}
        >
          {selectedItems.length ? (
            selectedItems.map(renderItem)
          ) : (
            <Typography color="black">No data</Typography>
          )}
        </OnboardingWrapper>
      )}

      {isDialogOpen && (
        <OnboardDialog
          isOpen={isDialogOpen}
          title="Select Options"
          items={allItemList}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          oppositeItems={oppositeItems}
          chipType={chipType}
          toggleDialog={toggleDialog}
          onSuggestValue={setSuggestedSearch}
          searchIndexKey={searchIndexKey}
          searchField={searchField}
        />
      )}
    </Box>
  )
}

export default OnboardPreferenceOptions


export const getActiveChipStyle = (
  chipType: 'green' | 'red',
): IActiveChipStyle => {
  const theme = useTheme()
  if (chipType === 'green') {
    return {
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
      backgroundColor: theme.palette.success[50],
    }
  } else if (chipType === 'red') {
    return {
      color: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      backgroundColor: theme.palette.error[50],
    }
  }
  return {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.background.paper,
  }
}