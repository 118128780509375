import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import {
  COOKIE_POLICY_URL,
  COPY_RIGHT_TEXT,
  FACEBOOK_PAGE_URL,
  INSTAGRAM_PAGE_URL,
  MOBILE_APP_HEIGHT,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITION_URL,
} from '../helpers/static-constants'
import { useDevice } from '../hooks/useDevice'
import {
  MuiAlert,
  MuiBackdrop,
  MuiSnackbar,
} from '../pages/eater/styled/global.styled'
import { useAppDispatch, useAppSelector } from '../store'
import {
  selectIsLoading,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../store/authSlice'
import FooterDisclaimerModel from './FooterDisclaimerModel'
import {
  FooterDisclaimerTypography,
  FooterLink,
  FooterLinkMainBox,
  FooterMainBox,
  FooterSecondBox,
  FooterSeparator,
  FooterSocialIconBox,
  LinkBox,
} from './styled/Footer.styled'
import SubscribeForm from './SubscribeForm'
import ImgIcon from './ui/ImgIcon'

const Footer: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const isLoading = useAppSelector(selectIsLoading)
  const { isHybrid } = useDevice()

  const [openDisclaimerModel, setOpenDisclaimerModel] = useState(false)

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <>
      <FooterMainBox>
        <FooterSecondBox>
          <FooterLinkMainBox>
            <LinkBox>
              <Box
                onClick={() => setOpenDisclaimerModel(true)}
                sx={{ cursor: 'pointer' }}
              >
                <FooterDisclaimerTypography>
                  Disclaimer
                </FooterDisclaimerTypography>
              </Box>
              <FooterLink href={COOKIE_POLICY_URL}>Cookie Policy</FooterLink>
              <FooterLink href={PRIVACY_POLICY_URL}>Privacy Policy</FooterLink>
              <FooterLink href={TERMS_AND_CONDITION_URL}>
                Terms and Conditions
              </FooterLink>
            </LinkBox>
            <Typography
              variant="subtitle1"
              color={theme.palette.grey[400]}
              dangerouslySetInnerHTML={{ __html: COPY_RIGHT_TEXT }}
            />
          </FooterLinkMainBox>
          <SubscribeForm />
          <FooterSeparator />
          <FooterSocialIconBox>
            <Box
              display="flex"
              pb={isHybrid ? `${MOBILE_APP_HEIGHT - 10}px` : '0px'}
            >
              <ImgIcon
                icon="/new/images/icons/facebook.svg"
                alt="facebook"
                wrapperStyle={{
                  backgroundColor: theme.palette.primary.light,
                  marginRight: isSmallScreen ? '16px' : '8px',
                }}
                onClick={() => window.open(FACEBOOK_PAGE_URL, '_target')}
              />
              <ImgIcon
                icon="/new/images/icons/instagram.svg"
                alt="instagram"
                wrapperStyle={{ backgroundColor: theme.palette.primary.light }}
                onClick={() => window.open(INSTAGRAM_PAGE_URL, '_target')}
              />
            </Box>
          </FooterSocialIconBox>
          <MuiBackdrop open={isLoading}>
            <CircularProgress color="inherit" />
          </MuiBackdrop>
        </FooterSecondBox>
        <MuiSnackbar open={openServer} onClose={setOpenServer}>
          <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
            {message}
          </MuiAlert>
        </MuiSnackbar>
      </FooterMainBox>
      <FooterDisclaimerModel
        open={openDisclaimerModel}
        handleClose={() => setOpenDisclaimerModel(false)}
      />
    </>
  )
}

export default Footer
