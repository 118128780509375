import {
  Autocomplete,
  Box,
  InputLabel,
  TextField,
  useTheme,
} from '@mui/material';
import styled from 'styled-components';

export const OnboardLocateYourRestaurantContainer = styled('div').attrs(
  () => ({})
)(({ theme }) => ({
  paddingTop: '40px',
}));

export const OnboardLocateYourRestaurantInputLabel = styled(InputLabel).attrs(
  () => ({
    shrink: true,
    sx: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
  })
)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.secondary[800],
  };
});

export const OnboardLocateYourRestaurantAutocomplete = styled(
  Autocomplete
).attrs(() => ({
  size: 'small',
  fullWidth: true,
  autoHighlight: true,
}))(() => {
  const theme = useTheme();
  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[300],
      },
    },
  };
});

export const OnboardLocateYourRestaurantRenderOption = styled(Box).attrs(
  () => ({
    component: 'li',
    sx: { '& > img': { mr: 2, flexShrink: 0 } },
  })
)(({ theme }) => ({}));

export const OnboardLocateYourRestaurantTextField = styled(TextField).attrs(
  () => ({
    size: 'small',
    fullWidth: true,
    autoHighlight: true,
    required: true,
    variant: 'outlined',
  })
)(() => {
  const theme = useTheme();
  return {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[300],
      },
    },
    color: theme.palette.secondary[300],
  };
});
