/* eslint-disable no-console */
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'

const deg2rad = (deg: number): any => {
  return deg * (Math.PI / 180)
}

export interface Location {
  longitude: number
  latitude: number
}

// Define default coordinates
export const defaultLocationCoords: Location = {
  latitude: 40.73061,
  longitude: -73.935242,
}

export const getLocationLocalStorage = (): Location => {
  const location = window.localStorage.getItem('location')
  return location ? JSON.parse(location) : defaultLocationCoords
}

export const getGoogleMapsLocation = async (): Promise<
  Location | undefined
> => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ''
  if (!apiKey) {
    console.error('Google Maps API key is missing')
    return undefined
  }

  try {
    const response = await fetch(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`,
      {
        method: 'POST',
      },
    )

    if (response.ok) {
      const data = await response.json()
      const location = data.location
      return {
        latitude: location.lat,
        longitude: location.lng,
      }
    } else {
      console.error(
        'Failed to fetch location:',
        response.status,
        response.statusText,
      )
      return undefined
    }
  } catch (error) {
    console.error('Error while fetching Google Maps location:', error)
    return undefined
  }
}

// Main function to determine environment and get location
export const getLocation = async (): Promise<Location> => {
  if (Capacitor.isNative) {
    // Use Capacitor Geolocation for mobile environments
    return await getMobileLocation()
  } else {
    // Use web Geolocation API for web environments
    return await getWebLocation()
  }
}

// Function to get location using the web Geolocation API
const getWebLocation = async (): Promise<Location> => {
  if (navigator.geolocation) {
    try {
      // Promise to handle the geolocation request
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error('Timeout'))
          }, 10000)

          navigator.geolocation.getCurrentPosition(
            (pos) => {
              clearTimeout(timeoutId)
              resolve(pos)
            },
            (err) => {
              clearTimeout(timeoutId)
              reject(err)
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            },
          )
        },
      )

      // Return coordinates from the position object
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
    } catch (error) {
      return await handleError(error)
    }
  } else {
    console.error('Geolocation is not supported by this browser.')
    return await handleError('Geolocation is not supported.')
  }
}

// Function to get location using the Capacitor Geolocation API
const getMobileLocation = async (): Promise<Location> => {
  try {
    // Retrieve the current position using Capacitor Geolocation API
    const coordinates = await Geolocation.getCurrentPosition({
      timeout: 5000,
      enableHighAccuracy: true,
    })

    // Return coordinates from the Capacitor API response
    return {
      latitude: coordinates.coords.latitude,
      longitude: coordinates.coords.longitude,
    }
  } catch (error) {
    return await handleError(error)
  }
}

// Function to handle errors and provide default location
const handleError = async (error: any): Promise<Location> => {
  console.error("Error while getting user's location:", error)
  // Fallback to Google Maps location if available or default to NYC coordinates
  return (await getGoogleMapsLocation()) ?? defaultLocationCoords
}

export const getDistanceFromLatLonInKm = (
  location1: Location,
  location2: Location,
): any => {
  const lat1 = location1.latitude
  const lon1 = location1.longitude
  const lat2 = location2.latitude
  const lon2 = location2.longitude

  const R = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

// export const getLocation = async (): Promise<Location | undefined> => {
//   const newYorkCityCoords = defaultLocationCoords

//   if (navigator.geolocation !== null && navigator.geolocation !== undefined) {
//     try {
//       const position = await new Promise<GeolocationPosition>(
//         (resolve, reject) => {
//           const timeoutId = setTimeout(() => {
//             reject(new Error('Timeout'))
//           }, 1000)

//           navigator.geolocation.getCurrentPosition(
//             (pos) => {
//               clearTimeout(timeoutId)
//               resolve(pos)
//             },
//             (err) => {
//               clearTimeout(timeoutId)
//               reject(err)
//             },
//             {
//               enableHighAccuracy: true,
//               timeout: 5000,
//               maximumAge: 0,
//             },
//           )
//         },
//       )

//       const latitude = position.coords.latitude
//       const longitude = position.coords.longitude

//       return { latitude, longitude }
//     } catch (error) {
//       debugLog("Error while getting user's location:", error)
//       return (await getGoogleMapsLocation()) ?? newYorkCityCoords
//     }
//   } else {
//     debugLog('Geolocation is not supported by this browser.')
//     return (await getGoogleMapsLocation()) ?? newYorkCityCoords
//   }
// }
