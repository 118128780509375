import { Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store'
import { selectRestaurantDetail } from '../../../../store/restaurantSlice'
import {
  AboutTabContactInfoMuiContainer,
  AboutTabContactInfoTypography,
  AboutTabRestaurantTypography,
} from '../styled/AboutTab.styled'

const AboutTab: React.FC = () => {
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO

  const [website, setWebsite] = useState('')

  const contactInfo = [
    restaurant?.phone && {
      label: 'Phone Number',
      value: restaurant.phone,
    },
    restaurant?.contact_email && {
      label: 'Email',
      value: `mailto:${restaurant.contact_email}`,
    },
    restaurant?.website && {
      label: 'Website',
      value: website,
    },
    restaurant?.instagram && {
      label: 'Instagram',
      value: restaurant.instagram,
    },
    restaurant?.facebook && {
      label: 'Facebook',
      value: restaurant.facebook,
    },
  ].filter(Boolean) as Array<{ label: string; value: string; target?: string }>

  useEffect(() => {
    setWebsite(normalizeUrl(restaurant?.website ?? ''))
  }, [])

  const normalizeUrl = (url: string): string => {
    let normalized = url.replace(/^https?:\/\//, '').replace(/\/.*$/, '')
    if (!normalized.startsWith('www.')) {
      normalized = 'www.' + normalized
    }
    normalized = 'http://' + normalized

    return normalized
  }

  return (
    <div>
      {restaurant?.description && (
        <AboutTabRestaurantTypography>
          {restaurant.description}
        </AboutTabRestaurantTypography>
      )}
      <AboutTabContactInfoMuiContainer>
        {contactInfo.map((info, index) => (
          <div key={index}>
            <AboutTabContactInfoTypography>
              {info?.label}
            </AboutTabContactInfoTypography>
            {info?.label !== 'Phone Number' ? (
              <Link
                variant="body2"
                href={info?.value?.replace(/^(tel:|mailto:)/, '')}
                fontWeight={500}
                color="blue"
                underline="hover"
                target="_blank"
                sx={{
                  cursor: 'pointer',
                }}
              >
                {info?.value?.replace(/^(tel:|mailto:)/, '')}
              </Link>
            ) : (
              <Link
                variant="body2"
                href={`tel:${info?.value}`}
                fontWeight={500}
                color="blue"
                underline="hover"
                target="_self"
                sx={{
                  cursor: 'pointer',
                }}
              >
                {info?.value}
              </Link>
            )}
          </div>
        ))}
      </AboutTabContactInfoMuiContainer>
    </div>
  )
}

export default AboutTab
