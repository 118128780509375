import { useMediaQuery, useTheme } from '@mui/material'
import Step from '@mui/material/Step'
import * as React from 'react'
import { IStepper } from '../../../types'
import StepperIcon from './StepperIcon'
import {
  ColorLibConnector,
  OnboardingContainer,
  OnboardingStepLabel,
  OnboardingStepper,
  OnboardingTypography,
} from './styled/OnboardSteppar.styled'

interface OnboardStepperProps {
  activeStep: number
  steps: IStepper[]
}

const OnboardStepper: React.FC<OnboardStepperProps> = ({
  activeStep,
  steps,
}): JSX.Element => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  
  return (
    <OnboardingContainer>
      <OnboardingStepper
        orientation={isSmallScreen ? 'horizontal' : 'vertical'}
        activeStep={activeStep}
        connector={<ColorLibConnector />}
      >
        {steps.map((step: IStepper, index) => (
          <Step
            key={step.title}
            style={{
              paddingLeft: isSmallScreen ? 0 : 8,
              paddingRight: isSmallScreen ? 0 : 8,
            }}
          >
            <OnboardingStepLabel
              StepIconComponent={(props) => (
                <StepperIcon
                  {...props}
                  activeStepCount={activeStep}
                  step={step.step}
                />
              )}
            >
              {!isSmallScreen && (
                <OnboardingTypography
                  color={
                    step.step === activeStep
                      ? theme.palette.background.paper
                      : step.step < activeStep
                      ? theme.palette.background.paper
                      : theme.palette.grey[400]
                  }
                >
                  {step.title}
                </OnboardingTypography>
              )}
            </OnboardingStepLabel>
          </Step>
        ))}
      </OnboardingStepper>
    </OnboardingContainer>
  )
}
OnboardStepper.displayName = 'OnboardStepper'

export default OnboardStepper
