import {
  Box,
  Button,
  Popover,
  Slider,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import React, { useState } from 'react'
import {
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
  WEB_NAV_HEIGHT,
} from '../../helpers/static-constants'
import { useDevice } from '../../hooks/useDevice'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectRadius, setRadius } from '../../store/restaurantSlice'

const marks = [
  {
    value: 5,
    label: 'Under 5 mi',
  },
  {
    value: 30,
    label: 'Under 30 mi',
  },
  {
    value: 25,
    label: 'Under 25 mi',
  },
  {
    value: 20,
    label: 'Under 20 mi',
  },
  {
    value: 15,
    label: 'Under 15 mi',
  },
  {
    value: 10,
    label: 'Under 10 mi',
  },
]

const Range: React.FC = () => {
  const theme = useTheme()
  const rang = useAppSelector(selectRadius)
  const dispatch = useAppDispatch()
  const { isHybrid } = useDevice()

  const [value, setValue] = useState<number>(rang)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  const handleSliderChange = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[],
  ): void => {
    setValue(newValue as number)
    dispatch(setRadius(newValue as number))
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    let value = event.target.value === '' ? 0 : Number(event.target.value)

    if (value < 0) {
      value = 0
    } else if (value > 30) {
      value = 30
    }

    setValue(value)
    dispatch(setRadius(value))
  }

  const handleBlur = (): void => {
    if (value < 2) {
      setValue(2)
    } else if (value > 30) {
      setValue(30)
    }
  }

  return (
    <>
      <Button
        aria-describedby={id}
        sx={{
          paddingX: '16px',
          height: '100%',
          width: { xs: '200px', md: '140px' },
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={handleClick}
      >
        <Typography
          noWrap
          component="p"
          variant="subtitle1"
          color={theme.palette.grey[400]}
        >
          Set Range
        </Typography>
        <IconChevronDown
          color={theme.palette.grey[400]}
          style={{ marginLeft: '15px' }}
          stroke={2}
          size={16}
        />
      </Button>
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            top: {
              xs: `${
                isHybrid ? 58 : MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT + 8
              }px !important`,
              md: `${4 + WEB_NAV_HEIGHT}px !important`,
            },
          },
        }}
      >
        <Box
          sx={{
            width: { xs: '160px', md: '150px' },
            padding: 1,
          }}
        >
          <Box
            sx={{
              marginBottom: '16px',
            }}
          >
            <TextField
              value={value}
              onChange={handleInputChange}
              onBlur={handleBlur}
              type="number"
              inputProps={{
                min: 5,
                max: 30,
              }}
              placeholder="Distance"
              InputProps={{
                endAdornment: <Typography variant="caption">mi</Typography>,
              }}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  fontSize: '16px',
                  fontWeight: 500,
                  '& fieldset': {
                    borderColor: theme.palette.grey[300],
                    borderRadius: '8px',
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.grey[300],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.grey[300],
                  },
                },
              }}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box sx={{ height: 200, display: 'flex', justifyContent: 'end' }}>
            <Slider
              orientation="vertical"
              value={typeof value === 'number' ? value : 0}
              onChangeCommitted={handleSliderChange}
              aria-label="Default"
              sx={{
                height: '92% !important',
                width: '8px',
                margin: '0px',
                '& .MuiSlider-root': {
                  color: theme.palette.primary.light,
                },
                '& .MuiSlider-mark': {
                  width: '0',
                },
                '& .MuiSlider-markLabel': {
                  fontSize: {
                    sm: '12px',
                    md: '14px',
                  },
                  fontWeight: 500,
                  left: '-95px',
                  color: theme.palette.grey[800],
                },
                '& .MuiSlider-markLabelActive': {
                  color: theme.palette.secondary.dark,
                  fontWeight: 600,
                },
                '& .MuiSlider-thumb': {
                  width: 24,
                  height: 24,
                  backgroundColor: theme.palette.background.paper,
                  border: `5px solid ${theme.palette.primary.light}`,
                  '&::before': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                  },
                  '&:hover, &.Mui-focusVisible, &.Mui-active': {
                    boxShadow: 'none',
                  },
                },
              }}
              min={5}
              max={30}
              marks={marks}
            />
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default Range
