import React from 'react'
import {
  RestaurantCardRoundedSkeletonContainer,
  RestaurantCardSkeletonContainer,
  RestaurantCardSkeletonInnerContainer,
  RestaurantCardTextSkeletonContainer60,
  RestaurantCardTextSkeletonContainer80,
} from '../styled/skeleton/RestaurantCardSkeleton.styled'

const RestaurantCardSkeleton: React.FC = () => {
  return (
    <RestaurantCardSkeletonContainer>
      <RestaurantCardRoundedSkeletonContainer />
      <RestaurantCardSkeletonInnerContainer>
        <RestaurantCardTextSkeletonContainer60 />
        <RestaurantCardTextSkeletonContainer80 />
      </RestaurantCardSkeletonInnerContainer>
    </RestaurantCardSkeletonContainer>
  )
}

export default RestaurantCardSkeleton
