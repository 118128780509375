import { AlertColor } from '@mui/material'
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { RootState, store } from '.'
import okaoAxios from '../helpers/axios'
import { PredictedRestaurantGroupRequest, RestaurantResponse } from '../types'
import {
  AcceptDeclineRequest,
  AddRemoveGroupRequest,
  Group,
  GroupRequest,
  UpdateGroup,
} from '../types/groupTypes'

export interface IProfileSlice {
  status: string
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  groups: Group[]
  group: Group
  groupRequests: GroupRequest[]
  page: number
  pageSize: number
  groupPredictedItems?: RestaurantResponse[]
  totalPages: number
  hasMore: boolean
}

const initialState: IProfileSlice = {
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  groups: [],
  group: {},
  groupRequests: [],
  page: 0,
  pageSize: 10,
  groupPredictedItems: [],
  totalPages: 1,
  hasMore: false,
}

export const fetchGroups = createAsyncThunk(
  'api/fetchGroups',
  async (page: number) => {
    const response = await okaoAxios.get(
      `/groups/${page}?size=${store.getState().social.pageSize}`,
    )
    return response?.data
  },
)

export const createGroup = createAsyncThunk(
  'api/createGroup',
  async (group: Group | undefined) => {
    const response = await okaoAxios.post(`/groups`, group)
    return response?.data
  },
)

export const deleteGroup = createAsyncThunk(
  'api/deleteGroup',
  async (groupId: string) => {
    const response = await okaoAxios.delete(
      `/groups/delete-user-group/${groupId}`,
    )
    return response?.data
  },
)

export const getGroupPredictions = createAsyncThunk(
  'api/getGroupPredictions',
  async (payload: PredictedRestaurantGroupRequest) => {
    const response = await okaoAxios.post(
      `/restaurants/group-predict/${payload.page}`,
      payload,
    )
    return response?.data
  },
)
export const updateGroup = createAsyncThunk(
  'api/updateGroup',
  async (payload: UpdateGroup) => {
    const response = await okaoAxios.put(`/groups`, payload)
    return response?.data
  },
)

export const fetchGroupById = createAsyncThunk(
  'api/fetchGroupById',
  async (id: string) => {
    const response = await okaoAxios.get(`/groups/${id}/detail`)
    return response?.data
  },
)

export const addToGroup = createAsyncThunk(
  'api/addToGroup',
  async (group: AddRemoveGroupRequest) => {
    const response = await okaoAxios.post(
      `/groups/${group?.groupId}/add`,
      group.member,
    )
    return response?.data
  },
)

export const acceptInvitation = createAsyncThunk(
  'api/acceptInvitation',
  async (group: AcceptDeclineRequest) => {
    const response = await okaoAxios.post(`/groups/accept`, group)
    return response?.data
  },
)
export const declineInvitation = createAsyncThunk(
  'api/declineInvitation',
  async (group: AcceptDeclineRequest) => {
    const response = await okaoAxios.post(`/groups/reject`, group)
    return response?.data
  },
)

export const removeFromGroup = createAsyncThunk(
  'api/removeFromGroup',
  async (group: AddRemoveGroupRequest) => {
    const response = await okaoAxios.post(
      `/groups/${group?.groupId}/remove`,
      group.member,
    )
    return response?.data
  },
)

export const socialSlice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setGroup: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.fulfilled, (state, action) => {
        if (action.payload.page === 0) {
          state.groups = action.payload.groups
          state.groupRequests = action.payload.requests
        } else {
          if (state.page < action.payload.page) {
            state.groups = [...state.groups, ...action.payload.groups]
            state.groupRequests = [
              ...state.groupRequests,
              ...action.payload.requests,
            ]
          }
        }

        state.totalPages = Math.ceil(action.payload.count / state.pageSize)
        state.page = action.payload.page
        state.status = 'idle'
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(fetchGroupById.fulfilled, (state, action) => {
        state.group = action.payload
        state.status = 'idle'
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        const findIndex = state.groups.findIndex(
          (g) => g.id === action.payload.id,
        )
        if (findIndex >= 0) {
          state.groups[findIndex] = action.payload
        }
        state.status = 'idle'
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.groups.unshift(action.payload)
        state.status = 'idle'
      })
      .addCase(getGroupPredictions.fulfilled, (state, action) => {
        state.status = 'idle'
        state.groupPredictedItems = action.payload.result
      })
      .addCase(addToGroup.fulfilled, (state, action) => {
        state.groups = state.groups?.filter(
          (group) => group.id !== action.payload.id,
        )
        state.groups?.push(action.payload)
        state.status = 'idle'
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        state.groups?.unshift(action.payload)
        state.groupRequests = state.groupRequests?.filter(
          (groupRequest) => groupRequest.groupId !== action.payload.id,
        )
        state.status = 'idle'
      })
      .addCase(declineInvitation.fulfilled, (state, action) => {
        state.groupRequests = state.groupRequests?.filter(
          (groupRequest) => groupRequest.groupId !== action.payload.id,
        )
        state.status = 'idle'
      })
      .addCase(removeFromGroup.fulfilled, (state, action) => {
        state.groups = state.groups?.filter(
          (group) => group.id !== action.payload.id,
        )
        state.groups?.push(action.payload)
        state.status = 'idle'
      })
      .addMatcher(
        isAnyOf(
          fetchGroups.rejected,
          deleteGroup.rejected,
          getGroupPredictions.rejected,
          createGroup.rejected,
          declineInvitation.rejected,
          acceptInvitation.rejected,
          removeFromGroup.rejected,
          addToGroup.rejected,
          updateGroup.rejected,
          fetchGroupById.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.message =
            'Oops! We had a little trouble working with your groups. Could you please try refreshing the page? Thanks for your understanding!'
          state.status = 'error'
          state.severity = 'error'
        },
      )
      .addMatcher(
        isAnyOf(
          fetchGroups.pending,
          deleteGroup.pending,
          getGroupPredictions.pending,
          addToGroup.pending,
          updateGroup.pending,
          fetchGroupById.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectIsLoading = (state: RootState): boolean =>
  state.social.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state.social.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.social.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.social.severity
export const selectGroups = (state: RootState): Group[] => state.social.groups
export const selectGroup = (state: RootState): Group => state.social.group
export const selectGroupRequests = (state: RootState): GroupRequest[] =>
  state.social.groupRequests
export const selectPredictedGroupRestaurants = (
  state: RootState,
): RestaurantResponse[] => state.social.groupPredictedItems ?? []
export const selectTotalPages = (state: RootState): number =>
  state.social.totalPages
export const selectPage = (state: RootState): number => state.social.page
export const selectHasMore = (state: RootState): boolean => state.social.hasMore

export const { setOpen, setGroup, clearData } = socialSlice.actions

export default socialSlice.reducer
