export default [
  {
    "alias": "1-or-8-brooklyn"
  },
  {
    "alias": "116-crown-new-haven"
  },
  {
    "alias": "12-chairs-new-york"
  },
  {
    "alias": "1789-restaurant-and-bar-washington"
  },
  {
    "alias": "2-hopewell-south-glastonbury"
  },
  {
    "alias": "21-greenpoint-brooklyn-2"
  },
  {
    "alias": "212-steakhouse-new-york-5"
  },
  {
    "alias": "3-giovani-new-york"
  },
  {
    "alias": "3-sheets-new-york"
  },
  {
    "alias": "4-charles-prime-rib-new-york"
  },
  {
    "alias": "44-and-x-hells-kitchen-new-york-5"
  },
  {
    "alias": "5-o-clock-somewhere-bar-times-square-new-york-2"
  },
  {
    "alias": "512-quantum-sound-jersey-city"
  },
  {
    "alias": "53-nyc-new-york"
  },
  {
    "alias": "5a5-steak-lounge-san-francisco-4"
  },
  {
    "alias": "5ive-spice-new-york-2"
  },
  {
    "alias": "601-miami-6"
  },
  {
    "alias": "83-1-2-new-york"
  },
  {
    "alias": "99-favor-taste-new-york-new-york"
  },
  {
    "alias": "a-fish-called-avalon-miami-beach-2"
  },
  {
    "alias": "a-pasta-bar-new-york"
  },
  {
    "alias": "a-plus-thai-place-new-york"
  },
  {
    "alias": "a-salt-and-battery-new-york"
  },
  {
    "alias": "a1-asian-fusion-new-york-7"
  },
  {
    "alias": "a16-san-francisco-4"
  },
  {
    "alias": "aarons-chinese-and-thai-new-york"
  },
  {
    "alias": "abacky-fusion-cuisine-new-york-2"
  },
  {
    "alias": "abacá-san-francisco-3"
  },
  {
    "alias": "abaya-thai-new-york"
  },
  {
    "alias": "abbalé-telavivian-kitchen-miami-beach"
  },
  {
    "alias": "abc-cocina-new-york"
  },
  {
    "alias": "abc-kitchen-new-york"
  },
  {
    "alias": "abcv-new-york-2"
  },
  {
    "alias": "abrazo-san-francisco-2"
  },
  {
    "alias": "absinthe-brasserie-and-bar-san-francisco-2"
  },
  {
    "alias": "abyssinia-ethiopian-restaurant-new-york"
  },
  {
    "alias": "accra-restaurant-new-york"
  },
  {
    "alias": "acme-new-york"
  },
  {
    "alias": "acquerello-san-francisco"
  },
  {
    "alias": "adams-rib-restaurant-norwalk-2"
  },
  {
    "alias": "adoro-lei-new-york"
  },
  {
    "alias": "adyar-ananda-bhavan-new-york"
  },
  {
    "alias": "africa-kine-restaurant-new-york-2"
  },
  {
    "alias": "agave-new-york"
  },
  {
    "alias": "ageha-sushi-new-york"
  },
  {
    "alias": "agora-dc-washington-8"
  },
  {
    "alias": "ai-fiori-new-york"
  },
  {
    "alias": "ai-ki-ya-new-york-3"
  },
  {
    "alias": "air-restaurant-lounge-washington"
  },
  {
    "alias": "airs-new-york"
  },
  {
    "alias": "aita-brooklyn"
  },
  {
    "alias": "aiyara-thai-new-york"
  },
  {
    "alias": "aji-sushi-new-york-2"
  },
  {
    "alias": "ajisai-japanese-fusion-new-york"
  },
  {
    "alias": "akdeniz-mediterranean-cuisine-new-york"
  },
  {
    "alias": "aki-sushi-new-york"
  },
  {
    "alias": "al-di-la-trattoria-brooklyn"
  },
  {
    "alias": "al-tiramisu-washington"
  },
  {
    "alias": "ala-washington"
  },
  {
    "alias": "alcala-restaurant-new-york"
  },
  {
    "alias": "aldo-sohm-wine-bar-new-york"
  },
  {
    "alias": "alenbi-brooklyn"
  },
  {
    "alias": "alexanders-steakhouse-cupertino-11"
  },
  {
    "alias": "alhambra-washington"
  },
  {
    "alias": "alidoro-bryant-park-new-york"
  },
  {
    "alias": "alison-new-york"
  },
  {
    "alias": "all-stars-sports-bar-and-grill-new-york-5"
  },
  {
    "alias": "allora-ristorante-new-york-2"
  },
  {
    "alias": "alma-cocina-latina-baltimore"
  },
  {
    "alias": "alta-new-york"
  },
  {
    "alias": "altesi-downtown-new-york"
  },
  {
    "alias": "altesi-ristorante-new-york"
  },
  {
    "alias": "altovino-san-francisco"
  },
  {
    "alias": "altro-paradiso-new-york"
  },
  {
    "alias": "amal-miami"
  },
  {
    "alias": "amali-new-york-3"
  },
  {
    "alias": "amandas-restaurant-hoboken-2"
  },
  {
    "alias": "amara-at-paraiso-miami-2"
  },
  {
    "alias": "amaranth-restaurant-new-york"
  },
  {
    "alias": "amarone-kitchen-and-wine-west-hollywood"
  },
  {
    "alias": "amarone-ny-new-york-2"
  },
  {
    "alias": "amarone-scarlatto-new-york"
  },
  {
    "alias": "amata-new-york-2"
  },
  {
    "alias": "amaze-new-york"
  },
  {
    "alias": "ambar-arlington-4"
  },
  {
    "alias": "ambassador-dining-room-baltimore"
  },
  {
    "alias": "amber-steakhouse-brooklyn"
  },
  {
    "alias": "amber-west-village-new-york-93"
  },
  {
    "alias": "ambo-new-york-2"
  },
  {
    "alias": "american-bar-new-york"
  },
  {
    "alias": "american-brass-long-island-city-2"
  },
  {
    "alias": "american-cut-steakhouse-midtown-new-york"
  },
  {
    "alias": "american-cut-steakhouse-tribeca-new-york"
  },
  {
    "alias": "american-retro-new-york"
  },
  {
    "alias": "amity-hall-downtown-new-york"
  },
  {
    "alias": "ammoora-baltimore-2"
  },
  {
    "alias": "ammos-estiatorio-new-york-4"
  },
  {
    "alias": "amor-cubano-new-york"
  },
  {
    "alias": "amor-loco-new-york"
  },
  {
    "alias": "amélie-new-york"
  },
  {
    "alias": "an-japanese-restaurant-san-francisco-2"
  },
  {
    "alias": "ana-bar-and-eatery-new-york"
  },
  {
    "alias": "ananda-new-york-4"
  },
  {
    "alias": "anassa-taverna-nyc-new-york"
  },
  {
    "alias": "andreas-ristorante-italiano-waldwick-5"
  },
  {
    "alias": "anejo-new-york-10"
  },
  {
    "alias": "anfora-new-york"
  },
  {
    "alias": "angeletto-restaurant-new-york"
  },
  {
    "alias": "angelina-paris-new-york-4"
  },
  {
    "alias": "angelinas-pizzeria-napoletana-irvine-2"
  },
  {
    "alias": "anixi-new-york"
  },
  {
    "alias": "annabelle-washington"
  },
  {
    "alias": "annam-new-york-2"
  },
  {
    "alias": "annie-moores-bar-and-restaurant-new-york-2"
  },
  {
    "alias": "anomaly-sf-san-francisco-2"
  },
  {
    "alias": "antica-pesa-brooklyn-3"
  },
  {
    "alias": "antica-ristorante-new-york"
  },
  {
    "alias": "antique-bar-and-bakery-hoboken-2"
  },
  {
    "alias": "antique-garage-tribeca-new-york"
  },
  {
    "alias": "antons-new-york"
  },
  {
    "alias": "antonucci-cafe-new-york"
  },
  {
    "alias": "antoya-korean-bbq-new-york"
  },
  {
    "alias": "anything-at-all-new-york-2"
  },
  {
    "alias": "anytime-kitchen-new-york-2"
  },
  {
    "alias": "aphotic-san-francisco"
  },
  {
    "alias": "aqua-boil-new-york-2"
  },
  {
    "alias": "aquavit-new-york"
  },
  {
    "alias": "archer-and-goat-new-york"
  },
  {
    "alias": "ardesia-new-york"
  },
  {
    "alias": "area-31-miami"
  },
  {
    "alias": "aretskys-patroon-new-york-2"
  },
  {
    "alias": "ari-sushi-new-york"
  },
  {
    "alias": "aria-hells-kitchen-new-york-5"
  },
  {
    "alias": "ariete-miami-2"
  },
  {
    "alias": "arisu-korean-bbq-buena-park-2"
  },
  {
    "alias": "armani-ristorante-new-york"
  },
  {
    "alias": "armonie-new-york"
  },
  {
    "alias": "arno-ristorante-new-york"
  },
  {
    "alias": "aromas-del-peru-coral-gables-coral-gables"
  },
  {
    "alias": "aroqa-new-york"
  },
  {
    "alias": "art-and-soul-washington-2"
  },
  {
    "alias": "arte-restaurant-new-york-2"
  },
  {
    "alias": "article-one-lounge-washington"
  },
  {
    "alias": "artisan-restaurant-west-hartford"
  },
  {
    "alias": "artisan-southport"
  },
  {
    "alias": "arturos-new-york-5"
  },
  {
    "alias": "asa-los-gatos-los-gatos-2"
  },
  {
    "alias": "aska-brooklyn-3"
  },
  {
    "alias": "astra-miami-4"
  },
  {
    "alias": "astro-restaurant-new-york"
  },
  {
    "alias": "at-nine-restaurant-and-bar-new-york"
  },
  {
    "alias": "atami-japanese-fusion-new-york"
  },
  {
    "alias": "atelier-crenn-san-francisco"
  },
  {
    "alias": "atelier-florian-new-haven"
  },
  {
    "alias": "atera-new-york"
  },
  {
    "alias": "atla-new-york"
  },
  {
    "alias": "atlantic-grill-new-york-3"
  },
  {
    "alias": "atoboy-new-york"
  },
  {
    "alias": "atomix-new-york"
  },
  {
    "alias": "atrio-wine-bar-and-restaurant-new-york"
  },
  {
    "alias": "atrium-los-angeles-2"
  },
  {
    "alias": "atti-brooklyn"
  },
  {
    "alias": "au-cheval-new-york-7"
  },
  {
    "alias": "auden-bistro-and-bar-new-york"
  },
  {
    "alias": "august-gatherings-new-york-2"
  },
  {
    "alias": "august-new-york-3"
  },
  {
    "alias": "augustinos-kitchen-and-bar-hoboken-2"
  },
  {
    "alias": "auntie-guans-kitchen-new-york-6"
  },
  {
    "alias": "aura-cocina-brooklyn-2"
  },
  {
    "alias": "aurora-soho-new-york"
  },
  {
    "alias": "avant-garden-new-york"
  },
  {
    "alias": "avena-downtown-new-york"
  },
  {
    "alias": "avert-brasserie-west-hartford-2"
  },
  {
    "alias": "avery-restaurant-san-francisco"
  },
  {
    "alias": "avra-48th-street-new-york"
  },
  {
    "alias": "axia-taverna-tenafly"
  },
  {
    "alias": "aya-sushi-new-york"
  },
  {
    "alias": "ayce-sushi-hb-huntington-beach-3"
  },
  {
    "alias": "ayza-wine-and-chocolate-bar-new-york-6"
  },
  {
    "alias": "azabu-miami-beach-miami-beach-2"
  },
  {
    "alias": "azalea-new-york-2"
  },
  {
    "alias": "azalea-ristorante-new-york"
  },
  {
    "alias": "azuki-japanese-restaurant-new-york"
  },
  {
    "alias": "azumi-baltimore"
  },
  {
    "alias": "b44-catalan-bistro-san-francisco"
  },
  {
    "alias": "baar-baar-new-york-2"
  },
  {
    "alias": "babbo-new-york"
  },
  {
    "alias": "baby-brasa-new-york-4"
  },
  {
    "alias": "bacalls-new-york-2"
  },
  {
    "alias": "bacaro-new-york"
  },
  {
    "alias": "baccarat-boutique-bbar-and-lounge-miami"
  },
  {
    "alias": "back-to-back-san-francisco"
  },
  {
    "alias": "backstage-brasserie-saratoga"
  },
  {
    "alias": "bad-roman-new-york"
  },
  {
    "alias": "baekjeong-new-york-new-york"
  },
  {
    "alias": "baia-beach-club-miami-miami-beach"
  },
  {
    "alias": "baiocco-miami-2"
  },
  {
    "alias": "baires-grill-brickell-miami-3"
  },
  {
    "alias": "baker-street-irregulars-new-york"
  },
  {
    "alias": "balthazar-new-york-2"
  },
  {
    "alias": "balvanera-new-york-3"
  },
  {
    "alias": "balzem-new-york"
  },
  {
    "alias": "bamboo-sushi-new-york-5"
  },
  {
    "alias": "bangia-new-york-2"
  },
  {
    "alias": "banter-new-york"
  },
  {
    "alias": "bao-by-kaya-new-york-4"
  },
  {
    "alias": "baoli-miami-miami-beach-5"
  },
  {
    "alias": "bar-54-new-york-2"
  },
  {
    "alias": "bar-and-bistro-888-san-francisco"
  },
  {
    "alias": "bar-b-new-york"
  },
  {
    "alias": "bar-boulud-new-york-2"
  },
  {
    "alias": "bar-crenn-san-francisco"
  },
  {
    "alias": "bar-crudo-brooklyn-2"
  },
  {
    "alias": "bar-goyana-new-york"
  },
  {
    "alias": "bar-italia-madison-new-york"
  },
  {
    "alias": "bar-jamón-new-york-2"
  },
  {
    "alias": "bar-masa-new-york"
  },
  {
    "alias": "bar-moga-new-york-2"
  },
  {
    "alias": "bar-one-miami-beach-miami-beach-2"
  },
  {
    "alias": "bar-six-new-york"
  },
  {
    "alias": "barano-brooklyn"
  },
  {
    "alias": "barbaresco-restaurant-new-york"
  },
  {
    "alias": "barbetta-new-york"
  },
  {
    "alias": "barbounia-new-york"
  },
  {
    "alias": "barcelona-waypointe-norwalk"
  },
  {
    "alias": "barcelona-wine-bar-cathedral-heights-washington-2"
  },
  {
    "alias": "barcha-san-francisco"
  },
  {
    "alias": "barn-joo-nomad-new-york-3"
  },
  {
    "alias": "barney-brown-new-york"
  },
  {
    "alias": "barolo-east-new-york"
  },
  {
    "alias": "barrio-bar-new-york"
  },
  {
    "alias": "barrow-street-ale-house-new-york"
  },
  {
    "alias": "barton-g-miami-beach-miami-beach"
  },
  {
    "alias": "basil-brooklyn"
  },
  {
    "alias": "basilico-millburn-2"
  },
  {
    "alias": "battello-jersey-city-3"
  },
  {
    "alias": "bazár-tapas-bar-and-restaurant-new-york-9"
  },
  {
    "alias": "bea-new-york"
  },
  {
    "alias": "beatnic-rock-center-new-york-3"
  },
  {
    "alias": "beauty-and-essex-los-angeles"
  },
  {
    "alias": "beauty-and-essex-new-york-city"
  },
  {
    "alias": "becco-new-york-4"
  },
  {
    "alias": "bedford-cheese-shop-new-york"
  },
  {
    "alias": "bedford-falls-new-york-2"
  },
  {
    "alias": "bedford-on-park-new-york"
  },
  {
    "alias": "beetle-house-la-los-angeles"
  },
  {
    "alias": "beetle-house-new-york-3"
  },
  {
    "alias": "bell-book-and-candle-new-york"
  },
  {
    "alias": "bella-blu-new-york"
  },
  {
    "alias": "ben-and-jacks-steakhouse-new-york-5"
  },
  {
    "alias": "benchmark-restaurant-brooklyn"
  },
  {
    "alias": "bengal-tiger-indian-food-new-york"
  },
  {
    "alias": "benihana-bethesda"
  },
  {
    "alias": "benihana-san-francisco"
  },
  {
    "alias": "benihibachi-japanese-grill-los-angeles-4"
  },
  {
    "alias": "benjamin-steakhouse-new-york-city"
  },
  {
    "alias": "benjamin-steakhouse-prime-new-york-4"
  },
  {
    "alias": "benoit-new-york"
  },
  {
    "alias": "bentley-beach-club-miami-beach"
  },
  {
    "alias": "benu-san-francisco-4"
  },
  {
    "alias": "berimbau-brazilian-kitchen-new-york-2"
  },
  {
    "alias": "berlin-currywurst-new-york-3"
  },
  {
    "alias": "bethesda-crab-house-bethesda"
  },
  {
    "alias": "bevri-palo-alto-palo-alto-2"
  },
  {
    "alias": "biagio-osteria-stratford"
  },
  {
    "alias": "bierhaus-nyc-new-york-4"
  },
  {
    "alias": "bin-100-restaurant-milford"
  },
  {
    "alias": "bin-14-hoboken"
  },
  {
    "alias": "bird-dog-palo-alto"
  },
  {
    "alias": "birdland-new-york-2"
  },
  {
    "alias": "birdsong-san-francisco"
  },
  {
    "alias": "biricchino-new-york-2"
  },
  {
    "alias": "bison-and-bourbon-brooklyn"
  },
  {
    "alias": "bistango-new-york-8"
  },
  {
    "alias": "bistro-aracosia-washington"
  },
  {
    "alias": "bistro-cacao-washington-2"
  },
  {
    "alias": "bistro-les-amis-new-york"
  },
  {
    "alias": "bistro-milano-new-york"
  },
  {
    "alias": "bistro-provence-bethesda"
  },
  {
    "alias": "bistro-vendome-new-york"
  },
  {
    "alias": "bistrot-leo-new-york"
  },
  {
    "alias": "bistrot-lepic-and-wine-bar-washington"
  },
  {
    "alias": "bix-san-francisco"
  },
  {
    "alias": "black-walnut-brooklyn-2"
  },
  {
    "alias": "blackbarn-new-york"
  },
  {
    "alias": "blacks-bar-and-kitchen-bethesda"
  },
  {
    "alias": "blacksalt-washington-2"
  },
  {
    "alias": "blackstones-grille-southport"
  },
  {
    "alias": "blackwells-pub-and-restaurant-new-york"
  },
  {
    "alias": "blade-miami-beach-2"
  },
  {
    "alias": "blaggard-restaurant-manhattan"
  },
  {
    "alias": "blarney-stone-new-york-3"
  },
  {
    "alias": "blind-tiger-ale-house-new-york"
  },
  {
    "alias": "blt-steak-washington-2"
  },
  {
    "alias": "blue-duck-tavern-washington"
  },
  {
    "alias": "blue-fin-new-york"
  },
  {
    "alias": "blue-haven-new-york"
  },
  {
    "alias": "blue-hill-new-york"
  },
  {
    "alias": "blue-maiz-new-york"
  },
  {
    "alias": "blue-martini-lounge-miami"
  },
  {
    "alias": "blue-note-new-york-new-york-5"
  },
  {
    "alias": "blue-ribbon-brasserie-soho-new-york"
  },
  {
    "alias": "blue-ribbon-sushi-bar-and-grill-columbus-circle-new-york-3"
  },
  {
    "alias": "blue-ribbon-sushi-bar-rock-center-new-york-3"
  },
  {
    "alias": "blue-ribbon-sushi-new-york"
  },
  {
    "alias": "blue-seafood-bar-new-york"
  },
  {
    "alias": "blume-hütte-new-york"
  },
  {
    "alias": "blvd-bistro-new-york"
  },
  {
    "alias": "bobby-vans-grill-washington-2"
  },
  {
    "alias": "bobby-vans-steakhouse-new-york-10"
  },
  {
    "alias": "bobby-vans-steakhouse-new-york-11"
  },
  {
    "alias": "bobby-vans-steakhouse-new-york-12"
  },
  {
    "alias": "bobby-vans-steakhouse-new-york-13"
  },
  {
    "alias": "bobby-vans-steakhouse-washington"
  },
  {
    "alias": "bobo-new-york"
  },
  {
    "alias": "bobos-steakhouse-san-francisco-2"
  },
  {
    "alias": "boca-oyster-bar-bridgeport"
  },
  {
    "alias": "bocca-di-bacco-hells-kitchen-new-york"
  },
  {
    "alias": "bocca-di-bacco-new-york-12"
  },
  {
    "alias": "bodega-malbec-toluca-lake"
  },
  {
    "alias": "bodegon-de-brickell-miami"
  },
  {
    "alias": "boia-de-miami"
  },
  {
    "alias": "bombay-bistro-new-york-2"
  },
  {
    "alias": "bombay-grill-house-new-york-3"
  },
  {
    "alias": "bond-45-new-york-2"
  },
  {
    "alias": "bonda-restaurant-fairfield"
  },
  {
    "alias": "bondi-new-york-2"
  },
  {
    "alias": "bondst-new-york-2"
  },
  {
    "alias": "bonjour-crepes-and-wine-new-york-2"
  },
  {
    "alias": "bonnies-brooklyn"
  },
  {
    "alias": "bonsaii-tapas-and-wine-bar-new-york-2"
  },
  {
    "alias": "bonsignour-new-york"
  },
  {
    "alias": "boqueria-dupont-washington"
  },
  {
    "alias": "boqueria-flatiron-new-york-4"
  },
  {
    "alias": "boqueria-new-york-16"
  },
  {
    "alias": "boqueria-soho-new-york-2"
  },
  {
    "alias": "boston-market-newington"
  },
  {
    "alias": "bota-tapas-and-paella-bar-san-francisco-3"
  },
  {
    "alias": "bottega-restaurant-new-york"
  },
  {
    "alias": "bottino-new-york"
  },
  {
    "alias": "bouche-san-francisco"
  },
  {
    "alias": "boucherie-union-square-new-york-2"
  },
  {
    "alias": "boucherie-west-village-new-york-3"
  },
  {
    "alias": "boulud-sud-miami-miami-2"
  },
  {
    "alias": "boulud-sud-new-york"
  },
  {
    "alias": "bourbon-steak-washington-dc-washington"
  },
  {
    "alias": "boutros-brooklyn"
  },
  {
    "alias": "bowery-meat-company-new-york"
  },
  {
    "alias": "bowery-road-new-york-3"
  },
  {
    "alias": "boxwood-at-the-london-west-hollywood"
  },
  {
    "alias": "brandy-library-new-york"
  },
  {
    "alias": "brasserie-beck-washington-3"
  },
  {
    "alias": "brasserie-memere-closter-2"
  },
  {
    "alias": "brazen-head-san-francisco"
  },
  {
    "alias": "bread-n-wine-new-york"
  },
  {
    "alias": "bresca-washington"
  },
  {
    "alias": "briciola-new-york"
  },
  {
    "alias": "bricolage-brooklyn"
  },
  {
    "alias": "brine-new-york"
  },
  {
    "alias": "brisas-del-mar-new-york"
  },
  {
    "alias": "british-bankers-club-menlo-park-4"
  },
  {
    "alias": "brooklyn-chop-house-new-york-3"
  },
  {
    "alias": "brooklyn-chop-house-time-square-new-york"
  },
  {
    "alias": "browne-and-co-darien-4"
  },
  {
    "alias": "bruculino-restaurant-norwalk"
  },
  {
    "alias": "bryant-park-grill-new-york-3"
  },
  {
    "alias": "bubo-new-york"
  },
  {
    "alias": "bucks-fishing-and-camping-washington"
  },
  {
    "alias": "buddakan-new-york"
  },
  {
    "alias": "buena-vista-restaurant-and-bar-new-york"
  },
  {
    "alias": "buenos-aires-restaurant-new-york-2"
  },
  {
    "alias": "bukhara-grill-indian-spice-rave-new-york"
  },
  {
    "alias": "burger-and-lobster-bryant-park-new-york-2"
  },
  {
    "alias": "butcher-and-banker-new-york-3"
  },
  {
    "alias": "butcher-bros-steakhouse-cheshire-2"
  },
  {
    "alias": "butter-midtown-new-york"
  },
  {
    "alias": "byblos-miami-miami-beach"
  },
  {
    "alias": "bâtard-new-york"
  },
  {
    "alias": "cacio-e-pepe-new-york-4"
  },
  {
    "alias": "cafe-carlyle-new-york-4"
  },
  {
    "alias": "cafe-centro-new-york-8"
  },
  {
    "alias": "cafe-cluny-new-york-3"
  },
  {
    "alias": "cafe-d-alsace-new-york-5"
  },
  {
    "alias": "cafe-fiorello-new-york"
  },
  {
    "alias": "cafe-jacqueline-san-francisco"
  },
  {
    "alias": "cafe-los-gauchitos-ii-miami"
  },
  {
    "alias": "cafe-luxembourg-new-york"
  },
  {
    "alias": "cafe-milano-washington"
  },
  {
    "alias": "cafe-monet-millburn"
  },
  {
    "alias": "cafe-sierra-universal-city-2"
  },
  {
    "alias": "caffe-dei-fiori-new-york"
  },
  {
    "alias": "caffe-taci-new-york-2"
  },
  {
    "alias": "caffe-vialetto-coral-gables-2"
  },
  {
    "alias": "café-d-avignon-the-moxy-times-square-new-york-5"
  },
  {
    "alias": "café-luce-new-york"
  },
  {
    "alias": "café-on-one-manhattan"
  },
  {
    "alias": "cagen-new-york-2"
  },
  {
    "alias": "caliente-cab-new-york-2"
  },
  {
    "alias": "calista-greek-seafood-taverna-coral-gables"
  },
  {
    "alias": "calle-dao-bryant-park-new-york"
  },
  {
    "alias": "calle-dao-chelsea-new-york-3"
  },
  {
    "alias": "camino-alto-san-francisco-2"
  },
  {
    "alias": "campagnola-restaurant-new-york"
  },
  {
    "alias": "camper-menlo-park"
  },
  {
    "alias": "campton-place-san-francisco"
  },
  {
    "alias": "canaletto-restaurant-new-york"
  },
  {
    "alias": "cane-fire-grille-miami"
  },
  {
    "alias": "cantina-la-veinte-miami-miami-2"
  },
  {
    "alias": "cantina-taqueria-and-tequila-bar-new-york"
  },
  {
    "alias": "capital-bleu-silver-spring"
  },
  {
    "alias": "capt-loui-new-york"
  },
  {
    "alias": "cara-mia-millburn"
  },
  {
    "alias": "cara-restaurant-los-angeles"
  },
  {
    "alias": "caravaggio-new-york"
  },
  {
    "alias": "carbone-miami-miami-beach"
  },
  {
    "alias": "carbone-new-york"
  },
  {
    "alias": "carbones-prime-rocky-hill-2"
  },
  {
    "alias": "caribbean-starr-restaurant-new-york"
  },
  {
    "alias": "caridad-restaurant-new-york-4"
  },
  {
    "alias": "carla-new-york-2"
  },
  {
    "alias": "carmens-kitchen-new-york"
  },
  {
    "alias": "carmines-italian-restaurant-times-square-new-york-5"
  },
  {
    "alias": "carmines-italian-restaurant-washington-washington-4"
  },
  {
    "alias": "carnaval-new-york-2"
  },
  {
    "alias": "carousel-restaurant-los-angeles"
  },
  {
    "alias": "carroll-place-new-york"
  },
  {
    "alias": "casa-cortez-wallingford"
  },
  {
    "alias": "casa-juancho-miami"
  },
  {
    "alias": "casa-la-femme-new-york-2"
  },
  {
    "alias": "casa-lever-new-york"
  },
  {
    "alias": "casa-madera-west-hollywood-2"
  },
  {
    "alias": "casa-mono-new-york"
  },
  {
    "alias": "casa-ora-brooklyn"
  },
  {
    "alias": "casa-publica-brooklyn-2"
  },
  {
    "alias": "casa-tua-cucina-miami"
  },
  {
    "alias": "casa-tua-miami-beach"
  },
  {
    "alias": "casablanca-on-the-bay-miami"
  },
  {
    "alias": "cascalote-latin-bistro-new-york"
  },
  {
    "alias": "casellula-new-york"
  },
  {
    "alias": "cassava-san-francisco"
  },
  {
    "alias": "cassidys-pub-and-restaurant-new-york"
  },
  {
    "alias": "cast-iron-chef-chop-house-and-oyster-bar-new-haven"
  },
  {
    "alias": "castell-rooftop-lounge-new-york"
  },
  {
    "alias": "catch-nyc-new-york"
  },
  {
    "alias": "catch-steak-new-york"
  },
  {
    "alias": "cathédrale-restaurant-new-york-city"
  },
  {
    "alias": "cava-restaurant-southington"
  },
  {
    "alias": "cavatina-west-hollywood-2"
  },
  {
    "alias": "caviar-russe-miami"
  },
  {
    "alias": "caviar-russe-new-york"
  },
  {
    "alias": "cecconis-dumbo-brooklyn"
  },
  {
    "alias": "cecconis-miami-beach-miami-beach-4"
  },
  {
    "alias": "celestine-brooklyn"
  },
  {
    "alias": "cellini-restaurant-new-york"
  },
  {
    "alias": "central-market-new-york-new-york-5"
  },
  {
    "alias": "central-michel-richard-washington"
  },
  {
    "alias": "centrolina-washington"
  },
  {
    "alias": "cervos-new-york"
  },
  {
    "alias": "chai-new-york-7"
  },
  {
    "alias": "chai-thai-kitchen-new-york-10"
  },
  {
    "alias": "chama-mama-chelsea-new-york"
  },
  {
    "alias": "chamoun-s-way-new-york-6"
  },
  {
    "alias": "chanson-le-salon-new-york-2"
  },
  {
    "alias": "charleston-baltimore"
  },
  {
    "alias": "charlie-bird-new-york"
  },
  {
    "alias": "charlie-palmer-at-the-knick-new-york-2"
  },
  {
    "alias": "charlie-palmer-steak-dc-washington"
  },
  {
    "alias": "charlie-palmer-steak-new-york-new-york"
  },
  {
    "alias": "chart-house-weehawken"
  },
  {
    "alias": "chateau-49-bar-a-vin-new-york"
  },
  {
    "alias": "chazz-palminteri-italian-restaurant-new-york-3"
  },
  {
    "alias": "chazz-palminteri-italian-restaurant-new-york-4"
  },
  {
    "alias": "chazz-palminteri-italian-restaurant-new-york-new-york"
  },
  {
    "alias": "che-fico-alimentari-san-francisco"
  },
  {
    "alias": "che-fico-san-francisco-3"
  },
  {
    "alias": "chef-pho-and-peking-roast-duck-new-york"
  },
  {
    "alias": "chefs-table-at-brooklyn-fare-new-york"
  },
  {
    "alias": "chelsea-ristorante-new-york"
  },
  {
    "alias": "cheltons-bar-and-grill-manhattan"
  },
  {
    "alias": "chez-billy-sud-washington"
  },
  {
    "alias": "chez-gaston-miami"
  },
  {
    "alias": "chez-josephine-new-york"
  },
  {
    "alias": "chez-maty-et-sokhna-harlem"
  },
  {
    "alias": "chez-moi-brooklyn"
  },
  {
    "alias": "chez-napoleon-new-york"
  },
  {
    "alias": "chez-philippe-los-gatos-2"
  },
  {
    "alias": "chi-spacca-los-angeles"
  },
  {
    "alias": "chickpea-manhattan-8"
  },
  {
    "alias": "chika-and-sake-san-francisco"
  },
  {
    "alias": "china-chilcano-washington-2"
  },
  {
    "alias": "chinese-tuxedo-new-york-2"
  },
  {
    "alias": "chirp-new-york-3"
  },
  {
    "alias": "cho-dang-gol-korean-restaurant-new-york"
  },
  {
    "alias": "chocolat-restaurant-and-bar-new-york-3"
  },
  {
    "alias": "chola-new-york-3"
  },
  {
    "alias": "chophouse-and-brewery-washington-4"
  },
  {
    "alias": "chotto-matte-miami-miami-beach"
  },
  {
    "alias": "chow-house-new-york"
  },
  {
    "alias": "christys-coral-gables"
  },
  {
    "alias": "chu-ros-thai-new-york"
  },
  {
    "alias": "chungdam-santa-clara"
  },
  {
    "alias": "churrascaria-plataforma-new-york-3"
  },
  {
    "alias": "ci-siamo-new-york-5"
  },
  {
    "alias": "ciccio-new-york-11"
  },
  {
    "alias": "cinghiale-baltimore-3"
  },
  {
    "alias": "cipriani-dolci-new-york"
  },
  {
    "alias": "cipriani-downtown-miami-miami-6"
  },
  {
    "alias": "cipriani-downtown-new-york"
  },
  {
    "alias": "cipriani-new-york-2"
  },
  {
    "alias": "cipriani-wall-street-new-york-2"
  },
  {
    "alias": "cir-lounge-fountain-valley"
  },
  {
    "alias": "citron-baltimore-2"
  },
  {
    "alias": "city-vineyard-new-york-2"
  },
  {
    "alias": "cjs-crab-shack-miami-beach"
  },
  {
    "alias": "claudette-new-york"
  },
  {
    "alias": "claw-daddys-new-york"
  },
  {
    "alias": "clay-new-york-3"
  },
  {
    "alias": "clement-new-york-13"
  },
  {
    "alias": "closter-number-one-chinese-restaurant-closter-2"
  },
  {
    "alias": "clove-indian-restaurant-and-bar-new-york"
  },
  {
    "alias": "clover-club-brooklyn"
  },
  {
    "alias": "club-19-restaurant-southington"
  },
  {
    "alias": "club-a-steakhouse-new-york"
  },
  {
    "alias": "club-room-at-soho-grand-new-york-2"
  },
  {
    "alias": "coarse-ny-new-york"
  },
  {
    "alias": "cocina-del-sur-new-york-2"
  },
  {
    "alias": "cocotte-san-francisco"
  },
  {
    "alias": "cocu-new-york"
  },
  {
    "alias": "colbeh-new-york-3"
  },
  {
    "alias": "coletta-new-york-2"
  },
  {
    "alias": "colonie-brooklyn"
  },
  {
    "alias": "come-prima-ristorante-new-york-2"
  },
  {
    "alias": "commodore-grill-new-york"
  },
  {
    "alias": "common-ground-new-york-7"
  },
  {
    "alias": "common-lot-millburn"
  },
  {
    "alias": "compagnie-des-vins-surnaturels-new-york-2"
  },
  {
    "alias": "compass-rose-washington-3"
  },
  {
    "alias": "con-amore-ristorante-corona"
  },
  {
    "alias": "connie-and-teds-west-hollywood-2"
  },
  {
    "alias": "connollys-pub-and-restaurant-new-york"
  },
  {
    "alias": "contento-new-york-city"
  },
  {
    "alias": "contra-new-york"
  },
  {
    "alias": "convivium-osteria-brooklyn"
  },
  {
    "alias": "cook-eatery-new-york"
  },
  {
    "alias": "coopers-craft-and-cocktails-new-york-2"
  },
  {
    "alias": "copinette-new-york-3"
  },
  {
    "alias": "coppelia-new-york"
  },
  {
    "alias": "copra-san-francisco-2"
  },
  {
    "alias": "coqueta-san-francisco"
  },
  {
    "alias": "corduroy-washington-4"
  },
  {
    "alias": "corkbuzz-restaurant-and-wine-bar-new-york"
  },
  {
    "alias": "corner-bistro-new-york"
  },
  {
    "alias": "corner-social-new-york-2"
  },
  {
    "alias": "cornerstone-tavern-new-york-2"
  },
  {
    "alias": "corona-cafe-miami-beach"
  },
  {
    "alias": "cosima-baltimore"
  },
  {
    "alias": "cosme-new-york"
  },
  {
    "alias": "cosmos-club-washington"
  },
  {
    "alias": "costas-arepa-bar-new-york"
  },
  {
    "alias": "cote-korean-steakhouse-new-york"
  },
  {
    "alias": "cote-miami-miami-2"
  },
  {
    "alias": "cotenna-new-york"
  },
  {
    "alias": "cotogna-san-francisco"
  },
  {
    "alias": "cotton-club-new-york"
  },
  {
    "alias": "cousins-maine-lobster-new-york-new-york"
  },
  {
    "alias": "cowgirl-new-york"
  },
  {
    "alias": "crab-house-all-you-can-eat-seafood-new-york"
  },
  {
    "alias": "crab-house-at-pier-39-san-francisco-3"
  },
  {
    "alias": "craft-new-york"
  },
  {
    "alias": "cranes-spanish-kaiseki-washington"
  },
  {
    "alias": "crave-fishbar-new-york"
  },
  {
    "alias": "credo-san-francisco"
  },
  {
    "alias": "creole-on-14th-washington"
  },
  {
    "alias": "crepe-master-new-york-7"
  },
  {
    "alias": "crossroads-american-kitchen-and-bar-new-york"
  },
  {
    "alias": "croton-reservoir-tavern-new-york"
  },
  {
    "alias": "crown-room-at-the-fairmont-hotel-san-francisco"
  },
  {
    "alias": "crown-shy-new-york-2"
  },
  {
    "alias": "crustacean-san-francisco"
  },
  {
    "alias": "cuba-restaurant-and-rum-bar-new-york"
  },
  {
    "alias": "cucina-8-1-2-new-york-2"
  },
  {
    "alias": "cull-and-pistol-oyster-bar-new-york"
  },
  {
    "alias": "cut-new-york-city-new-york"
  },
  {
    "alias": "cypriana-of-roland-park-baltimore-2"
  },
  {
    "alias": "d-j-reynolds-new-york-5"
  },
  {
    "alias": "d-oro-ristorante-chatham"
  },
  {
    "alias": "da-andrea-new-york"
  },
  {
    "alias": "da-capo-new-york-2"
  },
  {
    "alias": "da-flora-a-venetian-osteria-san-francisco"
  },
  {
    "alias": "da-gennaro-new-york"
  },
  {
    "alias": "da-long-yi-hot-pot-long-island-city"
  },
  {
    "alias": "da-long-yi-hot-pot-new-york-4"
  },
  {
    "alias": "da-raffaele-new-york"
  },
  {
    "alias": "da-tang-szechuan-new-york-2"
  },
  {
    "alias": "da-tommaso-new-york-2"
  },
  {
    "alias": "da-umberto-new-york-2"
  },
  {
    "alias": "daeho-kalbijjim-and-beef-soup-san-francisco-9"
  },
  {
    "alias": "daffodil-san-francisco"
  },
  {
    "alias": "dainobu-new-york-3"
  },
  {
    "alias": "dallas-bbq-new-york-7"
  },
  {
    "alias": "daltons-bar-and-grill-new-york"
  },
  {
    "alias": "dan-and-brads-arlington"
  },
  {
    "alias": "daniel-new-york-4"
  },
  {
    "alias": "daniela-trattoria-new-york"
  },
  {
    "alias": "danji-new-york"
  },
  {
    "alias": "dante-new-york"
  },
  {
    "alias": "dante-west-village-new-york"
  },
  {
    "alias": "dark-side-of-the-moo-hell-s-kitchen-new-york-4"
  },
  {
    "alias": "datangzhenwei-miami"
  },
  {
    "alias": "dauphine-s-washington"
  },
  {
    "alias": "david-burke-tavern-new-york"
  },
  {
    "alias": "dear-mama-new-york-3"
  },
  {
    "alias": "death-ave-new-york-5"
  },
  {
    "alias": "decoy-new-york"
  },
  {
    "alias": "degrees-bistro-washington"
  },
  {
    "alias": "del-friscos-double-eagle-steakhouse-washington"
  },
  {
    "alias": "del-friscos-grille-hoboken"
  },
  {
    "alias": "del-friscos-grille-new-york"
  },
  {
    "alias": "del-friscos-grille-new-york-4"
  },
  {
    "alias": "delhi-masala-new-york"
  },
  {
    "alias": "delice-and-sarrasin-new-york"
  },
  {
    "alias": "delicia-restaurant-new-york"
  },
  {
    "alias": "delight-deli-and-grocery-hoboken"
  },
  {
    "alias": "delilah-west-hollywood"
  },
  {
    "alias": "dellanima-new-york-3"
  },
  {
    "alias": "delmonicos-new-york"
  },
  {
    "alias": "delphine-los-angeles"
  },
  {
    "alias": "deninos-greenwich-village-new-york"
  },
  {
    "alias": "destefanos-steakhouse-brooklyn"
  },
  {
    "alias": "deux-amis-new-york"
  },
  {
    "alias": "dhamaka-new-york"
  },
  {
    "alias": "di-mauros-italian-restaurant-and-bar-miami-beach"
  },
  {
    "alias": "dill-and-parsley-new-york"
  },
  {
    "alias": "dill-and-parsley-new-york-3"
  },
  {
    "alias": "dill-and-parsley-new-york-7"
  },
  {
    "alias": "dino-and-harry-s-steakhouse-hoboken"
  },
  {
    "alias": "dinosaur-bar-b-que-new-york-4"
  },
  {
    "alias": "dio-deka-los-gatos"
  },
  {
    "alias": "dirt-candy-new-york-2"
  },
  {
    "alias": "dirty-french-new-york"
  },
  {
    "alias": "dirty-french-steakhouse-miami"
  },
  {
    "alias": "dirty-habit-san-francisco"
  },
  {
    "alias": "discovery-fusion-bbq-san-jose-5"
  },
  {
    "alias": "dishdash-sunnyvale"
  },
  {
    "alias": "district-local-new-york-2"
  },
  {
    "alias": "district-m-new-york"
  },
  {
    "alias": "district-social-new-york"
  },
  {
    "alias": "district-tap-house-new-york"
  },
  {
    "alias": "dizzys-club-new-york"
  },
  {
    "alias": "dk-restaurant-nyc-new-york"
  },
  {
    "alias": "dleña-by-chef-richard-sandoval-washington"
  },
  {
    "alias": "dny-natural-land-brooklyn-2"
  },
  {
    "alias": "docks-oyster-bar-and-seafood-grill-new-york"
  },
  {
    "alias": "dolce-italian-miami-beach"
  },
  {
    "alias": "dolly-varden-new-york-2"
  },
  {
    "alias": "domodomo-new-york-new-york-4"
  },
  {
    "alias": "don-angie-new-york"
  },
  {
    "alias": "don-antonio-new-york-5"
  },
  {
    "alias": "don-giovanni-ristorante-new-york"
  },
  {
    "alias": "donahue-washington"
  },
  {
    "alias": "donato-enoteca-redwood-city"
  },
  {
    "alias": "donburiya-new-york-2"
  },
  {
    "alias": "donna-margherita-new-york"
  },
  {
    "alias": "dons-bogam-bbq-and-wine-bar-new-york-3"
  },
  {
    "alias": "dons-bogam-black-new-york-2"
  },
  {
    "alias": "down-the-hatch-new-york"
  },
  {
    "alias": "doya-miami"
  },
  {
    "alias": "dragon-town-new-york-2"
  },
  {
    "alias": "drunken-dragon-miami-beach"
  },
  {
    "alias": "dua-kafe-new-york"
  },
  {
    "alias": "duane-park-new-york-3"
  },
  {
    "alias": "duck-duck-goose-washington-2"
  },
  {
    "alias": "due-dieci-los-gatos"
  },
  {
    "alias": "due-new-york"
  },
  {
    "alias": "due-west-new-york"
  },
  {
    "alias": "duke-eatery-new-york-2"
  },
  {
    "alias": "dukunoo-miami-3"
  },
  {
    "alias": "dutch-freds-new-york-2"
  },
  {
    "alias": "dōma-miami-3"
  },
  {
    "alias": "e-mo-new-york"
  },
  {
    "alias": "earls-beer-and-cheese-new-york"
  },
  {
    "alias": "east-side-restaurant-new-britain"
  },
  {
    "alias": "easy-company-washington"
  },
  {
    "alias": "edge-steak-and-bar-miami-4"
  },
  {
    "alias": "eds-lobster-bar-new-york-3"
  },
  {
    "alias": "edwards-steak-house-jersey-city"
  },
  {
    "alias": "effys-kitchen-new-york-2"
  },
  {
    "alias": "eight-tables-by-george-chen-san-francisco"
  },
  {
    "alias": "eighteen-restaurant-new-york-5"
  },
  {
    "alias": "el-cielo-by-juan-manuel-barrientos-miami"
  },
  {
    "alias": "el-compadre-los-angeles-7"
  },
  {
    "alias": "el-encanto-de-lola-2-new-york"
  },
  {
    "alias": "el-kallejon-new-york"
  },
  {
    "alias": "el-lopo-san-francisco-2"
  },
  {
    "alias": "el-nuevo-caridad-restaurant-new-york"
  },
  {
    "alias": "el-nuevo-carribeno-new-york"
  },
  {
    "alias": "el-parador-cafe-new-york"
  },
  {
    "alias": "el-paso-taqueria-new-york-3"
  },
  {
    "alias": "el-porton-new-york-2"
  },
  {
    "alias": "el-puerto-seafood-new-york"
  },
  {
    "alias": "el-quijote-new-york-3"
  },
  {
    "alias": "el-toro-gourmet-meats-seafood-deli-lake-forest"
  },
  {
    "alias": "el-tucán-miami"
  },
  {
    "alias": "electric-lemon-new-york"
  },
  {
    "alias": "elenis-new-york-3"
  },
  {
    "alias": "elephant-and-castle-new-york"
  },
  {
    "alias": "elephant-sushi-san-francisco"
  },
  {
    "alias": "eleven-college-ave-los-gatos"
  },
  {
    "alias": "eleven-madison-park-new-york"
  },
  {
    "alias": "elios-new-york"
  },
  {
    "alias": "elis-essentials-new-york-9"
  },
  {
    "alias": "elmo-new-york"
  },
  {
    "alias": "emilios-ballato-new-york"
  },
  {
    "alias": "emily-west-village-new-york"
  },
  {
    "alias": "emmetts-new-york-2"
  },
  {
    "alias": "empellón-new-york-3"
  },
  {
    "alias": "empellón-taqueria-new-york"
  },
  {
    "alias": "empire-steak-house-east-new-york-2"
  },
  {
    "alias": "empire-steak-house-new-york-11"
  },
  {
    "alias": "empire-steak-house-new-york-9"
  },
  {
    "alias": "employees-only-new-york"
  },
  {
    "alias": "employees-only-west-hollywood"
  },
  {
    "alias": "emporio-new-york"
  },
  {
    "alias": "empress-by-boon-san-francisco"
  },
  {
    "alias": "en-japanese-brasserie-new-york"
  },
  {
    "alias": "entwine-new-york"
  },
  {
    "alias": "equinox-restaurant-washington-2"
  },
  {
    "alias": "eros-greek-restaurant-new-york"
  },
  {
    "alias": "esprit-events-new-york"
  },
  {
    "alias": "essex-new-york"
  },
  {
    "alias": "estela-new-york-2"
  },
  {
    "alias": "estiatorio-milos-by-costas-spiliadis-miami-beach"
  },
  {
    "alias": "estiatorio-milos-new-york-3"
  },
  {
    "alias": "et-voila-washington"
  },
  {
    "alias": "etc-steakhouse-teaneck"
  },
  {
    "alias": "etcetera-etcetera-new-york-2"
  },
  {
    "alias": "ethos-gallery-51st-new-york"
  },
  {
    "alias": "ettan-palo-alto"
  },
  {
    "alias": "europan-café-new-york-4"
  },
  {
    "alias": "eveleigh-west-hollywood"
  },
  {
    "alias": "evergreen-on-38-new-york"
  },
  {
    "alias": "evvia-estiatorio-palo-alto"
  },
  {
    "alias": "extra-virgin-new-york"
  },
  {
    "alias": "exupery-burger-and-pizza-miami-3"
  },
  {
    "alias": "eyval-brooklyn"
  },
  {
    "alias": "ez-paella-and-tapas-new-york-3"
  },
  {
    "alias": "faces-and-names-new-york"
  },
  {
    "alias": "faiccos-italian-specialties-new-york"
  },
  {
    "alias": "fairfax-new-york-2"
  },
  {
    "alias": "family-meal-at-blue-hill-new-york"
  },
  {
    "alias": "faun-brooklyn"
  },
  {
    "alias": "feile-new-york-13"
  },
  {
    "alias": "felice-15-gold-new-york"
  },
  {
    "alias": "felice-56-new-york"
  },
  {
    "alias": "felice-64-new-york"
  },
  {
    "alias": "felice-83-new-york"
  },
  {
    "alias": "felix-new-york"
  },
  {
    "alias": "feng-shui-new-york-2"
  },
  {
    "alias": "fiddlesticks-pub-new-york"
  },
  {
    "alias": "fieldtrip-rockefeller-center-new-york-2"
  },
  {
    "alias": "fig-and-olive-new-york-10"
  },
  {
    "alias": "fig-and-olive-new-york-12"
  },
  {
    "alias": "fig-and-olive-washington-9"
  },
  {
    "alias": "figaro-new-york-3"
  },
  {
    "alias": "filomena-ristorante-washington"
  },
  {
    "alias": "filé-gumbo-bar-new-york"
  },
  {
    "alias": "fin-raw-bar-and-kitchen-summit"
  },
  {
    "alias": "fine-and-rare-new-york"
  },
  {
    "alias": "fino-ristorante-san-francisco"
  },
  {
    "alias": "fiola-mare-washington"
  },
  {
    "alias": "fiola-washington"
  },
  {
    "alias": "fior-d-italia-san-francisco-4"
  },
  {
    "alias": "fiorino-ristorante-and-bar-summit"
  },
  {
    "alias": "fire-and-oak-jersey-city-jersey-city-2"
  },
  {
    "alias": "fire-at-the-ridge-middlefield"
  },
  {
    "alias": "firehouse-bistro-woodside"
  },
  {
    "alias": "fischer-brothers-and-leslie-kosher-meat-and-poultry-new-york"
  },
  {
    "alias": "five-senses-new-york"
  },
  {
    "alias": "fjord-fish-market-darien"
  },
  {
    "alias": "flame-hibachi-new-york-4"
  },
  {
    "alias": "flea-street-menlo-park"
  },
  {
    "alias": "fleming-s-prime-steakhouse-and-wine-bar-coral-gables-3"
  },
  {
    "alias": "fleming-s-prime-steakhouse-and-wine-bar-miami-2"
  },
  {
    "alias": "fleming-s-prime-steakhouse-and-wine-bar-palo-alto"
  },
  {
    "alias": "fleming-s-prime-steakhouse-and-wine-bar-west-hartford-2"
  },
  {
    "alias": "flex-mussels-new-york-2"
  },
  {
    "alias": "flip-new-york-2"
  },
  {
    "alias": "flip-sigi-new-york-2"
  },
  {
    "alias": "florida-cookery-miami-beach-2"
  },
  {
    "alias": "florio-bar-and-cafe-san-francisco"
  },
  {
    "alias": "flowers-saratoga-saratoga-2"
  },
  {
    "alias": "fogo-de-chao-brazilian-steakhouse-miami-beach-10"
  },
  {
    "alias": "fogo-de-chao-brazilian-steakhouse-new-york-6"
  },
  {
    "alias": "fogo-de-chao-brazilian-steakhouse-washington-10"
  },
  {
    "alias": "fogo-de-chao-coral-gables"
  },
  {
    "alias": "fogo-de-chao-irvine-2"
  },
  {
    "alias": "fogo-de-chao-san-francisco-san-francisco-2"
  },
  {
    "alias": "fogo-de-chão-brazilian-steakhouse-baltimore-10"
  },
  {
    "alias": "follia-new-york"
  },
  {
    "alias": "fonda-tribeca-new-york-4"
  },
  {
    "alias": "foova-tenafly"
  },
  {
    "alias": "forbes-mill-steakhouse-los-gatos"
  },
  {
    "alias": "forsythia-new-york-3"
  },
  {
    "alias": "forte-dei-marmi-miami-beach"
  },
  {
    "alias": "forty-carrots-new-york-2"
  },
  {
    "alias": "forty-four-lounge-new-york"
  },
  {
    "alias": "foxy-johns-bar-and-kitchen-new-york"
  },
  {
    "alias": "francie-brooklyn"
  },
  {
    "alias": "frankie-and-johnnies-steak-house-new-york-3"
  },
  {
    "alias": "frankies-457-spuntino-brooklyn-3"
  },
  {
    "alias": "frascati-san-francisco"
  },
  {
    "alias": "frenchette-new-york-2"
  },
  {
    "alias": "fresco-by-scotto-new-york"
  },
  {
    "alias": "fresh-basils-trattoria-new-york"
  },
  {
    "alias": "frevo-new-york"
  },
  {
    "alias": "frida-midtown-new-york"
  },
  {
    "alias": "friedmans-new-york-71"
  },
  {
    "alias": "friendship-bbq-santa-clara"
  },
  {
    "alias": "fruit-and-veggie-guy-new-york"
  },
  {
    "alias": "fuji-hibachi-new-york-3"
  },
  {
    "alias": "fumo-harlem-new-york"
  },
  {
    "alias": "fusion-hk-bar-and-grill-new-york-2"
  },
  {
    "alias": "gabriel-kreuther-new-york"
  },
  {
    "alias": "gage-and-tollner-brooklyn"
  },
  {
    "alias": "gaku-yakitori-san-jose"
  },
  {
    "alias": "gallaghers-steakhouse-new-york"
  },
  {
    "alias": "gallow-green-new-york"
  },
  {
    "alias": "galpão-gaucho-brazilian-steakhouse-cupertino-3"
  },
  {
    "alias": "gaonnuri-new-york-3"
  },
  {
    "alias": "gardenia-terrace-new-york"
  },
  {
    "alias": "gardenias-san-francisco-2"
  },
  {
    "alias": "garibaldis-san-francisco-2"
  },
  {
    "alias": "gary-danko-san-francisco"
  },
  {
    "alias": "gastro-at-35-bar-and-restaurant-new-york"
  },
  {
    "alias": "gayles-broadway-rose-new-york"
  },
  {
    "alias": "gekko-miami-3"
  },
  {
    "alias": "gelsons-hollywood-2"
  },
  {
    "alias": "gelsons-irvine-3"
  },
  {
    "alias": "gelsons-market-west-hollywood"
  },
  {
    "alias": "gemma-new-york"
  },
  {
    "alias": "genes-restaurant-new-york"
  },
  {
    "alias": "genki-omakase-new-york"
  },
  {
    "alias": "georgia-browns-washington-3"
  },
  {
    "alias": "german-doner-kebab-midtown-new-york"
  },
  {
    "alias": "giando-brooklyn"
  },
  {
    "alias": "giannis-miami-beach-5"
  },
  {
    "alias": "giardino-d-oro-new-york"
  },
  {
    "alias": "gibson-san-francisco"
  },
  {
    "alias": "gilligans-new-york"
  },
  {
    "alias": "gina-mexicana-new-york-2"
  },
  {
    "alias": "ginnys-supper-club-new-york"
  },
  {
    "alias": "giorgios-of-gramercy-new-york"
  },
  {
    "alias": "gitano-miami-at-casa-faena-miami-beach-3"
  },
  {
    "alias": "glass-house-tavern-new-york"
  },
  {
    "alias": "glasserie-brooklyn-2"
  },
  {
    "alias": "glur-thai-new-york"
  },
  {
    "alias": "gmt-tavern-new-york"
  },
  {
    "alias": "gogi-37-new-york"
  },
  {
    "alias": "gogi-yogi-washington-2"
  },
  {
    "alias": "golden-wuish-new-york"
  },
  {
    "alias": "goldie-s-tavern-new-york"
  },
  {
    "alias": "gonzalez-y-gonzalez-new-york-2"
  },
  {
    "alias": "goodfellas-restaurant-new-haven-2"
  },
  {
    "alias": "gopchang-story-bbq-new-york"
  },
  {
    "alias": "gordon-ramsay-fish-and-chips-new-york-4"
  },
  {
    "alias": "gossip-bar-and-restaurant-new-york"
  },
  {
    "alias": "gotham-lounge-new-york"
  },
  {
    "alias": "gotham-restaurant-new-york-2"
  },
  {
    "alias": "gottino-enoteca-e-salumeria-new-york"
  },
  {
    "alias": "graces-new-york"
  },
  {
    "alias": "gramercy-tavern-new-york"
  },
  {
    "alias": "grams-bbq-garden-grove-2"
  },
  {
    "alias": "gran-morsi-new-york"
  },
  {
    "alias": "grand-banks-new-york"
  },
  {
    "alias": "grand-bar-and-salon-new-york-2"
  },
  {
    "alias": "grand-central-oyster-bar-and-restaurant-new-york"
  },
  {
    "alias": "grand-tier-restaurant-new-york-2"
  },
  {
    "alias": "gravitas-washington-2"
  },
  {
    "alias": "gray-hawk-grill-new-york"
  },
  {
    "alias": "greca-new-york-5"
  },
  {
    "alias": "greek-eats-new-york"
  },
  {
    "alias": "greek-from-greece-new-york-2"
  },
  {
    "alias": "green-fig-new-york-2"
  },
  {
    "alias": "greens-restaurant-san-francisco-5"
  },
  {
    "alias": "gregorios-chatham"
  },
  {
    "alias": "grill-house-miami-beach-2"
  },
  {
    "alias": "grill-on-the-hill-new-york"
  },
  {
    "alias": "guajillo-new-york"
  },
  {
    "alias": "guantanamera-new-york"
  },
  {
    "alias": "gupshup-new-york"
  },
  {
    "alias": "guy-and-gallard-new-york-178"
  },
  {
    "alias": "gyu-kaku-japanese-bbq-new-york-2"
  },
  {
    "alias": "habitat-miami-beach"
  },
  {
    "alias": "hachi-ju-hachi-saratoga"
  },
  {
    "alias": "haenyeo-brooklyn"
  },
  {
    "alias": "haidilao-hot-pot-cupertino-cupertino"
  },
  {
    "alias": "hakkasan-miami-miami-beach"
  },
  {
    "alias": "haku-new-york"
  },
  {
    "alias": "halifax-hoboken-2"
  },
  {
    "alias": "halves-boiling-pot-grill-japanese-shabu-yakiniku-bbq-foothill-ranch"
  },
  {
    "alias": "hamachi-34-new-york-2"
  },
  {
    "alias": "han-sung-bbq-santa-clara-2"
  },
  {
    "alias": "hanami-japanese-cuisine-new-york"
  },
  {
    "alias": "hanamichi-new-york"
  },
  {
    "alias": "hangawi-new-york"
  },
  {
    "alias": "hannas-restaurant-and-bar-rancho-santa-margarita-3"
  },
  {
    "alias": "happy-lamb-hot-pot-san-francisco-2"
  },
  {
    "alias": "harbor-harvest-norwalk"
  },
  {
    "alias": "harbor-lights-norwalk"
  },
  {
    "alias": "harbor-nyc-new-york"
  },
  {
    "alias": "harbour-lights-new-york"
  },
  {
    "alias": "harbs-soho-new-york-3"
  },
  {
    "alias": "harlem-bar-b-q-new-york"
  },
  {
    "alias": "harlem-seafood-soul-new-york"
  },
  {
    "alias": "harlem-shake-new-york"
  },
  {
    "alias": "harlem-tavern-new-york-2"
  },
  {
    "alias": "harmonie-club-new-york"
  },
  {
    "alias": "harris-restaurant-san-francisco"
  },
  {
    "alias": "harry-cipriani-new-york"
  },
  {
    "alias": "harrys-new-york-3"
  },
  {
    "alias": "harts-restaurant-brooklyn-2"
  },
  {
    "alias": "harvest-new-haven-2"
  },
  {
    "alias": "hasaki-new-york"
  },
  {
    "alias": "haswell-greens-new-york-3"
  },
  {
    "alias": "hatchets-and-hops-brooklyn"
  },
  {
    "alias": "hatsuhana-new-york"
  },
  {
    "alias": "havana-central-new-york-6"
  },
  {
    "alias": "havana-ny-new-york-3"
  },
  {
    "alias": "haven-rooftop-new-york"
  },
  {
    "alias": "hawksmoor-new-york"
  },
  {
    "alias": "hayes-street-grill-san-francisco"
  },
  {
    "alias": "haymaker-bar-and-kitchen-new-york"
  },
  {
    "alias": "healthy-choice-new-york"
  },
  {
    "alias": "hearth-new-york"
  },
  {
    "alias": "heirloom-farmhouse-kitchen-irvine"
  },
  {
    "alias": "heirloom-new-haven-2"
  },
  {
    "alias": "hello-saigon-new-york"
  },
  {
    "alias": "hemingways-on-the-beach-turks-and-caicos-miami"
  },
  {
    "alias": "henrys-end-brooklyn-4"
  },
  {
    "alias": "her-name-was-carmen-new-york"
  },
  {
    "alias": "herbs-thai-bistro-new-york-2"
  },
  {
    "alias": "hilda-and-jesse-san-francisco"
  },
  {
    "alias": "hillstone-restaurant-coral-gables-2"
  },
  {
    "alias": "hillstone-restaurant-new-york"
  },
  {
    "alias": "hillstone-san-francisco-4"
  },
  {
    "alias": "himalayan-curry-house-restaurant-and-bar-new-york"
  },
  {
    "alias": "hina-yakitori-san-francisco-2"
  },
  {
    "alias": "hinata-san-francisco"
  },
  {
    "alias": "hirohisa-new-york"
  },
  {
    "alias": "hiyake-omakase-new-york"
  },
  {
    "alias": "ho-ho-kus-inn-and-tavern-ho-ho-kus"
  },
  {
    "alias": "hokey-poké-new-york-2"
  },
  {
    "alias": "home-sweet-harlem-new-york"
  },
  {
    "alias": "hometown-hotpot-and-bbq-new-york-2"
  },
  {
    "alias": "honest-new-york"
  },
  {
    "alias": "honey-badger-brooklyn-2"
  },
  {
    "alias": "honey-pig-buena-park-buena-park-2"
  },
  {
    "alias": "horses-los-angeles"
  },
  {
    "alias": "hortus-nyc-new-york"
  },
  {
    "alias": "horus-too-new-york"
  },
  {
    "alias": "hosteria-romana-miami-beach"
  },
  {
    "alias": "hot-jalapeño-new-york-2"
  },
  {
    "alias": "hot-n-juicy-crawfish-washington"
  },
  {
    "alias": "hou-yi-hot-pot-new-york"
  },
  {
    "alias": "house-of-lasagna-new-york"
  },
  {
    "alias": "house-of-prime-rib-san-francisco"
  },
  {
    "alias": "houseman-new-york"
  },
  {
    "alias": "hudson-and-co-jersey-city"
  },
  {
    "alias": "hudson-bar-room-new-york"
  },
  {
    "alias": "hudson-clearwater-new-york"
  },
  {
    "alias": "hudson-malone-a-new-york-joint-new-york"
  },
  {
    "alias": "hudson-square-exchange-new-york"
  },
  {
    "alias": "hudson-yards-grill-new-york-2"
  },
  {
    "alias": "hui-restaurant-and-bar-new-york"
  },
  {
    "alias": "hummus-kitchen-new-york"
  },
  {
    "alias": "hunan-slurp-new-york-2"
  },
  {
    "alias": "hunt-and-fish-club-new-york-2"
  },
  {
    "alias": "hurleys-new-york-4"
  },
  {
    "alias": "hutaoli-music-restaurant-and-bar-new-york"
  },
  {
    "alias": "hutong-miami"
  },
  {
    "alias": "hutong-new-york"
  },
  {
    "alias": "hwa-yuan-new-york-2"
  },
  {
    "alias": "hyde-sunset-kitchen-cocktails-los-angeles-2"
  },
  {
    "alias": "hyun-new-york"
  },
  {
    "alias": "i-can-barbecue-korean-grill-irvine-irvine"
  },
  {
    "alias": "i-ricchi-washington-2"
  },
  {
    "alias": "i-sodi-new-york"
  },
  {
    "alias": "ichiran-midtown-new-york"
  },
  {
    "alias": "iguana-restaurant-and-dance-lounge-new-york"
  },
  {
    "alias": "il-bastardo-new-york-4"
  },
  {
    "alias": "il-bolognese-on-ocean-miami-beach"
  },
  {
    "alias": "il-buco-alimentari-and-vineria-new-york-2"
  },
  {
    "alias": "il-buco-new-york-2"
  },
  {
    "alias": "il-caffe-latte-new-york-2"
  },
  {
    "alias": "il-cantinori-new-york"
  },
  {
    "alias": "il-corallo-trattoria-new-york"
  },
  {
    "alias": "il-corso-new-york"
  },
  {
    "alias": "il-cortile-restaurant-new-york"
  },
  {
    "alias": "il-falco-long-island-city"
  },
  {
    "alias": "il-gabbiano-miami"
  },
  {
    "alias": "il-gattopardo-new-york-2"
  },
  {
    "alias": "il-mulino-new-york-new-york-8"
  },
  {
    "alias": "il-mulino-new-york-uptown-new-york-3"
  },
  {
    "alias": "il-mulino-prime-soho-new-york"
  },
  {
    "alias": "il-pesce-at-eataly-flatiron-new-york"
  },
  {
    "alias": "il-postino-new-york-3"
  },
  {
    "alias": "il-posto-accanto-new-york"
  },
  {
    "alias": "il-punto-ristorante-new-york-4"
  },
  {
    "alias": "il-tinello-ristorante-italiano-new-york"
  },
  {
    "alias": "ilili-new-york-2"
  },
  {
    "alias": "imli-urban-indian-food-new-york-3"
  },
  {
    "alias": "imperfecto-washington"
  },
  {
    "alias": "inase-restaurant-new-york"
  },
  {
    "alias": "inday-new-york-5"
  },
  {
    "alias": "india-at-time-square-new-york"
  },
  {
    "alias": "indian-accent-new-york"
  },
  {
    "alias": "indian-summer-harlem-new-york"
  },
  {
    "alias": "indochine-new-york"
  },
  {
    "alias": "insa-brooklyn"
  },
  {
    "alias": "inside-park-at-st-barts-new-york-2"
  },
  {
    "alias": "iron-age-washington-2"
  },
  {
    "alias": "iron-gate-washington"
  },
  {
    "alias": "ironwood-cellar-craft-cook-laguna-hills-4"
  },
  {
    "alias": "isa-restaurant-san-francisco"
  },
  {
    "alias": "island-new-york-2"
  },
  {
    "alias": "isle-of-capri-new-york-2"
  },
  {
    "alias": "isohama-new-york"
  },
  {
    "alias": "istanbul-bay-new-york"
  },
  {
    "alias": "istanbul-kebab-house-new-york"
  },
  {
    "alias": "istanbul-modern-sf-san-francisco"
  },
  {
    "alias": "italianissimo-ristorante-new-york-2"
  },
  {
    "alias": "izakaya-juraku-new-york-2"
  },
  {
    "alias": "izakaya-mew-new-york-3"
  },
  {
    "alias": "izakaya-toribar-new-york"
  },
  {
    "alias": "izzys-san-francisco-san-francisco"
  },
  {
    "alias": "j-hollingers-watermans-chophouse-silver-spring"
  },
  {
    "alias": "j-s-kitchen-new-york-new-york"
  },
  {
    "alias": "jack-demseys-new-york"
  },
  {
    "alias": "jack-doyles-bar-and-restaurant-new-york"
  },
  {
    "alias": "jack-rose-dining-saloon-washington"
  },
  {
    "alias": "jacks-bar-and-steakhouse-new-haven"
  },
  {
    "alias": "jacks-wife-freda-new-york-2"
  },
  {
    "alias": "jacob-restaurant-new-york-4"
  },
  {
    "alias": "jacob-soul-food-restaurant-new-york-2"
  },
  {
    "alias": "jaguar-restaurant-new-york"
  },
  {
    "alias": "jai-yun-san-francisco-3"
  },
  {
    "alias": "jajaja-mexicana-new-york-3"
  },
  {
    "alias": "jakes-the-knick-new-york"
  },
  {
    "alias": "jaleo-by-josé-andrés-washington"
  },
  {
    "alias": "jams-new-york-3"
  },
  {
    "alias": "jane-restaurant-new-york"
  },
  {
    "alias": "jang-su-jang-santa-clara"
  },
  {
    "alias": "jasmines-caribbean-cuisine-new-york"
  },
  {
    "alias": "jaspers-taphouse-kitchen-new-york"
  },
  {
    "alias": "javiers-irvine-irvine"
  },
  {
    "alias": "jaya-at-the-setai-miami-beach-2"
  },
  {
    "alias": "jazz-at-kitano-new-york-2"
  },
  {
    "alias": "jean-claude-2-new-york"
  },
  {
    "alias": "jean-georges-new-york-2"
  },
  {
    "alias": "jeannies-new-york-3"
  },
  {
    "alias": "jeffreys-grocery-new-york"
  },
  {
    "alias": "jerrell-s-betr-brgr-new-york"
  },
  {
    "alias": "jitlada-los-angeles"
  },
  {
    "alias": "joe-allen-new-york"
  },
  {
    "alias": "joes-seafood-prime-steak-and-stone-crab-washington"
  },
  {
    "alias": "joes-stone-crab-miami-beach-3"
  },
  {
    "alias": "john-davenports-new-haven"
  },
  {
    "alias": "john-sullivans-new-york"
  },
  {
    "alias": "johns-grill-san-francisco"
  },
  {
    "alias": "johns-of-bleecker-street-new-york-2"
  },
  {
    "alias": "johns-of-times-square-new-york-2"
  },
  {
    "alias": "joji-new-york-2"
  },
  {
    "alias": "jojo-new-york-3"
  },
  {
    "alias": "jojo-restaurant-and-bar-washington"
  },
  {
    "alias": "joju-new-york-2"
  },
  {
    "alias": "jollibee-new-york-2"
  },
  {
    "alias": "jongro-bbq-new-york-3"
  },
  {
    "alias": "jongro-bbq-wheaton"
  },
  {
    "alias": "joomak-banjum-new-york"
  },
  {
    "alias": "jora-restaurant-and-bar-long-island-city"
  },
  {
    "alias": "joseph-leonard-new-york"
  },
  {
    "alias": "josephs-steakhouse-bridgeport"
  },
  {
    "alias": "joya-restaurant-and-lounge-palo-alto"
  },
  {
    "alias": "jua-new-york"
  },
  {
    "alias": "juban-new-york"
  },
  {
    "alias": "judge-roy-bean-public-house-new-york"
  },
  {
    "alias": "jue-lan-club-new-york-3"
  },
  {
    "alias": "jukai-new-york"
  },
  {
    "alias": "juku-new-york"
  },
  {
    "alias": "jungsik-new-york"
  },
  {
    "alias": "juniors-restaurant-and-bakery-new-york-3"
  },
  {
    "alias": "juniors-restaurant-new-york-9"
  },
  {
    "alias": "juniper-bar-new-york"
  },
  {
    "alias": "juniper-restaurant-washington-3"
  },
  {
    "alias": "junoon-new-york"
  },
  {
    "alias": "junzi-kitchen-new-york-16"
  },
  {
    "alias": "juvia-miami-beach"
  },
  {
    "alias": "jū-ni-san-francisco-5"
  },
  {
    "alias": "k-minamoto-new-york"
  },
  {
    "alias": "k-rico-steakhouse-new-york"
  },
  {
    "alias": "kaboozs-bar-and-grill-new-york"
  },
  {
    "alias": "kahlo-new-york"
  },
  {
    "alias": "kaia-greek-coral-gables"
  },
  {
    "alias": "kaikagetsu-nyc-new-york-2"
  },
  {
    "alias": "kali-restaurant-los-angeles"
  },
  {
    "alias": "kamasu-by-kissaki-new-york"
  },
  {
    "alias": "kame-new-york-2"
  },
  {
    "alias": "kang-ho-dong-baekjeong-new-york"
  },
  {
    "alias": "kanoyama-new-york"
  },
  {
    "alias": "kao-new-york"
  },
  {
    "alias": "kaori-miami"
  },
  {
    "alias": "kaoru-new-york"
  },
  {
    "alias": "kappo-dc-washington"
  },
  {
    "alias": "kappo-masa-new-york"
  },
  {
    "alias": "karakatta-new-york"
  },
  {
    "alias": "karasu-brooklyn-2"
  },
  {
    "alias": "karizma-new-york"
  },
  {
    "alias": "karma-modern-indian-washington-2"
  },
  {
    "alias": "katana-kitten-new-york-2"
  },
  {
    "alias": "katana-west-hollywood"
  },
  {
    "alias": "kati-shop-new-york-7"
  },
  {
    "alias": "katsuya-hollywood-los-angeles-2"
  },
  {
    "alias": "katsuya-south-beach-miami-beach-2"
  },
  {
    "alias": "kausa-new-york"
  },
  {
    "alias": "kawa-sushi-new-york"
  },
  {
    "alias": "kazu-sushi-studio-city"
  },
  {
    "alias": "kazunori-nomad-new-york"
  },
  {
    "alias": "kebab-kurry-laguna-beach-3"
  },
  {
    "alias": "keens-steakhouse-new-york"
  },
  {
    "alias": "kellari-new-york-new-york"
  },
  {
    "alias": "kemuri-japanese-barú-redwood-city-3"
  },
  {
    "alias": "khan-shabu-shabu-and-bbq-sunnyvale"
  },
  {
    "alias": "kiki-miami"
  },
  {
    "alias": "kikoo-sushi-new-york-6"
  },
  {
    "alias": "kiku-alpine-restaurant-alpine"
  },
  {
    "alias": "kiku-japanese-restaurant-new-york-2"
  },
  {
    "alias": "kimika-new-york"
  },
  {
    "alias": "kimura-new-york"
  },
  {
    "alias": "kin-khao-san-francisco-2"
  },
  {
    "alias": "king-barka-jamaican-and-american-restaurant-new-york-3"
  },
  {
    "alias": "king-new-york-3"
  },
  {
    "alias": "kingbird-washington"
  },
  {
    "alias": "kings-fish-house-corona-corona"
  },
  {
    "alias": "kings-of-kobe-new-york-8"
  },
  {
    "alias": "kinship-washington"
  },
  {
    "alias": "kintsugi-omakase-new-york-4"
  },
  {
    "alias": "kissaki-omakase-new-york-7"
  },
  {
    "alias": "kissaki-sushi-new-york"
  },
  {
    "alias": "kitchen-kocktails-washington"
  },
  {
    "alias": "kittery-of-brooklyn-brooklyn-2"
  },
  {
    "alias": "kjun-new-york"
  },
  {
    "alias": "klaw-miami-miami"
  },
  {
    "alias": "knickerbocker-bar-and-grill-new-york"
  },
  {
    "alias": "ko-sushi-new-york-4"
  },
  {
    "alias": "kobeyaki-new-york-3"
  },
  {
    "alias": "kochi-new-york"
  },
  {
    "alias": "kocos-pub-baltimore"
  },
  {
    "alias": "koi-restaurant-new-york-2"
  },
  {
    "alias": "kokkari-estiatorio-san-francisco"
  },
  {
    "alias": "kokomo-brooklyn-2"
  },
  {
    "alias": "koloman-new-york-2"
  },
  {
    "alias": "komodo-miami-2"
  },
  {
    "alias": "kono-new-york"
  },
  {
    "alias": "korali-estiatorio-new-york"
  },
  {
    "alias": "korea-house-new-york"
  },
  {
    "alias": "korean-spring-bbq-santa-clara"
  },
  {
    "alias": "kosaka-new-york-2"
  },
  {
    "alias": "kotobuki-restaurant-laguna-hills-2"
  },
  {
    "alias": "kubeh-new-york-2"
  },
  {
    "alias": "kuma-sushi-sake-san-francisco"
  },
  {
    "alias": "kung-fu-kitchen-new-york-2"
  },
  {
    "alias": "kuruma-zushi-new-york"
  },
  {
    "alias": "kusakabe-san-francisco"
  },
  {
    "alias": "kuun-brooklyn"
  },
  {
    "alias": "kyma-new-york"
  },
  {
    "alias": "kyosho-menlo-park-5"
  },
  {
    "alias": "kyu-miami-miami"
  },
  {
    "alias": "kyu-new-york-new-york-2"
  },
  {
    "alias": "kyungbokkung-buena-park-3"
  },
  {
    "alias": "l-accolade-new-york"
  },
  {
    "alias": "l-adresse-new-york"
  },
  {
    "alias": "l-adresse-nomad-new-york-4"
  },
  {
    "alias": "l-amico-nyc-new-york"
  },
  {
    "alias": "l-antagoniste-brooklyn"
  },
  {
    "alias": "l-antica-pizzeria-da-michele-los-angeles-2"
  },
  {
    "alias": "l-antica-pizzeria-da-michele-new-york"
  },
  {
    "alias": "l-appart-new-york"
  },
  {
    "alias": "l-artusi-new-york"
  },
  {
    "alias": "l-atelier-de-joel-robuchon-miami-2"
  },
  {
    "alias": "l-avenue-at-saks-new-york"
  },
  {
    "alias": "l-orcio-new-haven-2"
  },
  {
    "alias": "la-barbona-new-york"
  },
  {
    "alias": "la-bohème-west-hollywood-3"
  },
  {
    "alias": "la-boite-en-bois-new-york-2"
  },
  {
    "alias": "la-brochette-new-york"
  },
  {
    "alias": "la-cava-new-york"
  },
  {
    "alias": "la-chaumiere-washington-4"
  },
  {
    "alias": "la-chula-new-york"
  },
  {
    "alias": "la-contenta-oeste-new-york"
  },
  {
    "alias": "la-cuchara-baltimore"
  },
  {
    "alias": "la-diagonal-new-york-5"
  },
  {
    "alias": "la-ferme-chevy-chase"
  },
  {
    "alias": "la-focaccia-summit"
  },
  {
    "alias": "la-fonda-new-york-3"
  },
  {
    "alias": "la-fondue-saratoga"
  },
  {
    "alias": "la-goulue-new-york-2"
  },
  {
    "alias": "la-grande-boucherie-new-york-2"
  },
  {
    "alias": "la-grenouille-new-york"
  },
  {
    "alias": "la-lanterna-cafe-and-grill-ridgewood"
  },
  {
    "alias": "la-lanterna-di-vittorio-new-york"
  },
  {
    "alias": "la-lupa-ristorante-north-haven"
  },
  {
    "alias": "la-mar-by-gastón-acurio-miami-6"
  },
  {
    "alias": "la-masseria-new-york-3"
  },
  {
    "alias": "la-mere-michelle-saratoga"
  },
  {
    "alias": "la-nacional-new-york-6"
  },
  {
    "alias": "la-pecora-bianca-new-york-5"
  },
  {
    "alias": "la-pergola-millburn"
  },
  {
    "alias": "la-piquette-washington"
  },
  {
    "alias": "la-pulperia-hells-kitchen-new-york-2"
  },
  {
    "alias": "la-ripaille-new-york"
  },
  {
    "alias": "la-savane-new-york"
  },
  {
    "alias": "la-sirene-new-york-4"
  },
  {
    "alias": "la-sirène-uws-new-york"
  },
  {
    "alias": "la-stanza-new-york"
  },
  {
    "alias": "la-taberna-steak-and-tapas-bar-dumont"
  },
  {
    "alias": "la-vara-brooklyn"
  },
  {
    "alias": "la-vela-dining-and-bar-new-york"
  },
  {
    "alias": "la-villetta-new-york"
  },
  {
    "alias": "ladurée-new-york-soho-new-york"
  },
  {
    "alias": "lafayette-grand-café-and-bakery-new-york"
  },
  {
    "alias": "lagos-restaurant-and-lounge-new-york"
  },
  {
    "alias": "lamano-west-village-new-york"
  },
  {
    "alias": "lan-sheng-restaurant-new-york"
  },
  {
    "alias": "land-of-plenty-new-york"
  },
  {
    "alias": "landmark-tavern-new-york-2"
  },
  {
    "alias": "laojie-hotpot-manhattan-new-york"
  },
  {
    "alias": "las-panteras-negras-new-york"
  },
  {
    "alias": "lasagna-chelsea-restaurant-new-york-5"
  },
  {
    "alias": "laser-wolf-brooklyn-2"
  },
  {
    "alias": "lattanzi-restaurant-new-york"
  },
  {
    "alias": "laurel-court-restaurant-and-bar-san-francisco-4"
  },
  {
    "alias": "laurel-hardware-west-hollywood-2"
  },
  {
    "alias": "lavagna-new-york"
  },
  {
    "alias": "lavo-new-york-7"
  },
  {
    "alias": "lazuli-lounge-miami"
  },
  {
    "alias": "ldv-hospitality-new-york"
  },
  {
    "alias": "le-baratin-new-york"
  },
  {
    "alias": "le-bernardin-new-york"
  },
  {
    "alias": "le-bilboquet-new-york-2"
  },
  {
    "alias": "le-botaniste-new-york-5"
  },
  {
    "alias": "le-central-san-francisco-2"
  },
  {
    "alias": "le-colonial-san-francisco"
  },
  {
    "alias": "le-coucou-new-york"
  },
  {
    "alias": "le-desales-washington"
  },
  {
    "alias": "le-diplomate-washington"
  },
  {
    "alias": "le-district-market-district-new-york"
  },
  {
    "alias": "le-gigot-new-york"
  },
  {
    "alias": "le-gourmet-new-york"
  },
  {
    "alias": "le-marais-new-york"
  },
  {
    "alias": "le-monde-new-york-6"
  },
  {
    "alias": "le-papillon-san-jose"
  },
  {
    "alias": "le-pavillon-new-york"
  },
  {
    "alias": "le-rivage-new-york-2"
  },
  {
    "alias": "le-sia-new-york-4"
  },
  {
    "alias": "le-song-new-york-2"
  },
  {
    "alias": "le-veau-d-or-new-york-2"
  },
  {
    "alias": "le-zie-new-york"
  },
  {
    "alias": "left-bank-menlo-park-menlo-park"
  },
  {
    "alias": "left-bank-new-york"
  },
  {
    "alias": "legal-sea-foods-short-hills-short-hills-2"
  },
  {
    "alias": "legasea-bar-and-grill-new-york"
  },
  {
    "alias": "leitao-new-york-3"
  },
  {
    "alias": "lelabar-new-york-2"
  },
  {
    "alias": "lenwich-new-york-25"
  },
  {
    "alias": "lenwich-new-york-28"
  },
  {
    "alias": "leos-oyster-bar-san-francisco-2"
  },
  {
    "alias": "les-amiss-san-francisco"
  },
  {
    "alias": "les-trois-chevaux-new-york"
  },
  {
    "alias": "lets-meat-new-york"
  },
  {
    "alias": "leuca-brooklyn"
  },
  {
    "alias": "level-iii-san-francisco-3"
  },
  {
    "alias": "lex-restaurant-new-york"
  },
  {
    "alias": "liberty-house-restaurant-jersey-city-2"
  },
  {
    "alias": "liberty-prime-steakhouse-jersey-city"
  },
  {
    "alias": "lido-bayside-grill-miami-beach"
  },
  {
    "alias": "lido-new-york"
  },
  {
    "alias": "lifethyme-new-york"
  },
  {
    "alias": "light-horse-tavern-jersey-city-3"
  },
  {
    "alias": "lilia-brooklyn"
  },
  {
    "alias": "lilli-and-loo-new-york"
  },
  {
    "alias": "lillies-victorian-establishment-new-york-2"
  },
  {
    "alias": "lillys-craft-and-kitchen-new-york"
  },
  {
    "alias": "lima-ruby-peruvian-palo-alto-2"
  },
  {
    "alias": "limani-new-york"
  },
  {
    "alias": "lin-and-daughters-new-york"
  },
  {
    "alias": "lincoln-ristorante-new-york"
  },
  {
    "alias": "lionheartoc-irvine"
  },
  {
    "alias": "lips-new-york-new-york-2"
  },
  {
    "alias": "little-collins-new-york-3"
  },
  {
    "alias": "little-kirin-new-york"
  },
  {
    "alias": "little-mad-new-york-2"
  },
  {
    "alias": "little-owl-new-york-3"
  },
  {
    "alias": "little-prince-new-york"
  },
  {
    "alias": "little-thai-kitchen-new-york"
  },
  {
    "alias": "liuyishou-hot-pot-san-jose"
  },
  {
    "alias": "livingston-bagel-livingston"
  },
  {
    "alias": "llama-inn-brooklyn"
  },
  {
    "alias": "llama-san-new-york-4"
  },
  {
    "alias": "lobster-place-new-york-4"
  },
  {
    "alias": "locale-closter"
  },
  {
    "alias": "locanda-verde-new-york"
  },
  {
    "alias": "locanda-vini-e-olii-brooklyn-4"
  },
  {
    "alias": "loch-bar-baltimore"
  },
  {
    "alias": "loi-estiatorio-new-york"
  },
  {
    "alias": "lokal-eatery-and-bar-jersey-city-2"
  },
  {
    "alias": "lola-taverna-new-york"
  },
  {
    "alias": "lolas-hoboken"
  },
  {
    "alias": "lolos-seafood-shack-new-york"
  },
  {
    "alias": "londels-restaurant-new-york"
  },
  {
    "alias": "long-acre-tavern-new-york-3"
  },
  {
    "alias": "longo-bros-new-york"
  },
  {
    "alias": "loretta-and-the-butcher-miami"
  },
  {
    "alias": "loring-place-new-york-2"
  },
  {
    "alias": "los-fuegos-by-francis-mallmann-miami-beach-2"
  },
  {
    "alias": "los-jarritos-miami"
  },
  {
    "alias": "los-mariscos-new-york"
  },
  {
    "alias": "loulou-petit-bistro-and-speakeasy-new-york"
  },
  {
    "alias": "love-korean-bbq-new-york"
  },
  {
    "alias": "love-makoto-washington"
  },
  {
    "alias": "lpm-restaurant-and-bar-miami"
  },
  {
    "alias": "lt-steak-and-seafood-miami-beach-2"
  },
  {
    "alias": "luca-osteria-coral-gables"
  },
  {
    "alias": "luce-restaurant-middletown"
  },
  {
    "alias": "luce-san-francisco"
  },
  {
    "alias": "lucien-new-york"
  },
  {
    "alias": "lucky-cat-new-york-2"
  },
  {
    "alias": "lucky-chengs-new-york-3"
  },
  {
    "alias": "luna-del-sea-steak-and-seafood-bistro-baltimore-2"
  },
  {
    "alias": "luna-restaurant-jersey-city"
  },
  {
    "alias": "luna-rossa-new-york"
  },
  {
    "alias": "lupa-new-york"
  },
  {
    "alias": "lupitas-restaurant-new-york-2"
  },
  {
    "alias": "lure-fishbar-miami-beach-3"
  },
  {
    "alias": "lure-fishbar-new-york-2"
  },
  {
    "alias": "lusardis-new-york"
  },
  {
    "alias": "luscious-thai-new-york"
  },
  {
    "alias": "lux-restaurant-los-angeles"
  },
  {
    "alias": "luxor-lounge-new-york"
  },
  {
    "alias": "lysee-new-york-5"
  },
  {
    "alias": "m-korean-bbq-buena-park"
  },
  {
    "alias": "m-wells-long-island-city-2"
  },
  {
    "alias": "macao-trading-company-new-york"
  },
  {
    "alias": "macarthur-park-palo-alto-3"
  },
  {
    "alias": "macchialina-taverna-rustica-miami-beach"
  },
  {
    "alias": "madeo-ristorante-west-hollywood"
  },
  {
    "alias": "madera-cuban-grill-and-steakhouse-long-island-city"
  },
  {
    "alias": "madera-menlo-park"
  },
  {
    "alias": "madison-and-vine-new-york"
  },
  {
    "alias": "madison-restaurant-new-york-2"
  },
  {
    "alias": "madre-brooklyn-2"
  },
  {
    "alias": "maestro-pasta-new-york-2"
  },
  {
    "alias": "magdalena-a-maryland-bistro-baltimore"
  },
  {
    "alias": "maggie-reillys-restaurant-new-york"
  },
  {
    "alias": "maggies-place-new-york"
  },
  {
    "alias": "maharaja-palace-new-york-2"
  },
  {
    "alias": "maialino-new-york"
  },
  {
    "alias": "maiella-long-island-city"
  },
  {
    "alias": "main-wah-restaurant-stratford"
  },
  {
    "alias": "mainrō-los-angeles-3"
  },
  {
    "alias": "maison-cheryl-arlington"
  },
  {
    "alias": "maison-danel-san-francisco-2"
  },
  {
    "alias": "maison-harlem-new-york"
  },
  {
    "alias": "maison-premiere-brooklyn"
  },
  {
    "alias": "maison-sun-brooklyn-5"
  },
  {
    "alias": "maiz-64-washington-3"
  },
  {
    "alias": "maizey-sunday-tacos-brooklyn-4"
  },
  {
    "alias": "majorelle-new-york"
  },
  {
    "alias": "makana-hawaiian-and-japanese-bbq-new-york"
  },
  {
    "alias": "makimaki-sushi-new-york-5"
  },
  {
    "alias": "mala-house-new-york"
  },
  {
    "alias": "malaparte-new-york"
  },
  {
    "alias": "malatesta-trattoria-new-york"
  },
  {
    "alias": "malii-gramercy-new-york"
  },
  {
    "alias": "mama-mia-44-sw-new-york-2"
  },
  {
    "alias": "mama-s-cupboard-new-york-2"
  },
  {
    "alias": "mamasita-bar-and-grill-new-york"
  },
  {
    "alias": "mamo-restaurant-new-york"
  },
  {
    "alias": "mangia-422-madison-new-york"
  },
  {
    "alias": "mangia-57th-new-york"
  },
  {
    "alias": "manhatta-new-york"
  },
  {
    "alias": "mannas-new-york-5"
  },
  {
    "alias": "manta-wynwood-miami"
  },
  {
    "alias": "mantra-new-york"
  },
  {
    "alias": "marabu-restaurant-miami"
  },
  {
    "alias": "marathi-new-york"
  },
  {
    "alias": "marc-forgione-new-york-2"
  },
  {
    "alias": "marco-polo-ristorante-brooklyn"
  },
  {
    "alias": "marea-new-york"
  },
  {
    "alias": "margaritaville-times-square-new-york"
  },
  {
    "alias": "margon-new-york"
  },
  {
    "alias": "mari-new-york-2"
  },
  {
    "alias": "mari-vanna-new-york-2"
  },
  {
    "alias": "mari-vanna-washington-3"
  },
  {
    "alias": "marino-restaurant-los-angeles"
  },
  {
    "alias": "marion-miami"
  },
  {
    "alias": "maritime-parc-jersey-city"
  },
  {
    "alias": "market-table-new-york"
  },
  {
    "alias": "markjoseph-steakhouse-new-york-2"
  },
  {
    "alias": "marlow-and-sons-brooklyn-2"
  },
  {
    "alias": "marlowe-san-francisco-2"
  },
  {
    "alias": "maroosh-mediterranean-restaurant-coral-gables"
  },
  {
    "alias": "marseille-new-york"
  },
  {
    "alias": "marta-new-york-7"
  },
  {
    "alias": "martel-restaurant-fairfield"
  },
  {
    "alias": "marumi-new-york"
  },
  {
    "alias": "marwin-thai-restaurant-new-york"
  },
  {
    "alias": "marys-fish-camp-new-york"
  },
  {
    "alias": "masa-new-york"
  },
  {
    "alias": "masala-king-new-york"
  },
  {
    "alias": "masala-times-greenwich-village-new-york-9"
  },
  {
    "alias": "massawa-new-york"
  },
  {
    "alias": "masseria-dei-vini-new-york"
  },
  {
    "alias": "masseria-washington-2"
  },
  {
    "alias": "mastros-steakhouse-new-york"
  },
  {
    "alias": "mastros-steakhouse-san-francisco-2"
  },
  {
    "alias": "mastros-steakhouse-washington"
  },
  {
    "alias": "matador-room-miami-beach"
  },
  {
    "alias": "match-65-brasserie-new-york-6"
  },
  {
    "alias": "match-restaurant-norwalk"
  },
  {
    "alias": "mathilde-french-bistro-san-francisco"
  },
  {
    "alias": "matisse-washington"
  },
  {
    "alias": "matsu-sushi-new-york-4"
  },
  {
    "alias": "matsunori-new-york"
  },
  {
    "alias": "matterhorn-restaurant-and-bakery-san-francisco-2"
  },
  {
    "alias": "max-downtown-hartford-3"
  },
  {
    "alias": "max-soha-restaurant-new-york"
  },
  {
    "alias": "maxs-oyster-bar-west-hartford"
  },
  {
    "alias": "maxwells-central-park-new-york"
  },
  {
    "alias": "maya-blue-new-york-3"
  },
  {
    "alias": "maya-new-york"
  },
  {
    "alias": "maydan-washington-2"
  },
  {
    "alias": "mazi-washington-2"
  },
  {
    "alias": "mc-kitchen-miami"
  },
  {
    "alias": "mccarthys-pub-nyc-new-york"
  },
  {
    "alias": "mccormick-and-kuletos-seafood-and-steaks-san-francisco-2"
  },
  {
    "alias": "mccormick-and-schmicks-seafood-and-steaks-baltimore-2"
  },
  {
    "alias": "mchales-bar-and-grill-new-york"
  },
  {
    "alias": "mcloones-boathouse-west-orange"
  },
  {
    "alias": "meadowsweet-brooklyn"
  },
  {
    "alias": "meat-and-bread-new-york"
  },
  {
    "alias": "medieval-times-dinner-and-tournament-buena-park"
  },
  {
    "alias": "mediterraneo-restaurant-new-york"
  },
  {
    "alias": "medium-rare-washington"
  },
  {
    "alias": "melbas-restaurant-new-york-2"
  },
  {
    "alias": "mele-bistro-arlington-3"
  },
  {
    "alias": "members-los-angeles"
  },
  {
    "alias": "menkoi-sato-new-york-2"
  },
  {
    "alias": "merakia-new-york-2"
  },
  {
    "alias": "mercado-hollywood-los-angeles"
  },
  {
    "alias": "mercato-della-pescheria-espanola-way-miami-beach-2"
  },
  {
    "alias": "mercato-new-york"
  },
  {
    "alias": "merchant-roots-san-francisco"
  },
  {
    "alias": "merchants-cigar-bar-new-york"
  },
  {
    "alias": "merchants-ny-new-york-4"
  },
  {
    "alias": "merchants-ny-new-york-8"
  },
  {
    "alias": "mercury-bar-west-new-york-5"
  },
  {
    "alias": "merois-west-hollywood"
  },
  {
    "alias": "mesamar-seafood-table-coral-gables"
  },
  {
    "alias": "meske-ethiopian-restaurant-new-york"
  },
  {
    "alias": "meskerem-ethiopian-cuisine-new-york"
  },
  {
    "alias": "meteora-los-angeles-2"
  },
  {
    "alias": "mezzaluna-new-york"
  },
  {
    "alias": "miami-supercar-rooms-miami"
  },
  {
    "alias": "michaels-genuine-food-and-drink-miami"
  },
  {
    "alias": "michaels-new-york"
  },
  {
    "alias": "mickey-spillanes-new-york"
  },
  {
    "alias": "mifune-new-york-new-york-2"
  },
  {
    "alias": "mighty-quinns-barbeque-new-york-5"
  },
  {
    "alias": "mignonette-miami-7"
  },
  {
    "alias": "mikes-bistro-new-york-2"
  },
  {
    "alias": "mikes-ristorante-norwalk"
  },
  {
    "alias": "mila-restaurant-miami-beach-3"
  },
  {
    "alias": "milk-and-roses-brooklyn"
  },
  {
    "alias": "millburn-standard-millburn"
  },
  {
    "alias": "mimi-new-york-3"
  },
  {
    "alias": "mimis-italian-restaurant-and-piano-bar-new-york"
  },
  {
    "alias": "minar-kabab-tikka-corner-jersey-city-2"
  },
  {
    "alias": "minetta-tavern-new-york"
  },
  {
    "alias": "minibar-by-josé-andrés-washington-7"
  },
  {
    "alias": "mint-masala-new-york"
  },
  {
    "alias": "mintons-new-york-5"
  },
  {
    "alias": "misi-brooklyn"
  },
  {
    "alias": "miss-ada-brooklyn-2"
  },
  {
    "alias": "mission-ceviche-new-york-3"
  },
  {
    "alias": "mister-gusto-restaurant-jersey-city"
  },
  {
    "alias": "mister-jius-san-francisco"
  },
  {
    "alias": "mister-softie-new-york"
  },
  {
    "alias": "mitsunobu-menlo-park"
  },
  {
    "alias": "miyabi-sushi-and-asian-cusine-new-york"
  },
  {
    "alias": "miznon-new-york"
  },
  {
    "alias": "mkt-restaurant-and-bar-san-francisco"
  },
  {
    "alias": "mocha-burger-sub-express-ues-new-york"
  },
  {
    "alias": "modena-washington-2"
  },
  {
    "alias": "modern-garden-miami"
  },
  {
    "alias": "mojo-omakase-new-york-3"
  },
  {
    "alias": "mokbar-new-york"
  },
  {
    "alias": "moko-new-york"
  },
  {
    "alias": "mole-new-york-3"
  },
  {
    "alias": "molos-weehawken-2"
  },
  {
    "alias": "momi-ramen-miami"
  },
  {
    "alias": "momofuku-ko-new-york-3"
  },
  {
    "alias": "momofuku-ssam-bar-new-york-6"
  },
  {
    "alias": "momokawa-new-york-2"
  },
  {
    "alias": "momoya-new-york"
  },
  {
    "alias": "momoya-new-york-2"
  },
  {
    "alias": "momoya-soho-new-york"
  },
  {
    "alias": "moms-kitchen-and-bar-new-york"
  },
  {
    "alias": "mon-ami-gabi-bethesda"
  },
  {
    "alias": "mona-lisa-restaurant-san-francisco"
  },
  {
    "alias": "monsieur-benjamin-san-francisco"
  },
  {
    "alias": "montes-trattoria-new-york-2"
  },
  {
    "alias": "moonstruck-eatery-new-york-2"
  },
  {
    "alias": "morandi-new-york"
  },
  {
    "alias": "morso-new-york"
  },
  {
    "alias": "mortons-of-chicago-washington"
  },
  {
    "alias": "mortons-restaurant-new-york"
  },
  {
    "alias": "mortons-the-steakhouse-baltimore-2"
  },
  {
    "alias": "mortons-the-steakhouse-bethesda-2"
  },
  {
    "alias": "mortons-the-steakhouse-burbank"
  },
  {
    "alias": "mortons-the-steakhouse-coral-gables"
  },
  {
    "alias": "mortons-the-steakhouse-new-york-2"
  },
  {
    "alias": "mortons-the-steakhouse-new-york-9"
  },
  {
    "alias": "mortons-the-steakhouse-san-francisco"
  },
  {
    "alias": "mortons-the-steakhouse-washington-3"
  },
  {
    "alias": "mos-fishermans-wharf-inner-harbor-baltimore"
  },
  {
    "alias": "mother-wolf-los-angeles"
  },
  {
    "alias": "moti-mahal-delux-new-york"
  },
  {
    "alias": "mourad-restaurant-san-francisco"
  },
  {
    "alias": "moustache-new-york-9"
  },
  {
    "alias": "mr-broadway-new-york"
  },
  {
    "alias": "mr-chow-miami-beach"
  },
  {
    "alias": "mr-chow-new-york"
  },
  {
    "alias": "mr-chow-new-york-2"
  },
  {
    "alias": "mr-jones-supper-club-new-york"
  },
  {
    "alias": "mr-sun-new-york"
  },
  {
    "alias": "mr-t-los-angeles"
  },
  {
    "alias": "mrs-an-new-york-3"
  },
  {
    "alias": "ms-fus-yummy-food-truck-jersey-city"
  },
  {
    "alias": "ms-yoo-new-york-2"
  },
  {
    "alias": "mui-new-york"
  },
  {
    "alias": "mulligans-pub-new-york"
  },
  {
    "alias": "murphys-law-new-york"
  },
  {
    "alias": "musso-and-frank-grill-hollywood"
  },
  {
    "alias": "my-moon-brooklyn-2"
  },
  {
    "alias": "myers-of-keswick-new-york"
  },
  {
    "alias": "mykonos-bleu-new-york"
  },
  {
    "alias": "mytango-menlo-park"
  },
  {
    "alias": "mémé-mediterranean-new-york-12"
  },
  {
    "alias": "mémé-mediterranean-new-york-4"
  },
  {
    "alias": "métier-washington-4"
  },
  {
    "alias": "n-by-naoe-miami"
  },
  {
    "alias": "nai-new-york"
  },
  {
    "alias": "naked-tomato-new-york-2"
  },
  {
    "alias": "nami-nori-brooklyn"
  },
  {
    "alias": "nami-nori-new-york"
  },
  {
    "alias": "nan-xiang-xiao-long-bao-manhattan-new-york"
  },
  {
    "alias": "nangman-bbq-new-york-2"
  },
  {
    "alias": "naoe-miami-3"
  },
  {
    "alias": "nare-sushi-new-york"
  },
  {
    "alias": "nari-san-francisco-3"
  },
  {
    "alias": "naro-new-york"
  },
  {
    "alias": "nataz-restaurant-southington"
  },
  {
    "alias": "natsumi-new-york-3"
  },
  {
    "alias": "natural-tofu-new-york-2"
  },
  {
    "alias": "naya-mezze-and-grill-new-york"
  },
  {
    "alias": "nbhd-new-york"
  },
  {
    "alias": "nearys-new-york"
  },
  {
    "alias": "negril-bk-brooklyn"
  },
  {
    "alias": "negril-village-new-york"
  },
  {
    "alias": "nellies-sports-bar-washington"
  },
  {
    "alias": "nello-new-york"
  },
  {
    "alias": "nerai-new-york"
  },
  {
    "alias": "neros-grille-livingston"
  },
  {
    "alias": "nespresso-boutique-at-bloomingdales-short-hills"
  },
  {
    "alias": "new-heights-restaurant-washington-3"
  },
  {
    "alias": "new-ivoire-new-york"
  },
  {
    "alias": "new-mill-restaurant-plantsville"
  },
  {
    "alias": "new-york-fried-chicken-jersey-city"
  },
  {
    "alias": "new-york-kimchi-new-york-2"
  },
  {
    "alias": "new-york-luncheonette-new-york"
  },
  {
    "alias": "new-york-rolln-katsu-new-york"
  },
  {
    "alias": "new-yoshi-japanese-restaurant-new-york"
  },
  {
    "alias": "nialls-new-york"
  },
  {
    "alias": "nica-trattoria-new-york"
  },
  {
    "alias": "nick-stef-s-steakhouse-new-york"
  },
  {
    "alias": "nicolas-restaurant-new-york"
  },
  {
    "alias": "nightbird-san-francisco"
  },
  {
    "alias": "nikki-beach-miami-beach-miami-beach-2"
  },
  {
    "alias": "nikko-hibachi-asian-fusion-new-york-2"
  },
  {
    "alias": "nina-may-washington"
  },
  {
    "alias": "nine-miami-beach"
  },
  {
    "alias": "ninos-46-new-york"
  },
  {
    "alias": "ninos-restaurant-new-york"
  },
  {
    "alias": "nippon-new-york"
  },
  {
    "alias": "nirvana-grille-laguna-beach"
  },
  {
    "alias": "nisei-san-francisco-3"
  },
  {
    "alias": "nittis-new-york"
  },
  {
    "alias": "niu-kitchen-miami"
  },
  {
    "alias": "nizza-new-york"
  },
  {
    "alias": "nm-cafe-palo-alto-3"
  },
  {
    "alias": "nm-cafe-short-hills"
  },
  {
    "alias": "nob-hill-club-san-francisco-5"
  },
  {
    "alias": "nobu-downtown-new-york"
  },
  {
    "alias": "nobu-fifty-seven-new-york-2"
  },
  {
    "alias": "nobu-miami-miami-beach"
  },
  {
    "alias": "nobu-palo-alto-palo-alto"
  },
  {
    "alias": "nobu-washington-d-c-washington-2"
  },
  {
    "alias": "nocciola-ristorante-new-york-3"
  },
  {
    "alias": "nocello-new-york"
  },
  {
    "alias": "noda-new-york"
  },
  {
    "alias": "nohu-rooftop-bar-and-restaurant-weehawken-3"
  },
  {
    "alias": "noi-due-new-york-2"
  },
  {
    "alias": "non-solo-piada-new-york"
  },
  {
    "alias": "nonna-beppa-soho-new-york"
  },
  {
    "alias": "nonono-new-york-6"
  },
  {
    "alias": "noodies-new-york"
  },
  {
    "alias": "norcina-san-francisco-2"
  },
  {
    "alias": "noreetuh-new-york"
  },
  {
    "alias": "noren-izakaya-san-francisco"
  },
  {
    "alias": "north-beach-restaurant-san-francisco"
  },
  {
    "alias": "north-square-new-york"
  },
  {
    "alias": "norwegian-cruise-line-epic-miami"
  },
  {
    "alias": "nougatine-at-jean-georges-new-york-3"
  },
  {
    "alias": "novikov-miami-miami"
  },
  {
    "alias": "novita-restaurant-new-york"
  },
  {
    "alias": "nowon-east-village-new-york-3"
  },
  {
    "alias": "nr-new-york-4"
  },
  {
    "alias": "nusr-et-steakhouse-miami"
  },
  {
    "alias": "nusr-et-steakhouse-new-york-4"
  },
  {
    "alias": "o-by-claude-le-tohic-san-francisco-3"
  },
  {
    "alias": "o-donoghues-pub-new-york"
  },
  {
    "alias": "o-ku-washington-2"
  },
  {
    "alias": "ocabanon-new-york"
  },
  {
    "alias": "ocean-prime-new-york"
  },
  {
    "alias": "ocean-prime-washington"
  },
  {
    "alias": "oceana-new-york-2"
  },
  {
    "alias": "oceans-new-york-new-york"
  },
  {
    "alias": "oceans-ten-miami-beach"
  },
  {
    "alias": "octavia-san-francisco"
  },
  {
    "alias": "odo-new-york"
  },
  {
    "alias": "off-the-record-washington"
  },
  {
    "alias": "off-the-wagon-new-york"
  },
  {
    "alias": "officina-washington"
  },
  {
    "alias": "ofrenda-new-york"
  },
  {
    "alias": "oiji-mi-new-york-2"
  },
  {
    "alias": "okami-miami-design-district-miami-3"
  },
  {
    "alias": "okinii-new-york"
  },
  {
    "alias": "okoze-sushi-san-francisco"
  },
  {
    "alias": "old-homestead-steak-house-new-york"
  },
  {
    "alias": "old-lisbon-restaurants-miami-miami"
  },
  {
    "alias": "old-tbilisi-garden-new-york-5"
  },
  {
    "alias": "ole-and-steen-new-york-4"
  },
  {
    "alias": "olea-new-haven-2"
  },
  {
    "alias": "olio-e-più-new-york-7"
  },
  {
    "alias": "olives-new-york-3"
  },
  {
    "alias": "ollies-sichuan-new-york-2"
  },
  {
    "alias": "ollin-restaurant-new-york"
  },
  {
    "alias": "olmsted-brooklyn"
  },
  {
    "alias": "omakai-sushi-miami-4"
  },
  {
    "alias": "omakase-by-korami-new-york"
  },
  {
    "alias": "omakase-room-by-maaser-new-york"
  },
  {
    "alias": "omakase-room-by-mitsu-new-york"
  },
  {
    "alias": "omars-mediterranean-cuisine-new-york-2"
  },
  {
    "alias": "omen-azen-new-york"
  },
  {
    "alias": "omusubi-gonbei-new-york-3"
  },
  {
    "alias": "on-ocean-7-cafe-miami-beach"
  },
  {
    "alias": "ondo-jersey-city-jersey-city"
  },
  {
    "alias": "one-dine-new-york"
  },
  {
    "alias": "one-if-by-land-two-if-by-sea-new-york"
  },
  {
    "alias": "one-pot-shabu-shabu-cupertino-cupertino"
  },
  {
    "alias": "one65-bistro-and-grill-san-francisco-2"
  },
  {
    "alias": "ootoya-times-square-new-york-2"
  },
  {
    "alias": "opal-washington"
  },
  {
    "alias": "open-kitchen-new-york-2"
  },
  {
    "alias": "orale-mexican-kitchen-hoboken"
  },
  {
    "alias": "orilla-bar-and-grill-miami-beach"
  },
  {
    "alias": "orsay-new-york"
  },
  {
    "alias": "orso-new-york"
  },
  {
    "alias": "osa-middletown"
  },
  {
    "alias": "osaka-miami"
  },
  {
    "alias": "osaka-vibe-japanese-cuisine-and-sushi-new-york-2"
  },
  {
    "alias": "osamil-new-york"
  },
  {
    "alias": "oscars-place-new-york-2"
  },
  {
    "alias": "oso-new-york"
  },
  {
    "alias": "osso-steakhouse-san-francisco"
  },
  {
    "alias": "osteria-57-new-york"
  },
  {
    "alias": "osteria-al-doge-new-york"
  },
  {
    "alias": "osteria-brooklyn-brooklyn"
  },
  {
    "alias": "osteria-del-porto-new-york"
  },
  {
    "alias": "osteria-del-teatro-miami-beach"
  },
  {
    "alias": "osteria-delbianco-new-york"
  },
  {
    "alias": "osteria-della-pace-new-york"
  },
  {
    "alias": "osteria-la-buca-los-angeles-2"
  },
  {
    "alias": "osteria-laguna-new-york"
  },
  {
    "alias": "osteria-morini-miami-beach"
  },
  {
    "alias": "osteria-morini-new-york-3"
  },
  {
    "alias": "osteria-mozza-los-angeles"
  },
  {
    "alias": "otaru-sushi-bar-new-haven"
  },
  {
    "alias": "otis-brooklyn-2"
  },
  {
    "alias": "otto-strada-hoboken-3"
  },
  {
    "alias": "otway-brooklyn"
  },
  {
    "alias": "ouzo-bay-baltimore"
  },
  {
    "alias": "overlook-new-york-2"
  },
  {
    "alias": "ox-and-tiger-san-francisco"
  },
  {
    "alias": "oxalis-brooklyn"
  },
  {
    "alias": "oxomoco-brooklyn-2"
  },
  {
    "alias": "oyster-oyster-washington"
  },
  {
    "alias": "oyster-riot-old-ebbitt-grill-washington-2"
  },
  {
    "alias": "p-j-clarkes-new-york-3"
  },
  {
    "alias": "p-s-kitchen-new-york-3"
  },
  {
    "alias": "pabu-izakaya-san-francisco-san-francisco"
  },
  {
    "alias": "pace-los-angeles-3"
  },
  {
    "alias": "paci-restaurant-southport"
  },
  {
    "alias": "padi-d-nyc-new-york"
  },
  {
    "alias": "paint-n-pour-new-york-4"
  },
  {
    "alias": "palace-restaurant-new-york"
  },
  {
    "alias": "palio-san-francisco-2"
  },
  {
    "alias": "palma-new-york"
  },
  {
    "alias": "palo-santo-brooklyn"
  },
  {
    "alias": "panda-harlem-new-york-2"
  },
  {
    "alias": "panineria-italiana-new-york"
  },
  {
    "alias": "panini-grill-new-york-2"
  },
  {
    "alias": "pao-by-paul-qui-miami-beach-3"
  },
  {
    "alias": "paolas-restaurant-new-york"
  },
  {
    "alias": "papi-steak-miami-beach"
  },
  {
    "alias": "papillon-bistro-and-bar-new-york"
  },
  {
    "alias": "pappas-taverna-new-york-2"
  },
  {
    "alias": "par-440-restaurant-and-lounge-short-hills"
  },
  {
    "alias": "parallel-37-san-francisco"
  },
  {
    "alias": "parallel-post-trumbull"
  },
  {
    "alias": "park-east-kosher-butchers-new-york-2"
  },
  {
    "alias": "park-tavern-san-francisco"
  },
  {
    "alias": "park-west-tavern-ridgewood"
  },
  {
    "alias": "parker-and-quinn-new-york"
  },
  {
    "alias": "parker-steaks-and-scotch-trumbull"
  },
  {
    "alias": "parks-pizza-parlor-fairfield"
  },
  {
    "alias": "parnells-pub-and-restaurant-new-york"
  },
  {
    "alias": "party-pizza-cromwell"
  },
  {
    "alias": "pascalou-new-york"
  },
  {
    "alias": "pascals-on-ponce-coral-gables"
  },
  {
    "alias": "pasquale-jones-new-york"
  },
  {
    "alias": "pasta-bistro-grill-new-york-2"
  },
  {
    "alias": "pasta-by-hudson-new-york-4"
  },
  {
    "alias": "pasta-lovers-trattoria-new-york-2"
  },
  {
    "alias": "pastis-new-york-3"
  },
  {
    "alias": "patiala-indian-grill-new-york"
  },
  {
    "alias": "patisserie-des-ambassades-new-york"
  },
  {
    "alias": "patrick-steakhouse-brooklyn"
  },
  {
    "alias": "patsys-italian-restaurant-new-york"
  },
  {
    "alias": "paul-and-jimmys-new-york"
  },
  {
    "alias": "paul-martins-american-grill-irvine"
  },
  {
    "alias": "pauls-on-times-square-new-york"
  },
  {
    "alias": "pazza-notte-new-york-2"
  },
  {
    "alias": "peak-new-york-2"
  },
  {
    "alias": "peak-thai-restaurant-new-york"
  },
  {
    "alias": "pearl-street-supper-club-brooklyn"
  },
  {
    "alias": "pearls-chinese-restaurant-new-york-2"
  },
  {
    "alias": "peasant-new-york"
  },
  {
    "alias": "pee-dee-steak-house-new-york"
  },
  {
    "alias": "peking-duck-house-new-york"
  },
  {
    "alias": "peking-duck-house-new-york-2"
  },
  {
    "alias": "pelican-cafe-miami-beach-3"
  },
  {
    "alias": "penn-sushi-new-york"
  },
  {
    "alias": "pennsylvania-6-nyc-new-york"
  },
  {
    "alias": "pepe-giallo-new-york"
  },
  {
    "alias": "pepolino-restaurant-new-york"
  },
  {
    "alias": "peppercorns-grill-hartford"
  },
  {
    "alias": "per-se-new-york"
  },
  {
    "alias": "pera-mediterranean-brasserie-new-york"
  },
  {
    "alias": "pera-soho-new-york"
  },
  {
    "alias": "perbacco-san-francisco"
  },
  {
    "alias": "perennial-towson"
  },
  {
    "alias": "pergola-new-york-4"
  },
  {
    "alias": "periyali-new-york"
  },
  {
    "alias": "perrine-new-york-city"
  },
  {
    "alias": "perry-st-new-york"
  },
  {
    "alias": "perrys-steakhouse-and-grille-coral-gables-coral-gables"
  },
  {
    "alias": "peter-luger-brooklyn-2"
  },
  {
    "alias": "petit-louis-bistro-baltimore"
  },
  {
    "alias": "petit-trois-los-angeles-15"
  },
  {
    "alias": "petite-boucherie-new-york"
  },
  {
    "alias": "petrarca-cucina-e-vino-new-york-2"
  },
  {
    "alias": "pheasant-brooklyn"
  },
  {
    "alias": "philippe-by-philippe-chow-new-york"
  },
  {
    "alias": "philippe-downtown-new-york"
  },
  {
    "alias": "phillips-seafood-baltimore"
  },
  {
    "alias": "piccola-cucina-estiatorio-new-york"
  },
  {
    "alias": "piccola-cucina-osteria-new-york-2"
  },
  {
    "alias": "piccola-cucina-uptown-new-york"
  },
  {
    "alias": "piccolino-ristorante-mission-viejo"
  },
  {
    "alias": "piccolo-angolo-restaurant-new-york"
  },
  {
    "alias": "piccolo-bistro-ridgewood"
  },
  {
    "alias": "pie-corps-brooklyn"
  },
  {
    "alias": "pied-piper-san-francisco-san-francisco"
  },
  {
    "alias": "pied-à-terre-miami-beach-2"
  },
  {
    "alias": "pietros-new-york"
  },
  {
    "alias": "pig-and-khao-new-york"
  },
  {
    "alias": "pig-n-whistle-public-house-new-york"
  },
  {
    "alias": "pineapple-club-new-york"
  },
  {
    "alias": "pinocchio-restaurant-new-york-3"
  },
  {
    "alias": "pinto-garden-new-york-3"
  },
  {
    "alias": "pio-pio-8-new-york-5"
  },
  {
    "alias": "piperade-san-francisco"
  },
  {
    "alias": "pirates-dinner-adventure-buena-park-2"
  },
  {
    "alias": "pisco-bar-saratoga"
  },
  {
    "alias": "pisillo-italian-panini-chelsea-new-york"
  },
  {
    "alias": "pisticci-new-york"
  },
  {
    "alias": "pizzapie-plainville-4"
  },
  {
    "alias": "pj-carneys-new-york"
  },
  {
    "alias": "pj-charlton-new-york-2"
  },
  {
    "alias": "plan-b-new-york-8"
  },
  {
    "alias": "planta-south-beach-miami-beach-3"
  },
  {
    "alias": "playwright-celtic-pub-new-york-4"
  },
  {
    "alias": "playwright-irish-pub-new-york-3"
  },
  {
    "alias": "plumed-horse-saratoga"
  },
  {
    "alias": "pm-fish-and-steak-house-miami"
  },
  {
    "alias": "pocha-32-new-york"
  },
  {
    "alias": "poco-nyc-new-york-15"
  },
  {
    "alias": "poke-fresh-new-york"
  },
  {
    "alias": "polash-new-york"
  },
  {
    "alias": "polenteria-los-gatos"
  },
  {
    "alias": "pommes-frites-new-york-2"
  },
  {
    "alias": "pomodoro-new-york-2"
  },
  {
    "alias": "pomp-and-circumstance-dining-williamsburg"
  },
  {
    "alias": "ponche-taqueria-and-cantina-new-york"
  },
  {
    "alias": "ponty-bistro-new-york-3"
  },
  {
    "alias": "popular-new-york-3"
  },
  {
    "alias": "porta-blu-menlo-park-2"
  },
  {
    "alias": "portale-new-york-2"
  },
  {
    "alias": "porteno-restaurant-new-york"
  },
  {
    "alias": "porter-house-bar-and-grill-new-york"
  },
  {
    "alias": "porto-alegre-brazilian-grill-and-bar-miami"
  },
  {
    "alias": "porto-leggero-jersey-city"
  },
  {
    "alias": "portofinos-restaurant-and-bar-new-haven"
  },
  {
    "alias": "poseidon-greek-restaurant-and-outdoor-lounge-miami-beach-2"
  },
  {
    "alias": "poulette-midtown-east-new-york"
  },
  {
    "alias": "poulette-rotisserie-new-york-2"
  },
  {
    "alias": "press-manhattan"
  },
  {
    "alias": "prestons-market-miami-beach-2"
  },
  {
    "alias": "primal-cut-steakhouse-new-york"
  },
  {
    "alias": "prime-fish-miami-beach"
  },
  {
    "alias": "prime-italian-miami-beach"
  },
  {
    "alias": "prime-one-twelve-miami-beach"
  },
  {
    "alias": "primola-restaurant-new-york"
  },
  {
    "alias": "print-new-york"
  },
  {
    "alias": "print-new-york-6"
  },
  {
    "alias": "providence-los-angeles-2"
  },
  {
    "alias": "pubbelly-sushi-brickell-miami-4"
  },
  {
    "alias": "pubbelly-sushi-miami-beach-miami-beach-3"
  },
  {
    "alias": "pure-grit-bbq-new-york"
  },
  {
    "alias": "pure-thai-cookhouse-new-york"
  },
  {
    "alias": "pylos-new-york"
  },
  {
    "alias": "quality-bistro-new-york-3"
  },
  {
    "alias": "quality-eats-new-york"
  },
  {
    "alias": "quality-italian-new-york"
  },
  {
    "alias": "quality-meats-new-york"
  },
  {
    "alias": "quantum-leap-new-york-4"
  },
  {
    "alias": "quarterdeck-arlington"
  },
  {
    "alias": "quatorze-new-york"
  },
  {
    "alias": "quechua-nostra-peruvian-food-new-york-5"
  },
  {
    "alias": "queen-miami-beach-miami-beach"
  },
  {
    "alias": "queensyard-new-york"
  },
  {
    "alias": "quill-washington"
  },
  {
    "alias": "quince-san-francisco"
  },
  {
    "alias": "quinto-miami"
  },
  {
    "alias": "r-house-wynwood-miami-2"
  },
  {
    "alias": "r-lounge-at-two-times-square-new-york"
  },
  {
    "alias": "r40-long-island-city-2"
  },
  {
    "alias": "ra-sushi-times-square-new-york"
  },
  {
    "alias": "rabbit-house-omakase-and-sake-bar-new-york"
  },
  {
    "alias": "rafele-ristorante-new-york"
  },
  {
    "alias": "rai-rai-ken-harlem-new-york"
  },
  {
    "alias": "raiz-modern-mexican-new-york-2"
  },
  {
    "alias": "raku-its-japanese-new-york-2"
  },
  {
    "alias": "raku-new-york-6"
  },
  {
    "alias": "ramerino-italian-prime-new-york"
  },
  {
    "alias": "rancho-tequileria-new-york-3"
  },
  {
    "alias": "randolphs-bar-and-lounge-new-york"
  },
  {
    "alias": "rania-washington"
  },
  {
    "alias": "raos-hollywood-los-angeles-2"
  },
  {
    "alias": "raos-new-york"
  },
  {
    "alias": "raouls-restaurant-new-york"
  },
  {
    "alias": "rare-steakhouse-and-tavern-washington-3"
  },
  {
    "alias": "rasa-new-york"
  },
  {
    "alias": "rasika-west-end-washington"
  },
  {
    "alias": "ravagh-persian-grill-new-york-2"
  },
  {
    "alias": "red-bamboo-new-york"
  },
  {
    "alias": "red-hook-lobster-pound-new-york-3"
  },
  {
    "alias": "red-hook-tavern-brooklyn"
  },
  {
    "alias": "red-rooster-harlem-new-york-3"
  },
  {
    "alias": "red-rooster-overtown-miami"
  },
  {
    "alias": "red-south-beach-miami-beach-3"
  },
  {
    "alias": "redemption-nyc-new-york"
  },
  {
    "alias": "redeye-grill-new-york-2"
  },
  {
    "alias": "redfarm-new-york"
  },
  {
    "alias": "redfarm-new-york-2"
  },
  {
    "alias": "reichenbach-hall-new-york"
  },
  {
    "alias": "renaissance-harlem-new-york"
  },
  {
    "alias": "republic-latin-fusion-brooklyn"
  },
  {
    "alias": "reserve-cut-new-york"
  },
  {
    "alias": "restaurant-anzu-san-francisco-7"
  },
  {
    "alias": "restaurant-jeanne-d-arc-san-francisco"
  },
  {
    "alias": "restaurant-serenade-chatham"
  },
  {
    "alias": "restaurante-tio-pepe-baltimore-2"
  },
  {
    "alias": "rh-guesthouse-the-dining-room-new-york"
  },
  {
    "alias": "rh-rooftop-restaurant-new-york-new-york-2"
  },
  {
    "alias": "rib-no-7-new-york"
  },
  {
    "alias": "ribs-on-the-run-mission-viejo"
  },
  {
    "alias": "ricardo-steak-house-new-york"
  },
  {
    "alias": "rice-kitchen-new-york"
  },
  {
    "alias": "ridgelys-market-baltimore"
  },
  {
    "alias": "riot-house-restaurant-and-bar-west-hollywood"
  },
  {
    "alias": "ristorante-la-perla-of-washington-washington-3"
  },
  {
    "alias": "ristorante-milano-san-francisco"
  },
  {
    "alias": "ristorante-tosca-washington"
  },
  {
    "alias": "riverpark-new-york"
  },
  {
    "alias": "riverwalk-cafe-miami"
  },
  {
    "alias": "robert-new-york-25"
  },
  {
    "alias": "roberts-steakhouse-new-york-3"
  },
  {
    "alias": "robin-san-francisco"
  },
  {
    "alias": "rocco-steakhouse-new-york"
  },
  {
    "alias": "rockmeisha-new-york"
  },
  {
    "alias": "roeys-new-york"
  },
  {
    "alias": "roka-akor-san-francisco-san-francisco-4"
  },
  {
    "alias": "rolfs-new-york"
  },
  {
    "alias": "romans-brooklyn-3"
  },
  {
    "alias": "roof-terrace-restaurant-washington-3"
  },
  {
    "alias": "rooftop-at-exchange-place-jersey-city-2"
  },
  {
    "alias": "rooh-palo-alto-palo-alto-2"
  },
  {
    "alias": "roots-restaurant-san-francisco"
  },
  {
    "alias": "roots-steakhouse-ridgewood-3"
  },
  {
    "alias": "roots-steakhouse-summit"
  },
  {
    "alias": "rosemary-s-new-york"
  },
  {
    "alias": "rosemarys-new-york"
  },
  {
    "alias": "rossinis-new-york"
  },
  {
    "alias": "rouge-studio-city-2"
  },
  {
    "alias": "routier-san-francisco"
  },
  {
    "alias": "row-house-new-york-6"
  },
  {
    "alias": "rowayton-market-norwalk"
  },
  {
    "alias": "roxy-bar-new-york-2"
  },
  {
    "alias": "royal-35-steakhouse-new-york"
  },
  {
    "alias": "rpm-italian-washington-4"
  },
  {
    "alias": "rt60-new-york"
  },
  {
    "alias": "rt60-new-york-3"
  },
  {
    "alias": "rue-57-new-york-3"
  },
  {
    "alias": "rue-lepic-san-francisco"
  },
  {
    "alias": "ruffian-new-york-4"
  },
  {
    "alias": "rumi-s-kitchen-washington"
  },
  {
    "alias": "russia-restaurant-los-angeles"
  },
  {
    "alias": "russian-samovar-restaurant-and-piano-bar-new-york-2"
  },
  {
    "alias": "rusty-pelican-key-biscayne-3"
  },
  {
    "alias": "rusty-scupper-baltimore"
  },
  {
    "alias": "ruths-chris-steak-house-new-york-5"
  },
  {
    "alias": "sadelles-new-york"
  },
  {
    "alias": "saffys-los-angeles-3"
  },
  {
    "alias": "saga-new-york-6"
  },
  {
    "alias": "sagaponack-new-york-6"
  },
  {
    "alias": "saigon-shack-new-york"
  },
  {
    "alias": "sails-bar-and-grill-norwalk"
  },
  {
    "alias": "saint-michaels-alley-palo-alto-2"
  },
  {
    "alias": "sakagura-new-york"
  },
  {
    "alias": "sakamai-new-york"
  },
  {
    "alias": "sake-bar-hagi-new-york-7"
  },
  {
    "alias": "salinas-new-york"
  },
  {
    "alias": "salma-new-york"
  },
  {
    "alias": "salt-arlington"
  },
  {
    "alias": "sam-sunny-new-york"
  },
  {
    "alias": "sama-street-brooklyn-2"
  },
  {
    "alias": "sambas-deli-new-york-2"
  },
  {
    "alias": "sams-grill-and-seafood-restaurant-san-francisco"
  },
  {
    "alias": "sams-place-new-york"
  },
  {
    "alias": "san-carlo-osteria-piemonte-new-york-2"
  },
  {
    "alias": "san-marino-ristorante-new-york-2"
  },
  {
    "alias": "san-pietro-new-york"
  },
  {
    "alias": "sandbox-miami-beach-2"
  },
  {
    "alias": "sandros-new-york"
  },
  {
    "alias": "sant-ambroeus-new-york"
  },
  {
    "alias": "sant-ambroeus-west-village-new-york"
  },
  {
    "alias": "santiagos-beer-garden-new-york"
  },
  {
    "alias": "santo-bruklin-brooklyn"
  },
  {
    "alias": "sanyuu-west-new-york"
  },
  {
    "alias": "sapoara-new-york-6"
  },
  {
    "alias": "sarabeths-central-park-new-york"
  },
  {
    "alias": "sardinia-enoteca-ristorante-miami-beach"
  },
  {
    "alias": "sardis-new-york"
  },
  {
    "alias": "saru-handroll-bar-san-francisco-2"
  },
  {
    "alias": "sasa-japanese-restaurant-san-francisco-2"
  },
  {
    "alias": "sasabune-express-new-york-2"
  },
  {
    "alias": "sasabune-new-york"
  },
  {
    "alias": "sathi-new-york"
  },
  {
    "alias": "satis-bistro-jersey-city"
  },
  {
    "alias": "sato-omakase-san-francisco"
  },
  {
    "alias": "savoi-latin-bistro-brooklyn-2"
  },
  {
    "alias": "sawa-sushi-sunnyvale"
  },
  {
    "alias": "sax-restaurant-and-lounge-washington-4"
  },
  {
    "alias": "sayola-restaurant-tenafly"
  },
  {
    "alias": "scalini-fedeli-chatham"
  },
  {
    "alias": "scalini-fedeli-new-york"
  },
  {
    "alias": "scampi-new-york-2"
  },
  {
    "alias": "scarlet-kitchen-and-lounge-rancho-mission-viejo-2"
  },
  {
    "alias": "scarpetta-miami-beach"
  },
  {
    "alias": "scarpetta-new-york"
  },
  {
    "alias": "scomas-restaurant-san-francisco-3"
  },
  {
    "alias": "scottadito-osteria-toscana-brooklyn"
  },
  {
    "alias": "sea-fire-grill-new-york"
  },
  {
    "alias": "sea-grill-coral-gables"
  },
  {
    "alias": "sea-wolf-williamsburg-waterfront-brooklyn"
  },
  {
    "alias": "seamores-chelsea-new-york"
  },
  {
    "alias": "seamores-upper-east-side-new-york"
  },
  {
    "alias": "seans-bar-and-kitchen-new-york"
  },
  {
    "alias": "seaport-food-lab-new-york-3"
  },
  {
    "alias": "seapot-hot-pot-sunnyvale"
  },
  {
    "alias": "sear-house-closter-2"
  },
  {
    "alias": "seasons-restaurant-washington"
  },
  {
    "alias": "seaspice-brasserie-and-lounge-miami"
  },
  {
    "alias": "secchu-yokota-new-york"
  },
  {
    "alias": "sel-et-poivre-new-york-2"
  },
  {
    "alias": "selbys-redwood-city-2"
  },
  {
    "alias": "semma-new-york-2"
  },
  {
    "alias": "sen-sakana-new-york"
  },
  {
    "alias": "senya-new-york"
  },
  {
    "alias": "senza-gluten-new-york-2"
  },
  {
    "alias": "seoul-new-york"
  },
  {
    "alias": "sequoia-washington-11"
  },
  {
    "alias": "serafina-italian-restaurant-meatpacking-new-york"
  },
  {
    "alias": "serafina-italian-restaurant-tribeca-new-york"
  },
  {
    "alias": "serafina-new-york-32"
  },
  {
    "alias": "serafina-san-francisco"
  },
  {
    "alias": "serena-rooftop-miami-beach"
  },
  {
    "alias": "serendipity-3-new-york"
  },
  {
    "alias": "serengeti-baltimore"
  },
  {
    "alias": "sergimmo-salumeria-new-york"
  },
  {
    "alias": "serra-by-birreria-at-eataly-flatiron-new-york"
  },
  {
    "alias": "sette-mezzo-new-york"
  },
  {
    "alias": "settepani-new-york-5"
  },
  {
    "alias": "seven-hills-san-francisco"
  },
  {
    "alias": "seven-reasons-washington"
  },
  {
    "alias": "seventh-state-restaurant-and-lounge-bethesda"
  },
  {
    "alias": "sevilla-restaurant-new-york"
  },
  {
    "alias": "sexy-fish-miami-miami"
  },
  {
    "alias": "sfoglia-restaurant-new-york"
  },
  {
    "alias": "sfoglina-downtown-washington"
  },
  {
    "alias": "sfoglina-rosslyn-arlington-2"
  },
  {
    "alias": "sfoglina-van-ness-washington-3"
  },
  {
    "alias": "shalom-japan-brooklyn"
  },
  {
    "alias": "shang-cafe-san-jose-3"
  },
  {
    "alias": "shanghai-mong-new-york"
  },
  {
    "alias": "shanghai-sogo-new-york"
  },
  {
    "alias": "shellys-back-room-washington-2"
  },
  {
    "alias": "shik-do-rak-garden-grove"
  },
  {
    "alias": "shiki-omakase-new-york-2"
  },
  {
    "alias": "shinn-east-new-york"
  },
  {
    "alias": "shion-69-leonard-st-new-york"
  },
  {
    "alias": "shoo-shoo-new-york"
  },
  {
    "alias": "shu-han-ju-new-york-2"
  },
  {
    "alias": "shuka-new-york-3"
  },
  {
    "alias": "shukette-new-york"
  },
  {
    "alias": "shuko-new-york-2"
  },
  {
    "alias": "shun-lee-palace-new-york"
  },
  {
    "alias": "shun-lee-west-and-cafe-new-york-2"
  },
  {
    "alias": "sidecar-new-york-2"
  },
  {
    "alias": "silky-kitchen-new-york-5"
  },
  {
    "alias": "silvana-new-york"
  },
  {
    "alias": "silver-lyan-washington"
  },
  {
    "alias": "silver-moon-cafe-greenbelt"
  },
  {
    "alias": "silver-star-new-york"
  },
  {
    "alias": "sings-deli-and-grocery-new-york"
  },
  {
    "alias": "sinigual-manhattan-2"
  },
  {
    "alias": "sip-and-co-new-york"
  },
  {
    "alias": "sip-sak-new-york"
  },
  {
    "alias": "sirenetta-seafood-and-raw-bar-hoboken"
  },
  {
    "alias": "sistina-new-york"
  },
  {
    "alias": "sixty-three-clinton-new-york"
  },
  {
    "alias": "skirt-steak-new-york"
  },
  {
    "alias": "slatterys-midtown-pub-new-york"
  },
  {
    "alias": "slutty-vegan-harlem-new-york"
  },
  {
    "alias": "smith-and-wollensky-miami-beach"
  },
  {
    "alias": "smith-and-wollensky-new-york"
  },
  {
    "alias": "smoke-house-restaurant-burbank-3"
  },
  {
    "alias": "smokin-joe-new-york"
  },
  {
    "alias": "snack-taverna-new-york"
  },
  {
    "alias": "so-good-closter"
  },
  {
    "alias": "socarrat-paella-bar-chelsea-new-york-3"
  },
  {
    "alias": "socarrat-paella-bar-midtown-east-new-york-3"
  },
  {
    "alias": "socarrat-paella-bar-nolita-new-york-3"
  },
  {
    "alias": "sofia-wine-bar-new-york"
  },
  {
    "alias": "sofreh-brooklyn"
  },
  {
    "alias": "soho-park-new-york-6"
  },
  {
    "alias": "soho-sushi-new-york"
  },
  {
    "alias": "som-bo-new-york-2"
  },
  {
    "alias": "sombrero-restaurant-new-york"
  },
  {
    "alias": "son-and-garden-san-francisco"
  },
  {
    "alias": "son-cubano-west-new-york-4"
  },
  {
    "alias": "sona-new-york"
  },
  {
    "alias": "song-e-napule-new-york"
  },
  {
    "alias": "sonoma-restaurant-and-wine-bar-washington"
  },
  {
    "alias": "sons-and-daughters-san-francisco"
  },
  {
    "alias": "soogil-new-york"
  },
  {
    "alias": "sophra-grill-new-york"
  },
  {
    "alias": "soregashi-los-angeles"
  },
  {
    "alias": "sorella-san-francisco"
  },
  {
    "alias": "sorrel-san-francisco-3"
  },
  {
    "alias": "sotto-13-new-york-7"
  },
  {
    "alias": "sotto-sopra-baltimore"
  },
  {
    "alias": "south-of-the-clouds-new-york"
  },
  {
    "alias": "southgate-bar-and-restaurant-new-york-4"
  },
  {
    "alias": "souvlaki-gr-midtown-new-york-3"
  },
  {
    "alias": "souvlaki-gr-new-york-9"
  },
  {
    "alias": "spaha-soul-and-bone-broth-new-york"
  },
  {
    "alias": "sparks-steak-house-new-york"
  },
  {
    "alias": "spice-symphony-new-york-3"
  },
  {
    "alias": "spiga-miami-beach"
  },
  {
    "alias": "spirit-of-new-york-new-york-3"
  },
  {
    "alias": "spqr-san-francisco"
  },
  {
    "alias": "springbone-kitchen-new-york"
  },
  {
    "alias": "spunto-new-york-7"
  },
  {
    "alias": "ssal-san-francisco"
  },
  {
    "alias": "st-anselm-brooklyn"
  },
  {
    "alias": "st-anselm-washington"
  },
  {
    "alias": "st-eves-ho-ho-kus"
  },
  {
    "alias": "st-jardim-new-york"
  },
  {
    "alias": "st-pats-bar-and-grill-new-york"
  },
  {
    "alias": "st-tropez-west-village-new-york-3"
  },
  {
    "alias": "state-bird-provisions-san-francisco"
  },
  {
    "alias": "state-grill-and-bar-new-york"
  },
  {
    "alias": "steak-frites-bistro-new-york-3"
  },
  {
    "alias": "steam-shanghai-asian-fusion-new-york-2"
  },
  {
    "alias": "steamers-grillhouse-los-gatos"
  },
  {
    "alias": "stella-34-trattoria-new-york"
  },
  {
    "alias": "stern-and-bow-closter"
  },
  {
    "alias": "stiltsville-fish-bar-miami-beach"
  },
  {
    "alias": "stingray-lounge-uptown-hoboken-2"
  },
  {
    "alias": "stix-bar-and-grill-new-york"
  },
  {
    "alias": "stk-steakhouse-downtown-new-york-2"
  },
  {
    "alias": "stk-steakhouse-miami-beach-miami-beach"
  },
  {
    "alias": "stk-steakhouse-midtown-new-york"
  },
  {
    "alias": "stk-steakhouse-midtown-nyc-new-york"
  },
  {
    "alias": "stk-steakhouse-rooftop-nyc-new-york"
  },
  {
    "alias": "stoney-river-towson"
  },
  {
    "alias": "storico-new-york"
  },
  {
    "alias": "strada-in-the-grove-miami"
  },
  {
    "alias": "strip-house-downtown-new-york-3"
  },
  {
    "alias": "strip-house-new-york-6"
  },
  {
    "alias": "strip-house-speakeasy-new-york"
  },
  {
    "alias": "stripsteak-miami-beach-5"
  },
  {
    "alias": "stubborn-seed-miami-beach"
  },
  {
    "alias": "sugar-factory-new-york-8"
  },
  {
    "alias": "sugar-factory-restaurant-and-night-club-miami-beach-3"
  },
  {
    "alias": "sugarcane-raw-bar-grill-miami"
  },
  {
    "alias": "sugarfish-by-sushi-nozawa-los-angeles-10"
  },
  {
    "alias": "sugarfish-by-sushi-nozawa-new-york"
  },
  {
    "alias": "sugarfish-by-sushi-nozawa-new-york-2"
  },
  {
    "alias": "sugarfish-soho-new-york"
  },
  {
    "alias": "sukhumvit-51-new-york"
  },
  {
    "alias": "suki-new-york-5"
  },
  {
    "alias": "summit-house-summit"
  },
  {
    "alias": "sun-rise-house-north-haven"
  },
  {
    "alias": "sunac-natural-deli-brooklyn"
  },
  {
    "alias": "supra-washington-2"
  },
  {
    "alias": "suprema-provisions-new-york"
  },
  {
    "alias": "surya-madam-ji-new-york"
  },
  {
    "alias": "sushi-amane-new-york"
  },
  {
    "alias": "sushi-ann-restaurant-new-york"
  },
  {
    "alias": "sushi-azabu-new-york"
  },
  {
    "alias": "sushi-by-bou-jersey-city-jersey-city-4"
  },
  {
    "alias": "sushi-by-bou-times-square-new-york"
  },
  {
    "alias": "sushi-by-boū-nomad-new-york-4"
  },
  {
    "alias": "sushi-by-m-new-york"
  },
  {
    "alias": "sushi-dojo-nyc-new-york"
  },
  {
    "alias": "sushi-fussion-47th-nyc-new-york"
  },
  {
    "alias": "sushi-gakyu-washington"
  },
  {
    "alias": "sushi-garage-miami-beach"
  },
  {
    "alias": "sushi-ginza-onodera-new-york-2"
  },
  {
    "alias": "sushi-hakko-san-francisco"
  },
  {
    "alias": "sushi-ike-los-angeles"
  },
  {
    "alias": "sushi-ishikawa-new-york-5"
  },
  {
    "alias": "sushi-ishikawa-new-york-6"
  },
  {
    "alias": "sushi-kai-new-york-3"
  },
  {
    "alias": "sushi-kaito-new-york"
  },
  {
    "alias": "sushi-katsuei-brooklyn"
  },
  {
    "alias": "sushi-katsuei-new-york"
  },
  {
    "alias": "sushi-lab-new-york-5"
  },
  {
    "alias": "sushi-lin-brooklyn-brooklyn-4"
  },
  {
    "alias": "sushi-muse-new-york"
  },
  {
    "alias": "sushi-noz-new-york"
  },
  {
    "alias": "sushi-of-gari-46-new-york"
  },
  {
    "alias": "sushi-of-gari-new-york-15"
  },
  {
    "alias": "sushi-of-gari-tribeca-new-york-3"
  },
  {
    "alias": "sushi-of-gari-uws-gari-columbus-new-york"
  },
  {
    "alias": "sushi-ogawa-washington"
  },
  {
    "alias": "sushi-on-jones-new-york"
  },
  {
    "alias": "sushi-on-jones-new-york-3"
  },
  {
    "alias": "sushi-ryusei-new-york-2"
  },
  {
    "alias": "sushi-sato-san-francisco"
  },
  {
    "alias": "sushi-seki-chelsea-new-york"
  },
  {
    "alias": "sushi-seki-new-york-9"
  },
  {
    "alias": "sushi-seki-times-square-new-york-4"
  },
  {
    "alias": "sushi-sen-nin-new-york"
  },
  {
    "alias": "sushi-shin-redwood-city"
  },
  {
    "alias": "sushi-star-new-york"
  },
  {
    "alias": "sushi-sushi-new-york-6"
  },
  {
    "alias": "sushi-taro-washington"
  },
  {
    "alias": "sushi-teria-new-york-7"
  },
  {
    "alias": "sushi-tokyo-chelsea-new-york-3"
  },
  {
    "alias": "sushi-yasuda-new-york"
  },
  {
    "alias": "sushi-yuzu-toluca-lake"
  },
  {
    "alias": "sushi-zo-hanare-new-york-2"
  },
  {
    "alias": "sushi-zo-midtown-new-york-new-york"
  },
  {
    "alias": "sushi-zo-new-york-new-york"
  },
  {
    "alias": "sushiato-brickell-miami"
  },
  {
    "alias": "suteishi-new-york-3"
  },
  {
    "alias": "sveta-new-york"
  },
  {
    "alias": "swahili-village-beltsville-6"
  },
  {
    "alias": "swan-miami-2"
  },
  {
    "alias": "sweet-catch-new-york"
  },
  {
    "alias": "sweetbriar-new-york-3"
  },
  {
    "alias": "swing-46-new-york-2"
  },
  {
    "alias": "sylvias-restaurant-new-york"
  },
  {
    "alias": "symposium-new-york-2"
  },
  {
    "alias": "taam-tov-new-york"
  },
  {
    "alias": "taberna-del-alabardero-washington"
  },
  {
    "alias": "tabernacle-steakhouse-new-york"
  },
  {
    "alias": "table-d-hôte-new-york-2"
  },
  {
    "alias": "tacos-el-fogon-menlo-park"
  },
  {
    "alias": "tadich-grill-san-francisco"
  },
  {
    "alias": "tagliata-baltimore"
  },
  {
    "alias": "tahan-sushi-new-york-2"
  },
  {
    "alias": "tai-pan-palo-alto-3"
  },
  {
    "alias": "taichi-pot-shabu-shabu-santa-clara-10"
  },
  {
    "alias": "taiki-brooklyn"
  },
  {
    "alias": "tail-up-goat-washington"
  },
  {
    "alias": "takahachi-tribeca-restaurant-new-york"
  },
  {
    "alias": "takara-14-washington"
  },
  {
    "alias": "take31-new-york"
  },
  {
    "alias": "takumi-omakase-new-york"
  },
  {
    "alias": "tam-sang-thai-kitchen-new-york-2"
  },
  {
    "alias": "tamarind-new-york-4"
  },
  {
    "alias": "tamarine-restaurant-palo-alto"
  },
  {
    "alias": "tang-pavilion-new-york-3"
  },
  {
    "alias": "tanoshi-sushi-sake-bar-new-york"
  },
  {
    "alias": "tao-asian-bistro-los-angeles-2"
  },
  {
    "alias": "tao-downtown-restaurant-new-york-city"
  },
  {
    "alias": "tao-uptown-new-york-2"
  },
  {
    "alias": "taps-fish-house-and-brewery-corona"
  },
  {
    "alias": "taqueria-el-patron-mexican-grill-brooklyn"
  },
  {
    "alias": "taqueria-guadalupe-new-york"
  },
  {
    "alias": "taqueria-meza-new-york"
  },
  {
    "alias": "tara-rose-new-york"
  },
  {
    "alias": "tartina-new-york-4"
  },
  {
    "alias": "tartine-new-york"
  },
  {
    "alias": "tartinery-new-york-13"
  },
  {
    "alias": "taru-new-york"
  },
  {
    "alias": "tasca-new-york-2"
  },
  {
    "alias": "tatel-miami-miami-beach-2"
  },
  {
    "alias": "taureau-new-york"
  },
  {
    "alias": "tavern-on-jane-new-york"
  },
  {
    "alias": "tavern-on-the-green-new-york-7"
  },
  {
    "alias": "taverna-kyclades-east-village-new-york-2"
  },
  {
    "alias": "taverna-palo-alto"
  },
  {
    "alias": "tavira-restaurant-chevy-chase"
  },
  {
    "alias": "tavola-new-york"
  },
  {
    "alias": "tea-and-sympathy-new-york"
  },
  {
    "alias": "teddy-and-the-bully-bar-washington"
  },
  {
    "alias": "teds-montana-grill-new-york-new-york"
  },
  {
    "alias": "telefèric-barcelona-los-gatos-los-gatos-2"
  },
  {
    "alias": "telefèric-barcelona-palo-alto-palo-alto"
  },
  {
    "alias": "temple-court-new-york"
  },
  {
    "alias": "tempura-matsui-new-york"
  },
  {
    "alias": "ten-hope-brooklyn"
  },
  {
    "alias": "ten-twenty-post-oyster-bar-and-bistro-darien"
  },
  {
    "alias": "tenzan-new-york-3"
  },
  {
    "alias": "teriyaki-r-us-new-york-4"
  },
  {
    "alias": "terre-brooklyn"
  },
  {
    "alias": "terzo-san-francisco"
  },
  {
    "alias": "tessa-new-york"
  },
  {
    "alias": "tesse-west-hollywood-3"
  },
  {
    "alias": "texas-de-brazil-miami-beach-2"
  },
  {
    "alias": "texas-rotisserie-and-grill-new-york"
  },
  {
    "alias": "thai-peppercorn-new-york-2"
  },
  {
    "alias": "thalassa-restaurant-new-york"
  },
  {
    "alias": "the-ainsworth-hoboken-hoboken"
  },
  {
    "alias": "the-ainsworth-midtown-new-york"
  },
  {
    "alias": "the-alvin-public-house-new-york"
  },
  {
    "alias": "the-artist-tree-studio-dispensary-lounge-west-hollywood"
  },
  {
    "alias": "the-ashford-jersey-city-2"
  },
  {
    "alias": "the-bar-downstairs-and-kitchen-new-york-3"
  },
  {
    "alias": "the-bar-room-at-the-modern-new-york-3"
  },
  {
    "alias": "the-bar-room-new-york"
  },
  {
    "alias": "the-bar-room-new-york-7"
  },
  {
    "alias": "the-barish-los-angeles"
  },
  {
    "alias": "the-bazaar-by-josé-andrés-miami-beach-3"
  },
  {
    "alias": "the-biergarten-at-the-standard-high-line-new-york"
  },
  {
    "alias": "the-blue-dog-cookhouse-and-bar-new-york"
  },
  {
    "alias": "the-boil-jersey-city"
  },
  {
    "alias": "the-boil-new-york-5"
  },
  {
    "alias": "the-bombay-club-washington"
  },
  {
    "alias": "the-brazen-tavern-new-york"
  },
  {
    "alias": "the-bridge-los-angeles-2"
  },
  {
    "alias": "the-brooklyneer-new-york"
  },
  {
    "alias": "the-butchers-daughter-new-york-2"
  },
  {
    "alias": "the-bygone-baltimore"
  },
  {
    "alias": "the-carriage-house-restaurant-millburn"
  },
  {
    "alias": "the-cavalier-san-francisco"
  },
  {
    "alias": "the-center-club-baltimore"
  },
  {
    "alias": "the-champagne-bar-new-york-2"
  },
  {
    "alias": "the-chelsea-bell-new-york"
  },
  {
    "alias": "the-chelsea-fairfield"
  },
  {
    "alias": "the-chemistry-room-new-york-3"
  },
  {
    "alias": "the-chester-new-york"
  },
  {
    "alias": "the-cibo-new-york"
  },
  {
    "alias": "the-clam-new-york"
  },
  {
    "alias": "the-clocktower-new-york"
  },
  {
    "alias": "the-commons-chelsea-new-york"
  },
  {
    "alias": "the-core-club-new-york-3"
  },
  {
    "alias": "the-craftsman-new-york-2"
  },
  {
    "alias": "the-crooked-knife-new-york-3"
  },
  {
    "alias": "the-crosby-bar-new-york"
  },
  {
    "alias": "the-deck-at-island-gardens-miami-3"
  },
  {
    "alias": "the-dining-room-short-hills-2"
  },
  {
    "alias": "the-district-new-york"
  },
  {
    "alias": "the-django-new-york"
  },
  {
    "alias": "the-dolar-shop-new-york"
  },
  {
    "alias": "the-dorian-san-francisco"
  },
  {
    "alias": "the-dutch-new-york"
  },
  {
    "alias": "the-ear-inn-new-york"
  },
  {
    "alias": "the-east-pole-new-york"
  },
  {
    "alias": "the-edge-harlem-new-york"
  },
  {
    "alias": "the-elgin-new-york"
  },
  {
    "alias": "the-elk-new-york"
  },
  {
    "alias": "the-empire-rooftop-new-york-3"
  },
  {
    "alias": "the-expat-new-york-2"
  },
  {
    "alias": "the-flatiron-room-murray-hill-new-york"
  },
  {
    "alias": "the-flatiron-room-nomad-new-york-3"
  },
  {
    "alias": "the-folly-new-york"
  },
  {
    "alias": "the-fulton-by-jean-georges-new-york-2"
  },
  {
    "alias": "the-garden-court-san-francisco"
  },
  {
    "alias": "the-greek-new-york-2"
  },
  {
    "alias": "the-greenhouse-washington-2"
  },
  {
    "alias": "the-greens-new-york-4"
  },
  {
    "alias": "the-grey-dog-new-york-18"
  },
  {
    "alias": "the-grill-new-york-4"
  },
  {
    "alias": "the-grisly-pear-new-york"
  },
  {
    "alias": "the-half-pint-new-york"
  },
  {
    "alias": "the-happiest-hour-new-york"
  },
  {
    "alias": "the-hero-ranch-kitchen-saratoga-2"
  },
  {
    "alias": "the-hugh-new-york"
  },
  {
    "alias": "the-huntley-taverne-summit-2"
  },
  {
    "alias": "the-independent-new-york-3"
  },
  {
    "alias": "the-jeffrey-craft-beer-and-bites-new-york"
  },
  {
    "alias": "the-junction-new-york"
  },
  {
    "alias": "the-keg-room-new-york"
  },
  {
    "alias": "the-knife-parrilla-argentina-baysides-marketplace-miami"
  },
  {
    "alias": "the-lafayette-washington-3"
  },
  {
    "alias": "the-lambs-club-new-york"
  },
  {
    "alias": "the-lavaux-new-york"
  },
  {
    "alias": "the-leopard-at-des-artistes-new-york"
  },
  {
    "alias": "the-leroy-house-new-york-5"
  },
  {
    "alias": "the-lexington-house-los-gatos"
  },
  {
    "alias": "the-lexington-social-new-york"
  },
  {
    "alias": "the-liberty-new-york-3"
  },
  {
    "alias": "the-library-at-the-public-new-york"
  },
  {
    "alias": "the-lobster-club-new-york-2"
  },
  {
    "alias": "the-loeb-boathouse-central-park-new-york-4"
  },
  {
    "alias": "the-long-room-new-york"
  },
  {
    "alias": "the-lounge-san-francisco"
  },
  {
    "alias": "the-magic-castle-hollywood"
  },
  {
    "alias": "the-malt-house-new-york"
  },
  {
    "alias": "the-mark-restaurant-new-york-2"
  },
  {
    "alias": "the-marshal-new-york"
  },
  {
    "alias": "the-maze-new-york-3"
  },
  {
    "alias": "the-mean-fiddler-new-york"
  },
  {
    "alias": "the-melting-pot-arlington-5"
  },
  {
    "alias": "the-melting-pot-towson-towson-2"
  },
  {
    "alias": "the-mermaid-inn-chelsea-new-york"
  },
  {
    "alias": "the-met-dining-room-new-york"
  },
  {
    "alias": "the-milling-room-new-york-2"
  },
  {
    "alias": "the-modern-new-york-3"
  },
  {
    "alias": "the-monocle-washington"
  },
  {
    "alias": "the-musket-room-new-york"
  },
  {
    "alias": "the-noortwyck-new-york"
  },
  {
    "alias": "the-north-fork-new-york-2"
  },
  {
    "alias": "the-oak-room-restaurant-san-francisco"
  },
  {
    "alias": "the-ocean-grill-miami-beach"
  },
  {
    "alias": "the-oceanaire-seafood-room-baltimore"
  },
  {
    "alias": "the-oceanaire-seafood-room-washington-3"
  },
  {
    "alias": "the-odeon-new-york"
  },
  {
    "alias": "the-orchard-townhouse-new-york"
  },
  {
    "alias": "the-organic-grill-new-york-132"
  },
  {
    "alias": "the-original-soupman-new-york-7"
  },
  {
    "alias": "the-palm-court-new-york"
  },
  {
    "alias": "the-palm-midtown-new-york"
  },
  {
    "alias": "the-palm-washington-dc-washington-2"
  },
  {
    "alias": "the-palms-los-gatos"
  },
  {
    "alias": "the-park-room-new-york"
  },
  {
    "alias": "the-parlour-at-the-roger-hotel-new-york"
  },
  {
    "alias": "the-parlour-new-york-2"
  },
  {
    "alias": "the-perfect-pint-new-york"
  },
  {
    "alias": "the-picnic-basket-new-york"
  },
  {
    "alias": "the-pink-elephant-san-francisco-5"
  },
  {
    "alias": "the-polo-bar-new-york"
  },
  {
    "alias": "the-prime-rib-baltimore"
  },
  {
    "alias": "the-prime-rib-washington"
  },
  {
    "alias": "the-progress-san-francisco-2"
  },
  {
    "alias": "the-red-hen-washington-4"
  },
  {
    "alias": "the-regency-bar-and-grill-new-york-2"
  },
  {
    "alias": "the-restaurant-at-rowayton-seafood-norwalk"
  },
  {
    "alias": "the-restaurant-at-w-south-beach-miami-beach-3"
  },
  {
    "alias": "the-river-café-brooklyn-3"
  },
  {
    "alias": "the-river-oyster-bar-miami-4"
  },
  {
    "alias": "the-rotunda-san-francisco-2"
  },
  {
    "alias": "the-russian-tea-room-new-york"
  },
  {
    "alias": "the-shadmoor-new-york"
  },
  {
    "alias": "the-shota-san-francisco"
  },
  {
    "alias": "the-smith-new-york-7"
  },
  {
    "alias": "the-spaniard-new-york"
  },
  {
    "alias": "the-standard-grill-new-york-3"
  },
  {
    "alias": "the-standard-plaza-new-york"
  },
  {
    "alias": "the-stinger-cocktail-bar-and-kitchen-new-york-3"
  },
  {
    "alias": "the-tailor-public-house-new-york"
  },
  {
    "alias": "the-terrace-and-outdoor-gardens-new-york-2"
  },
  {
    "alias": "the-tyger-new-york-2"
  },
  {
    "alias": "the-vault-garden-san-francisco-2"
  },
  {
    "alias": "the-vault-steakhouse-san-francisco-2"
  },
  {
    "alias": "the-warren-new-york"
  },
  {
    "alias": "the-waterfront-restaurant-san-francisco-5"
  },
  {
    "alias": "the-waverly-inn-and-garden-new-york-2"
  },
  {
    "alias": "the-whitby-bar-and-restaurant-new-york"
  },
  {
    "alias": "the-wilson-nyc-new-york-2"
  },
  {
    "alias": "third-avenue-ale-house-new-york"
  },
  {
    "alias": "thirteen-water-new-york"
  },
  {
    "alias": "three-roosters-new-york"
  },
  {
    "alias": "thyme-palo-alto"
  },
  {
    "alias": "tia-pol-new-york"
  },
  {
    "alias": "tian-fu-lou-new-york"
  },
  {
    "alias": "tiger-sugar-new-york-city"
  },
  {
    "alias": "tim-ho-wan-hells-kitchen-new-york-2"
  },
  {
    "alias": "tinas-cuban-cuisine-new-york-13"
  },
  {
    "alias": "tio-pepe-new-york"
  },
  {
    "alias": "tios-bistro-new-york"
  },
  {
    "alias": "tito-murphys-new-york"
  },
  {
    "alias": "tocqueville-new-york-6"
  },
  {
    "alias": "toku-unagi-and-sushi-west-hollywood-2"
  },
  {
    "alias": "tokyo-record-bar-new-york"
  },
  {
    "alias": "toledo-restaurant-new-york"
  },
  {
    "alias": "toloache-50-new-york"
  },
  {
    "alias": "toloache-82nd-new-york"
  },
  {
    "alias": "tomi-jazz-new-york"
  },
  {
    "alias": "tomiño-taberna-gallega-new-york"
  },
  {
    "alias": "tommy-bahama-restaurant-bar-store-new-york-new-york"
  },
  {
    "alias": "tonga-room-and-hurricane-club-san-francisco"
  },
  {
    "alias": "tony-and-joes-seafood-place-washington"
  },
  {
    "alias": "tonys-di-napoli-new-york-4"
  },
  {
    "alias": "tonys-di-napoli-new-york-7"
  },
  {
    "alias": "top-of-the-mark-san-francisco"
  },
  {
    "alias": "top-thai-greenwich-new-york"
  },
  {
    "alias": "top-thai-vintage-new-york-3"
  },
  {
    "alias": "topaz-thai-authentic-new-york"
  },
  {
    "alias": "torch-and-crown-brewing-company-new-york"
  },
  {
    "alias": "torien-new-york"
  },
  {
    "alias": "torishin-new-york-3"
  },
  {
    "alias": "toro-toro-miami-7"
  },
  {
    "alias": "torrisi-new-york-2"
  },
  {
    "alias": "tosca-cafe-san-francisco-3"
  },
  {
    "alias": "toscana-49-new-york"
  },
  {
    "alias": "toscana-divino-miami"
  },
  {
    "alias": "toto-restaurant-fairfield"
  },
  {
    "alias": "towa-new-york-3"
  },
  {
    "alias": "trabuco-oaks-steak-house-trabuco-canyon"
  },
  {
    "alias": "tracks-raw-bar-and-grill-new-york-2"
  },
  {
    "alias": "traif-brooklyn"
  },
  {
    "alias": "trattoria-casa-di-isacco-new-york"
  },
  {
    "alias": "trattoria-dellarte-new-york"
  },
  {
    "alias": "trattoria-miami-beach"
  },
  {
    "alias": "trattoria-pesce-pasta-new-york"
  },
  {
    "alias": "trattoria-trecolori-new-york"
  },
  {
    "alias": "tre-scalini-restaurant-new-haven"
  },
  {
    "alias": "treadwell-park-new-york"
  },
  {
    "alias": "trestle-san-francisco-5"
  },
  {
    "alias": "tri-tip-grill-new-york-2"
  },
  {
    "alias": "tribeca-grill-new-york-2"
  },
  {
    "alias": "true-chesapeake-oyster-baltimore"
  },
  {
    "alias": "truffles-mystery-new-york-2"
  },
  {
    "alias": "trulucks-oceans-finest-seafood-and-crab-miami"
  },
  {
    "alias": "trulucks-oceans-finest-seafood-and-crab-washington"
  },
  {
    "alias": "trust-bae-new-york-2"
  },
  {
    "alias": "tsukimi-new-york"
  },
  {
    "alias": "tsunami-panhandle-san-francisco-2"
  },
  {
    "alias": "tudor-city-steakhouse-new-york"
  },
  {
    "alias": "tue-thai-restaurant-new-york"
  },
  {
    "alias": "tulcingo-restaurant-new-york-3"
  },
  {
    "alias": "tuome-new-york-2"
  },
  {
    "alias": "tur-kitchen-coral-gables"
  },
  {
    "alias": "turco-mediterranean-grill-new-york"
  },
  {
    "alias": "turkish-cuisine-new-york"
  },
  {
    "alias": "turkish-kitchen-new-york"
  },
  {
    "alias": "turks-and-frogs-new-york"
  },
  {
    "alias": "turks-inn-brooklyn"
  },
  {
    "alias": "turkuaz-restaurant-new-york-16"
  },
  {
    "alias": "turntable-at-lord-stanley-san-francisco"
  },
  {
    "alias": "tuscany-steakhouse-new-york"
  },
  {
    "alias": "twenty-seven-restaurant-and-bar-miami-beach"
  },
  {
    "alias": "txikito-new-york"
  },
  {
    "alias": "tír-na-nóg-new-york-14"
  },
  {
    "alias": "ubani-new-york-2"
  },
  {
    "alias": "uchi-miami"
  },
  {
    "alias": "udon-lab-new-york"
  },
  {
    "alias": "ugly-baby-brooklyn-3"
  },
  {
    "alias": "uluh-new-york"
  },
  {
    "alias": "umami-sushi-new-york"
  },
  {
    "alias": "ume-williamsburg-brooklyn"
  },
  {
    "alias": "un-plaza-grill-new-york"
  },
  {
    "alias": "una-pizza-napoletana-new-york-2"
  },
  {
    "alias": "uncaged-mimosas-washington"
  },
  {
    "alias": "uncle-jacks-steakhouse-westside-new-york-3"
  },
  {
    "alias": "union-club-new-york"
  },
  {
    "alias": "union-kitchen-west-hartford"
  },
  {
    "alias": "union-league-cafe-new-haven"
  },
  {
    "alias": "union-league-club-new-york"
  },
  {
    "alias": "union-square-cafe-new-york-8"
  },
  {
    "alias": "university-club-new-york"
  },
  {
    "alias": "uotora-brooklyn"
  },
  {
    "alias": "upland-new-york"
  },
  {
    "alias": "upstairs-at-the-kimberly-new-york"
  },
  {
    "alias": "urban-hawker-new-york"
  },
  {
    "alias": "urban-vegan-kitchen-new-york"
  },
  {
    "alias": "ushiwakamaru-new-york-4"
  },
  {
    "alias": "uva-new-york"
  },
  {
    "alias": "uva-next-door-new-york-4"
  },
  {
    "alias": "valerie-new-york"
  },
  {
    "alias": "valla-table-new-york"
  },
  {
    "alias": "vatan-indian-vegetarian-new-york-2"
  },
  {
    "alias": "velvet-room-san-francisco"
  },
  {
    "alias": "veranda-miami-beach-2"
  },
  {
    "alias": "veranda-new-york-2"
  },
  {
    "alias": "verōnika-new-york-2"
  },
  {
    "alias": "vestry-new-york"
  },
  {
    "alias": "via-carota-new-york"
  },
  {
    "alias": "viceversa-new-york"
  },
  {
    "alias": "victors-cafe-new-york-6"
  },
  {
    "alias": "victory-restaurant-and-lounge-miami-2"
  },
  {
    "alias": "vida-miami-beach-2"
  },
  {
    "alias": "vida-verde-new-york"
  },
  {
    "alias": "villa-azur-miami-beach-2"
  },
  {
    "alias": "villa-mosconi-new-york"
  },
  {
    "alias": "villa-taverna-san-francisco"
  },
  {
    "alias": "village-restaurant-9-street-new-york"
  },
  {
    "alias": "villanelle-new-york"
  },
  {
    "alias": "villard-restaurant-new-york"
  },
  {
    "alias": "vina-enoteca-palo-alto-4"
  },
  {
    "alias": "vinatería-harlem"
  },
  {
    "alias": "vinegar-hill-house-brooklyn"
  },
  {
    "alias": "vino-teca-san-francisco"
  },
  {
    "alias": "virgils-real-bbq-new-york-new-york"
  },
  {
    "alias": "viron-rondo-osteria-cheshire"
  },
  {
    "alias": "viva-toro-brooklyn-3"
  },
  {
    "alias": "vu-jersey-city"
  },
  {
    "alias": "wallse-new-york-8"
  },
  {
    "alias": "walrus-rodeo-miami"
  },
  {
    "alias": "washington-prime-south-norwalk"
  },
  {
    "alias": "watermark-new-york"
  },
  {
    "alias": "wayan-new-york-4"
  },
  {
    "alias": "wayfare-tavern-san-francisco-2"
  },
  {
    "alias": "wayla-new-york"
  },
  {
    "alias": "weinstein-passport-dining-hall-new-york"
  },
  {
    "alias": "west-end-bar-and-grill-new-york"
  },
  {
    "alias": "west-side-steakhouse-new-york-2"
  },
  {
    "alias": "westlight-brooklyn"
  },
  {
    "alias": "westville-ny-2"
  },
  {
    "alias": "wet-restaurant-and-lounge-miami-5"
  },
  {
    "alias": "while-we-were-young-new-york"
  },
  {
    "alias": "white-horse-tavern-new-york"
  },
  {
    "alias": "whitmans-hudson-yards-new-york"
  },
  {
    "alias": "wicked-willys-new-york"
  },
  {
    "alias": "wild-new-york"
  },
  {
    "alias": "wildair-new-york"
  },
  {
    "alias": "wilfie-and-nell-new-york"
  },
  {
    "alias": "willow-vegan-restaurant-new-york"
  },
  {
    "alias": "wine-cellar-restaurant-los-gatos"
  },
  {
    "alias": "wineworks-for-everyone-mission-viejo"
  },
  {
    "alias": "wogies-bar-and-grill-new-york-3"
  },
  {
    "alias": "wokuni-new-york"
  },
  {
    "alias": "wolf-and-lamb-steakhouse-new-york"
  },
  {
    "alias": "wolfgangs-steakhouse-new-york-10"
  },
  {
    "alias": "wolfgangs-steakhouse-new-york-2"
  },
  {
    "alias": "wolfgangs-steakhouse-new-york-4"
  },
  {
    "alias": "wolfgangs-steakhouse-new-york-5"
  },
  {
    "alias": "wollensky-grill-new-york"
  },
  {
    "alias": "wolvesmouth-at-the-wolvesden-los-angeles"
  },
  {
    "alias": "wondee-siam-new-york-3"
  },
  {
    "alias": "wonder-pig-kbbq-long-island-city"
  },
  {
    "alias": "woodmont-grill-bethesda"
  },
  {
    "alias": "world-yacht-new-york"
  },
  {
    "alias": "wu-liang-ye-new-york"
  },
  {
    "alias": "xacalli-kitchen-silverado"
  },
  {
    "alias": "xian-famous-foods-new-york-40"
  },
  {
    "alias": "xiquet-by-danny-lledó-washington"
  },
  {
    "alias": "xixa-brooklyn"
  },
  {
    "alias": "xus-public-house-new-york-2"
  },
  {
    "alias": "yakiniku-futago-new-york"
  },
  {
    "alias": "yakiniku-gen-new-york"
  },
  {
    "alias": "yakiniku-toraji-new-york"
  },
  {
    "alias": "yakiniq-san-francisco-4"
  },
  {
    "alias": "yakitori-totto-new-york"
  },
  {
    "alias": "yamame-rancho-santa-margarita-5"
  },
  {
    "alias": "yamashiro-hollywood-hollywood"
  },
  {
    "alias": "yank-sing-san-francisco"
  },
  {
    "alias": "yardbird-table-and-bar-miami-beach"
  },
  {
    "alias": "yardbird-washington-3"
  },
  {
    "alias": "yatenga-french-bistro-and-bar-new-york"
  },
  {
    "alias": "yefsi-estiatorio-new-york"
  },
  {
    "alias": "yes-apothecary-new-york-4"
  },
  {
    "alias": "yia-yias-homemade-greek-food-new-york"
  },
  {
    "alias": "yin-traditional-hot-pot-long-island-city"
  },
  {
    "alias": "yoon-haeundae-galbi-new-york-2"
  },
  {
    "alias": "yopparai-new-york"
  },
  {
    "alias": "yougu-hibachi-and-sushi-lounge-cheshire"
  },
  {
    "alias": "ysabel-west-hollywood"
  },
  {
    "alias": "yuca-105-miami-beach"
  },
  {
    "alias": "yum-yum-too-new-york"
  },
  {
    "alias": "yuri-sushi-house-new-york"
  },
  {
    "alias": "yuzu-new-york"
  },
  {
    "alias": "zawa-japanese-new-york"
  },
  {
    "alias": "zaytinya-washington"
  },
  {
    "alias": "zeppelin-washington"
  },
  {
    "alias": "zinc-new-haven-new-haven-2"
  },
  {
    "alias": "zingari-ristorante-and-tapas-bar-san-francisco"
  },
  {
    "alias": "zion-kitchen-lounge-and-cafe-washington"
  },
  {
    "alias": "zola-barzola-palo-alto-2"
  },
  {
    "alias": "zou-zou-s-new-york-3"
  },
  {
    "alias": "zucca-coral-gables"
  },
  {
    "alias": "zuma-miami-2"
  },
  {
    "alias": "zuma-new-york-2"
  },
  {
    "alias": "zushi-puzzle-san-francisco-2"
  },
  {
    "alias": "zzs-clam-bar-new-york"
  },
  {
    "alias": "épernay-bistro-los-gatos"
  }
]