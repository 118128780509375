import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import InputComponent from '../ui/InputComponent'
import CustomButton from '../ui/CustomButton'

export const SubscribeFormMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      flexGrow: 0,
      width: { xs: '100%', md: 'auto' },
    },
    className: 'order-2 tab-order-1',
  }
})(() => ({}))

export const SubscribeFormTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.common.white,
    mb: '6px',
  }
})(() => ({}))

export const SubscribeFormInput = styled(InputComponent).attrs(() => {
  return {
    id: 'email',
    placeholder: 'Enter email here',
    type: 'fill',
    sx: {
      width: { xs: '100%', md: 'auto' },
      marginRight: '10px',
    },
    inputProps: { autoComplete: 'off' },
  }
})(() => ({}))

export const SubscribeFormInputSubscribeButton = styled(CustomButton).attrs(
  () => {
    const theme = useTheme()
    return {
      title: 'Subscribe',
      textColor: theme.palette.grey.A100,
      backgroundColor: theme.palette.primary.main,
      textVariant: 'subtitle1',
    }
  },
)(() => ({}))
