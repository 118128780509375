/* eslint-disable no-console */
const PRODUCTION_MODE = 'production'

export function debugLog(message: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_MODE !== PRODUCTION_MODE) {
    console.log(message, ...optionalParams)
  }
}

export function infoLog(message: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_MODE !== PRODUCTION_MODE) {
    console.info(message, ...optionalParams)
  }
}

export function errorLog(message: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_MODE !== PRODUCTION_MODE) {
    console.error(message, ...optionalParams)
  }
}
