import React from 'react'
import { IFilterResponseItem } from '../../types'
import {
  FilterButtonBox,
  FilterButtonCloseIcon,
  FilterButtonMainBox,
  FilterButtonTypography,
} from '../styled/filter/FilterButton.styled'

interface FilterButtonProps {
  filterResponse: IFilterResponseItem
  priceStep: string | undefined
  priceType: string | undefined
  onButtonClick: (parentName: string) => void
}

const FilterButton: React.FC<FilterButtonProps> = ({
  filterResponse,
  onButtonClick,
  priceType,
  priceStep,
}) => {
  const renderButtons = (data: IFilterResponseItem): JSX.Element[] => {
    const buttons: JSX.Element[] = []

    const addButton = (
      key: string,
      label: string,
      parentName: string,
    ): void => {
      buttons.push(
        <FilterButtonBox key={key}>
          <FilterButtonTypography>{label}</FilterButtonTypography>
          <FilterButtonCloseIcon onClick={() => onButtonClick(parentName)} />
        </FilterButtonBox>,
      )
    }

    if (data.distance && data.distance !== 0) {
      addButton('distance', `${data.distance} mi`, 'distance')
    }

    if (data.rating) {
      addButton('rating', data.rating.name, 'rating')
    }

    if (data.price && Number(priceStep) && data.price !== Number(priceStep)) {
      addButton(
        'price',
        `${priceType ? price[data.price - 1] : `$${data.price}`}`,
        'price',
      )
    }

    if (
      data.dietary &&
      Array.isArray(data.dietary) &&
      data.dietary.length > 0
    ) {
      const dietaryValues = data.dietary.map((item) => item.name).join(', ')
      addButton('dietary', dietaryValues, 'dietary')
    }

    if (
      data.cuisine &&
      Array.isArray(data.cuisine) &&
      data.cuisine.length > 0
    ) {
      const cuisineValues = data.cuisine.map((item) => item.name).join(', ')
      addButton('cuisine', cuisineValues, 'cuisine')
    }

    if (
      data.ambience &&
      Array.isArray(data.ambience) &&
      data.ambience.length > 0
    ) {
      const ambienceValues = data.ambience.map((item) => item.name).join(', ')
      addButton('ambience', ambienceValues, 'ambience')
    }
    if (data.menu && Array.isArray(data.menu) && data.menu.length > 0) {
      const menuValues = data.menu.map((item) => item.name).join(', ')
      addButton('menu', menuValues, 'menu')
    }

    return buttons
  }

  return (
    <FilterButtonMainBox>{renderButtons(filterResponse)}</FilterButtonMainBox>
  )
}

export default FilterButton

const price = ['$', '$$', '$$$', '$$$$']
