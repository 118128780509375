import { Box, Skeleton } from '@mui/material'
import styled from 'styled-components'

export const RestaurantCardSkeletonContainer = styled(Box).attrs(() => {
  return {
    sx: {
      width: '100%',
      minWidth: '276px',
    },
  }
})(() => ({}))

export const RestaurantCardSkeletonInnerContainer = styled(Box).attrs(() => {
  return {
    sx: {
      marginTop: '10px',
    },
  }
})(() => ({}))

export const RestaurantCardRoundedSkeletonContainer = styled(Skeleton).attrs(
  () => {
    return {
      variant: 'rounded',
      height: 200,
      sx: {
        borderRadius: '20px',
      },
    }
  },
)(() => ({}))

export const RestaurantCardTextSkeletonContainer60 = styled(Skeleton).attrs(
  () => {
    return {
      variant: 'text',
      sx: {
        borfontSize: '1rem',
      },
      width: '60%',
    }
  },
)(() => ({}))

export const RestaurantCardTextSkeletonContainer80 = styled(Skeleton).attrs(
  () => {
    return {
      variant: 'text',
      sx: {
        borfontSize: '1rem',
      },
      width: '80%',
    }
  },
)(() => ({}))
