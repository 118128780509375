import {
  alpha,
  Box,
  Button,
  Chip,
  Divider,
  Popover,
  Typography,
  useTheme,
} from '@mui/material'
import {
  IconArrowBearRight,
  IconBookmark,
  IconBookmarkFilled,
  IconCalendarCheck,
  IconChevronDown,
  IconClockHour7,
  IconCurrencyDollar,
  IconMapPin,
  IconPhone,
  IconPointFilled,
  IconRosetteDiscountCheck,
} from '@tabler/icons-react'
import styled from 'styled-components'

export const RestaurantInfoMainContainer = styled(Box).attrs(() => ({
  width: '100%',
  display: 'flex',
  marginTop: '40px',
  marginBottom: '32px',
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', md: 'row' },
}))(() => ({}))

export const RestaurantInfoInnerContainer = styled(Box).attrs(() => ({
  flex: '2',
  marginRight: { sm: '0', md: '16px' },
}))(() => ({}))

export const RestaurantInfoTitleWrapper = styled(Box).attrs(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}))(() => ({}))

export const RestaurantInfoTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'h3',
    fontWeight: 600,
    color: theme.palette.grey[800],
    maxWidth: { sm: '500px', md: '400px' },
    sx: { textTransform: 'capitalize', paddingRight: '12px' },
  }
})(() => ({}))

export const RestaurantInfoTitleChip = styled(Chip).attrs(() => {
  const theme = useTheme()
  return {
    size: 'small',
    variant: 'outlined',
    sx: {
      fontWeight: 400,
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
      backgroundColor: theme.palette.success[50],
      ml: '10px',
    },
  }
})(() => ({}))

export const RestaurantInfoTitleChipWrapper = styled(Box).attrs(() => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
}))(() => ({}))

export const RestaurantInfoTitleChipIconRosetteDiscountCheck = styled(
  IconRosetteDiscountCheck,
).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 17,
    color: theme.palette.success.dark,
  }
})(() => ({ fontSize: '14px' }))

export const RestaurantInfoTitleVerifiedTypography = styled(Typography).attrs(
  () => ({
    variant: 'caption',
    sx: { ml: '3px' },
  }),
)(() => ({}))

export const RestaurantInfoSignUpTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    fontWeight: 500,
    color: theme.palette.grey[400],
    sx: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      marginRight: '5px',
      marginLeft: '12px',
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const RestaurantInfoAiMatchTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    component: 'p',
    fontWeight: 500,
    color: theme.palette.grey[400],
  }
})(() => ({}))

export const RestaurantBasicInfoWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: { sx: 'flex-start', md: 'center' },
    flexDirection: { sx: 'column', md: 'row' },
    flexWrap: 'wrap',
    marginTop: '16px',
    marginBottom: '16px',
  },
}))(() => ({}))

export const RestaurantBasicInfoAddressWrapper = styled(Box).attrs(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}))(() => ({}))

export const RestaurantBasicInfoIconMapPin = styled(IconMapPin).attrs(() => ({
  size: 18,
  stroke: 2,
}))(() => ({}))

export const RestaurantBasicInfoAddressTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'body2',
      component: 'p',
      fontWeight: 500,
      paddingLeft: '5px',
      color: theme.palette.grey[600],
      sx: { textTransform: 'capitalize' },
    }
  },
)(() => ({}))

export const RestaurantBasicInfoIconPointFilled = styled(IconPointFilled).attrs(
  () => {
    const theme = useTheme()
    return {
      color: theme.palette.grey[300],
      size: 12,
    }
  },
)(() => ({}))

export const RestaurantBasicInfoDistanceTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'body2',
      component: 'p',
      fontWeight: 500,
      color: theme.palette.grey[600],
      sx: { textTransform: 'lowercase' },
    }
  },
)(() => ({}))

export const RestaurantBasicInfoTimeWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: { xs: '14px', md: '0px' },
  },
}))(() => ({}))

export const RestaurantBasicInfoTimezoneWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))(() => ({}))

export const RestaurantBasicInfoTimeDivider = styled(Divider).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[300],
    orientation: 'vertical',
    flexItem: true,
    sx: {
      marginX: '16px',
    },
  }
})(() => ({}))

export const RestaurantBasicInfoTimeIconClockHour7 = styled(
  IconClockHour7,
).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(() => ({}))

export const RestaurantBasicInfoActiveTimeTypography = styled(Typography).attrs(
  () => ({
    variant: 'body2',
    component: 'p',
    fontWeight: 500,
    sx: {
      textTransform: 'lowercase',
      paddingLeft: '6px',
    },
  }),
)(() => ({}))

export const RestaurantBasicInfoTimeButton = styled(Button).attrs(() => {
  return {
    variant: 'text',
    sx: {
      textTransform: 'capitalize',
      marginLeft: '6px',
    },
  }
})(() => ({}))

export const RestaurantBasicInfoTimeButtonIconChevronDown = styled(
  IconChevronDown,
).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[400],
    stroke: 2,
    size: 18,
  }
})(() => ({}))

export const RestaurantBasicInfoTimeTodayTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'subtitle1',
      component: 'p',
      fontWeight: 500,
      color: theme.palette.grey[400],
      sx: {
        textTransform: 'capitalize',
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
      },
    }
  },
)(() => ({}))

export const RestaurantBasicInfoPriceRangeWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '2px',
  },
}))(() => ({}))

export const RestaurantBasicInfoPriceRangeIconCurrencyDollar = styled(
  IconCurrencyDollar,
).attrs(() => {
  const theme = useTheme()
  return {
    size: 18,
    color: theme.palette.grey[600],
    stroke: 2,
  }
})(() => ({}))

export const RestaurantBasicInfoPriceRangeTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'body2',
      component: 'p',
      fontWeight: 500,
      color: theme.palette.grey[600],
      sx: { textTransform: 'capitalize', marginX: '4px' },
    }
  },
)(() => ({}))

export const RestaurantBasicInfoPriceRangePerPersonTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    component: 'p',
    fontWeight: 500,
    color: theme.palette.grey[400],
  }
})(() => ({}))

export const RestaurantBasicInfoPopover = styled(Popover).attrs(() => {
  return {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    sx: {
      '& .MuiPaper-root': {
        borderRadius: '8px',
        width: '230px',
      },
    },
  }
})(() => ({}))

export const RestaurantBasicInfoPopoverWrapper = styled(Box).attrs(() => {
  return {
    padding: '8px',
  }
})(() => ({}))

export const RestaurantBasicInfoPopoverInnerWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '8px',
    },
  }
})(() => ({}))

export const RestaurantBasicInfoPopoverDayTypography = styled(Typography).attrs(
  () => {
    return {
      noWrap: true,
      component: 'p',
      variant: 'subtitle2',
    }
  },
)(() => ({}))

export const RestaurantBasicInfoPopoverTimeWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '5px',
    },
  }
})(() => ({}))

export const RestaurantBasicInfoPopoverTimeTypography = styled(
  Typography,
).attrs(() => {
  return {
    noWrap: true,
    component: 'p',
    variant: 'subtitle2',
  }
})(() => ({}))

export const RestaurantBasicInfoChipWrapper = styled(Box).attrs(() => {
  return {
    sx: {
      gap: '8px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }
})(() => ({}))

export const RestaurantBasicInfoChipChip = styled(Chip).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      height: '24px',
      fontSize: '12px',
      borderRadius: '6px',
      color: theme.palette.grey[500],
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  }
})(() => ({}))

export const RestaurantInfoActionContainer = styled(Box).attrs(() => {
  return {
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    marginTop: { xs: '24px', md: '0' },
    justifyContent: { xs: 'start', md: 'end' },
  }
})(() => ({}))

export const RestaurantInfoActionReserveTableButton = styled(Button).attrs(
  () => {
    const theme = useTheme()
    return {
      component: 'a',
      variant: 'contained',
      color: 'primary',
      size: 'small',
      target: '_blank',
      disableElevation: true,
      rel: 'noopener noreferrer',
      sx: {
        fontSize: '12px',
        borderRadius: '10px',
        fontWeight: 500,
        textTransform: 'capitalize',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: '8px',
        paddingX: '16px',
        '&.MuiButton-root': {
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.8),
            color: 'white',
          },
        },
      },
    }
  },
)(() => ({}))

export const RestaurantInfoActionIconCalendarCheck = styled(
  IconCalendarCheck,
).attrs(() => {
  return {
    size: 14,
    stroke: 2,
  }
})(() => ({}))

export const RestaurantInfoActionDirectionsWebButton = styled(Button).attrs(
  () => {
    const theme = useTheme()
    return {
      component: 'a',
      variant: 'contained',
      color: 'primary',
      size: 'small',
      target: '_blank',
      disableElevation: true,
      rel: 'noopener noreferrer',
      sx: {
        fontSize: '12px',
        borderRadius: '10px',
        fontWeight: 500,
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: '8px',
        paddingX: '16px',
        borderColor: theme.palette.grey[300],
        '&.MuiButton-root': {
          borderColor: theme.palette.grey[300],
        },
      },
    }
  },
)(() => ({}))

export const RestaurantInfoActionDirectionsWebButtonIconArrowBearRight = styled(
  IconArrowBearRight,
).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(() => ({}))

export const RestaurantInfoActionIconArrowBearRight = styled(
  IconArrowBearRight,
).attrs(() => {
  const theme = useTheme()
  return {
    size: 18,
    color: theme.palette.grey[800],
    stroke: 2,
  }
})(() => ({}))

export const RestaurantInfoActionIconPhone = styled(IconPhone).attrs(() => {
  const theme = useTheme()
  return {
    size: 18,
    color: theme.palette.grey[800],
    stroke: 2,
  }
})(() => ({}))

export const RestaurantInfoActionIconBookmarkFilled = styled(
  IconBookmarkFilled,
).attrs(() => {
  const theme = useTheme()
  return {
    size: 18,
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const RestaurantInfoActionIconBookmark = styled(IconBookmark).attrs(
  () => {
    const theme = useTheme()
    return {
      size: 18,
      color: theme.palette.grey[800],
      stroke: 2,
    }
  },
)(() => ({}))
