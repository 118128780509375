import { Container, styled as muiStyled, Typography } from '@mui/material'
import styled from 'styled-components'

export const ErrorMainContainer = muiStyled('div')(() => {
  return {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 214px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }
})

export const ErrorContainer = styled(Container).attrs(() => {
  return {
    component: 'main',
    sx: { flex: 1 },
  }
})(() => ({}))

export const ErrorTitleTypography = styled(Typography).attrs(() => {
  return {
    variant: 'h1',
  }
})(() => ({}))

export const ErrorDescriptionTypography = styled(Typography).attrs(() => {
  return {
    variant: 'h3',
    sx: { marginY: '10px' },
  }
})(() => ({}))
