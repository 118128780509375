import { Alert, Backdrop, Snackbar, useTheme } from '@mui/material'
import styled from 'styled-components'

export const MuiSnackbar = styled(Snackbar).attrs(() => ({
  autoHideDuration: 5000,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
}))(() => ({}))

export const MuiAlert = styled(Alert).attrs(() => ({
  sx: { width: '100%', textTransform: 'capitalize' },
}))(() => ({}))

export const MuiBackdrop = styled(Backdrop).attrs(() => {
  const theme = useTheme()
  return {
    sx: { color: theme.palette.background.paper, zIndex: 1201 },
  }
})(() => ({}))
