import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dish, Restaurant } from '../types'
import { RootState } from './'

export interface IRestaurantSlice {
  elasticSearchRestaurants: Restaurant[] | undefined
  elasticSearchDishes: Dish[] | undefined
  isMobileSearchOpen: boolean
}

const initialState: IRestaurantSlice = {
  elasticSearchRestaurants: undefined,
  elasticSearchDishes: undefined,
  isMobileSearchOpen: false,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setElasticSearchRestaurants: (
      state,
      action: PayloadAction<Restaurant[] | undefined>,
    ) => {
      state.elasticSearchRestaurants = action.payload
    },
    setElasticSearchDishes: (
      state,
      action: PayloadAction<Dish[] | undefined>,
    ) => {
      state.elasticSearchDishes = action.payload
    },
    setMobileSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileSearchOpen = action.payload
    },
    clearData: () => initialState,
  },
})
export const selectPageSize = (state: RootState): number => state.eater.pageSize
export const selectElasticSearchRestaurants = (
  state: RootState,
): Restaurant[] | undefined => {
  return state?.search?.elasticSearchRestaurants
}
export const selectElasticSearchDishes = (
  state: RootState,
): Dish[] | undefined => {
  return state?.search?.elasticSearchDishes
}
export const selectIsMobileSearch = (state: RootState): boolean => {
  return state?.search?.isMobileSearchOpen
}

export const {
  setElasticSearchRestaurants,
  setElasticSearchDishes,
  setMobileSearchOpen,
  clearData,
} = searchSlice.actions

export default searchSlice.reducer
