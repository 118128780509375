import { Box, Grid } from '@mui/material'
import styled from 'styled-components'

export const SearchResultRestaurantsMainGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: { xs: 2, sm: 3, md: 4 },
  sx: {
    my: { xs: '8px', sm: '16px', md: '20px' },
    WebkitOverflowScrolling: 'touch',
  },
}))(() => ({}))

export const SearchResultRestaurantsInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
}))(() => ({}))

export const SearchResultRestaurantsWrapper = styled(Box).attrs(() => ({
  className: 'restaurant-card-swiper-slide',
  sx: { height: '280px', width: '100%' },
}))(() => ({}))
