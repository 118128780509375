import Box from '@mui/material/Box'
import React, { useRef } from 'react'
import { Outlet, useLocation } from 'react-router'
import Footer from '../components/Footer'
import BottomAppBar from '../components/navBar/BottomAppBar'
import NavBar from '../components/navBar/NavBar'
import ScrollToTop from '../components/ScrollToTop'
import { WebLayoutProvider } from '../context/WebLayoutContext'
import { ROUTES } from '../helpers/routes-helper'
import { useDevice } from '../hooks/useDevice'

const WebMobileLayout: React.FC = () => {
  const location = useLocation()
  const { isHybrid } = useDevice()

  const hideFooterPaths = [ROUTES.HOME, ROUTES.ABOUT]
  const showFooter = hideFooterPaths.includes(location.pathname)
  const scrollableContainerRef = useRef<HTMLDivElement>(null)

  return (
    <Box display="flex" flexDirection="column" height="100vh"  sx={{
      paddingTop: 'env(safe-area-inset-top)', 
      paddingBottom: 'env(safe-area-inset-bottom)', 
    }}>
     
      {!(isHybrid && [ROUTES.PROFILE].includes(location.pathname)) && (
        <NavBar />
      )}
      <WebLayoutProvider>
        <Box
          ref={scrollableContainerRef}
          sx={{
            flex: 1, 
            overflowY: 'auto', 
            scrollBehavior: 'smooth',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet context={{ scrollableContainerRef }} />
          <ScrollToTop scrollableContainerRef={scrollableContainerRef} />
          {showFooter && !isHybrid && <Footer />}
        </Box>
      </WebLayoutProvider>
      {isHybrid && <BottomAppBar />}
    </Box>
  )
}

export default WebMobileLayout
