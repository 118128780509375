import { Fab, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { MOBILE_APP_HEIGHT } from '../../../../helpers/static-constants'
import { useDevice } from '../../../../hooks/useDevice'
import { ProfileMoreButtonCustomButton } from '../styled/MoreButton.styled'
import {
  ProfileHeaderButtonWrapper,
  ProfileHeaderContainer,
  ProfileHeaderCustomButtonCancel,
  ProfileHeaderCustomButtonEdit,
  ProfileHeaderCustomButtonUpdate,
  ProfileHeaderIconDotsVertical,
  ProfileHeaderRightContainer,
  ProfileHeaderTitleTypography,
  ProfileHeaderWebEditButtonIconPencil,
  ProfileHeaderWebIconPencil,
} from '../styled/ProfileHeader.styled'
import ProfilePopup from './ProfilePopup'

interface ProfileHeaderProps {
  currentTab: string
  isEdit: boolean
  isValid: boolean
  onCancel: () => void
  onUpdate: () => void
  onEditProfile: (isEditing: boolean) => void
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  currentTab,
  isEdit,
  isValid,
  onCancel,
  onUpdate,
  onEditProfile,
}) => {
  const theme = useTheme()
  const { isHybrid } = useDevice()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const onPopupOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (currentTab === 'Preferences' && isSmallScreen) {
      onEditProfile(true)
    } else {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <>
      {!isSmallScreen ? (
        <ProfileHeaderContainer>
          <ProfileHeaderTitleTypography>Profile</ProfileHeaderTitleTypography>
          {(currentTab === 'Bio' || currentTab === 'Preferences') && (
            <ProfileHeaderRightContainer>
              {isEdit ? (
                <>
                  <ProfileHeaderCustomButtonCancel
                    title="Cancel"
                    onClick={onCancel}
                  />
                  <ProfileHeaderCustomButtonUpdate
                    title="Update"
                    disabled={isValid}
                    onClick={onUpdate}
                  />
                </>
              ) : (
                <>
                  <ProfileMoreButtonCustomButton
                    icon={<ProfileHeaderIconDotsVertical />}
                    aria-controls={open ? 'edit-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={onPopupOpen}
                  />
                  <ProfileHeaderCustomButtonEdit
                    title="Edit"
                    onClick={() => onEditProfile(true)}
                    startIcon={<ProfileHeaderWebIconPencil />}
                  />
                </>
              )}
            </ProfileHeaderRightContainer>
          )}
        </ProfileHeaderContainer>
      ) : (
        <>
          {(currentTab === 'Bio' || currentTab === 'Preferences') && (
            <Box>
              {!isEdit ? (
                <>
                  <Fab
                    color="primary"
                    size="small"
                    id="edit-button"
                    aria-controls={open ? 'edit-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={onPopupOpen}
                    sx={{
                      position: 'fixed',
                      bottom: isHybrid
                        ? `calc(${
                            (MOBILE_APP_HEIGHT as number) + 10
                          }px + env(safe-area-inset-bottom))`
                        : 16,
                      right: 16,
                    }}
                  >
                    <ProfileHeaderWebEditButtonIconPencil />
                  </Fab>
                </>
              ) : (
                <ProfileHeaderButtonWrapper>
                  <ProfileHeaderCustomButtonCancel
                    title="Cancel"
                    onClick={onCancel}
                    fullWidth
                  />
                  <ProfileHeaderCustomButtonUpdate
                    title="Update"
                    disabled={isValid}
                    onClick={onUpdate}
                    fullWidth
                  />
                </ProfileHeaderButtonWrapper>
              )}
            </Box>
          )}
        </>
      )}
      <ProfilePopup
        anchorEl={anchorEl}
        id="edit-button"
        openPop={open}
        setAnchorEl={setAnchorEl}
        setEditProfile={onEditProfile}
      />
    </>
  )
}

export default ProfileHeader
