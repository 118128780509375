import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { IconCornerDownLeft, IconSearch } from '@tabler/icons-react'
import React from 'react'
import styled from 'styled-components'

interface OnboardSearchInputViewProps {
  maxWidth?: string
  searchWidth?: string
}
interface OnboardSearchResultSearchTermContainerProps {
  customWidth?: string | undefined
}
interface OnboardSearchContainerProps {
  customWidth?: string | undefined
}

export const OnboardSearchContainer = styled(
  ({ customWidth, ...rest }: OnboardSearchContainerProps & any) => (
    <Box {...rest} />
  ),
).attrs<OnboardSearchContainerProps>(({ customWidth }) => ({
  sx: {
    ...(!customWidth && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      position: 'relative',
    }),
  },
}))<OnboardSearchContainerProps>(({ theme }) => ({}))

export const OnboardSearchAutocompleteViewContainer = styled('div').attrs(
  () => {
    const theme = useTheme()
    return {
      className: 'onboard-autocomplete-results-container',
      style: {
        border: `1px solid ${theme.palette.grey[300]}`,
        marginTop: '10px',
        padding: '5px',
        borderRadius: '8px',
        position: 'absolute',
        top: '35px',
        backgroundColor: 'white',
        zIndex: '10',
        width: '100%',
        boxShadow: '0px 0px 20px 0px rgba(112, 112, 112, 0.25)',
        maxHeight: '230px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      },
    }
  },
)(({ theme }) => ({}))

export const OnboardSearchAutocompleteViewInnerContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  &:hover {
    background-color: ${({ theme }) => `#1F2041`};
    color: #ffffff;
  }
`

export const OnboardSearchInputView = styled.div<OnboardSearchInputViewProps>(
  ({ theme, maxWidth, searchWidth }) => ({
    maxWidth: maxWidth ?? '400px',
    '@media (min-width: 600px)': {
      width: searchWidth ?? '400px',
    },
  }),
)

export const OnboardSearchInputViewTextField = styled(TextField).attrs(() => ({
  id: 'MuiInputBase-input-MuiOutlinedInput-input-check',
  size: 'small',
  variant: 'outlined',
}))(({ theme }) => ({}))

export const OnboardSearchInputViewInputAdornment = styled(
  InputAdornment,
).attrs(() => ({
  position: 'start',
}))(({ theme }) => ({}))

export const OnboardSearchInputViewIconSearch = styled(IconSearch).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 22,
    style: { color: theme.palette.grey[300] },
  }
})(({ theme }) => ({}))

export const OnboardSearchResultSearchTermContainer = styled(
  ({
    customWidth,
    ...rest
  }: OnboardSearchResultSearchTermContainerProps & any) => <Box {...rest} />,
).attrs<OnboardSearchResultSearchTermContainerProps>(({ customWidth }) => {
  const theme = useTheme()
  return {
    sx: {
      border: `1px solid ${theme.palette.grey[300]}`,
      marginTop: '10px',
      padding: '24px',
      borderRadius: '8px',
      width: customWidth ?? '400px',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: '35px',
      backgroundColor: 'white',
      zIndex: '10',
      boxShadow: '0px 0px 20px 0px rgba(112, 112, 112, 0.25)',
    },
  }
})<OnboardSearchResultSearchTermContainerProps>(({ theme }) => ({}))

export const OnboardSearchResultSearchTermIconCornerDownLeft = styled(
  IconCornerDownLeft,
).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 32,
    style: {
      border: `1px solid ${theme.palette.grey[100]}`,
      color: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[50],
      borderRadius: '50%',
      padding: '7px',
    },
  }
})(({ theme }) => ({}))

export const OnboardSearchResultSearchTermTypographyNoItem = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'span',
    pl: 2,
    color: 'black',
    variant: 'body2',
    sx: {
      color: theme.palette.grey[400],
      fontSize: '12px',
      fontWeight: 500,
    },
  }
})(({ theme }) => ({}))

export const OnboardSearchResultSearchTermTypographyResult = styled(
  Typography,
).attrs(() => ({
  component: 'p',
  color: 'black',
  variant: 'body2',
}))(({ theme }) => ({
  cursor: 'pointer',
}))
