import { useAuth0 } from '@auth0/auth0-react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import CustomButton from '../../../../components/ui/CustomButton'
import {
  AIRevolutionEngineContentContainer,
  AIRevolutionEngineContentGrid,
  AIRevolutionEngineImgContainer,
  AIRevolutionEngineMainContainer,
  AIRevolutionEngineTypographyChip,
  AIRevolutionEngineTypographyMainSubTitle,
  AIRevolutionEngineTypographySubTitle,
  AIRevolutionEngineTypographyTitle,
} from '../styled/AIRevolutionEngine.styled'

const AIRevolutionEngine: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  const login: any = async (): Promise<void> => {
    await loginWithRedirect()
  }

  return (
    <AIRevolutionEngineMainContainer>
      <Grid container>
        <AIRevolutionEngineContentGrid>
          <AIRevolutionEngineContentContainer>
            <AIRevolutionEngineTypographyChip>
              Recommendation at its best
            </AIRevolutionEngineTypographyChip>
            <AIRevolutionEngineTypographyTitle marginTop={'16px'}>
              Revolutionary AI-powered
            </AIRevolutionEngineTypographyTitle>
            <AIRevolutionEngineTypographyTitle>
              recommendation engine
            </AIRevolutionEngineTypographyTitle>
            <AIRevolutionEngineTypographyMainSubTitle>
              Say goodbye to aimless searches - our proprietary AI does all the
              work to match you with your perfect meal.
            </AIRevolutionEngineTypographyMainSubTitle>
            <AIRevolutionEngineTypographySubTitle>
              Dine smarter with our pioneering AI engine that learns your tastes
              to recommend ideal restaurants.
            </AIRevolutionEngineTypographySubTitle>
            {!isAuthenticated && (
              <CustomButton
                title="Sign up now"
                style={{
                  padding: '12px 24px',
                  fontSize: '14px !important',
                }}
                onClick={login}
              />
            )}
          </AIRevolutionEngineContentContainer>
        </AIRevolutionEngineContentGrid>
        <Grid item xs={12} md={7}>
          <AIRevolutionEngineImgContainer>
            <img
              srcSet={
                isSmallScreen
                  ? '/images/homepage/al-revolution-engine-small.png'
                  : '/images/homepage/al-revolution-engine.svg'
              }
              src={
                isSmallScreen
                  ? '/images/homepage/al-revolution-engine-small.png'
                  : '/images/homepage/al-revolution-engine.svg'
              }
              loading="lazy"
              alt="al-revolution-engine"
            />
          </AIRevolutionEngineImgContainer>
        </Grid>
      </Grid>
    </AIRevolutionEngineMainContainer>
  )
}

export default AIRevolutionEngine
