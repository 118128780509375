import { Box, useTheme, Link, Typography } from '@mui/material'
import styled from 'styled-components'

export const FooterMainBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    component: 'footer',
    sx: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      position: 'relative',
      zIndex: 100,
    },
  }
})(() => ({}))

export const FooterSecondBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: {
        xs: 'start',
        md: 'end',
      },
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      marginX: {
        xs: 2,
        sm: 10,
      },
      paddingY: {
        xs: 4,
        md: 5,
      },
    },
  }
})(() => ({}))

export const FooterLinkMainBox = styled(Box).attrs(() => {
  return {
    style: {
      flexGrow: 5,
    },
    className: 'order-1 tab-order-3',
  }
})(() => ({}))

export const LinkBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      mb: 2,
      gap: 2,
    },
  }
})(() => ({}))

export const FooterDisclaimerTypography = styled(Typography).attrs(() => {
  const theme = useTheme()

  return {
    variant: 'subtitle1',
    color: theme.palette.common.white,
    sx: { textDecoration: 'underline' },
  }
})(() => ({}))

export const FooterLink = styled(Link).attrs(() => {
  const theme = useTheme()

  return {
    target: '_blank',
    variant: 'subtitle1',
    sx: { textDecoration: 'underline' },
    color: theme.palette.common.white,
  }
})(() => ({}))

export const FooterSeparator = styled(Box).attrs(() => {
  const theme = useTheme()

  return {
    sx: {
      flex: 0,
      color: theme.palette.primary[900],
      backgroundColor: theme.palette.primary[900],
      minWidth: { xs: '100%', md: '2px' },
      minHeight: { xs: '2px', md: '40px' },
      margin: { xs: '32px 0', md: '0 24px' },
    },
    className: 'order-3 tab-order-2',
  }
})(() => ({}))

export const FooterSocialIconBox = styled(Box).attrs(() => {
  return {
    sx: {
      mt: {
        xs: 4,
        md: 0,
      },
    },
    className: 'order-4 tab-order-4',
  }
})(() => ({}))
