import { Avatar, Popover, Typography, useTheme } from '@mui/material'
import React from 'react'
import TagChip, { IMatchData } from './TagChip'
interface GroupMatchProps {
  data: IMatchData[]
  id: string | undefined
  open: boolean
  anchorEl: HTMLDivElement | null
  handleClose: (e) => void
}

const GroupMatch: React.FC<GroupMatchProps> = ({
  data,
  anchorEl,
  handleClose,
  id,
  open,
}) => {
  const theme = useTheme()

  const onClickTest = (e: React.MouseEvent): void => {
    e.stopPropagation()
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.secondary.contrastText,
          borderRadius: '20px',
          boxShadow: '0px 0px 12px 0px #00000040',
        },
      }}
    >
      <div
        style={{
          backgroundColor: theme.palette.secondary.contrastText,
          borderRadius: '20px',
          padding: '8px',
          boxShadow: '0px 0px 12px 0px #00000040',
        }}
        onClick={(e) => onClickTest(e)}
      >
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: '250px',
            width: '283px',
            zIndex: 10000,
          }}
        >
          {data.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: index === 0 ? '0px 0px 5px 0px' : '5px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={item.image}
                  sx={{
                    border: `1px solid ${theme.palette.grey[300]}`,
                  }}
                />
                <div
                  style={{
                    textAlign: 'left',
                    paddingLeft: '10px',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: theme.palette.grey[800],
                      textTransform: 'capitalize',
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.grey[500] }}
                  >
                    {item.email}
                  </Typography>
                </div>
              </div>
              {item.percentage && (
                <TagChip
                  data={[{ percentage: `${item.percentage}` }]}
                  bgColor="white"
                />
              )}
            </div>
          ))}
        </div>
      </div>{' '}
    </Popover>
  )
}

export default GroupMatch
