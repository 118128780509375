import { useEffect, useRef, useState } from 'react'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import { debugLog } from './utils/log-helper'

const useRudderStackAnalytics = (): [RudderAnalytics | undefined, boolean] => {
  const analyticsRef = useRef<RudderAnalytics>()
  const [isAnalyticsReady, setAnalyticsReady] = useState(false)

  useEffect(() => {
    if (!analyticsRef.current) {
      const analyticsInstance = new RudderAnalytics()
      analyticsInstance.load(
        process.env.REACT_APP_RUDDERSTACK_SOURCE ?? '',
        'https://okaoincoomexbi.dataplane.rudderstack.com',
      )

      analyticsInstance.ready(() => {
        debugLog('AnalyticsInstance is ready!!!')
        setAnalyticsReady(true)
      })

      analyticsRef.current = analyticsInstance
    }
  }, [])

  return [analyticsRef.current, isAnalyticsReady]
}

export default useRudderStackAnalytics