import axios, { Axios, AxiosInstance } from 'axios'
import { debugLog } from '../utils/log-helper'
import { getAccessToken } from './auth-helper'

// Create an axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL, // Set your base URL here
  timeout: 300000, // Set a reasonable timeout 5 minutes
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor to include the auth token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  async (error) => {
    // Handle request error
    return await Promise.reject(error)
  },
)

// Add a response interceptor to handle responses globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // Handle response error
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors
    }
    return await Promise.reject(error)
  },
)

export const okaoAxiosNoAuth = (): Axios => {
  debugLog('okaoAxiosNoAuth: ', axiosInstance.defaults)
  if (axiosInstance.defaults.headers.common.Authorization) {
    delete axiosInstance.defaults.headers.common.Authorization
  }
  return axiosInstance
}

export default axiosInstance
