import { useAuth0 } from '@auth0/auth0-react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import {
  IconAdjustments,
  IconBuildingStore,
  IconToolsKitchen2,
} from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'
import Filter from '../../../components/filter/Filter'
import ResactlyTab from '../../../components/ResactlyTab'
import RoundedButton from '../../../components/ui/RoundedButton'
import {
  convertToPredictedAndPublicRestaurantList,
  generateEater,
} from '../../../helpers/converters'
import { getLocation } from '../../../helpers/location-helper'
import { ROUTES } from '../../../helpers/routes-helper'
import { price } from '../../../helpers/static-constants'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectLocation } from '../../../store/authSlice'
import {
  getLandingPageCuisineDetails,
  selectRestaurants,
  setRestaurants as setLandingRestaurants,
} from '../../../store/landingSlice'
import { selectEater } from '../../../store/profileSlice'
import { selectRadius } from '../../../store/restaurantSlice'
import {
  selectElasticSearchDishes,
  selectElasticSearchRestaurants,
} from '../../../store/searchSlice'
import {
  Dish,
  IFilterResponseItem,
  IFilterTabList,
  IRestaurantsCuisineRequest,
  ITabList,
  RestaurantAndPredictedRestaurant,
} from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import SearchDish from './components/SearchDish'
import SearchResultRestaurants from './components/SearchResultRestaurants'
import {
  SearchResultsFilterButton,
  SearchResultsHeaderContainer,
  SearchResultsHeaderLeftContainer,
  SearchResultsHeaderRightContainer,
  SearchResultsHeaderSearchTermTypography,
  SearchResultsMainContainer,
  SearchResultsResactlyTabContainer,
} from './styled/SearchResults.styled'

const SearchResults: React.FC = () => {
  const theme = useTheme()
  const location = useLocation();
  const dispatch = useAppDispatch()
  const urlLocation = useLocation()
  const { isAuthenticated } = useAuth0()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
  const queryParams = new URLSearchParams(urlLocation.search)
  const query = queryParams.get('q')
  const type = queryParams.get('type')
  const eater = useAppSelector(selectEater)
  const radius = useAppSelector(selectRadius)
  const locationData = useAppSelector(selectLocation)
  const restaurantsDetail = useAppSelector(selectRestaurants)
  const dishDataList = useAppSelector(selectElasticSearchDishes)
  const restaurantDataList = useAppSelector(selectElasticSearchRestaurants)
  const [dishes, setDishes] = useState<Dish[]>([])
  const [pagTitle, setPageTitle] = React.useState('')
  const [tabList, setTabList] = useState<ITabList[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterModalOpen, setFilterModalOpen] = React.useState(false)
  const [filterTabList, setFilterTabList] = useState<IFilterTabList[]>([])
  const [restaurants, setRestaurants] = useState<
    RestaurantAndPredictedRestaurant[]
  >([])
  const [filterRestaurants, setFilterRestaurants] = useState<
    RestaurantAndPredictedRestaurant[]
  >([])

  useEffect(() => {
    setPageTitle(
      `Resactly | Find Your Next Favorite Restaurant${
        type === 'cuisine' && query ? ` With ${query} Cuisine` : ''
      }`,
    )
  }, [])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})

    if(analytics && isAnalyticsReady){
      analytics?.page('PAGE_OPENED', {
        path: ROUTES.SEARCH,
        title: pagTitle,
        url: `${window.location.origin}${ROUTES.SEARCH}`,
        category: 'Search',
        name: 'Search Viewed',
        eaterName: fullName,
        email,
        eaterId,
        startTime: new Date().toISOString()
      })
    }
  }, [analytics, pagTitle, eater, isAnalyticsReady])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})

    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.SEARCH,
        title: pagTitle,
        url: `${window.location.origin}${ROUTES.SEARCH}`,
        category: 'Search',
        name: 'Search Viewed',
        eaterName: fullName,
        email,
        eaterId,
        endTime: new Date().toISOString()
      })
    }
  }, [location])

  useEffect(() => {
    const tabs = [
      {
        label: 'Restaurant',
        value: 'restaurant',
        icon: <IconBuildingStore stroke={2} />,
      },
    ]

    if (type === 'restaurant' && tabList.length < 3) {
      tabs.push({
        label: 'Dish',
        value: 'dish',
        icon: <IconToolsKitchen2 stroke={2} />,
      })
    }

    setTabList(tabs)
    void fetchData()
  }, [radius, type])

  useEffect(() => {
    if (type === 'cuisine') {
      setRestaurants(restaurantsDetail ?? [])
    }
    if (type === 'restaurant') {
      setDishes(dishDataList ?? [])
      setRestaurants(
        convertToPredictedAndPublicRestaurantList(restaurantDataList ?? []),
      )
    }
  }, [restaurantsDetail, restaurantDataList])

  useEffect(() => {
    if (restaurants && restaurants.length) {
      const filter = onFilterItems()
      setFilterTabList(filter)
    }
  }, [restaurants])

  const fetchData = async (): Promise<void> => {
    let local = locationData
    if (locationData === undefined) {
      local = await getLocation()
    }

    if (type === 'restaurant') {
      setDishes(dishDataList ?? [])
      setRestaurants(
        convertToPredictedAndPublicRestaurantList(restaurantDataList ?? []),
      )
      setSearchTerm(query ?? '')
    }

    if (type === 'cuisine' && query) {
      const payload: IRestaurantsCuisineRequest = {
        longitude: local?.longitude ?? -73.935242,
        latitude: local?.latitude ?? 40.73061,
        radius,
        name: query,
        page: 0,
      }

      const result = await dispatch(getLandingPageCuisineDetails(payload))
      if (getLandingPageCuisineDetails.fulfilled.match(result)) {
        dispatch(
          setLandingRestaurants({ response: result.payload, isAuthenticated }),
        )
      }
      setSearchTerm(query + ' cuisine Restaurants')
    }
  }

  const onFilter = (obj: IFilterResponseItem): void => {
    if (restaurants) {
      const data = restaurants.filter((dish) => {
        if (
          obj.price !== undefined &&
          obj.price > 1 &&
          price.indexOf(dish?.restaurantDTO?.priceRange) > Number(obj.price) - 1
        ) {
          return false
        }
        if (
          obj.distance !== undefined &&
          obj.distance > 0 &&
          Number(dish?.restaurantDTO?.distance ?? 0) > Number(obj.distance)
        ) {
          return false
        }

        if (obj.dietary && obj.dietary.length > 0) {
          const dietaryValues = obj.dietary.map((item) =>
            item.name.trim().toLowerCase(),
          )
          if (
            !dietaryValues.some((dietary) =>
              dish?.restaurantDTO?.dietary
                ?.map((d) => d.trim().toLowerCase())
                .includes(dietary.trim().toLowerCase()),
            )
          ) {
            return false
          }
        }
        if (obj.cuisine && obj.cuisine.length > 0) {
          const cuisineValues = obj.cuisine.map((item) => item.name)
          if (
            !cuisineValues.some(
              (dietary) =>
                dish?.restaurantDTO?.cuisine?.toLocaleLowerCase() ===
                dietary.toLowerCase(),
            )
          ) {
            return false
          }
        }

        return true
      })
      setFilterRestaurants(data)
    }
  }

  const onFilterItems = (): IFilterTabList[] => {
    const filterItems = [
      {
        key: 1,
        name: 'Distance',
        items: [
          { key: 1, name: '0 mi', value: '0' },
          { key: 2, name: '5 mi', value: '5' },
          { key: 3, name: '10 mi', value: '10' },
          { key: 4, name: '15 mi', value: '15' },
          { key: 5, name: '20 mi', value: '20' },
          { key: 6, name: '25 mi', value: '25' },
          { key: 7, name: '30 mi', value: '30' },
        ],
        rangeStep: 5,
        filterType: 'range',
      },
    ].filter(Boolean) as IFilterTabList[]

    if (restaurants && restaurants.length !== 0) {
      const allDietaryPreferences = restaurants.flatMap(
        (item) =>
          item?.restaurantDTO?.dietary?.map((preference) =>
            preference.trim(),
          ) ?? [],
      )

      const uniqueDietaryPreferences = Array.from(
        new Set(
          allDietaryPreferences.filter(
            (preference): preference is string =>
              preference !== undefined && preference.trim() !== '',
          ),
        ),
      )

      const cuisineList: string[] = restaurants
        .flatMap(
          (item) =>
            item?.restaurantDTO?.cuisine
              ?.split(',')
              .map((cuisine) => cuisine.trim()) ?? [],
        )
        .filter((cuisine): cuisine is string => cuisine !== undefined)
        .filter((cuisine, index, self) => self.indexOf(cuisine) === index)

      const priceRanges = restaurants.map(
        (dish) => dish?.restaurantDTO?.priceRange,
      )
      const uniquePriceRanges = Array.from(new Set(priceRanges))

      if (uniquePriceRanges.length > 1) {
        filterItems.push({
          key: 2,
          name: 'Price',

          items: Array.from({ length: 4 }).map((_, index) => {
            return {
              key: index + 1,
              name: price[index],
              value: `${index + 1}`,
            }
          }),
          rangeStep: 1,
          filterType: 'range',
          priceType: 'dollar',
        })
      }
      if (uniqueDietaryPreferences.length !== 0) {
        filterItems.push({
          key: 3,
          name: 'Dietary',
          items: uniqueDietaryPreferences.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
      if (cuisineList.length !== 0) {
        filterItems.push({
          key: 4,
          name: 'Cuisine',
          items: cuisineList.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
    }

    return filterItems
  }

  return (
    <SearchResultsMainContainer>
      <Helmet>
        <title>{pagTitle}</title>
        <meta
          name="description"
          content="Use Resactly’s advanced search functionality to discover restaurants that match your criteria. Filter by location, cuisine, and more to find the perfect dining spot for any occasion."
        />
      </Helmet>
      <SearchResultsHeaderContainer>
        <SearchResultsHeaderLeftContainer>
          <SearchResultsHeaderSearchTermTypography>
            {searchTerm}
          </SearchResultsHeaderSearchTermTypography>
        </SearchResultsHeaderLeftContainer>
        <SearchResultsHeaderRightContainer>
          {isMdScreen ? (
            <RoundedButton
              component="p"
              borderColor={theme.palette.grey[800]}
              diameter={37}
              onClick={() => setFilterModalOpen(true)}
            >
              <IconAdjustments
                size={18}
                color={theme.palette.grey[800]}
                stroke={2}
              />
            </RoundedButton>
          ) : (
            <>
              <div>
                <Filter
                  onFilterData={onFilter}
                  tabList={filterTabList}
                  rowData={restaurants}
                  isFilterModalOpen={filterModalOpen}
                  setFilterModalOpen={setFilterModalOpen}
                />
              </div>
              <SearchResultsFilterButton
                startIcon={<IconAdjustments size={18} stroke={2} />}
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
              </SearchResultsFilterButton>
            </>
          )}
        </SearchResultsHeaderRightContainer>
      </SearchResultsHeaderContainer>
      {isMdScreen && (
        <Box mt="12px">
          <Filter
            onFilterData={onFilter}
            tabList={filterTabList}
            rowData={restaurants}
            isFilterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
          />
        </Box>
      )}

      <SearchResultsResactlyTabContainer>
        <ResactlyTab
          list={tabList}
          tabComponent={[
            <>
              <SearchResultRestaurants restaurantList={filterRestaurants} />
            </>,
            <>
              <SearchDish dishList={dishes} />
            </>,
          ]}
          align="start"
        />
      </SearchResultsResactlyTabContainer>
    </SearchResultsMainContainer>
  )
}

export default SearchResults
