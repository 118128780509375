import {
  Box,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'

export const ColorLibConnector = styled(StepConnector).attrs(() => ({}))(() => {
  const theme = useTheme()
  return {
    '&': {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '20px !important',
      },
    },

    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      width: 2,
      border: 0,
      backgroundColor: theme.palette.primary.light,
      borderRadius: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }
})

export const OnboardingContainer = styled(Box).attrs(() => ({}))(
  ({ theme }) => ({}),
)

export const OnboardingStepper = styled(Stepper).attrs(() => ({}))(
  ({ theme }) => ({}),
)

export const OnboardingStepLabel = styled(StepLabel).attrs(() => ({
  style: { paddingBottom: '0px', paddingTop: '0px' },
}))(() => {
  const theme = useTheme()
  return {
    '& > span:first-child': {
      paddingRight: theme.breakpoints.down('sm') ? '0px' : '8px',
    },
  }
})

export const OnboardingTypography = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
  marginY: 'auto',
  style: {
    paddingLeft: '8px',
  },
}))(({ theme }) => ({}))
