import React from 'react'
import { Box, Grid, Skeleton } from '@mui/material'

const SkeletonTextBlock: React.FC<{
  width: string | number
  height: string | number
}> = ({ width, height }) => (
  <Skeleton variant="text" width={width} height={height} />
)

const ProfilePageSkeleton: React.FC = (): JSX.Element => {
  return (
    <Box
      component="main"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mb: '100px',
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <SkeletonTextBlock width={130} height={70} />
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <SkeletonTextBlock width={50} height={70} />
          <SkeletonTextBlock width={110} height={70} />
        </Box>
      </Box>

      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Skeleton
          variant="circular"
          width={100}
          height={100}
          sx={{ marginBottom: 2 }}
        />
        <SkeletonTextBlock width="150px" height={24} />
        <SkeletonTextBlock width="200px" height={24} />
      </Box>

      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100%', md: '840px' },
          marginTop: 5,
          mx: '10px',
        }}
      >
        <SkeletonTextBlock width="100%" height={80} />
        <Grid container spacing={{ xs: 2, md: 4 }} sx={{ marginTop: 4 }}>
          {Array.from({ length: 12 }).map((_, index) => (
            <Grid item xs={12} md={6} key={index}>
              <SkeletonTextBlock width="100%" height={50} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default ProfilePageSkeleton
