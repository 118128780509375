import styled from 'styled-components'
import CustomButton from '../../ui/CustomButton'
import { styled as muiStyled } from '@mui/system'
import { useTheme } from '@mui/material'

export const StyledCustomButton = muiStyled(CustomButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderColor: theme.palette.primary.light,
  color: theme.palette.grey.A100,
  paddingTop: '8px',
  paddingBottom: '8px',
  width: '100%',
  height: '36px',
}))

export const StyledCustomOutlineButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      backgroundColor: 'white',
      borderColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
      paddingTop: '8px',
      paddingBottom: '8px',
      width: '100%',
      height: '36px',
      mt: '16px',
    },
  }
})(() => ({}))
