import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import okaoAxios, { okaoAxiosNoAuth } from '../helpers/axios'
import {
  IRestaurantDishReviewRequest,
  IRestaurantSentPayloadReview,
  PredictedRestaurantRequest,
  Restaurant,
  RestaurantAndPredictedRestaurant,
  RestaurantResponse,
  RestaurantResponseContainer,
} from '../types'
import { RootState } from './'
import { AlertColor } from '@mui/material/Alert'
import { PredictedDish } from '../types/menuTypes'
import { convertToPredictedAndPublicRestaurant } from '../helpers/converters'

export interface IRestaurantSlice {
  restaurants: RestaurantResponse[] | undefined
  onboardRestaurants: Restaurant[] | undefined
  status: string
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  restaurant: RestaurantAndPredictedRestaurant | undefined
  restaurantDishList: PredictedDish[] | undefined
  radius: number
  menuNameList: string[]
  publicRestaurant: Restaurant | undefined
  predictedRestaurants: RestaurantResponseContainer
}

const initialState: IRestaurantSlice = {
  restaurants: undefined,
  onboardRestaurants: undefined,
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  restaurant: undefined,
  restaurantDishList: undefined,
  radius: 25,
  menuNameList: [],
  predictedRestaurants: {},
  publicRestaurant: undefined,
}

export const fetchRestaurants = createAsyncThunk(
  'api/fetchRestaurants',
  async (page: number) => {
    const response = await okaoAxios.get(`/restaurants/${page}`)
    return response?.data
  },
)

export const fetchOnboardRestaurants = createAsyncThunk(
  'api/fetchOnboardRestaurants',
  async () => {
    const response = await okaoAxios.get(`/restaurants/onboarded`)
    return response?.data
  },
)

export const fetchPublicRestaurantByAlias = createAsyncThunk(
  'api/fetchRestaurantByAlias',
  async (alias: string) => {
    const response = await okaoAxiosNoAuth().get(
      `/restaurants/public/${alias}/details`,
    )
    return response?.data
  },
)

export const getPredictedRestaurants = createAsyncThunk(
  'api/getPredictedRestaurants',
  async (payload: PredictedRestaurantRequest) => {
    const response = await okaoAxios.post(`/restaurants/predict`, payload)
    return response?.data
  },
)

export const saveRestaurantReviewsByAlias = createAsyncThunk(
  'api/saveDiningReviewsByAlias',
  async (payload: IRestaurantSentPayloadReview) => {
    const formData = new FormData()
    payload?.images?.forEach((img) => {
      formData.append('files', img)
    })

    if (payload.starCount) formData.append('starCount', `${payload.starCount}`)
    if (payload.value) formData.append('value', payload.value)

    const response = await okaoAxios.post(
      `/reviews/${payload.alias as string}/dining-review`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return response?.data
  },
)
export const updateRestaurantReviewsByAlias = createAsyncThunk(
  'api/updateRestaurantReviewsByAlias',
  async (payload: IRestaurantSentPayloadReview) => {
    const formData = new FormData()
    payload?.images?.forEach((img) => {
      formData.append('files', img)
    })

    payload?.deleted?.forEach((url) => {
      formData.append('deleted', url)
    })
    formData.append('starCount', `${payload.starCount ?? 0}`)
    formData.append('value', payload.value ?? '')

    const response = await okaoAxios.put(
      `/reviews/${payload.alias as string}/dining-review`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return response?.data
  },
)

export const saveRestaurantDish = createAsyncThunk(
  'api/saveRestaurantDish',
  async (payload: IRestaurantDishReviewRequest) => {
    const data = {
      starCount: payload.starCount,
    }
    const response = await okaoAxios.post(
      `/reviews/${payload.alias ?? ''}/dining-review/${payload.dishId ?? ''}`,
      data,
    )
    return response?.data
  },
)

export const favoriteRestaurant = createAsyncThunk(
  'api/favoriteRestaurant',
  async (alias: string) => {
    const response = await okaoAxios.post(`/eater/favorite/${alias}`)
    return response?.data
  },
)

export const deleteRestaurantReview = createAsyncThunk(
  'api/deleteRestaurantReview',
  async (id: string) => {
    const response = await okaoAxios.delete(`/reviews/${id}`)
    return response?.data
  },
)

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurants: (
      state,
      action: PayloadAction<RestaurantResponse[] | undefined>,
    ) => {
      state.restaurants = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setRadius: (state, action: PayloadAction<number>) => {
      state.radius = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setSeverity: (state, action: PayloadAction<AlertColor>) => {
      state.severity = action.payload
    },
    setPredictedRestaurantDetail: (
      state,
      action: PayloadAction<{ alias: string }>,
    ) => {
      const predicted = state.predictedRestaurants.result?.find(
        (restaurant) => restaurant.restaurantDTO.alias === action.payload.alias,
      )

      if (predicted) {
        state.restaurant = predicted

        state.restaurantDishList = predicted?.restaurantDTO?.menus
          ?.map((menu) => {
            if (!state.menuNameList.includes(menu.name)) {
              state.menuNameList.push(menu.name)
            }
            return menu.predictedDishes.map((dish) => {
              return {
                ...dish,
                menuName: menu.name,
                menuId: menu.menuID,
              }
            })
          })
          .flat()
      } else {
        state.restaurant = undefined
      }
    },
    setPublicRestaurantDetail: (state) => {
      if (state.publicRestaurant) {
        state.restaurant = convertToPredictedAndPublicRestaurant(
          state.publicRestaurant,
        )
        state.restaurantDishList = state.publicRestaurant?.menus
          ?.map((menu) => {
            if (!state.menuNameList.includes(menu.name)) {
              state.menuNameList.push(menu.name)
            }
            return menu.dishes.map((dish) => {
              return {
                probability: {
                  no: 0,
                  yes: 0,
                },
                dish,
                menuName: menu.name,
                menuId: menu.menuID,
              }
            })
          })
          .flat()
      }
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicRestaurantByAlias.fulfilled, (state, action) => {
        state.menuNameList = []
        state.publicRestaurant = action.payload
        state.status = 'idle'
      })
      .addCase(getPredictedRestaurants.fulfilled, (state, action) => {
        state.menuNameList = []
        state.predictedRestaurants = action.payload
        state.status = 'idle'
      })
      .addCase(fetchRestaurants.fulfilled, (state, action) => {
        state.restaurants = action.payload.result
        state.status = 'idle'
      })
      .addCase(fetchOnboardRestaurants.fulfilled, (state, action) => {
        state.onboardRestaurants = action.payload.restaurants
        state.status = 'idle'
      })
      .addCase(favoriteRestaurant.fulfilled, (state, action) => {
        state.status = 'idle'
        state.shouldOpen = true
        state.severity = 'success'
        state.message = `${
          action.payload.name as string
        } has been saved successfully.`
      })
      .addCase(saveRestaurantDish.fulfilled, (state, action) => {
        state.status = 'idle'
        state.shouldOpen = true
        state.severity = 'success'
        state.message = 'dish review saved successfully.'
      })
      .addCase(
        (saveRestaurantReviewsByAlias.rejected,
        updateRestaurantReviewsByAlias.rejected),
        (state, action) => {
          state.shouldOpen = true
          state.message =
            'An error occurred while saving the restaurant review. Please try again later.'
          state.status = 'error'
          state.severity = 'error'
        },
      )
      .addCase(deleteRestaurantReview.rejected, (state, action) => {
        state.shouldOpen = true
        state.message =
          'An error occurred while delete the restaurant review. Please try again later.'
        state.status = 'error'
        state.severity = 'error'
      })
      .addMatcher(
        isAnyOf(
          fetchOnboardRestaurants.rejected,
          fetchRestaurants.rejected,
          fetchPublicRestaurantByAlias.rejected,
          getPredictedRestaurants.rejected,
          favoriteRestaurant.rejected,
          saveRestaurantDish.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.severity = 'error'
          state.status = 'error'
          state.message =
            'Oops! Looks like something went wrong on our end. But don’t worry, a quick refresh might just do the trick! Give it a whirl and let’s try this again. If the problem persists, we’ll be here to sort it out!'
        },
      )
      .addMatcher(
        isAnyOf(
          fetchOnboardRestaurants.pending,
          fetchRestaurants.pending,
          fetchPublicRestaurantByAlias.pending,
          getPredictedRestaurants.pending,
          saveRestaurantReviewsByAlias.pending,
          updateRestaurantReviewsByAlias.pending,
          favoriteRestaurant.pending,
          deleteRestaurantReview.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectOnboardRestaurants = (state: RootState): Restaurant[] =>
  state.restaurant.onboardRestaurants ?? []

export const selectIsLoading = (state: RootState): boolean =>
  state.restaurant.status === 'loading'

export const selectShouldOpen = (state: RootState): boolean =>
  state.restaurant.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.restaurant.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.restaurant.severity

export const selectRadius = (state: RootState): number => {
  return state?.restaurant?.radius
}
export const selectRestaurantDetail = (
  state: RootState,
): RestaurantAndPredictedRestaurant | undefined => state.restaurant.restaurant
export const selectRestaurantDishList = (
  state: RootState,
): PredictedDish[] | undefined => state.restaurant.restaurantDishList
export const selectMenuNameList = (state: RootState): string[] =>
  state.restaurant.menuNameList

export const {
  setRadius,
  setOpen,
  setPublicRestaurantDetail,
  setPredictedRestaurantDetail,
  setMessage,
  setSeverity,
  clearData,
} = restaurantSlice.actions

export default restaurantSlice.reducer
