import React from 'react'
import { Route, RouteProps, Routes } from 'react-router-dom'
import { ROUTES } from './helpers/routes-helper'
import About from './pages/eater/about/About'
import Error from './pages/eater/error/Error'
import Home from './pages/eater/home/Home'
import Onboard from './pages/eater/onboardPages/Onboard'
import RestaurantDetail from './pages/eater/restaurant/RestaurantDetail'
import SearchResults from './pages/eater/restaurant/SearchResults'
import Sitemap from './sitemap-generator'
import sitemapRestaurants from './sitemap-restaurants'

type SitemapRouteProps = RouteProps & {
  sitemapIndex?: boolean
  priority?: number
  changefreq?:
    | 'always'
    | 'hourly'
    | 'daily'
    | 'weekly'
    | 'monthly'
    | 'yearly'
    | 'never'
  slugs?: Array<{ [key: string]: string }>
}

const homeSitemapProps: SitemapRouteProps = {
  sitemapIndex: true,
  priority: 1,
  changefreq: 'daily',
}
const restaurantSitemapProps: SitemapRouteProps = {
  sitemapIndex: true,
  priority: 1,
  changefreq: 'daily',
  slugs: sitemapRestaurants,
}

const SitemapRouterApp: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Error type="404" />} />
      <Route
        path={ROUTES.UNAUTHORIZED}
        element={<Error type="unauthorized" />}
      />
      <Route path={ROUTES.HOME} {...homeSitemapProps} element={<Home />} />
      <Route path={ROUTES.SEARCH} element={<SearchResults />} />
      <Route path={ROUTES.ABOUT} {...homeSitemapProps} element={<About />} />
      <Route
        path={`${ROUTES?.RESTAURANT_DETAILS}/:alias/details`}
        element={<RestaurantDetail />}
        {...restaurantSitemapProps}
      />
      <Route path={ROUTES.ONBOARD} element={<Onboard />} />
      <Route path={ROUTES.SITEMAP} Component={Sitemap} />
    </Routes>
  )
}

export default SitemapRouterApp
