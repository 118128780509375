import {
  Avatar,
  Box,
  Chip,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import React from 'react'

interface UserEmailOptionDialogMainBoxProp {
  isHeight?: boolean
  isEmailValid?: boolean
}

export const UserEmailOptionDialogMainBox = styled(
  ({
    isHeight,
    isEmailValid,
    ...rest
  }: UserEmailOptionDialogMainBoxProp & any) => <Box {...rest} />,
).attrs<UserEmailOptionDialogMainBoxProp>(({ isHeight, isEmailValid }) => {
  const theme = useTheme()
  return {
    sx: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '5px',
      border: `1px solid ${isEmailValid ? 'red' : theme.palette.grey[300]}`,
      borderRadius: '8px',
      position: 'relative',
      boxShadow: 'none',
      maxHeight: '250px',
      height: isHeight ? '50px' : 'auto',
    },
  }
})<UserEmailOptionDialogMainBoxProp>(({ theme }) => ({}))

export const UserEmailOptionDialogChip = styled(Chip).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      margin: '5px',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '7px',
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const UserEmailOptionDialogDetailBox = styled(Box).attrs(() => {
  return {
    style: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 5px',
      width: '100%',
    },
  }
})(() => ({}))

export const UserEmailOptionDialogDetailTypography = styled(Typography).attrs(
  () => {
    return {
      py: '5px',
      fontFamily: "'General Sans', sans-serif",
      variant: 'subtitle1',
      lineHeight: '16px',
    }
  },
)(() => ({}))

export const UserEmailOptionDialogDetailIcon = styled(IconX).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 16,
    color: theme.palette.grey[500],
    style: {
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const UserEmailOptionTextField = styled(TextField).attrs(() => {
  return {
    autoFocus: true,
    fullWidth: true,
    sx: {
      flexGrow: 1,
      '& .MuiInput-root': {
        padding: '5px',
        fontFamily: "'General Sans', sans-serif",
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
      },
    },
    variant: 'standard',
    InputProps: {
      disableUnderline: true,
    },
  }
})(() => ({}))

export const UserEmailAutocompleteViewMainBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      position: 'absolute',
      top: '115%',
      zIndex: 100,
      padding: '8px',
      width: '100%',
      left: '0px',
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
    },
  }
})(() => ({}))
export const UserEmailAutocompleteViewSecondBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
      flexDirection: 'column',
      gap: '8px',
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const UserEmailAutocompleteViewListDetailBox = styled(Box).attrs(() => {
  return {
    style: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
    },
  }
})(() => ({}))

export const UserEmailAutocompleteViewListDetailImage = styled(Avatar).attrs(
  () => {
    return {
      sx: {
        height: '37px',
        width: '37px',
      },
    }
  },
)(() => ({}))

export const UserEmailAutocompleteViewListDetailTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const UserEmailAutocompleteViewListDetailSubTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle2',
    color: theme.palette.grey[500],
  }
})(() => ({}))
