import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes-helper'
import { debugLog } from '../../../utils/log-helper'
import {
  ErrorContainer,
  ErrorDescriptionTypography,
  ErrorMainContainer,
  ErrorTitleTypography,
} from './styled/Error.styled'

interface ErrorData {
  title: string
  heading: string
  desc: string
  navigatePath: string
  navigateText: string
}
interface ErrorPageData {
  unauthorized: ErrorData
  '404': ErrorData
}
interface ErrorProps {
  type: keyof ErrorPageData
}

const Error: React.FC<ErrorProps> = ({ type }) => {
  const navigate = useNavigate()
  const [errData, setErrData] = useState<ErrorData>(errorData[type])

  useEffect(() => {
    const currentErrData = errorData[type]
    setErrData(currentErrData)
    document.title = `Resactly | ${errData.title}`

    debugLog('errData: ', errData)
  }, [])

  return (
    <ErrorContainer>
      <ErrorMainContainer>
        <ErrorTitleTypography>{errData.heading}</ErrorTitleTypography>
        <ErrorDescriptionTypography>{errData.desc}</ErrorDescriptionTypography>
        <Button
          variant="outlined"
          onClick={() => navigate(errData.navigatePath)}
        >
          {errData.navigateText}
        </Button>
      </ErrorMainContainer>
    </ErrorContainer>
  )
}

export default Error

const errorData: ErrorPageData = {
  unauthorized: {
    title: 'Unauthorized Access',
    heading: 'Unauthorized Access',
    desc: 'You need to be authorized to access this application',
    navigatePath: ROUTES.HOME,
    navigateText: 'Home',
  },
  '404': {
    title: 'Page Not Found',
    heading: 'Oops 404!',
    desc: 'Page Not Found',
    navigatePath: ROUTES.HOME,
    navigateText: 'Home',
  },
}
