import { styled, useTheme } from '@mui/material'

export const NavbarSearchAutocompleteViewContainer = styled('div')(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '8px',
    position: 'absolute',
    top: '57px',
    backgroundColor: 'white',
    zIndex: '10',
    width: '527px',
    maxHeight: '340px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: '385px',
      marginTop: '10px',
      borderRadius: '8px',
      boxShadow: '0px 0px 20px 0px rgba(112, 112, 112, 0.25)',
      top: '57px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      marginTop: '0px',
      top: '47px',
      boxShadow: 'none',
      borderRadius: '0px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '540px',
      borderRadius: '8px',
      boxShadow: '0px 0px 20px 0px rgba(112, 112, 112, 0.25)',
      marginTop: '10px',
      top: '57px',
    },
  }),
)

export const NavbarSearchAutocompleteViewInnerContainer = styled('div')(() => {
  const theme = useTheme()
  return {
    padding: '8px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      '& *': {
        color: `${theme.palette.background.paper} !important`,
      },
    },
  }
})
