import { alpha, Button, ButtonProps, Typography, useTheme } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { SxProps, Theme } from '@mui/system'
import React from 'react'

export interface CustomButtonProps extends Omit<ButtonProps, 'color'> {
  icon?: React.ReactNode
  endComponent?: React.ReactNode
  title?: string
  textColor?: string
  backgroundColor?: string
  action?: () => void
  sx?: SxProps<Theme>
  textVariant?: Variant
  hoverColor?: string
  disabledTextColor?: string
}

const CustomButton: React.FC<CustomButtonProps> = ({
  icon,
  title,
  textColor,
  backgroundColor,
  action,
  sx,
  textVariant = 'body2',
  endComponent,
  hoverColor,
  disabledTextColor,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Button
      onClick={action}
      sx={{
        backgroundColor: backgroundColor ?? theme.palette.primary.main,
        color: textColor ?? theme.palette.primary.contrastText,
        border: `1px solid ${
          textColor ?? backgroundColor ?? theme.palette.primary.main
        } `,
        borderRadius: theme.shape.borderRadius,
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
        '&.MuiButton-root': {
          '&:hover': {
            backgroundColor: alpha(
              backgroundColor ?? theme.palette.primary.main,
              0.8,
            ),
            color: alpha(hoverColor ?? theme.palette.background.paper, 0.8),
          },
          '&.Mui-disabled': {
            color: disabledTextColor ?? textColor,
            backgroundColor: alpha(
              backgroundColor ?? theme.palette.background.paper,
              0.8,
            ),
            cursor: 'none',
          },
        },
        paddingX: '16px',
        ...sx,
      }}
      {...props}
    >
      {icon && icon}
      {title && (
        <Typography variant={textVariant} sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      )}
      {endComponent && endComponent}
    </Button>
  )
}

export default CustomButton
