import { Box, useTheme } from '@mui/material'
import { IconUsers } from '@tabler/icons-react'
import React from 'react'
import GroupMatch from './GroupMatch'
import ImgIcon from './ImgIcon'

export interface IMatchData {
  name?: string
  email?: string
  image?: string
  percentage?: string
}

export interface Data {
  percentage: string
  title?: string
  matchData?: IMatchData[]
}

interface TagChipProps {
  data: Data[]
  hoverDisable?: boolean
  textBlur?: boolean
  bgColor?: 'orange' | 'liteOrange' | 'white'
}

const TagChip: React.FC<TagChipProps> = ({
  data,
  hoverDisable = false,
  bgColor = 'orange',
  textBlur = false,
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e): void => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const getBackgroundColor = (color: string): string => {
    switch (color) {
      case 'orange':
        return 'rgba(254, 84, 17, 100%)'
      case 'liteOrange':
        return 'rgba(254, 84, 17, 70%)'
      case 'white':
        return theme.palette.grey[100]
      default:
        return theme.palette.primary.main
    }
  }

  const getColor = (color: string): string => {
    return color === 'orange' || color === 'liteOrange'
      ? theme.palette.background.paper
      : theme.palette.common.black
  }

  return (
    <Box
      sx={{ p: '0px', mt: '3px' }}
      className={!textBlur ? 'tag-chip-component' : ''}
      style={{ position: 'relative' }}
    >
      <Box
        sx={{
          px: '10px',
          backgroundColor: getBackgroundColor(bgColor),
          color: getColor(bgColor),
          display: 'flex',
          alignItems: 'center',
          borderRadius: data.length > 1 ? '10px' : '15px',
          boxShadow: bgColor === 'white' ? 'none' : '0px 0px 4px 0px #00000040',
          height: '24px',
        }}
        onClick={handleClick}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '14px',
            paddingTop: '1px',
            paddingBottom: '1px',
          }}
        >
          {data.map((val, i) => (
            <Box
              component="div"
              key={i}
              aria-describedby={id}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  maxHeight: '17px',
                  maxWidth: '17px',
                  margin: '0px',
                  padding: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={
                  i === 0 && !textBlur ? 'tag-chip-component-icon' : ''
                }
              >
                {i === 0 ? (
                  <>
                    {bgColor === 'white' ? (
                      <div>
                        <ImgIcon
                          icon="/new/images/icons/tagChipMatchBlack.svg"
                          alt="restaurant-match"
                          wrapperStyle={{
                            backgroundColor: 'transparent',
                            height: '18px',
                            width: '18px',
                          }}
                          imgSize={12}
                        />
                      </div>
                    ) : (
                      <div>
                        <ImgIcon
                          icon="/new/images/icons/tagChipMatch.svg"
                          alt="group-restaurant-match"
                          wrapperStyle={{
                            backgroundColor: 'transparent',
                            height: '12px',
                            width: '12px',
                          }}
                          imgSize={12}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <IconUsers stroke={2} height={15} width={15} />
                )}
              </div>

              <span
                style={{
                  marginLeft: '2px',
                  marginRight: '2px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    marginLeft: '2px',
                    marginRight: '2px',
                    fontSize: '12px',
                    fontWeight: 700,
                    ...(textBlur && { filter: 'blur(2px)' }),
                  }}
                >
                  {val.percentage}%
                </span>
                {val.title && (
                  <span
                    className={!hoverDisable ? 'tag-chip-component-title' : ''}
                    style={{
                      padding: '1px 0px 2px 0px',
                      fontSize: '12px',
                      fontWeight: 700,
                      ...(textBlur && { filter: 'blur(2px)' }),
                    }}
                  >
                    {val.title}
                  </span>
                )}
              </span>
              {data.length - 1 > i && (
                <span
                  style={{
                    margin: '0px 2px',
                    opacity: 0.6,
                    fontSize: '16px',
                    paddingTop: '1px',
                  }}
                >
                  |
                </span>
              )}
            </Box>
          ))}
        </div>
      </Box>
      {open && data[1]?.matchData && (
        <GroupMatch
          data={data[1].matchData ?? []}
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </Box>
  )
}

export default TagChip
