import {
  Box,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconX } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { PredictedDish } from '../../types/menuTypes'
import ImgIcon from '../ui/ImgIcon'
import TagChip from '../ui/TagChip'
import useRudderStackAnalytics from '../../useRudderAnalytics'
import { useAppSelector } from '../../store'
import { selectLogin } from '../../store/authSlice'

interface RestaurantDrawerProps {
  open: boolean
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
  data: Partial<PredictedDish>
  restaurantAlias: string
}

const RestaurantDrawer: React.FC<RestaurantDrawerProps> = ({
  open,
  toggleDrawer,
  data,
  restaurantAlias,
}) => {
  const theme = useTheme()
  const userDetail = useAppSelector(selectLogin)
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const suitableDietaryBgColors = [
    theme.palette.success.light,
    theme.palette.error.main,
    theme.palette.warning.dark,
    theme.palette.primary.dark,
    theme.palette.warning.light,
  ]
  const unsuitableDietaryBgColors = [
    theme.palette.success[600],
    theme.palette.primary.dark,
    theme.palette.grey[800],
    theme.palette.warning.light,
    theme.palette.error.light,
    theme.palette.secondary.light,
    theme.palette.info.light,
  ]

  useEffect(() => {
    if(analytics && isAnalyticsReady){
      analytics?.track('DISH_PAGE', {
        category: 'DISH',
        opened: open,
        eaterId: `${userDetail?.eaterDTO?.eaterId ?? ''}`,
        eaterName: `${userDetail?.eaterDTO?.firstName ?? ''} ${
          userDetail?.eaterDTO?.lastName ?? ''
        }`,
        eaterEmail: `${userDetail?.eaterDTO?.email ?? ''}`,
        dishName: `${data?.dish?.name??''}`,
        dishId: `${data?.dish?.dishID??''}`,
        menuName: `${data?.menuName??''}`,
        menuId: `${data?.menuId??''}`,
        restaurantAlias: `${restaurantAlias}`,
        startTime: new Date().toISOString()
      })
    }
  }, [open, analytics, isAnalyticsReady])

  return (
    <Drawer
      open={open}
      onClose={() => toggleDrawer(false)}
      anchor={isSmallScreen ? 'bottom' : 'right'}
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: isSmallScreen ? '16px' : 'none',
          borderTopRightRadius: isSmallScreen ? '16px' : 'none',
        },
      }}
    >
      <Box
        sx={{
          width: { xs: 'calc(100vw - 5px)', md: '320px' },
          height: '100%',
        }}
      >
        <div style={{ padding: '16px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" color="text.primary">
              Details
            </Typography>
            <ImgIcon
              iconElement={<IconX stroke={2} style={{ margin: '7px' }} />}
              alt="close-drawer"
              wrapperStyle={{
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                cursor: 'pointer',
              }}
              onClick={() => toggleDrawer(false)}
            />
          </div>
        </div>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ color: theme.palette.grey[300] }}
        />
        <div style={{ padding: '16px' }}>
          <div
            style={{
              position: 'relative',
            }}
          >
            <img
              src={
                data?.dish?.imageurl && data.dish.imageurl.length !== 0
                  ? data?.dish?.imageurl
                  : '/logoLetter-resize.svg'
              }
              alt={`resactly-${data?.dish?.name ?? ''}-dish`}
              style={{
                width: '100%',
                height: isSmallScreen ? '200px' : '160px',
                borderRadius: '16px',
              }}
            />
            {data.probability && data.probability.yes !== 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: '6px',
                  left: '6px',
                  zIndex: 100,
                }}
              >
                <TagChip
                  data={[
                    {
                      percentage: `${data?.probability?.yes.toFixed(2)}`,
                      title: 'Match',
                    },
                  ]}
                  bgColor="liteOrange"
                />
              </div>
            )}
          </div>
          <div style={{ paddingTop: '20px' }}>
            <Typography variant="body1" textTransform="capitalize">
              {data?.dish?.name?.toLocaleLowerCase()}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[500], paddingRight: '10px' }}
            >
              {data?.dish?.description}
            </Typography>
          </div>
          {data?.dish?.price && (
            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <Typography variant="body2">${data?.dish?.price}</Typography>
            </div>
          )}
          <Divider
            orientation="horizontal"
            flexItem
            sx={{
              color: theme.palette.grey[300],
              marginTop: !data?.dish?.price ? '20px' : '',
            }}
          />
          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[500] }}
            >
              Ingredients
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[800],wordBreak:'break-all' }}
            >
              {data?.dish?.ingredients}
            </Typography>
          </div>
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ color: theme.palette.grey[300] }}
          />

          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[500], paddingBottom: '8px' }}
            >
              Suitable Dietary:
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {data?.dish?.dietary
                ?.split(',')
                .filter((item) => item.trim().length > 0)
                .map((item, index) => (
                  <div
                    key={index}
                    style={{
                      padding: '6px 10px',
                      backgroundColor:
                        suitableDietaryBgColors[
                          index % suitableDietaryBgColors.length
                        ],
                      borderRadius: '7px',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ color: theme.palette.background.paper }}
                    >
                      {item}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
          <div style={{ paddingBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey[500], paddingBottom: '8px' }}
            >
              Unsuitable Dietary:
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {data?.dish?.unsuitabledietary
                ?.split(',')
                .filter((item) => item.trim().length > 0)
                .map((item, index) => (
                  <div
                    key={index}
                    style={{
                      padding: '6px 10px',
                      backgroundColor:
                        unsuitableDietaryBgColors[
                          index % unsuitableDietaryBgColors.length
                        ],
                      borderRadius: '7px',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ color: theme.palette.background.paper }}
                    >
                      {item}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Box>
    </Drawer>
  )
}

export default RestaurantDrawer
