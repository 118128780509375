import { Box, Card, Skeleton } from '@mui/material'
import styled from 'styled-components'

export const RestaurantImagesMainContainer = styled(Box).attrs(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  height: '400px',
}))(() => ({}))

export const RestaurantImagesMainImgWrapper = styled(Box).attrs(() => ({
  flex: { xs: '0 0 auto', md: '0 0 58.33%' },
  height: { xs: 'calc(50% + 5px)', md: '100%' },
}))(() => ({}))

export const RestaurantImagesMainImgCard = styled(Card).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: '6px',
  },
}))(() => ({}))

export const RestaurantImagesSkeleton = styled(Skeleton).attrs(() => ({
  variant: 'rectangular',
  width: '100%',
  height: '100%',
  sx: {
    borderRadius: '6px',
  },
}))(() => ({}))

export const RestaurantImagesOtherImgsWrapper = styled(Box).attrs(() => ({
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  flexWrap: 'wrap',
  flex: { xs: '0 0 auto', md: '0 0 41.67%' },
  height: { xs: 'calc(50% + 5px)', md: '100%' },
  gap: '10px',
}))(() => ({}))

export const RestaurantImagesOtherImgsInnerWrapper = styled(Box).attrs(() => ({
  flex: { xs: '0 0 calc(50% - 5px)', md: '0 0 calc(50% - 5px)' },
  height: { xs: 'calc(50% - 5px)', md: 'calc(50% - 5px)' },
}))(() => ({}))

export const RestaurantImagesOtherImgsCard = styled(Card).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: '6px',
  },
}))(() => ({}))

export const RestaurantImagesSmallScreenImgsWrapper = styled(Box).attrs(() => ({
  className: 'hide-scrollbar',
  sx: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    display: 'flex',
    gap: '10px',
    height: '225px',
  },
}))(() => ({}))

export const RestaurantImagesSmallScreenImgsCard = styled(Card).attrs(() => ({
  className: 'hide-scrollbar',
  sx: {
    display: 'inline-block',
    minWidth: '350px',
    height: '100%',
    borderRadius: '6px',
  },
}))``
