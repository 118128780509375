import { Grid } from '@mui/material'
import styled from 'styled-components'

export const ProfileReviewTabMainGrid = styled(Grid).attrs(() => {
  return {
    container: true,
    spacing: 2,
  }
})(() => ({}))

export const ProfileReviewTabInnerGrid = styled(Grid).attrs(() => {
  return {
    item: true,
    xs: 12,
    sm: 6,
    md: 4,
  }
})(() => ({}))
