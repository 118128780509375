import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const OnboardingTitleContainer = styled(Box)(({ theme }) => ({}))

export const OnboardingMainHeading = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'h3',
    component: 'h4',
    letterSpacing: 0.5,
    style: {
      fontWeight: 700,
      color: theme.palette.grey[800],
    },
  }
})(() => ({}))

export const OnboardingSubHeading = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
  component: 'p',
  letterSpacing: 0.5,
  pt: 1,
}))``
