import {
  Box,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import { IconInfoTriangle, IconPencil } from '@tabler/icons-react'
import styled from 'styled-components'
import RoundedButton from '../../../../components/ui/RoundedButton'
import { useDevice } from '../../../../hooks/useDevice'

export const ProfileMuiContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: { flex: 1 },
}))(() => ({}))

export const ProfileCircularProgress = styled(CircularProgress).attrs(() => ({
  color: 'inherit',
}))(() => ({}))

export const ProfileUserAvatarContainer = styled(Box).attrs(() => {
  const { isHybrid } = useDevice()
  return {
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      mb: {
        xs: '24px',
        md: '40px',
      },
      mt: {
        xs: '16px',
        md: '0px',
      },
      position: isHybrid ? 'relative' : 'inherit',
    },
  }
})(() => ({}))

export const ProfileUserInfoContainer = styled('div')({
  textAlign: 'center',
  marginTop: '16px',
})

export const ProfileUserInfoNameTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'body2',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const ProfileUserInfoEmailTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.grey[500],
  }
})(() => ({}))

export const ProfileUserDisclaimerButton = styled(RoundedButton).attrs(() => {
  const theme = useTheme()
  return {
    br: '50%',
    borderColor: 'transparent',
    diameter: 34,
    sx: {
      position: 'absolute',
      top: '0px',
      background: theme.palette.grey[100],
      right: '0px',
    },
  }
})(() => ({}))

export const ProfileUserDisclaimerButtonIcon = styled(IconInfoTriangle).attrs(
  () => {
    const theme = useTheme()
    return {
      stroke: 2,
      size: 18,
      color: theme.palette.grey[600],
      style: {
        cursor: 'pointer',
      },
    }
  },
)(() => ({}))

export const ProfileUserImageSkeleton = styled(Skeleton).attrs(() => {
  return {
    variant: 'circular',
    width: 96,
    height: 96,
  }
})(() => ({}))

export const ProfileUserImageEditIcon = styled(IconPencil).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(() => ({}))
