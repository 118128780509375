import React, { useEffect } from 'react'
import { Browser } from '@capacitor/browser'
import { App as CapApp } from '@capacitor/app'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'

import RouterApp from './RouterApp'

const App: React.FC = () => {
  // Get the callback handler from the Auth0 React hook
  const { handleRedirectCallback } = useAuth0()

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    const appUrlOpenHandler = async ({
      url,
    }: {
      url: string
    }): Promise<void> => {
      if (
        url.includes('state') &&
        (url.includes('code') || url.includes('error'))
      ) {
        // eslint-disable-next-line no-console
        console.log('AppUrlOpen URL : ', url)

        try {
          await handleRedirectCallback(url)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error handling redirect callback:', error)
        }
      }
      // No-op on Android
      await Browser.close()
    }

    // Function to register the listener with an explicit return type
    const registerListener = async (): Promise<() => void> => {
      const listener = await CapApp.addListener('appUrlOpen', (event) => {
        // eslint-disable-next-line no-console
        console.log('registerListener event: ', event)
        void appUrlOpenHandler(event)
      })

      // Cleanup listener on component unmount
      return () => {
        void listener.remove()
      }
    }

    // Register the listener and handle cleanup
    const cleanup = registerListener()

    return () => {
      // Ensure cleanup function is called
      void cleanup
    }
  }, [handleRedirectCallback])

  return (
    <Router>
      <RouterApp />
    </Router>
  )
}

export default App
