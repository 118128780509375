import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const AboutTabContactInfoMuiContainer = styled(Box).attrs(() => ({
  flexDirection: 'column',
  component: 'div',
}))(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  gap: '16px',
  paddingBottom: '126px',
}))

export const AboutTabContactInfoTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body2',
    component: 'p',
    fontWeight: 500,
    color: theme.palette.grey[500],
  }
})(() => ({}))

export const AboutTabRestaurantTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body2',
    component: 'p',
    fontWeight: 500,
    sx: { py: '24px' },
    color: theme.palette.grey[600],
  }
})(() => ({}))
