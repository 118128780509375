import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import {
  IconBookmark,
  IconBookmarkFilled,
  IconPointFilled,
  IconRosetteDiscountCheck,
} from '@tabler/icons-react'
import styled from 'styled-components'

export const RestaurantCardImgSectionContainer = styled(Box).attrs(() => {
  return {
    sx: {
      height: { xs: '182px', md: '200px' },
      marginBottom: '12px',
      position: 'relative',
    },
  }
})(() => ({}))

export const RestaurantCardImgSectionSkeletonContainer = styled(Skeleton).attrs(
  () => {
    return {
      variant: 'rectangular',
      sx: {
        borderRadius: '20px',
        height: { xs: 182, md: 200 },
      },
      width: '100%',
    }
  },
)(() => ({}))

export const RestaurantCardImgSectionTagChipContainer = styled(Box).attrs(
  () => {
    return {
      sx: {
        position: 'absolute',
        top: '6px',
        left: '6px',
        zIndex: 100,
      },
    }
  },
)(() => ({}))

export const RestaurantCardImgSectionIconBookmarkFilledContainer = styled(
  Box,
).attrs(() => {
  return {
    sx: {
      position: 'absolute',
      top: '7px',
      right: '7px',
      zIndex: 100,
      cursor: 'pointer',
    },
  }
})(() => ({}))

export const RestaurantCardImgSectionCircularProgress = styled(
  CircularProgress,
).attrs(() => {
  const theme = useTheme()
  return {
    color: 'inherit',
    style: {
      width: '30px',
      height: '30px',
      color: theme.palette.background.paper,
    },
  }
})(() => ({}))

export const RestaurantCardImgSectionIconBookmarkFilled = styled(
  IconBookmarkFilled,
).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.background.paper,
    height: 20,
    width: 20,
  }
})(() => ({}))

export const RestaurantCardImgSectionIconBookmark = styled(IconBookmark).attrs(
  () => {
    return {
      color: 'white',
      height: 20,
      width: 20,
      stroke: 2,
    }
  },
)(() => ({}))

export const RestaurantCardInfoContainer = styled(Box).attrs(() => {
  return {
    sx: {
      px: '4px',
    },
  }
})(() => ({}))

export const RestaurantCardInfoTitleContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
  }
})(() => ({}))

export const RestaurantCardInfoTitleTypography = styled(Typography).attrs(
  () => {
    return {
      noWrap: true,
      variant: 'body2',
      sx: {
        textTransform: 'capitalize',
        textAlign: 'left',
        mr: '5px',
        mb: '4px',
      },
      maxWidth: '100%',
    }
  },
)(() => ({}))

export const RestaurantCardInfoTitleIconRosetteDiscountCheck = styled(
  IconRosetteDiscountCheck,
).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 20,
    color: theme.palette.success.dark,
  }
})(() => ({}))

export const RestaurantCardInfoDescriptionContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
  }
})(() => ({}))

export const RestaurantCardInfoDescriptionTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()

    return {
      noWrap: true,
      variant: 'subtitle1',
      sx: {
        textOverflow: 'ellipsis',
        maxWidth: { xs: '65%', md: '70%' },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        color: theme.palette.grey[400],
        flexShrink: 1,
        flexGrow: 1,
      },
    }
  },
)(() => ({}))

export const RestaurantCardInfoDescriptionIconPointFilled = styled(
  IconPointFilled,
).attrs(() => {
  return {
    height: 17,
    width: 17,
  }
})(() => {
  const theme = useTheme()
  return {
    marginLeft: '8px',
    marginRight: '8px',
    color: theme.palette.grey[400],
  }
})

export const RestaurantCardInfoDescriptionDistanceTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()

  return {
    variant: 'subtitle1',
    sx: {
      textTransform: 'capitalize',
      color: theme.palette.grey[400],
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
    },
  }
})(() => ({}))
