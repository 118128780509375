import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'

export const RecoverBlockedAccountMainContainer = styled(Box).attrs(() => ({
  sx: {
    textAlign: 'center',
  },
}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  }
})

export const RecoverBlockedAccountTitleTypography = styled(Typography).attrs(
  () => ({
    component: 'p',
    variant: 'h2',
    sx: {
      mb: '16px',
    },
  }),
)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
  }
})

export const RecoverBlockedAccountDescriptionTypography = styled(
  Typography,
).attrs(() => ({
  component: 'p',
  variant: 'body1',
  fontWeight: 500,
  sx: {
    mb: '40px',
  },
}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
  }
})

export const RecoverBlockedAccountInnerContainer = styled('div').attrs(
  () => ({}),
)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
  gap: '12px',
  width: '100%',
}))

export const RecoverBlockedAccountCustomButton = styled(CustomButton).attrs(
  () => ({}),
)(() => ({
  padding: '8px 12px',
}))
