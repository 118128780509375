import { CircularProgress, useTheme } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../helpers/routes-helper'
import { useAuthHelper } from '../../../hooks/useAuthHelper'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  recoverUserAccount,
  selectIsLoading,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../store/authSlice'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiAlert, MuiSnackbar } from '../styled/global.styled'
import {
  RecoverAccountCustomButtonNo,
  RecoverAccountCustomButtonYes,
  RecoverAccountDescriptionTypography,
  RecoverAccountInnerContainer,
  RecoverAccountMainContainer,
  RecoverAccountTitleTypography,
} from './styled/RecoverAccount.styled'

const RecoverAccount: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { logoutAuthUser } = useAuthHelper()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const isLoading = useAppSelector(selectIsLoading)
  const openServer = useAppSelector(selectShouldOpen)

  const logoutUser = async (): Promise<void> => {
    if(analytics && isAnalyticsReady){
      analytics?.identify('', { isLoggedIn: false })
      analytics?.reset()
    }
    logoutAuthUser()
  }

  const handleRecoverAccount = async (): Promise<void> => {
    const result = await dispatch(recoverUserAccount())
    if (recoverUserAccount.fulfilled.match(result)) {
      navigate(ROUTES.HOME)
    }
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <RecoverAccountMainContainer>
      <Helmet>
        <title>Resactly | Recover Your Account</title>
        <meta
          name="description"
          content="Need help accessing your Resactly account? Use our account recovery page to reset your password or retrieve your account information and get back to discovering top dining spots."
        />
      </Helmet>
      <div>
        <RecoverAccountTitleTypography>
          Recover Your Account
        </RecoverAccountTitleTypography>
        <RecoverAccountDescriptionTypography>
          Your account is Deleted. Do you want to recover your account?
        </RecoverAccountDescriptionTypography>
        <RecoverAccountInnerContainer>
          <RecoverAccountCustomButtonYes
            onClick={() => {
              void handleRecoverAccount()
            }}
            title={isLoading ? '' : 'Yes'}
            icon={
              isLoading ? <CircularProgress color="inherit" size={16} /> : null
            }
          />
          <RecoverAccountCustomButtonNo
            disabled={isLoading}
            onClick={() => {
              void logoutUser()
            }}
            backgroundColor={theme.palette.error.main}
            title="No"
            disabledTextColor={theme.palette.background.paper}
          />
        </RecoverAccountInnerContainer>
      </div>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </RecoverAccountMainContainer>
  )
}

export default RecoverAccount
