import React from 'react';
import Routes from './SitemapRouterApp';
import DynamicSitemap from 'react-dynamic-sitemap';

const ResactlySitemap: React.FC = (): JSX.Element => {
  return (
    <DynamicSitemap routes={Routes} prettify={true}/>
  );
};

export default ResactlySitemap;
