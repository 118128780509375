import { Box, Tab } from '@mui/material'
import styled from 'styled-components'

export const ProfileTabMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      width: '100%',
    },
  }
})(() => ({}))

export const ProfileTabInnerContainer = styled(Box).attrs(() => {
  return {
    sx: {
      borderBottom: 1,
      borderColor: 'divider',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})(() => ({}))

export const ProfileTabTabComponent = styled(Tab).attrs(() => {
  return {
    sx: {
      paddingY: '0px',
      minHeight: '56px',
      textTransform: 'capitalize',
    },
    iconPosition: 'start',
  }
})(() => ({}))

export const ProfileTabProfileBioContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: { xs: '0px', md: '40px' },
    },
  }
})(() => ({}))
