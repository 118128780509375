import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const RestaurantCardContainerNoDataTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      variant: 'body2',
      component: 'p',
      sx: {
        fontWeight: 600,
        height: '280px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: '24px',
      },
      color: theme.palette.grey[400],
      bgcolor: theme.palette.grey[100],
    }
  },
)(() => ({}))

export const RestaurantCardContainerTopBarContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      my: { xs: '8px', sm: '16px', md: '24px' },
    },
  }
})(() => ({}))

export const RestaurantCardContainerTopBarTypography = styled(Typography).attrs(
  () => {
    return {
      variant: 'h3',
      sx: {
        textTransform: 'capitalize',
        fontSize: {
          xs: '16px',
          sm: '20px',
          md: '24px',
        },
      },
    }
  },
)(() => ({}))

export const RestaurantCardContainerTopBarInnerContainer = styled(Box).attrs(
  () => {
    return {
      sx: {
        display: 'flex',
        alignItems: 'center',
      },
    }
  },
)(() => ({}))

export const RestaurantCardContainerTopBarViewAllTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle1',
    component: 'span',
    sx: {
      textTransform: 'capitalize',
      marginRight: { xs: '0px', md: '36px' },
      marginLeft: { xs: '10px', md: '0px' },
      fontWeight: 600,
      cursor: 'pointer',
    },
    color: theme.palette.grey[400],
  }
})(() => ({}))

export const RestaurantCardContainerSwiperContainer = styled(Typography).attrs(
  () => {
    return {
      className: 'restaurant-card-swiper-slide',
      sx: {
        height: { xs: '256px', md: '280px' },
        width: { xs: '256px', md: '336px' },
      },
    }
  },
)(() => ({}))
