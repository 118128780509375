import { Box } from '@mui/material'
import styled from 'styled-components'

export const RestaurantCardContainerSkeletonContainer = styled(Box).attrs(
  () => {
    return {
      className: 'hide-scrollbar',
      sx: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginTop: '20px',
        width: '100%',
        gap: '20px',
        overflow: 'auto',
      },
    }
  },
)(() => ({}))
