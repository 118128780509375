import React from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const CenteredLogoWithLoader: React.FC = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: theme.palette.common.white,
      }}
    >
      {/* Logo */}
      <Box
        component="img"
        src={'/logo.svg'}
        alt="Logo"
        sx={{
          width: '150px', // Adjust the logo size
          mb: 2, // Margin bottom for spacing between logo and loader
        }}
      />
      {/* Loader */}
      <Box
        component="p"
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          color: theme.palette.common.black,
        }}
      >
        Loading....
      </Box>
    </Box>
  )
}

export default CenteredLogoWithLoader
