import { useState, useEffect } from 'react'
import { Device, DeviceInfo } from '@capacitor/device'

// Define the shape of the device context
interface DeviceState {
  deviceInfo: DeviceInfo | null
  isHybrid: boolean
  isIos: boolean
  isAndroid: boolean
  isWeb: boolean
  updateDeviceInfo: (info: DeviceInfo) => void
}

// Custom hook for managing device state
export const useDevice = (): DeviceState => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | null>(null)

  const isWeb = deviceInfo?.platform === 'web'
  const isIos = deviceInfo?.platform === 'ios'
  const isAndroid = deviceInfo?.platform === 'android'

  const isHybrid =
    deviceInfo?.platform === 'ios' || deviceInfo?.platform === 'android'

  const fetchDeviceInfo = async (): Promise<void> => {
    const info = await Device.getInfo()
    setDeviceInfo(info)
  }

  const updateDeviceInfo = (info: DeviceInfo): void => {
    setDeviceInfo(info)
  }

  // Fetch device info when the component mounts
  useEffect(() => {
    void fetchDeviceInfo()
  }, [])

  return {
    deviceInfo,
    isHybrid,
    isIos,
    isAndroid,
    isWeb,
    updateDeviceInfo,
  }
}
