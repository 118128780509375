import { Box } from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import React from 'react'
import styled from 'styled-components'

const images = [
  '/images/homepage/hero-salad.svg',
  '/images/homepage/hero-burger.svg',
]

const bgImage = '/images/homepage/arrow-up-right.svg'

export const HeroContainer = styled(Box).attrs(() => ({
  sx: {
    width: { xs: '90vw', md: '100%' },
    height: { xs: '90vw', md: '100%' },
    maxWidth: '608px',
    maxHeight: '608px',
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}))(({ theme }) => ({}))

const ForegroundImage = muiStyled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0,
  animation: `showImage 4s steps(1) infinite`,
  '&:nth-of-type(1)': {
    animationDelay: '0s',
  },
  '&:nth-of-type(2)': {
    animationDelay: '2s',
  },
  '@keyframes showImage': {
    '0%, 50%': { opacity: 1 },
    '50%, 100%': { opacity: 0 },
  },
}))

const HeroImages: React.FC = () => {
  return (
    <HeroContainer>
      {images.map((src, index) => (
        <ForegroundImage key={index} src={src} alt={`Hero ${index + 1}`} />
      ))}
    </HeroContainer>
  )
}

export default HeroImages
