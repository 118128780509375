import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  Slider,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'
import { IconInfoCircle } from '@tabler/icons-react'
import styled from 'styled-components'
import React from 'react'

interface FilterRangeMainBoxProp {
  isHeight: boolean
}

export const FilterCheckBoxFormControlLabel = styled(FormControlLabel).attrs(
  () => {
    const theme = useTheme()
    return {
      sx: {
        '&.MuiFormControlLabel-root': {
          width: '100%',
          padding: '12px',
          '& .MuiFormControlLabel-label': {
            fontSize: theme.typography.subtitle1,
            paddingLeft: '16px',
            fontWeight: 500,
            color: theme.palette.grey[800],
          },
        },
      },
    }
  },
)``

export const FilterCheckBox = styled(Checkbox).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      color: theme.palette.grey[300],
      textTransform: 'capitalize',
      padding: '0px',
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
    },
  }
})``

export const FilterRadioFormControlLabel = styled(FormControlLabel).attrs(
  () => {
    const theme = useTheme()
    return {
      sx: {
        '&.MuiFormControlLabel-root': {
          width: '100%',
          padding: '12px',
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
            fontWeight: 500,
            paddingLeft: '16px',
            color: theme.palette.grey[800],
          },
        },
      },
    }
  },
)``

export const FilterRadio = styled(Radio).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      color: theme.palette.grey[300],
      padding: '0px',
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
    },
  }
})``

export const FilterRangeInfoBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    padding: 1,
    borderRadius: '4px',
    bgcolor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '8px',
  }
})``

export const FilterRangeInfoIcon = styled(IconInfoCircle).attrs(() => {
  const theme = useTheme()
  return {
    stroke: 2,
    size: 14,
    style: {
      color: theme.palette.grey[500],
    },
  }
})``

export const FilterRangeInfoTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle2',
    paddingLeft: '10px',
    color: theme.palette.grey[500],
    component: 'p',
  }
})``

export const FilterRangeMainBox = styled(
  ({ isHeight, ...rest }: FilterRangeMainBoxProp & any) => <Stack {...rest} />,
).attrs<FilterRangeMainBoxProp>(({ isHeight }) => {
  return {
    sx: { height: { xs: isHeight ? 220 : 260, md: 'auto' } },
    spacing: 1,
    direction: { xs: 'row', md: 'column' },
  }
})<FilterRangeMainBoxProp>(({ theme }) => ({}))

export const FilterRangeSliderLabelBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: { xs: 'flex' },
      justifyContent: { xs: 'space-between' },
      flexDirection: { xs: 'column', md: 'row' },
      flexFlow: { xs: 'column-reverse', md: 'row' },
      width: { xs: '40px', md: 'auto' },
      order: { md: 1 },
    },
  }
})``

export const FilterRangeSlider = styled(Slider).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      '&.MuiSlider-root': {
        color: theme.palette.primary.light,
        width: { md: '95%' },
      },
      '& .MuiSlider-mark': {
        width: '0',
      },

      '& .MuiSlider-markLabelActive': {
        color: theme.palette.secondary.dark,
        fontWeight: 600,
      },
      '& .MuiSlider-thumb': {
        width: 24,
        height: 24,
        backgroundColor: theme.palette.background.paper,
        border: `5px solid ${theme.palette.primary.light}`,
        '&::before': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
        },
        '&:hover, &.Mui-focusVisible, &.Mui-active': {
          boxShadow: 'none',
        },
      },
      '& .MuiSlider-thumb::after': {
        width: 24,
        height: 24,
      },
    },
  }
})``

export const FilterListSelectedItemCountTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      color: 'white',
      component: 'p',
      variant: 'subtitle2',
      bgcolor: theme.palette.secondary.main,
      sx: {
        marginLeft: '7px',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
  },
)``

export const FilterListMainContainer = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      flexGrow: 1,
      bgcolor: 'background.paper',
      display: 'flex',
      marginY: '16px',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
  }
})``

export const FilterListMainTabs = styled(Tabs).attrs(() => {
  return {
    orientation: 'vertical',
    variant: 'scrollable',
    ariaLabel: 'Vertical tabs example',
    sx: {
      borderRight: 1,
      borderColor: 'divider',
      width: '130px',
      textAlign: 'start',
    },
  }
})``

export const FilterListMainTab = styled(Tab).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      textTransform: 'capitalize',
      paddingY: '10px',
      alignItems: 'start',
      px: { xs: '0px', sm: '16px' },
      '&.MuiTab-root': {
        fontSize: '14px',
        fontWeight: 500,
        color: theme.palette.grey[800],
        minHeight: '48px',
        justifyContent: 'start',
        alignItems: 'center',
        '&.Mui-selected': {
          color: theme.palette.secondary.dark,
        },
      },
    },
  }
})``
