import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const EmptyScreenMainBox = styled(Box).attrs(() => {
  return {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})(() => ({}))

export const EmptyScreenImage = styled('img').attrs(() => {
  return {
    alt: 'No result found',
    loading: 'lazy',
    style: {
      margin: '0px auto',
    },
  }
})(() => ({}))

export const EmptyScreenTitleTypography = styled(Typography).attrs(() => {
  return {
    component: 'p',
    variant: 'h4',
    fontWeight: 400,
    sx: { marginTop: '40px' },
  }
})(() => ({}))

export const EmptyScreenSecondaryTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.grey[400],
    sx: {
      paddingTop: '',
      maxWidth: '565px',
      textAlign: 'center',
      margin: '0px auto',
    },
  }
})(() => ({}))
