import { Box, Drawer, useTheme } from '@mui/material'
import styled from 'styled-components'
import {
  MOBILE_NAV_HEIGHT,
  WEB_NAV_HEIGHT,
} from '../../../../helpers/static-constants'
import { useDevice } from '../../../../hooks/useDevice'

export const ChatDrawerDrawer = styled(Drawer).attrs(() => ({
  width: { xs: '100vw', md: 392 },
  transitionDuration: 300,
  translate: 'yes',
  sx: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: { xs: '100vw', md: 392 },
      marginTop: { xs: `${MOBILE_NAV_HEIGHT}px`, md: `${WEB_NAV_HEIGHT}px` },
    },
  },
}))(({ theme }) => ({}))

export const ChatDrawerChatContainer = styled(Box).attrs(() => ({}))(
  ({ theme }) => ({}),
)

export const ChatBoxContainer = styled(Box).attrs(() => ({
  sx: {
    width: '100%',
    height: {
      xs: `calc(100vh - (78px + env(safe-area-inset-top) + env(safe-area-inset-bottom)))`,
      md: `calc(100vh - ${WEB_NAV_HEIGHT + 73}px)`,
    },
  },
}))(() => ({}))

export const DrawerHeader = styled(Box).attrs(() => {
  const theme = useTheme()
  const { isIos,isAndroid } = useDevice()
  return {
    sx: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
      justifyContent: 'space-between',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      ...(isIos && { pt: 'env(safe-area-inset-top)' }),
      ...(isAndroid && { pt: '20px' }),
    },
  }
})(() => ({}))
