import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'
import { useDevice } from '../hooks/useDevice'

const OnboardLayout: React.FC = () => {
  const { isHybrid } = useDevice()
  return (
    <>
    <Box display="flex" flexDirection="column" height="100vh"  sx={{
   ...(isHybrid &&   {paddingTop: 'env(safe-area-inset-top)', 
      paddingBottom: 'env(safe-area-inset-bottom)', })
    }}>
      <Outlet />
      </Box>
    </>
  )
}

export default OnboardLayout
