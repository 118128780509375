import React from 'react'
import { RestaurantCardContainerSkeletonContainer } from '../styled/skeleton/RestaurantCardContainerSkeleton.styled'
import RestaurantCardSkeleton from './RestaurantCardSkeleton'

const RestaurantCardContainerSkeleton: React.FC = () => {
  return (
    <RestaurantCardContainerSkeletonContainer>
      {[1, 3, 4].map((_, index) => (
        <RestaurantCardSkeleton key={index} />
      ))}
    </RestaurantCardContainerSkeletonContainer>
  )
}

export default RestaurantCardContainerSkeleton
