import { useAuth0 } from '@auth0/auth0-react'
import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import RestaurantCardContainer from '../../../components/restaurant/RestaurantCardContainer'
import { generateEater } from '../../../helpers/converters'
import { getLocation } from '../../../helpers/location-helper'
import { ROUTES } from '../../../helpers/routes-helper'
import { useDevice } from '../../../hooks/useDevice'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectAccessToken, selectLocation } from '../../../store/authSlice'
import {
  getLandingPageDetails,
  selectCuisineList,
  selectIsLoading,
  selectLocalList,
  selectMessage,
  selectMustTryList,
  selectSeverity,
  selectShouldOpen,
  selectTopRatedList,
  selectWalletList,
  setOpen,
} from '../../../store/landingSlice'
import { selectEater } from '../../../store/profileSlice'
import { selectRadius } from '../../../store/restaurantSlice'
import { ILandingDetailsRequest, LandingType } from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiAlert, MuiSnackbar } from '../styled/global.styled'
import AIRevolutionEngine from './components/AIRevolutionEngine'
import CuisineToTry from './components/CuisineToTry'
import DownloadApp from './components/DownloadApp'
import HeroSection from './components/HeroSection'
import { HomeRestaurantCardContainer } from './styled/Home.styled'
import { useLocation } from 'react-router'

const DocumentTitle =
  'Resactly | Discover Top Restaurants and Dining Experiences'

const Home: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const user = useAppSelector(selectEater)
  const radius = useAppSelector(selectRadius)
  const message = useAppSelector(selectMessage)
  const location = useAppSelector(selectLocation)
  const severity = useAppSelector(selectSeverity)
  const loading = useAppSelector(selectIsLoading)
  const localList = useAppSelector(selectLocalList)
  const openServer = useAppSelector(selectShouldOpen)
  const walletList = useAppSelector(selectWalletList)
  const topRatedList = useAppSelector(selectTopRatedList)
  const mustTryList = useAppSelector(selectMustTryList)
  const cuisineList = useAppSelector(selectCuisineList)
  const accessToken = useAppSelector(selectAccessToken)
  const { isHybrid } = useDevice()
  const navLocation = useLocation();

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(user ?? {})

    if(analytics && isAnalyticsReady){
      analytics?.page('PAGE_OPENED', {
        path: ROUTES.HOME,
        title: DocumentTitle,
        url: window.location.origin,
        category: 'Home',
        name: 'Home Page Viewed',
        eaterName: fullName,
        startTime: new Date().toISOString(),
        email,
        eaterId,
      })
    }

    if (isAuthenticated) {
      analytics?.identify(eaterId, {
        name: fullName,
        email,
      })
    }
  }, [analytics, isAuthenticated, user, isAnalyticsReady])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(user ?? {})

    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.HOME,
        title: DocumentTitle,
        url: window.location.origin,
        category: 'Home',
        name: 'Home Page Viewed',
        eaterName: fullName,
        endTime: new Date().toISOString(),
        email,
        eaterId
      })
    }

    }, [navLocation, analytics]);

  useEffect(() => {
    void fetchData()
  }, [radius, accessToken])

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const fetchData = async (): Promise<void> => {
    let local = location
    if (location === undefined) {
      local = await getLocation()
    }
    const request: ILandingDetailsRequest = {
      longitude: local?.longitude,
      latitude: local?.latitude,
      radius,
    }
    await dispatch(getLandingPageDetails(request))
  }

  return (
    <div>
      <Helmet>
        <title>{DocumentTitle}</title>
        <meta
          name="description"
          content="Explore Resactly's curated list of top restaurants and dining experiences. Use AI-powered recommendations to find the perfect spot for your next meal and get personalized dining suggestions."
        />
      </Helmet>
      {!isHybrid && <HeroSection />}
      <Container component="main" sx={{ flex: 1 }}>
        <HomeRestaurantCardContainer>
          <RestaurantCardContainer
            title={mustTryList?.name ?? 'Must Try Cuisine Hotspots'}
            cardList={mustTryList}
            loading={loading}
            name={LandingType.MUSTTRY}
          />
        </HomeRestaurantCardContainer>

        <HomeRestaurantCardContainer>
          <RestaurantCardContainer
            title={topRatedList?.name ?? 'Top Rated Restaurants'}
            cardList={topRatedList}
            loading={loading}
            name={LandingType.TOPRATED}
          />
        </HomeRestaurantCardContainer>
      </Container>
      <CuisineToTry cuisineList={cuisineList} />
      <Container>
        <HomeRestaurantCardContainer>
          <RestaurantCardContainer
            title={walletList?.name ?? 'Wallet Friendly Restaurants'}
            cardList={walletList}
            loading={loading}
            name={LandingType.WALLET}
          />
        </HomeRestaurantCardContainer>
        <HomeRestaurantCardContainer mb={isHybrid ? '10px' : '0px'}>
          <RestaurantCardContainer
            title={localList?.name ?? 'Local Flavors To Explore'}
            cardList={localList}
            loading={loading}
            name={LandingType.LOCAL}
          />
        </HomeRestaurantCardContainer>
        {!isHybrid && <AIRevolutionEngine />}
      </Container>
      {!isHybrid && <DownloadApp />}
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </div>
  )
}

export default Home
