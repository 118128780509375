import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  styled as muiStyled,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'

export const ResactlyCommunityInvitationGridContainer = muiStyled('div')(() => {
  const theme = useTheme()
  return {
    borderRadius: '24px',
    backgroundColor: theme.palette.secondary[100],
    overflow: 'hidden',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      minHeight: '225px',
      marginBottom: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '230px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '314px',
      marginBottom: '24px',
    },
  }
})

export const ResactlyCommunityInvitationGridInnerContainer = muiStyled('div')(
  () => {
    return {
      padding: '24px',
    }
  },
)

export const ResactlyCommunityInvitationGridContainerSecond = muiStyled('div')(
  () => {
    const theme = useTheme()
    return {
      backgroundColor: theme.palette.secondary[100],
      padding: '24px',
      borderRadius: '24px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  },
)

export const ResactlyCommunityInvitationContainer = muiStyled('div')(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary[100],
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '48px',
    backgroundImage: `url(${'/images/about/Just_eat-1.png'}),url('/images/about/Just_eat-2.png')`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
      marginBottom: '40px',
      padding: '24px 16px 100px 16px',
      backgroundSize: '25%,25%',
      backgroundPosition: 'bottom left, bottom right',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
      marginBottom: '44px',
      padding: '24px 16px 100px 16px',
      backgroundSize: '20%,20%',
      backgroundPosition: 'bottom left, bottom right',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
      marginBottom: '48px',
      paddingBottom: '48px',
      paddingTop: '48px',
      backgroundSize: '23%,23%',
      backgroundPosition: 'center left -20px, center right -20px',
    },
  }
})

export const ResactlyCommunityInvitationInnerContainer = muiStyled('div')(
  () => {
    return {
      maxWidth: '610px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
)

export const ResactlyCommunityInvitationTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      component: 'p',
      variant: 'h3',
      color: theme.palette.primary.main,
    }
  },
)(() => ({}))

export const ResactlyCommunityInvitationDescriptionTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.primary.main,
    sx: { mt: 1, mb: 3 },
  }
})(() => ({}))

export const ResactlyCommunityMainGrid = styled(Grid).attrs(() => {
  return {
    container: true,
    columnSpacing: ['16px', '20px', '24px'],
  }
})(() => ({}))

export const ResactlyCommunityInvitationGrid = styled(Grid).attrs(() => {
  return {
    item: true,
    sm: 12,
    md: 4.2,
    sx: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }
})(() => ({}))

export const ResactlyCommunityJoinTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'h3',
    fontWeight: 700,
    color: theme.palette.primary.main,
  }
})(() => ({}))

export const ResactlyCommunityJoinDescriptionTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    sx: {
      mt: {
        xs: '8px',
        md: '16px',
      },
    },
    color: theme.palette.primary.main,
  }
})(() => ({}))

export const ResactlyCommunityAvatarGroup = styled(AvatarGroup).attrs(() => {
  return {
    max: 9,
    sx: {
      position: 'absolute',
      bottom: '-6px',
    },
  }
})(() => ({}))

export const ResactlyCommunityAvatar = styled(Avatar).attrs(() => {
  return {
    sx: {
      width: ['46px', '50px', '54px'],
      height: ['46px', '50px', '54px'],
    },
  }
})(() => ({}))

export const ResactlyCommunityForFoodLoversTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'h3',
    fontWeight: 700,
    color: theme.palette.primary.main,
  }
})(() => ({}))

export const ResactlyCommunityForFoodLoversDescriptionTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    sx: {
      mt: {
        xs: '8px',
        md: '16px',
      },
      mb: {
        xs: '26px',
        sm: '20px',
        md: '0px',
      },
    },
    color: theme.palette.primary.main,
  }
})(() => ({}))

export const ResactlyCommunityDataDriveGrid = styled(Grid).attrs(() => {
  return {
    item: true,
    sm: 12,
    md: 7.8,
    sx: {
      mt: {
        xs: '16px',
        sm: '20px',
        md: '0px',
      },
    },
  }
})(() => ({}))

export const ResactlyCommunityDataDriveContainer = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    backgroundColor: theme.palette.secondary[100],
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
})(() => ({}))

export const ResactlyCommunityDataDriveContentWrapper = styled(Box).attrs(
  () => {
    return {
      maxWidth: '725px',
      padding: '24px',
    }
  },
)(() => ({}))

export const ResactlyCommunityDataDriveImgWrapper = styled(Box).attrs(() => {
  return {
    paddingLeft: '24px',
    height: ' -webkit-fill-available',
    width: '-webkit-fill-available ',
  }
})(() => ({}))

export const ResactlyCommunityDataDriveTypography = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      component: 'p',
      variant: 'h3',
      fontWeight: 700,
      color: theme.palette.primary.main,
    }
  },
)(() => ({}))

export const ResactlyCommunityDataDriveDescriptionTypography = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    sx: {
      mt: {
        xs: '8px',
        md: '16px',
      },
    },
    color: theme.palette.primary.main,
  }
})(() => ({}))

export const ResactlyCommunityDataDriveImgSkeleton = styled(Skeleton).attrs(
  () => {
    return {
      variant: 'rounded',
      height: '100%',
      width: '100%',
      sx: {
        borderTopLeftRadius: '20px',
      },
    }
  },
)(() => ({}))
