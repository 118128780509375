import { Box, Grid, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const ReviewsTabTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body1',
    component: 'p',
    fontWeight: 600,
    color: theme.palette.grey[700],
    sx: { paddingTop: '24px' },
  }
})(() => ({}))

export const ReviewsTabMainContainer = styled(Box).attrs(() => ({
  padding: '24px 0',
}))(() => ({}))

export const ReviewsTabMainGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 2,
}))(() => ({}))

export const ReviewsTabInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 6,
}))(() => ({}))

export const RestaurantListWrapper = styled(Box).attrs(() => ({
  className: 'restaurant-card-swiper-slide',
  sx: { height: '280px', width: '100%' },
}))(() => ({}))

export const ReviewsTabDishReviewTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body1',
    component: 'p',
    fontWeight: 500,
    color: theme.palette.grey[700],
    sx: { paddingBottom: '8px' },
  }
})(() => ({}))

export const RestaurantListDishWrapper = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: { md: '480px' },
    overflowY: { md: 'auto' },
    marginTop: '10px',
  },
}))(() => ({}))

export const RestaurantListDishInnerWrapper = styled(Box).attrs(() => ({
  sx: {
    width: '100%',
  },
}))(() => ({}))
