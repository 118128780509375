import { Box, Container, styled as muiStyled } from '@mui/material'
import styled from 'styled-components'

export const HomeContainer = muiStyled(Container)(() => {
  return {
    flex: 1,
  }
})

export const HomeRestaurantCardContainer = styled(Box).attrs(() => ({
  sx: {
    padding: { xs: '26px 0 0 0', md: '50px 0' },
  },
}))``
