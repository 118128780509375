import { Grid } from '@mui/material'
import styled from 'styled-components'

export const SearchDishMainGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 4,
  sx: {
    marginTop: '20px',
    marginBottom: '20px',
    WebkitOverflowScrolling: 'touch',
  },
}))(() => ({}))

export const SearchDishInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 6,
}))(() => ({}))
