import { Box } from '@mui/material'
import React from 'react'
import {
  EmptyScreenImage,
  EmptyScreenMainBox,
  EmptyScreenSecondaryTypography,
  EmptyScreenTitleTypography,
} from './styled/EmptyScreen.styled'
interface EmptyStateProps {
  containerHeight: string
  mainText?: string
  secondaryText?: string
  imageUrl?: string
  imageWidth?: string
  imageHeight?: string
  children?: React.ReactNode
}
const EmptyScreen: React.FC<EmptyStateProps> = ({
  containerHeight,
  mainText = 'Oops! No Results Found',
  secondaryText = `We couldn't find any item matching your search`,
  imageUrl = '/images/no-result-found.svg',
  imageWidth = '180px',
  imageHeight = '150px',
  children,
}) => {
  return (
    <EmptyScreenMainBox height={containerHeight}>
      <Box width="100%" textAlign="center">
        <EmptyScreenImage
          width={imageWidth}
          height={imageHeight}
          src={imageUrl || '/images/no-result-found.svg'}
          srcSet={`${imageUrl || '/images/no-result-found.svg'} 2x`}
        />
        <EmptyScreenTitleTypography>{mainText}</EmptyScreenTitleTypography>
        {secondaryText.length !== 0 && (
          <EmptyScreenSecondaryTypography>
            {secondaryText}
          </EmptyScreenSecondaryTypography>
        )}
        {children && <Box mt="16px">{children}</Box>}
      </Box>
    </EmptyScreenMainBox>
  )
}
export default EmptyScreen
