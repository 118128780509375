import { Box, Typography, useTheme } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import styled from 'styled-components'

export const FilterButtonMainBox = styled(Box).attrs(() => {
  return {
    display: 'flex',
    gap: '12px',
    height: '100%',
    overflow: 'auto',
    className: 'hide-scrollbar',
  }
})``

export const FilterButtonBox = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      borderRadius: '10px',
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.secondary[50],
      textTransform: 'none',
      fontSize: '14px',
      height: '100%',
      minWidth: 'min-content',
      color: theme.palette.secondary.main,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
    },
  }
})``

export const FilterButtonTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    variant: 'subtitle1',
    color: theme.palette.secondary.dark,
    component: 'p',
    maxWidth: '60px',
  }
})``

export const FilterButtonCloseIcon = styled(IconX).attrs(() => {
  return {
    size: 16,
    stroke: 2,
    style: {
      marginLeft: '2px',
    },
  }
})``
