import React from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'

interface OnboardPreferencesLikeContainerProps {
  isProfile?: boolean
}

export const OnboardPreferencesLikeContainer = styled(
  ({
    isProfile = false,
    ...rest
  }: OnboardPreferencesLikeContainerProps & any) => <Box {...rest} />,
).attrs<OnboardPreferencesLikeContainerProps>(({ isProfile }) => {
  return {
    sx: {
      pt: { xs: isProfile ? '8px' : '40px', md: '40px' },
    },
  }
})<OnboardPreferencesLikeContainerProps>(({ theme }) => ({}))

export const OnboardPreferencesDisLikeContainer = styled(Box).attrs(() => {
  return {
    sx: {
      pt: { xs: '16px', md: '80px' },
      pb: { xs: '25px', md: '40px' },
    },
  }
})(() => ({}))
