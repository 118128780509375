import { Box, Container } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { generateEater } from '../../../helpers/converters'
import { useAppSelector } from '../../../store'
import { selectEater } from '../../../store/profileSlice'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import FAQs from './components/FAQs'
import ResactlyCommunityInvitation from './components/ResactlyCommunityInvitation'
import {
  AboutContainer,
  AboutDescriptionContainerTypography,
  AboutInnerContainer,
  AboutTitleContainer,
  AboutTitleContainerTypography,
} from './styled/AboutPage.styled'
import { ROUTES } from '../../../helpers/routes-helper'
import { useLocation } from 'react-router'

const DocumentTitle = 'Resactly | Revolutionizing Restaurant Discovery'

const About: React.FC = () => {
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const user = useAppSelector(selectEater)
  const location = useLocation();

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(user ?? {})

    if(analytics && isAnalyticsReady){
      analytics?.page('PAGE_OPENED', {
        path: ROUTES.ABOUT,
        title: DocumentTitle,
        url: `${window.location.origin}${ROUTES.ABOUT}`,
        category: 'About',
        name: 'About Viewed',
        eaterName: fullName,
        startTime: new Date().toISOString(),
        email,
        eaterId,
      })
    }
  }, [analytics, user, isAnalyticsReady])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(user ?? {})

    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.ABOUT,
        title: DocumentTitle,
        url: `${window.location.origin}${ROUTES.ABOUT}`,
        category: 'About',
        name: 'About Viewed',
        eaterName: fullName,
        endTime: new Date().toISOString(),
        email,
        eaterId,
      })
    }
  }, [location])

  return (
    <AboutContainer>
      <Helmet>
        <title>{DocumentTitle}</title>
        <meta
          name="description"
          content="Learn more about Resactly, our mission to transform how you discover and experience dining. Dive into our AI-driven platform that provides insightful recommendations and analytics for restaurant enthusiasts."
        />
      </Helmet>
      <Container component="main" sx={{ flex: 1 }}>
        <Box height="100%">
          <AboutInnerContainer>
            <AboutTitleContainer>
              <AboutTitleContainerTypography variant="h1">
                Welcome to Resactly
              </AboutTitleContainerTypography>
              <AboutTitleContainerTypography variant="h1">
                Where Every Bite Counts.
              </AboutTitleContainerTypography>
              <AboutDescriptionContainerTypography variant="body1">
                {`In a world brimming with dining options, Resactly stands out as your
                  personal culinary compass, guiding you to your next unforgettable
                  meal. But we're more than just a restaurant recommendation platform;
                  we're a bridge between your palate's desires and the kitchens that
                  can fulfill them.`}
              </AboutDescriptionContainerTypography>
            </AboutTitleContainer>
          </AboutInnerContainer>

          <ResactlyCommunityInvitation />
          <FAQs />
        </Box>
      </Container>
    </AboutContainer>
  )
}

export default About
