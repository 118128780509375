import { useAuth0 } from '@auth0/auth0-react'
import {
  IconInfoSquareRounded,
  IconStar,
  IconToolsKitchen2,
} from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import NoResultsFound from '../../../components/EmptyScreen'
import ResactlyTab from '../../../components/ResactlyTab'
import { generateEater } from '../../../helpers/converters'
import { getLocation } from '../../../helpers/location-helper'
import { ROUTES } from '../../../helpers/routes-helper'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectLocation } from '../../../store/authSlice'
import { selectEater } from '../../../store/profileSlice'
import {
  fetchPublicRestaurantByAlias,
  getPredictedRestaurants,
  selectMessage,
  selectRestaurantDetail,
  selectSeverity,
  selectShouldOpen,
  setOpen,
  setPredictedRestaurantDetail,
  setPublicRestaurantDetail,
} from '../../../store/restaurantSlice'
import { PredictedRestaurantRequest } from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiAlert, MuiSnackbar } from '../styled/global.styled'
import AboutTab from './components/AboutTab'
import DishesTab from './components/DishesTab'
import RestaurantImages from './components/RestaurantImages'
import RestaurantInfo from './components/RestaurantInfo'
import ReviewsTab from './components/ReviewsTab'
import {
  RestaurantDetailMuiContainer,
  RestaurantDetailRestaurantContainer,
  RestaurantDetailRestaurantIconChevronLeft,
  RestaurantDetailRestaurantTypography,
} from './styled/RestaurantDetail.styled'
import RestaurantDetailSkeleton from './components/RestaurantDetailSkeleton'

const RestaurantDetail: React.FC = () => {
  const { alias } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const routeLocation = useLocation()
  const { isAuthenticated } = useAuth0()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const queryParams = new URLSearchParams(routeLocation.search)
  const name = queryParams.get('name')
  const eater = useAppSelector(selectEater)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const location = useAppSelector(selectLocation)
  const openServer = useAppSelector(selectShouldOpen)
  const restaurant = useAppSelector(selectRestaurantDetail)
  const [changeTab, setChangeTab] = useState(0)
  const [pageTitle, setPageTitle] = useState('')
  const [staticLoading, setStaticLoading] = useState(false)

  useEffect(() => {
    if(restaurant){
      const restaurantTitle = restaurant?.restaurantDTO?.name
        ? `Resactly | ${restaurant?.restaurantDTO.name} - Details`
        : 'Resactly | Restaurant Details'
      setPageTitle(restaurantTitle)
    }
  }, [restaurant])

  useEffect(() => {
    const restaurantTitle = restaurant?.restaurantDTO?.name
      ? `Resactly | ${restaurant?.restaurantDTO.name} - Details`
      : 'Resactly | Restaurant Details'

    const { fullName, eaterId, email } = generateEater(eater ?? {})

    if(analytics && isAnalyticsReady){
      analytics?.page('RESTAURANT_PAGE_OPENED',{
        path: `${ROUTES.RESTAURANT_DETAILS}/${
          restaurant?.restaurantDTO?.alias ?? ''
        }/details`,
        title: restaurantTitle,
        url: `${window.location.origin}${ROUTES.RESTAURANT_DETAILS}/${
          restaurant?.restaurantDTO?.alias ?? ''
        }/details`,
        category: 'Restaurant',
        name: `${restaurant?.restaurantDTO?.name ?? ''} Restaurant Detail Viewed`,
        eaterName: fullName,
        email,
        eaterId,
        restaurantAlias: `${restaurant?.restaurantDTO?.alias ?? ''}`,
        startTime: new Date().toISOString()
      })
    }
  }, [analytics, isAnalyticsReady])

  useEffect(() => {
    const restaurantTitle = restaurant?.restaurantDTO?.name
      ? `Resactly | ${restaurant?.restaurantDTO.name} - Details`
      : 'Resactly | Restaurant Details'

    const { fullName, eaterId, email } = generateEater(eater ?? {})

    return () => {
      analytics?.page('RESTAURANT_PAGE_CLOSED', {
        path: `${ROUTES.RESTAURANT_DETAILS}/${
          restaurant?.restaurantDTO?.alias ?? ''
        }/details`,
        title: restaurantTitle,
        url: `${window.location.origin}${ROUTES.RESTAURANT_DETAILS}/${
          restaurant?.restaurantDTO?.alias ?? ''
        }/details`,
        category: 'Restaurant',
        name: `${restaurant?.restaurantDTO?.name ?? ''} Restaurant Detail Viewed`,
        eaterName: fullName,
        email,
        eaterId,
        restaurantAlias: `${restaurant?.restaurantDTO?.alias ?? ''}`,
        endTime: new Date().toISOString()
      })
    }
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setStaticLoading(true)
      if (isAuthenticated) {
        await authenticatedRestaurant()
      } else {
        await unAuthenticatedRestaurant()
      }
      setStaticLoading(false)
    }

    void fetchData()

    if (name === 'review') {
      setChangeTab(1)
    }
  }, [alias, isAuthenticated, dispatch])

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const unAuthenticatedRestaurant = async (): Promise<void> => {
    const result = await dispatch(fetchPublicRestaurantByAlias(alias ?? ''))
    if (fetchPublicRestaurantByAlias.fulfilled.match(result)) {
      dispatch(setPublicRestaurantDetail())
    }
  }

  const authenticatedRestaurant = async (): Promise<void> => {
    let local = location
    if (location === undefined) {
      local = await getLocation()
    }
    const request: PredictedRestaurantRequest = {
      aliases: [alias ?? ''],
      includeMenus: true,
      longitude: local?.longitude,
      latitude: local?.latitude,
    }
    const result = await dispatch(getPredictedRestaurants(request))
    if (getPredictedRestaurants.fulfilled.match(result)) {
      if (result.payload.count < 1) {
        await unAuthenticatedRestaurant()
      } else {
        dispatch(
          setPredictedRestaurantDetail({
            alias: alias ?? '',
          }),
        )
      }
    }
  }

  return (
    <RestaurantDetailMuiContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={`Manage your Resactly profile for ${
            restaurant?.restaurantDTO?.name ?? ''
          }, view your dining preferences, saved restaurants, and personalized recommendations. Enhance your restaurant discovery journey with tailored insights.`}
        />
      </Helmet>
      {!staticLoading ? (
        restaurant ? (
          <div>
            <RestaurantDetailRestaurantContainer>
              <RestaurantDetailRestaurantIconChevronLeft />
              <RestaurantDetailRestaurantTypography
                onClick={() => navigate(-1)}
              >
                Back
              </RestaurantDetailRestaurantTypography>
            </RestaurantDetailRestaurantContainer>
            <RestaurantImages />
            <RestaurantInfo />
            <ResactlyTab
              list={
                isAuthenticated
                  ? tabList
                  : tabList.filter((_, index) => index !== 1)
              }
              tabComponent={
                isAuthenticated
                  ? tabComponentList
                  : tabComponentList.filter((_, index) => index !== 1)
              }
              align="start"
              changeTab={changeTab}
            />
          </div>
        ) : (
          <NoResultsFound
            containerHeight="calc(100vh - 208px)"
            mainText="No data available"
            secondaryText="Requested information is not found"
          />
        )
      ) : (
        <RestaurantDetailSkeleton />
      )}

      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </RestaurantDetailMuiContainer>
  )
}

export default RestaurantDetail

const tabComponentList = [
  <div key="dishes">
    <DishesTab />
  </div>,
  <div key="reviews">
    <ReviewsTab />
  </div>,
  <div key="about">
    <AboutTab />
  </div>,
]

const tabList = [
  {
    label: 'Dishes',
    value: 'dishes',
    icon: <IconToolsKitchen2 stroke={2} />,
  },
  {
    label: 'Reviews',
    value: 'reviews',
    icon: <IconStar stroke={2} />,
  },
  {
    label: 'About',
    value: 'about',
    icon: <IconInfoSquareRounded stroke={2} />,
  },
]
