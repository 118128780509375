import { Box } from '@mui/material'
import styled from 'styled-components'
import BasicModal from '../../modal/BasicModal'

export const FilterMainBox = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      gap: '12px',
      justifyContent: 'flex-end',
      overflow: 'auto',
    },
  }
})(() => ({}))

export const FilterModel = styled(BasicModal).attrs(() => {
  return {
    title: 'Filter',
    modelOpenType: 'drawer',
    cancelButtonName: 'Clear Filter',
    buttonName: 'Filter',
    cancelIcon: true,
    modelWidth: '550px',
  }
})(() => ({}))
