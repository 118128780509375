import React, { createContext, useContext, useState, ReactNode } from 'react'

interface WebLayoutContextType {
  isScrollBottom: boolean
  setIsScrollBottom: React.Dispatch<React.SetStateAction<boolean>>
}

const WebLayoutContext = createContext<WebLayoutContextType | undefined>(
  undefined,
)

export const useWebLayoutContext = (): WebLayoutContextType => {
  const context = useContext(WebLayoutContext)
  if (!context) {
    throw new Error('useWebLayoutContext must be used within an AppProvider')
  }
  return context
}

interface WebLayoutProviderProps {
  children: ReactNode
}

export const WebLayoutProvider = ({
  children,
}: WebLayoutProviderProps): JSX.Element => {
  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false)

  return (
    <WebLayoutContext.Provider value={{ isScrollBottom, setIsScrollBottom }}>
      {children}
    </WebLayoutContext.Provider>
  )
}
