import {
  Box,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import OnboardSearch from '../../pages/eater/onboardPages/OnboardSearch'
import {
  IFilterItem,
  IFilterResponseItem,
  IFilterTabItems,
  IFilterTabList,
  restaurantFilterRefMethods,
} from '../../types'
import { debugLog } from '../../utils/log-helper'
import {
  FilterCheckBox,
  FilterCheckBoxFormControlLabel,
  FilterListMainContainer,
  FilterListMainTab,
  FilterListMainTabs,
  FilterListSelectedItemCountTypography,
  FilterRadio,
  FilterRadioFormControlLabel,
  FilterRangeInfoBox,
  FilterRangeInfoIcon,
  FilterRangeInfoTypography,
  FilterRangeMainBox,
  FilterRangeSlider,
  FilterRangeSliderLabelBox,
} from '../styled/filter/FilterList.styled'

interface FilterProps {
  tabList: IFilterTabList[]
  currentValue: IFilterResponseItem
}

interface PanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface PaneCheckboxFilterProps {
  items: IFilterTabItems[]
  selectedValues: IFilterResponseItem
  updateSelectedValues: (tabKey: string, values: any) => void
  tabKey: string
  allowSearch: boolean
}

interface RadioFilterProps {
  items: IFilterTabItems[]
  selectedValues: IFilterResponseItem
  updateSelectedValues: (tabKey: string, value: any) => void
  tabKey: string
}

interface RangeFilterProps {
  items: IFilterTabList
  selectedValues: IFilterResponseItem
  updateSelectedValues: (tabKey: string, value: number | number[]) => void
  tabKey: string
  priceText: string
}

interface SelectedItemCountProps {
  tabName: string
  selectedItemTabName: IFilterResponseItem
}

const TabPanel = (props: PanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: '100%',
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, width: '100%' }}>{children}</Box>}
    </div>
  )
}

const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const CheckboxFilter = ({
  items,
  selectedValues,
  updateSelectedValues,
  tabKey,
  allowSearch = false,
}: PaneCheckboxFilterProps): JSX.Element => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IFilterItem,
  ): void => {
    const updatedValues = { ...selectedValues }
    updatedValues[tabKey] = updatedValues[tabKey] || []
    if (event.target.checked) {
      updatedValues[tabKey].push(item)
    } else {
      updatedValues[tabKey] = updatedValues[tabKey].filter(
        (selectedItem: IFilterItem) => selectedItem.key !== item.key,
      )
    }
    updateSelectedValues(tabKey, updatedValues[tabKey])
  }

  const setSuggestedSearch = useCallback(
    (item: string): void => debugLog(item.toLocaleLowerCase()),
    [],
  )

  return (
    <Box>
      {allowSearch && (
        <Box mb="16px">
          <OnboardSearch
            indexKey={'REACT_APP_RESTAURANT_INDEX'}
            key={`restaurant-filter`}
            setWord={setSuggestedSearch}
            searchFieldName={'name'}
            searchWidth="100%"
          />
        </Box>
      )}
      {items.map((item) => (
        <FilterCheckBoxFormControlLabel
          key={item.key}
          control={
            <FilterCheckBox
              checked={
                selectedValues[tabKey]?.some(
                  (selectedItem: {
                    key: number
                    name: string
                    value: string
                  }) => selectedItem.key === item.key,
                ) || false
              }
              onChange={(event) => handleChange(event, item)}
            />
          }
          label={item.name}
        />
      ))}
    </Box>
  )
}

const RadioFilter = ({
  items,
  selectedValues,
  updateSelectedValues,
  tabKey,
}: RadioFilterProps): JSX.Element => {
  const theme = useTheme()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedItem = items.find(
      (item) => item.key === parseInt(event.target.value),
    )
    if (selectedItem) {
      updateSelectedValues(tabKey, selectedItem)
    }
  }

  return (
    <RadioGroup
      value={selectedValues[tabKey]?.key.toString() || ''}
      onChange={handleChange}
    >
      {items.map((item) => (
        <FilterRadioFormControlLabel
          color={theme.palette.grey[300]}
          key={item.key}
          value={item.key.toString()}
          control={<FilterRadio />}
          label={item.name}
        />
      ))}
    </RadioGroup>
  )
}

const RangeFilter = ({
  items,
  selectedValues,
  updateSelectedValues,
  tabKey,
  priceText,
}: RangeFilterProps): JSX.Element => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleChange = (event: Event, newValue: number | number[]): void => {
    updateSelectedValues(tabKey, newValue)
  }

  const marks = items.items.map((item) => {
    return { value: Number(item.value), label: '' }
  })

  return (
    <Box width={'100%'}>
      {priceText === 'Price' && (
        <FilterRangeInfoBox>
          <FilterRangeInfoIcon />
          <FilterRangeInfoTypography>
            The price is based on per person
          </FilterRangeInfoTypography>
        </FilterRangeInfoBox>
      )}
      <FilterRangeMainBox isHeight={priceText === 'Price'}>
        <FilterRangeSliderLabelBox>
          {items.items.map((item, index) => {
            return (
              <Typography
                key={index}
                variant="subtitle2"
                sx={{
                  cursor: 'pointer',
                  fontWeight:
                    (selectedValues[tabKey] || marks[0].value) ===
                    Number(item.value)
                      ? 600
                      : 500,
                }}
                color={
                  (selectedValues[tabKey] || marks[0].value) ===
                  Number(item.value)
                    ? theme.palette.secondary.dark
                    : theme.palette.grey[800]
                }
              >
                {item.name}
              </Typography>
            )
          })}
        </FilterRangeSliderLabelBox>
        <FilterRangeSlider
          value={selectedValues[tabKey] || marks[0].value}
          onChange={handleChange}
          aria-labelledby="discrete-slider"
          step={items.rangeStep ?? 5}
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          marks={marks}
          min={marks[0].value}
          max={marks[marks.length - 1].value}
        />
      </FilterRangeMainBox>
    </Box>
  )
}

const SelectedItemCount = ({
  tabName,
  selectedItemTabName,
}: SelectedItemCountProps): JSX.Element => {
  return (
    <FilterListSelectedItemCountTypography>
      {selectedItemTabName[tabName].length}
    </FilterListSelectedItemCountTypography>
  )
}

const FilterList = forwardRef<restaurantFilterRefMethods, FilterProps>(
  function FilterList(props: FilterProps, ref) {
    const { tabList, currentValue } = props
    const [value, setValue] = useState<number>(0)
    const [selectedValues, setSelectedValues] =
      useState<IFilterResponseItem>(currentValue)
    const filterRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const defaultValues = { ...currentValue }
      tabList.forEach((tab) => {
        if (
          tab.filterType === 'range' &&
          !defaultValues[tab.name.toLowerCase()]
        ) {
          const defaultValue = tab.items[0]?.value
            ? Number(tab.items[0].value)
            : 0

          defaultValues[tab.name.toLowerCase()] = defaultValue
        }
      })
      setSelectedValues(defaultValues)
    }, [tabList, currentValue])

    useImperativeHandle(ref, () => ({
      getSelectedValues: () => selectedValues,
    }))

    const handleChange = (
      event: React.SyntheticEvent,
      newValue: number,
    ): void => {
      setValue(newValue)
    }

    const updateSelectedValues = (tabKey: string, values: any): void => {
      setSelectedValues((prevValues: any) => ({
        ...prevValues,
        [tabKey]: values,
      }))
    }

    const renderFilterContent = (tab: IFilterTabList): JSX.Element | null => {
      switch (tab.filterType) {
        case 'checkbox':
          return (
            <CheckboxFilter
              items={tab.items}
              selectedValues={selectedValues}
              updateSelectedValues={updateSelectedValues}
              tabKey={tab.name.toLowerCase()}
              allowSearch={tab.allowSearch ?? false}
            />
          )
        case 'radio':
          return (
            <RadioFilter
              items={tab.items}
              selectedValues={selectedValues}
              updateSelectedValues={updateSelectedValues}
              tabKey={tab.name.toLowerCase()}
            />
          )
        case 'range':
          return (
            <RangeFilter
              items={tab}
              selectedValues={selectedValues}
              updateSelectedValues={updateSelectedValues}
              tabKey={tab.name.toLowerCase()}
              priceText={tab.name}
            />
          )
        default:
          return null
      }
    }

    return (
      <div ref={filterRef}>
        <FilterListMainContainer>
          <FilterListMainTabs value={value} onChange={handleChange}>
            {tabList.map((tab, index) => (
              <FilterListMainTab
                icon={
                  tab.filterType === 'checkbox' &&
                  selectedValues?.[tab.name.toLowerCase()]?.length !== 0 &&
                  Object.keys(selectedValues).includes(
                    tab.name.toLowerCase(),
                  ) ? (
                    <SelectedItemCount
                      tabName={tab.name.toLowerCase()}
                      selectedItemTabName={selectedValues}
                    />
                  ) : undefined
                }
                iconPosition="end"
                label={tab.name}
                {...a11yProps(index)}
                key={index}
              />
            ))}
          </FilterListMainTabs>
          <div style={{ height: '300px', overflow: 'auto', width: '376px' }}>
            {tabList.map((tab, index) => (
              <TabPanel key={index} value={value} index={index}>
                {renderFilterContent(tab)}
              </TabPanel>
            ))}
          </div>
        </FilterListMainContainer>
      </div>
    )
  },
)

export default FilterList
