import React, { useEffect, useRef, useState } from 'react'
import {
  IFilterResponseItem,
  IFilterTabList,
  restaurantFilterRefMethods,
} from '../../types'
import { FilterMainBox, FilterModel } from '../styled/filter/Filter.styled'
import FilterButton from './FilterButton'
import FilterList from './FilterList'

interface IFilterProp {
  tabList: IFilterTabList[]
  onFilterData?: (item: IFilterResponseItem) => void
  rowData?: any
  isFilterModalOpen: boolean
  setFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Filter: React.FC<IFilterProp> = ({
  tabList,
  onFilterData,
  rowData,
  isFilterModalOpen,
  setFilterModalOpen,
}) => {
  const filterRef = useRef<restaurantFilterRefMethods>(null)
  const [selectedValues, setSelectedValues] = useState<IFilterResponseItem>({})

  const onHandleModalClose = (): void => {
    setFilterModalOpen(false)
  }

  const onClearFilter = (): void => {
    setSelectedValues({})
    setFilterModalOpen(false)
  }

  const onFilter = (): void => {
    handleGetSelectedValues()
    setFilterModalOpen(false)
  }

  const onRemoveFilter = (itemName: string): void => {
    const removeFilterData = Object.keys(selectedValues).reduce((acc, curr) => {
      if (curr !== itemName) {
        acc[curr] = selectedValues[curr]
      }
      return acc
    }, {})
    setSelectedValues(removeFilterData)
  }

  const handleGetSelectedValues = (): void => {
    if (filterRef.current) {
      const selectedValues = filterRef.current?.getSelectedValues()
      setSelectedValues(selectedValues)
    }
  }

  useEffect(() => {
    if (onFilterData) {
      onFilterData(selectedValues)
    }
  }, [selectedValues, rowData])

  return (
    <>
      {tabList.length > 0 && (
        <>
          <FilterMainBox>
            <FilterButton
              onButtonClick={(itemName: string) => onRemoveFilter(itemName)}
              filterResponse={selectedValues}
              priceStep={
                tabList.find((item) => {
                  return item.name === 'Price'
                })?.items[0].value
              }
              priceType={
                tabList.find((item) => {
                  return item.name === 'Price'
                })?.priceType
              }
            />
          </FilterMainBox>
          <FilterModel
            open={isFilterModalOpen}
            onCancel={onClearFilter}
            onConfirm={onFilter}
            handleClose={onHandleModalClose}
          >
            <FilterList
              ref={filterRef}
              currentValue={selectedValues}
              tabList={tabList}
            />
          </FilterModel>
        </>
      )}
    </>
  )
}

export default Filter
