import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  selectDiningFrequency,
  setDiningFrequency,
} from '../../../store/onboardSlice'
import {
  DiningFrequencyState,
  OnboardDiningFrequencyRefMethods,
} from '../../../types'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardDiningFrequencyFormControl,
  OnboardDiningFrequencyGridContainer,
  OnboardDiningFrequencyInnerGrid,
  OnboardDiningFrequencyTextField,
  OnboardDiningFrequencyWrapper,
} from './styled/OnboardDiningFrequency.styled'

interface OnboardDiningFrequencyProps {
  title?: string
  isEditPreference?: boolean
  alignItems?: 'center' | 'right' | 'left'
}

const OnboardDiningFrequency = forwardRef<
  OnboardDiningFrequencyRefMethods,
  OnboardDiningFrequencyProps
>(function OnboardDiningFrequency(
  { title, isEditPreference, alignItems = 'center' },
  ref,
): JSX.Element {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const diningFrequency = useAppSelector(selectDiningFrequency)
  const [diningFrequencyData, setDiningFrequencyData] =
    useState<DiningFrequencyState>(diningFrequency)

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >,
  ): void => {
    const { id, value } = event.target as HTMLInputElement
    const parsedValue = parseInt(value, 10)

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setDiningFrequencyData((prev) => ({ ...prev, [id]: Number(value) }))
    }
  }

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const { value } = event.target
    setDiningFrequencyData((prev) => ({ ...prev, frequency: value }))
  }

  const getPayload = (): DiningFrequencyState => {
    dispatch(setDiningFrequency(diningFrequencyData))
    return diningFrequencyData
  }

  useImperativeHandle(ref, () => ({ getPayload }))

  return (
    <>
      <OnboardTitleTypography
        title={title ?? ''}
        subTitle=""
        isEditPreference={isEditPreference}
        alignItems={alignItems}
      />

      {!isEditPreference && (
        <OnboardDiningFrequencyWrapper>
          <OnboardDiningFrequencyGridContainer
            sx={{
              minWidth: {
                xs: '370px',
                sm: '600px',
                md: '700px',
              },
            }}
          >
            <OnboardDiningFrequencyInnerGrid>
              <OnboardDiningFrequencyTextField
                id="number"
                label="Number"
                value={diningFrequencyData.number}
                onChange={handleChange}
              />
            </OnboardDiningFrequencyInnerGrid>
            <OnboardDiningFrequencyInnerGrid>
              <OnboardDiningFrequencyFormControl>
                <InputLabel>
                  <span>Frequency</span>
                </InputLabel>
                <Select
                  id="frequency"
                  value={diningFrequencyData.frequency}
                  onChange={handleSelectChange}
                  label="Frequency"
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: '16px',
                  }}
                >
                  <MenuItem value={'WEEK'}>
                    <Typography
                      noWrap
                      component="p"
                      variant="body2"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Week
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'MONTH'}>
                    <Typography
                      noWrap
                      component="p"
                      variant="body2"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Month
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'YEAR'}>
                    <Typography
                      noWrap
                      component="p"
                      variant="body2"
                      fontWeight={500}
                      color={theme.palette.grey[800]}
                    >
                      Year
                    </Typography>
                  </MenuItem>
                </Select>
              </OnboardDiningFrequencyFormControl>
            </OnboardDiningFrequencyInnerGrid>
          </OnboardDiningFrequencyGridContainer>
        </OnboardDiningFrequencyWrapper>
      )}
      {isEditPreference && (
        <OnboardDiningFrequencyWrapper>
          <span style={{ color: 'black' }}>
            Your dining frequency is <b>{diningFrequency.number}</b> times per{' '}
            <b>{diningFrequency.frequency}</b>
          </span>
        </OnboardDiningFrequencyWrapper>
      )}
    </>
  )
})

export default OnboardDiningFrequency
