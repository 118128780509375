import { Box, Grid } from '@mui/material'
import styled from 'styled-components'

export const RestaurantListMainContainer = styled(Box).attrs(() => ({
  sx: { py: '16px' },
}))(() => ({}))

export const RestaurantListMainGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 2,
  overflow: 'auto',
}))(() => ({}))

export const RestaurantListInnerGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
}))(() => ({}))

export const RestaurantListWrapper = styled(Box).attrs(() => ({
  className: 'restaurant-card-swiper-slide',
  sx: { height: '280px', width: '100%' },
}))(() => ({}))
