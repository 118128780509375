import { AccessTime, AttachMoney, LocationOn } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import React from 'react'
import theme from '../../../../theme'
import {
  RestaurantImagesMainContainer,
  RestaurantImagesMainImgWrapper,
  RestaurantImagesOtherImgsInnerWrapper,
  RestaurantImagesOtherImgsWrapper,
  RestaurantImagesSkeleton,
} from '../styled/RestaurantImages.styled'

const RestaurantDetailSkeleton: React.FC = () => {
  const DishCardSkeleton = (): JSX.Element => (
    <Box display="flex">
      <Skeleton variant="rounded" width={350} height={140} />
      <Box ml={2} width="100%">
        <Skeleton variant="text" width="60%" height={25} />
        <Skeleton variant="text" width="40%" height={18} />
      </Box>
    </Box>
  )
  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 1 }}>
          <Skeleton width="150px" />
        </Typography>
      </Box>

      <RestaurantImagesMainContainer>
        <RestaurantImagesMainImgWrapper>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              borderRadius: '6px',
            }}
          >
            <RestaurantImagesSkeleton />
          </Box>
        </RestaurantImagesMainImgWrapper>
        <RestaurantImagesOtherImgsWrapper>
          {[1, 2, 3, 4].map((item, index) => (
            <RestaurantImagesOtherImgsInnerWrapper key={index}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderRadius: '6px',
                }}
              >
                <RestaurantImagesSkeleton />
              </Box>
            </RestaurantImagesOtherImgsInnerWrapper>
          ))}
        </RestaurantImagesOtherImgsWrapper>
      </RestaurantImagesMainContainer>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">
          <Skeleton width="200px" />
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <LocationOn fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            <Skeleton width="100px" />
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <AccessTime fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            <Skeleton width="150px" />
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <AttachMoney fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            <Skeleton width="50px" />
          </Typography>
        </Box>
      </Box>

      <Tabs
        value={0}
        sx={{ mt: 3, borderBottom: `1px solid ${theme.palette.grey[300]}` }}
      >
        <Tab label="Dishes" />
        <Tab label="Reviews" />
        <Tab label="About" />
      </Tabs>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Skeleton
          variant="rectangular"
          width="30%"
          height={40}
          sx={{ borderRadius: 1 }}
        />

        <Box sx={{ width: '20px' }} />

        <Skeleton
          variant="rectangular"
          width="100px"
          height={40}
          sx={{ borderRadius: 1 }}
        />
      </Box>

      <Grid container spacing={4} mt={5}>
        {Array.from(new Array(4)).map((_, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <DishCardSkeleton />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RestaurantDetailSkeleton
