import { Box, Chip, Grid, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const OnboardTitle = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    letterSpacing: 0.5,
    variant: 'body1',
    fontWeight: 600,
    style: {
      color: theme.palette.grey[800],
      fontSize: '1rem',
      fontFamily: 'Inter',
    },
  }
})``

export const OnboardingSearchContainer = styled(Box).attrs(() => ({
  py: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px !important',
      paddingBottom: '16px !important',
    },
  }
})

export const OnboardingSearchInnerContainer = styled(Box).attrs(() => ({
  maxWidth: 400,
}))(({ theme }) => ({
  '@media (min-width: 600px)': {
    width: '400px',
  },
}))

export const OnboardingItemListContainer = styled(Grid).attrs(() => ({
  gap: 1,
  container: true,
}))(({ theme }) => ({
  justifyContent: 'center',
}))

export const OnboardingMoreItemListChip = styled(Chip).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      paddingX: '8px',
      color: theme.palette.grey[800],
      borderColor: theme.palette.grey[300],
      borderStyle: 'dashed',
      fontSize: '14px',
      '& .MuiChip-label': {
        fontFamily: 'Inter',
      },
    },
    variant: 'outlined',
    fontWeight: 400,
  }
})(() => {
  const theme = useTheme()
  return {
    '&': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  }
})

export const OnboardingItemListChip = styled(Chip).attrs(() => ({
  variant: 'outlined',
  fontWeight: 400,
}))(() => {
  const theme = useTheme()
  return {
    '&': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  }
})

export const OnboardingWrapper = styled(Grid).attrs(() => ({
  container: true,
  gap: 1,
  maxWidth: 600,
}))(({ theme }) => ({}))

export const OnboardingInnerWrapper = styled(Grid).attrs(() => ({
  padding: 0,
  display: 'flex',
  alignItems: 'center',
}))(({ theme }) => ({}))
