import { useAuth0 } from '@auth0/auth0-react'
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Toolbar,
  useTheme,
} from '@mui/material'
import {
  IconHome,
  IconLogin,
  IconLogout,
  IconUserCircle,
  IconUsers,
} from '@tabler/icons-react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ROUTES } from '../../helpers/routes-helper'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import useRudderStackAnalytics from '../../useRudderAnalytics'

interface NavigationItem {
  label: string
  icon: React.ReactElement
  route?: string
  login?: boolean
  logout?: boolean
}

const BottomAppBar: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isAuthenticated } = useAuth0()
  const { loginAuthUser, logoutAuthUser } = useAuthHelper()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()

  const handleNavigation = (item: NavigationItem): void => {
    if (item.login) {
      loginAuthUser()
    } else if (item.logout) {
      handleLogout()
    } else if (item.route) {
      navigate(item.route)
    }
  }

  const handleLogout = (): void => {
    if(analytics && isAnalyticsReady){
      analytics?.identify('', { isLoggedIn: false })
      analytics?.reset()
    }

    logoutAuthUser()
    navigate(ROUTES.HOME)
  }

  const navigationItems: NavigationItem[] = isAuthenticated
    ? [
        { label: 'Home', icon: <IconHome />, route: ROUTES.HOME },
        { label: 'Social', icon: <IconUsers />, route: ROUTES.SOCIAL },
        { label: 'Profile', icon: <IconUserCircle />, route: ROUTES.PROFILE },
        { label: 'Logout', icon: <IconLogout />, logout: true },
      ]
    : [
        { label: 'Home', icon: <IconHome />, route: ROUTES.HOME },
        { label: 'Login', icon: <IconLogin />, login: true },
      ]

  const isRouteActive = (route?: string): boolean => {
    return route ? `/${pathname.split('/')[1]}` === route : false
  }

  return (
    <AppBar
      position="relative"
      component="footer"
      sx={{
        flex:'0 0 auto',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={{
          zIndex: 1200,
          width: '100%',
          p: 1,
        }}
      >
        <BottomNavigation
          showLabels
          sx={{
            backgroundColor: 'white',
            width: '100%',
            height: '58px',
            color: theme.palette.grey[800],
            '&.MuiBottomNavigation-root': {
              justifyContent: 'space-between !important',
            },
          }}
        >
          {navigationItems.map((item, index) => (
            <BottomNavigationAction
              key={index}
              label={item.label}
              icon={React.cloneElement(item.icon, {
                stroke: 2,
                size: 18,
              })}
              sx={{
                maxWidth: '87px',
                '& .Mui-selected': {
                  fontSize: '0.75rem !important',
                },
                '& .MuiBottomNavigationAction-label': {
                  mt: '8px !important',
                },
                color: isRouteActive(item.route)
                  ? theme.palette.secondary.main
                  : theme.palette.grey[800],
                backgroundColor: isRouteActive(item.route)
                  ? theme.palette.secondary[50]
                  : 'transparent',
                borderRadius: isRouteActive(item.route) ? '8px' : 'none',
              }}
              onClick={() => handleNavigation(item)}
            />
          ))}
        </BottomNavigation>
      </Toolbar>
    </AppBar>
  )
}

export default BottomAppBar
