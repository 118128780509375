import { useState } from 'react'
import { Preferences } from '@capacitor/preferences'

// Define the Cache interface
interface Cache {
  get: (key: string) => Promise<any> | any
  set: (key: string, value: any) => Promise<void> | void
  remove: (key: string) => Promise<void> | void
  allKeys?: () => Promise<string[]> | string[]
}

// Define the web cache using sessionStorage
const webCache: Cache = {
  get(key: string) {
    return JSON.parse(sessionStorage.getItem(key) ?? 'null')
  },

  set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },

  remove(key: string) {
    sessionStorage.removeItem(key)
  },

  allKeys() {
    return Object.keys(sessionStorage)
  },
}

// Define the mobile cache using Capacitor Preferences
const mobileCache: Cache = {
  async get(key: string) {
    const { value } = await Preferences.get({ key })
    return value
  },

  async set(key: string, value: any) {
    await Preferences.set({ key, value: JSON.stringify(value) })
  },

  async remove(key: string) {
    await Preferences.remove({ key })
  },

  //   async allKeys() {
  //     // Note: Capacitor Preferences does not have an allKeys method
  //     // Implement if necessary or handle it internally
  //     return [] // Placeholder
  //   },
}

// Define the return type for the hook
interface UseCustomCacheReturn {
  get: (key: string) => Promise<any> | any
  set: (key: string, value: any) => Promise<void>
  remove: (key: string) => Promise<void>
  allKeys: () => Promise<string[]> | string[]
}

// Define the hook with the mode parameter
const useCustomCache = (isHybrid: boolean): UseCustomCacheReturn => {
  const [cache] = useState<Cache>(isHybrid ? mobileCache : webCache)

  return {
    get: (key: string) => cache.get(key),
    set: async (key: string, value: any) => await cache.set(key, value),
    remove: async (key: string) => await cache.remove(key),
    allKeys: cache.allKeys
      ? cache.allKeys
      : async () => await Promise.resolve([]), // Handle optional allKeys
  }
}

export default useCustomCache
