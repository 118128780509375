import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

interface RoundedButtonProps extends ButtonProps {
  children: React.ReactNode
  diameter?: number
  borderColor?: string
  br?: string
  target?: string
  rel?: string
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'diameter' && prop !== 'borderColor' && prop !== 'br',
})<{
  diameter?: number
  borderColor?: string
  br?: string
}>(({ theme, diameter, borderColor, br = '50%' }) => ({
  borderRadius: br,
  borderColor: borderColor ?? theme.palette.primary.main,
  color: borderColor ?? theme.palette.primary.main,
  width: diameter,
  height: diameter,
  minWidth: diameter,
  padding: 0,
  '&:hover': {
    borderColor: borderColor ? `${borderColor}` : theme.palette.primary.dark,
    color: borderColor ? `${borderColor}` : theme.palette.primary.dark,
  },
}))

const RoundedButton: React.FC<RoundedButtonProps> = ({
  diameter = 50,
  borderColor,
  children,
  target,
  rel,
  ...props
}) => {
  return (
    <StyledButton
      variant="outlined"
      diameter={diameter}
      borderColor={borderColor}
      {...(target && { target })}
      {...(rel && { rel })}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export default RoundedButton
