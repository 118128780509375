import { useAuth0 } from '@auth0/auth0-react'
import { Container, LinearProgress } from '@mui/material'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { ROUTES } from '../helpers/routes-helper'

const AuthGuard: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return (
      <>
        <LinearProgress />
        <Container component="main" sx={{ flex: 1 }} />
      </>
    )
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.UNAUTHORIZED} />
}

export default AuthGuard
