import {
  Box,
  Button,
  styled as muiStyled,
  Typography,
  useTheme,
} from '@mui/material'
import CustomButton from '../../../../components/ui/CustomButton'
import RoundedButton from '../../../../components/ui/RoundedButton'
import styled from 'styled-components'
import React from 'react'
import {
  IconAdjustments,
  IconChevronLeft,
  IconMessageCircle,
  IconPointFilled,
} from '@tabler/icons-react'

export const GroupHeaderLeftContainer = muiStyled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
})

export const GroupHeaderRightContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'stretch',
      gap: '12px',
    },
  }
})(({ theme }) => ({}))

export const GroupHeaderRoundedButton = muiStyled(RoundedButton)({
  boxShadow: '0px 0px 4px 0px #33333340',
  marginRight: '12px',
  marginLeft: '3px',
})

export const GroupHeaderTitle = styled(Typography).attrs(() => {
  return {
    textTransform: 'capitalize',
    noWrap: true,
    maxWidth: { xs: '160px', md: '350px' },
    component: 'h3',
    variant: 'h3',
  }
})(({ theme }) => ({}))

export const GroupHeaderLeftInnerContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
  }
})(({ theme }) => ({}))

export const GroupHeaderLeftMainContainerIconChevronLeft = styled(
  IconChevronLeft,
).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
    size: 18,
    stroke: 2,
  }
})(({ theme }) => ({}))

export const GroupHeaderSubTitle = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    textTransform: 'capitalize',
    noWrap: true,
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.grey[600],
  }
})(({ theme }) => ({}))

export const GroupHeaderCustomButton = muiStyled(CustomButton)(() => {
  const theme = useTheme()
  return {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    color: theme.palette.grey.A100,
    paddingTop: '8px',
    paddingBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0px 0px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '12px 0px 0px 0px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px',
    },
    width: '100%',
    sx: {
      height: '100%',
      mt: '16px',
    },
  }
})

export const GroupHeaderMainContainer = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    pt: { xs: '16px', md: '46px' },
    overflow: 'auto',
    className: 'hide-scrollbar',
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '12px',
      borderBottom: { md: `1px solid ${theme.palette.grey[200]}` },
      pb: { md: '16px' },
    },
  }
})(({ theme }) => ({}))

export const GroupHeaderLeftMainContainerIconPointFilled = styled(
  IconPointFilled,
).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[300],
    size: 12,
    margin: '0px 6px',
  }
})(({ theme }) => ({}))

export const GroupHeaderLeftMainContainerIconAdjustments = styled(
  IconAdjustments,
).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
    size: 18,
    stroke: 2,
  }
})(({ theme }) => ({}))

export const GroupHeaderLeftMainContainerButton = styled(Button).attrs(() => {
  return {
    variant: 'outlined',
    color: 'primary',
    size: 'large',
    sx: {
      borderRadius: '10px',
      textTransform: 'none',
      px: '24px',
      minWidth: '100px',
    },
  }
})(({ theme }) => ({}))

// TODO: not in use

interface GroupHeaderBadgeTypographyProps {
  badgeContent: number
}

export const GroupHeaderBadgeTypography = styled(
  ({ badgeContent, ...rest }: GroupHeaderBadgeTypographyProps) => (
    <Typography {...rest} />
  ),
).attrs<GroupHeaderBadgeTypographyProps>(({ badgeContent }) => {
  const theme = useTheme()
  return {
    color: 'white',
    component: 'p',
    variant: 'caption',
    bgcolor: theme.palette.secondary.main,
    sx: {
      width: badgeContent > 99 ? '25px' : '17px',
      height: badgeContent > 99 ? '18px' : '17px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})<GroupHeaderBadgeTypographyProps>(() => ({}))

export const GroupHeaderIconAdjustments = styled(IconAdjustments).attrs(() => {
  return {
    size: 18,
    stroke: 2,
  }
})(({ theme }) => ({}))

export const GroupHeaderIconMessageCircle = styled(IconMessageCircle).attrs(
  () => {
    return {
      size: 18,
      stroke: 2,
    }
  },
)(({ theme }) => ({}))
