import * as React from 'react'
import styled from 'styled-components'

interface StepperIconProps {
  icon?: string
  iconElement?: React.ReactNode
  imgSize?: number
  width?: string
  height?: string
  alt: string
  wrapperStyle?: React.CSSProperties
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`

const ImgIcon: React.FC<StepperIconProps> = ({
  icon,
  iconElement,
  imgSize = 20,
  width,
  height,
  wrapperStyle,
  onClick,
  alt,
}: StepperIconProps): JSX.Element => {
  return (
    <IconWrapper style={wrapperStyle} onClick={onClick}>
      {icon && (
        <img
          src={icon}
          alt={alt}
          width={width ?? imgSize}
          height={height ?? imgSize}
        />
      )}
      {iconElement && iconElement}
    </IconWrapper>
  )
}

export default ImgIcon
