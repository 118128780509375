import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled as muiStyled,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import React from 'react'

export const FAQMainContainer = muiStyled('div')(() => {
  const theme = useTheme()
  return {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '112px',
      marginBottom: '112px',
    },
  }
})

export const FAQContentContainer = muiStyled('div')({
  maxWidth: '960px',
})

export const FAQAccordion = muiStyled(Accordion)(() => {
  const theme = useTheme()

  return {
    marginTop: '8px',
    '&.MuiAccordion-root': {
      '&:before': {
        height: '0px',
      },
      '&.Mui-expanded': {
        margin: '8px 0px',
      },
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '12px',
      backgroundColor: theme.palette.grey[50],
    },
  }
})

export const FAQAccordionDetails = muiStyled(AccordionDetails)(() => ({
  paddingX: '24px',
}))

interface StyledIconContainerProps {
  isExpand?: boolean
}

export const StyledIconContainer = styled('div')<StyledIconContainerProps>`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px #33333340;
  background-color: white;
  position: ${({ isExpand }) => (isExpand ? 'absolute' : 'relative')};
  bottom: ${({ isExpand }) => (isExpand ? '-6px' : 'initial')};
`

export const FAQATypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'h1',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const FAQDescriptionTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'body1',
    component: 'p',
    color: theme.palette.grey[800],
    sx: {
      marginTop: '16px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '24px',
        fontWeight: 500,
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: '30px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '32px',
      },
    },
  }
})(() => ({}))

interface FAQAccordionSummaryProp {
  isOpen: boolean
}

export const FAQAccordionSummary = styled(
  ({ isOpen, ...rest }: FAQAccordionSummaryProp & any) => (
    <AccordionSummary {...rest} />
  ),
).attrs<FAQAccordionSummaryProp>(({ isOpen }) => {
  const theme = useTheme()
  return {
    sx: {
      '&.MuiAccordionSummary-root': {
        '& .MuiAccordionSummary-expandIconWrapper': {
          alignSelf: 'center',
        },
        '& .MuiAccordionSummary-content': {
          WebkitTransition: 'none',
          transition: 'none',
          mt: {
            xs: isOpen ? '0px' : '10px',
            sm: isOpen ? '0px' : '20px',
          },
          mb: {
            xs: '12px',
            sm: isOpen ? '0px' : '15px',
            md: isOpen ? '0px' : '20px',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
          },
        },
      },
    },
  }
})<FAQAccordionSummaryProp>(({ theme }) => ({}))

export const FAQAQuestionTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'body1',
    fontWeight: 500,
    textAlign: 'start',
    maxWidth: {
      sx: '90%',
      sm: '100%',
    },
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const FAQAAnsTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    fontWeight: 500,
    textAlign: 'start',
    maxWidth: '95%',
    color: theme.palette.grey[800],
  }
})(() => ({}))
