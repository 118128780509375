import { useMediaQuery, useTheme } from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import EmptyScreen from '../../../components/EmptyScreen'
import ImgIcon from '../../../components/ui/ImgIcon'
import { useWebLayoutContext } from '../../../context/WebLayoutContext'
import { generateEater } from '../../../helpers/converters'
import { ROUTES } from '../../../helpers/routes-helper'
import { useDevice } from '../../../hooks/useDevice'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectEater } from '../../../store/profileSlice'
import {
  fetchGroups,
  selectGroupRequests,
  selectGroups,
  selectIsLoading,
  selectMessage,
  selectPage,
  selectSeverity,
  selectShouldOpen,
  selectTotalPages,
  setOpen,
} from '../../../store/socialSlice'
import { Group } from '../../../types/groupTypes'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiAlert, MuiSnackbar } from '../styled/global.styled'
import GroupAddEditDialog from './components/GroupAddEditDialog'
import SocialGroup from './components/SocialGroup'
import SocialHeader from './components/SocialHeader'
import {
  SocialEmptyScreenButton,
  SocialEmptyScreenContainer,
  SocialEmptyScreenIcon,
  SocialMainContainer,
  SocialNewGroupTypography,
  SocialSkeleton,
} from './styled/Social.styled'
import { useLocation } from 'react-router'

const DocumentTitle = 'Resactly | Social Connect & Share Dining Recommendations'

const Social: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const groups = useAppSelector(selectGroups)
  const groupRequests = useAppSelector(selectGroupRequests)
  const isLoading = useAppSelector(selectIsLoading)
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const totalPages = useAppSelector(selectTotalPages)
  const page = useAppSelector(selectPage)
  const eater = useAppSelector(selectEater)
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const { isHybrid } = useDevice()
  const { isScrollBottom } = useWebLayoutContext()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [groupList, setGroupList] = useState<Group[]>(groups ?? [])

  const [loading, setLoading] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})

    if(analytics && isAnalyticsReady){
      analytics?.page('PAGE_OPENED',{
        path: ROUTES.SOCIAL,
        title: DocumentTitle,
        url: `${window.location.origin}${ROUTES.SOCIAL}`,
        category: 'Social',
        name: 'Social Viewed',
        eaterName: fullName,
        startTime: new Date().toISOString(),
        email,
        eaterId,
      })
    }
  }, [analytics, isAnalyticsReady, eater])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {});
    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.SOCIAL,
        title: DocumentTitle,
        url: `${window.location.origin}${ROUTES.SOCIAL}`,
        category: 'Social',
        name: 'Social Viewed',
        eaterName: fullName,
        endTime: new Date().toISOString(),
        email,
        eaterId,
      });
    };
  }, [location, analytics]);


  useEffect(() => {
    if (groupList.length === 0 && groupRequests.length === 0) {
      fetchItems(0).catch(() => {})
    }
  }, [])

  useEffect(() => {
    setGroupList(groups)
  }, [groups])

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const fetchItems = async (pageNumber: number): Promise<void> => {
    if (loading) return
    setLoading(true)
    await dispatch(fetchGroups(pageNumber))
    setLoading(false)
  }

  useEffect(() => {
    if (isScrollBottom && totalPages - 1 > page && !loading) {
      fetchItems(page + 1).catch(() => {})
    }
  }, [isScrollBottom])

  return (
    <SocialMainContainer>
      <Helmet>
        <title>{DocumentTitle}</title>
        <meta
          name="description"
          content="Engage with the Resactly community on the Social page. Share your dining experiences, join group discussions, and connect with fellow food enthusiasts for collective dining recommendations."
        />
      </Helmet>
      <div
        style={{
          position: 'relative',
        }}
      >
        {!isSmallScreen && (
          <SocialHeader onAddGroup={() => setIsModalOpen(true)} />
        )}
        {isSmallScreen && (
          <ImgIcon
            alt="create-resactly-group"
            iconElement={
              <IconPlus
                size={18}
                stroke={2}
                color={theme.palette.background.paper}
              />
            }
            wrapperStyle={{
              backgroundColor: theme.palette.primary.light,
              position: 'fixed',
              bottom: isHybrid
                ? `calc(84px + env(safe-area-inset-bottom))`
                : '20px',
              right: isHybrid ? '16px' : '20px',
              boxShadow: '0px 0px 12px 0px #00000059',
              zIndex: 10,
            }}
            onClick={() => setIsModalOpen(true)}
          />
        )}
        {(groupList.length !== 0 || groupRequests.length !== 0) && (
          <SocialGroup
            isLoader={isLoading}
            groups={groupList}
            groupRequests={groupRequests}
            page={page}
          />
        )}

        {isLoading && groupList.length < 1 && (
          <SocialSkeleton variant="rounded" height={98} />
        )}

        {!isLoading && groupList.length === 0 && groupRequests.length === 0 && (
          <EmptyScreen
            containerHeight="calc(100vh - 296px)"
            imageUrl="/images/empty-screen.svg"
            mainText="No Groups Yet"
            secondaryText={`Looks like you haven't created or joined any group Groups allow you to connect with others to discover collective dining recommendations`}
          >
            <SocialEmptyScreenContainer>
              <SocialEmptyScreenButton
                variant="text"
                onClick={() => setIsModalOpen(true)}
              >
                <SocialEmptyScreenIcon stroke={2} size={18} />
                <SocialNewGroupTypography>New Group</SocialNewGroupTypography>
              </SocialEmptyScreenButton>
            </SocialEmptyScreenContainer>
          </EmptyScreen>
        )}
      </div>

      <GroupAddEditDialog
        groupAction="add"
        openModel={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      ></GroupAddEditDialog>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </SocialMainContainer>
  )
}

export default Social
