import { Box, Button, Container, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import RoundedButton from '../../../../components/ui/RoundedButton'
import { IconAdjustments, IconChevronLeft } from '@tabler/icons-react'

export const RestaurantsMainContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: {
    flex: 1,
  },
}))(() => ({}))

export const RestaurantsContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    flexDirection: 'column',
  },
}))(() => ({}))

export const RestaurantsHeaderContainer = styled(Box).attrs(() => ({
  sx: {
    mb: { xs: '8px', sm: '24px', md: '30px' },
  },
}))(() => ({}))

export const RestaurantsHeaderInnerContainer = styled(Box).attrs(() => ({
  pt: { xs: '16px', md: '46px' },
  pb: '10px',
  overflow: 'auto',
  className: 'hide-scrollbar',
  sx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },
}))(() => ({}))

export const RestaurantsHeaderTitleContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}))(() => ({}))

export const RestaurantsHeaderBackRoundedButton = styled(RoundedButton).attrs(
  () => ({
    sx: {
      boxShadow: '0px 0px 4px 0px #33333340',
      marginRight: '12px',
      marginLeft: '3px',
    },
    diameter: 32,
    borderColor: 'transparent',
  }),
)(() => ({}))

export const RestaurantsHeaderTitleTypography = styled(Typography).attrs(
  () => ({
    component: 'h3',
    variant: 'h3',
  }),
)(() => ({}))

export const RestaurantsHeaderFilterButtonContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'stretch',
    gap: '12px',
  },
}))(() => ({}))

export const RestaurantsHeaderFilterButton = styled(Button).attrs(() => ({
  variant: 'outlined',
  color: 'primary',
  size: 'large',
  sx: {
    borderRadius: '10px',
    textTransform: 'none',
  },
}))(() => ({}))

export const RestaurantsContentContainer = styled(Box).attrs(() => ({
  sx: {
    flexGrow: 1,
    overflowY: 'auto',
  },
}))(() => ({}))

export const RestaurantsIconChevronLeft = styled(IconChevronLeft).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[600],
    size: 18,
    stroke: 2,
  }
})(() => ({}))

export const RestaurantsIconAdjustments = styled(IconAdjustments).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[600],
    size: 18,
    stroke: 2,
  }
})(() => ({}))

export const RestaurantsIconAdjustmentsSecond = styled(IconAdjustments).attrs(
  () => {
    return {
      size: 18,
      stroke: 2,
    }
  },
)(() => ({}))
