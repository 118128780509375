import { Box, Button, Container, Typography } from '@mui/material'
import styled from 'styled-components'

export const SearchResultsMainContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: {
    flex: 1,
  },
}))(() => ({}))

export const SearchResultsHeaderContainer = styled(Box).attrs(() => ({
  pt: { xs: '16px', md: '46px' },
  pb: '10px',
  overflow: 'auto',
  className: 'hide-scrollbar',
  sx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },
}))(() => ({}))

export const SearchResultsHeaderLeftContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}))(() => ({}))

export const SearchResultsFilterButton = styled(Button).attrs(() => ({
  variant: 'outlined',
  color: 'primary',
  size: 'large',
  sx: {
    borderRadius: '10px',
    textTransform: 'none',
  },
}))(() => ({}))

export const SearchResultsHeaderRightContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'stretch',
    gap: '12px',
  },
}))(() => ({}))

export const SearchResultsHeaderSearchTermTypography = styled(Typography).attrs(
  () => ({
    variant: 'h3',
    flexGrow: 9,
    component: 'h3',
    textTransform: 'capitalize',
  }),
)(() => ({}))

export const SearchResultsResactlyTabContainer = styled(Box).attrs(() => ({
  sx: {
    mt: { xs: '16px', md: '40px' },
  },
}))(() => ({}))
