import {
  Box,
  CSSObject,
  IconButton,
  Link as MuiLink,
  styled,
  Theme,
  useTheme,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {
  IconBell,
  IconBook,
  IconChevronsLeft,
  IconChevronsRight,
  IconId,
  IconLayoutDashboard,
  IconSettings,
  IconStar,
} from '@tabler/icons-react'
import * as React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { ROUTES } from '../helpers/routes-helper'

const drawerWidth = '285px'
const closeDrawerWidth = '100px'

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closeDrawerWidth,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const RestaurantLayout: React.FC = () => {
  const theme = useTheme()
  const navigation = useNavigate()
  const [open, setOpen] = React.useState(false)

  const [activeItem, setActiveItem] = React.useState('Dashboard')
  const [hoveredItem, setHoveredItem] = React.useState<string | null>(null)

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: open ? drawerWidth : closeDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }))

  const handleMenuItemClick = (label: string, path: string): void => {
    setActiveItem(label)
    navigation(path)
  }

  return (
    <Box sx={{ display: 'flex', backgroundColor: theme.palette.primary.main }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.primary.main,
            border: 'none',
            padding: '24px',
          },
        }}
      >
        <DrawerHeader>
          {open ? (
            <Box
              component="div"
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MuiLink component={Link} to="/">
                <img
                  srcSet={`${'/logo.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                  src={`${'/logo.svg'}?w=30&h=30&fit=crop&auto=format`}
                  style={{
                    height: '56px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  loading="lazy"
                  alt="Resactly-Logo"
                />
              </MuiLink>
              <IconButton
                onClick={handleDrawerClose}
                style={{
                  border: `2px solid ${theme.palette.primary[900] as string}`,
                  height: '30px',
                  width: '30px',
                  padding: '0px',
                }}
              >
                <IconChevronsLeft
                  stroke={2}
                  height={'20px'}
                  width={'20px'}
                  style={{
                    margin: 'auto',
                    color: theme.palette.background.paper,
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              onClick={handleDrawerOpen}
              style={{
                border: `2px solid ${theme.palette.primary[900] as string}`,
                height: '30px',
                width: '30px',
                padding: '0px',
                marginBottom: '24px',
              }}
            >
              <IconChevronsRight
                stroke={2}
                height={'20px'}
                width={'20px'}
                style={{
                  margin: 'auto',
                  color: theme.palette.background.paper,
                }}
              />
            </IconButton>
          )}
        </DrawerHeader>
        <List>
          {menulist.map((menu, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: 'block',
              }}
              onMouseEnter={() => setHoveredItem(menu.label)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  borderRadius: '10px',
                  backgroundColor:
                    activeItem === menu.label
                      ? theme.palette.primary.light
                      : 'transparent',
                  '&:hover': {
                    backgroundColor:
                      activeItem === menu.label
                        ? theme.palette.primary.light
                        : 'transparent',
                  },
                }}
                onClick={() => handleMenuItemClick(menu.label, menu.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:
                      activeItem === menu.label || hoveredItem === menu.label
                        ? theme.palette.grey.A100
                        : theme.palette.grey[400],
                    transition: 'color 0.3s',
                  }}
                >
                  {menu.icons}
                </ListItemIcon>
                <ListItemText
                  primary={menu.label}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': {
                      color:
                        activeItem === menu.label || hoveredItem === menu.label
                          ? theme.palette.grey.A100
                          : theme.palette.grey[400],
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, height: '100vh' }}>
        <Box
          sx={{
            marginTop: '24px',
            marginRight: '24px',
            padding: '20px',
            height: 'calc(100% - 24px)',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            backgroundColor: theme.palette.background.paper,
            overflowY: 'scroll',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default RestaurantLayout

const menulist = [
  {
    icons: <IconLayoutDashboard stroke={2} />,
    label: 'Dashboard',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
  {
    icons: <IconBook stroke={2} />,
    label: 'Menu',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
  {
    icons: <IconStar stroke={2} />,
    label: 'Reviews',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
  {
    icons: <IconId stroke={2} />,
    label: 'Recommendations',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
  {
    icons: <IconBell stroke={2} />,
    label: 'Notification',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
  {
    icons: <IconSettings stroke={2} />,
    label: 'Settings',
    path: ROUTES.RESTAURANT_ADMIN_DASHBOARD,
  },
]
