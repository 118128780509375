import { Box, Drawer, Typography, useTheme } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import React from 'react'

export const BasicModalHeaderMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
})``

export const BasicModalHeaderTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    color: theme.palette.grey[800],
    component: 'p',
    variant: 'h4',
    fontSize: '18px !important',
  }
})``

export const BasicModalHeaderIconX = styled(IconX).attrs(() => {
  return {
    stroke: 2,
  }
})(() => ({ cursor: 'pointer' }))

export const BasicModalFooterMainContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      alignItems: 'center',
    },
  }
})``

export const BasicModalDrawer = styled(Drawer).attrs(() => {
  return {
    anchor: 'bottom',
    sx: {
      '& .MuiPaper-root': {
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        p: '16px',
      },
    },
  }
})``

interface BasicModalWrapperProp {
  modelWidth: boolean
}

export const BasicModalWrapper = styled(
  ({ modelWidth, ...rest }: BasicModalWrapperProp & any) => <Box {...rest} />,
).attrs<BasicModalWrapperProp>(({ modelWidth }) => {
  return {
    sx: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: '16px',
      p: { xs: '16px', md: '24px' },
      width: { xs: 'calc(100% - 20px)', md: modelWidth },
    },
  }
})<BasicModalWrapperProp>(({ theme }) => ({}))
