import { createTheme } from '@mui/material/styles'

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

const typography = {
  fontSize: 14,
  h1: {
    fontFamily: "'Nohemi', sans-serif",
    fontSize: '3rem',
    fontWeight: 800,
    lineHeight: '58px',
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '2.25rem',
      lineHeight: '40px',
    },
  },
  h2: {
    fontFamily: "'Nohemi', sans-serif",
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 700,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '18px',
      lineHeight: '24.3px',
    },
  },
  h3: {
    fontFamily: "'Nohemi', sans-serif",
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '20px',
      lineHeight: '24.3px',
    },
  },
  h4: {
    fontFamily: "'Nohemi', sans-serif",
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  body1: {
    fontFamily: "'General Sans', sans-serif",
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 600,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  body2: {
    fontFamily: "'General Sans', sans-serif",
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  subtitle1: {
    fontFamily: "'General Sans', sans-serif",
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  subtitle2: {
    fontFamily: "'General Sans', sans-serif",
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  caption: {
    fontFamily: "'General Sans', sans-serif",
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    [`@media (max-width:${breakpoints.values.sm}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  button: {},
}

const success = {
  main: '#12B76A',
  light: '#32D583',
  dark: '#039855',
  contrastText: '#FFFFFF',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
  900: '#054F31',
}
const warning = {
  main: '#F79009',
  light: '#FDB022',
  dark: '#DC6803',
  contrastText: '#FFFFFF',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
}
const error = {
  main: '#F04438',
  light: '#F97066',
  dark: '#D92D20',
  contrastText: '#FFFFFF',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
}

const theme = createTheme({
  breakpoints,
  palette: {
    primary: {
      main: '#1F2041',
      light: '#2B2C5A',
      50: '#F0F4FD',
      100: '#E4E8FB',
      200: '#CEDAF7',
      300: '#B0C1F1',
      400: '#90A0E9',
      500: '#7571DF',
      600: '#5A5FD1',
      700: '#4A4CBB',
      800: '#3E4195',
      900: '#383B77',
    },
    secondary: {
      main: '#FE5411',
      light: '#FF864C',
      dark: '#EF3A07',
      contrastText: '#FFFFFF',
      50: '#FFF5ED',
      100: '#FFE8D4',
    },
    grey: {
      50: '#F9FAFB',
      100: '#F2F4F7',
      200: '#EAECF0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      600: '#475467',
      700: '#344054',
      800: '#1D2939',
      900: '#101828',
      A100: '#FCFCFD',
      A200: '#0C111D',
    },
    background: {
      default: '#F1F1F8',
      paper: '#ffffff',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    success,
    warning,
    error,
  },
  typography,
  shape: {
    borderRadius: 3,
  },
})

export default theme
