import * as React from 'react'
import {
  OnboardingMainHeading,
  OnboardingSubHeading,
  OnboardingTitleContainer,
} from './styled/OnboardTitleTypography.styled'

interface Props {
  title: string
  subTitle: string
  isEditPreference?: boolean
  alignItems?: 'center' | 'right' | 'left'
}

const OnboardTitleTypography: React.FC<Props> = ({
  title,
  subTitle,
  isEditPreference = false,
  alignItems = 'center',
}: Props): JSX.Element => {
  return (
    <OnboardingTitleContainer
      textAlign={alignItems}
      data-testid="onboarding-title-container"
    >
      <OnboardingMainHeading>{title}</OnboardingMainHeading>
      {!isEditPreference && (
        <OnboardingSubHeading>{subTitle}</OnboardingSubHeading>
      )}
    </OnboardingTitleContainer>
  )
}

export default OnboardTitleTypography
