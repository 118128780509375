import { OnboardPage } from './helpers/onboard-helper'
import { Chef } from './types/chefTypes'
import { Menu } from './types/menuTypes'
import { Press, Review } from './types/reviewTypes'

export interface Recommendation {
  name?: string
  yes: number
  no: number
  email?: string
}

export interface RestaurantResponse {
  label?: string
  value?: number
  groupValue?: number
  starCount: number
  recommendation: Recommendation
  groupRecommendations?: Recommendation[]
  restaurantDTO: IRestaurantDetail
  group?: boolean
}
export interface RestaurantAndPredictedRestaurant {
  label?: string
  value?: number
  groupValue?: number
  starCount?: number
  recommendation?: Recommendation
  groupRecommendations?: Recommendation[]
  restaurantDTO: Restaurant
  group?: boolean
}
export interface RestaurantAndPredictedRestaurantResponse {
  count: number
  page: number
  predictedRestaurants?: RestaurantAndPredictedRestaurant[]
  totalCount: number
  result: Restaurant[]
}

export interface RestaurantResponseContainer {
  result?: RestaurantAndPredictedRestaurant[]
  totalCount?: number
  count?: number
  page?: 0
}

export interface Restaurant {
  restaurantID: string
  alias: string
  name: string
  address1: string
  address2?: string
  address3?: string
  city: string
  state: string
  country: string
  phone?: string
  website?: string
  created?: Date
  updated?: Date
  zip_code?: string
  contact_name?: string
  contact_email?: string
  place_id: string
  status: string
  tags?: string
  description?: string
  instagram?: string
  facebook?: string
  menus?: Menu[]
  atmosphere?: string
  category?: string
  cuisine?: string
  occasion?: string
  specialFeatures?: string
  awards?: string
  knownFor?: string
  reviews?: Review[]
  press?: Press[]
  priceRange: string
  distance?: number
  saved: boolean
  verified?: boolean
  imageurl?: string
  restaurantImages?: RestaurantImage[]
  open?: boolean
  reservationUrl?: string
  weekdayText?: string[]
  orderCount?: number
  viewCount?: number
  reservationCount?: number
  userReviewCount?: number
  dietary?: string[]
}

export interface RestaurantImage {
  restaurantImageId: string;
  url: string;
  order: number;
  created: Date;
  updated: Date;
}

export interface IRestaurantDetail extends Restaurant {
  recommendation?: Recommendation
}

export interface Summary {
  name: string
  count: number
}

export interface LocationPayload {
  page: number
  size: number
  longitude: number
  latitude: number
  radius?: any
}

export interface PredictedRestaurantRequest {
  aliases: string[]
  includeMenus: boolean
  longitude?: number
  latitude?: number
}

export interface PredictedRestaurantGroupRequest {
  groupId: string
  location?: string
  searchTerm?: string
  longitude?: number
  latitude?: number
  page: number
  email?: string
}

export interface Eater {
  eaterId?: string
  bio?: string
  firstName?: string
  lastName?: string
  email?: string
  dob?: any
  phone?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zipcode?: string
  country?: string
  instagram?: string
  facebook?: string
  x?: string
  tiktok?: string
  persona?: string
  cuisineLikes?: string
  cuisineDislikes?: string
  ingredientLikes?: string
  ingredientDislikes?: string
  favoriteReviewers?: string
  foodPreparationLiked?: string
  foodPreparationDisliked?: string
  ambienceDisliked?: string
  ambienceLiked?: string
  created?: Date
  updated?: Date
  gender?: string
  incomeLevel?: string | number
  diningFrequency?: string
  typeOfDiningEstablishment?: string
  dietaryPreferences?: string
  allergies?: string
  socialMediaActivity?: string
  favoriteRestaurants?: string
  savedRestaurants?: Restaurant[]
  predictedSavedRestaurants?: RestaurantResponseContainer
  reviews?: DiningReview[]
  restaurantReviews?: IRestaurantReview[]
  occupation?: string
  imageUrl?: string
  step?: string
}

export interface AddEaterRequest {
  firstName?: string
  lastName?: string
  email?: string
  dob?: Date
  phone?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zipcode?: string
  country?: string
  instagram?: string
  facebook?: string
  tiktok?: string
  persona?: string
  cuisineLikes?: string
  cuisineDislikes?: string
  ingredientLikes?: string
  ingredientDislikes?: string
  favoriteReviewers?: string
  gender?: string
  incomeLevel?: string
  diningFrequency?: string
  typeOfDiningEstablishment?: string
  dietaryPreferences?: string
  allergies?: string
  socialMediaActivity?: string
  favoriteRestaurants?: string
  reviews?: DiningReview[]
  occupation?: string
  x?: string
}

export interface DiningReview {
  diningReviewId: string
  restaurantAlias: string
  dishId: string
  value: string
  sentimentLabel: string
  sentimentScore: number
  sentimentSummary: string
  sentimentTags: string
  name: string
  email: string
  location: string
  menuId: string
  created: string
  updated: string
  starCount: number
}
export interface IRestaurantReview {
  restaurantReviewId: string
  restaurantAlias: string
  review: string
  starCount: number
  restaurantName?: string
  images?: ReviewImage[]
  imageUrl?: string
  sentimentScore: number
  sentimentLabel: string
  sentimentSummary: string
  sentimentTags: string
  created: string
  updated: string
}
export interface IRestaurantSentPayloadReview {
  alias?: string
  starCount?: number
  images?: Blob[]
  value?: string
  selectedImages?: ReviewImage[]
  deleted?: string[]
}
export interface ReviewImage {
  reviewImageId?: string
  restaurant?: Restaurant
  eater?: Eater
  url?: string
  status?: ReviewImageStatus
  created?: string
}

export interface AddDiningReviewRequest {
  restaurantAlias: string
  eaterId: string
  menuId: string
  dishId: string
  value: string
}

export interface UserInfo {
  sub: string
  name: string
  given_name: string
  family_name: string
  middle_name: string
  nickname: string
  preferred_username: string
  profile: string
  picture: string
  website: string
  email: string
  email_verified: boolean
  gender: string
  birthdate: string
  zoneinfo: string
  locale: string
  phone_number: string
  phone_number_verified: boolean
  address: {
    country: string
  }
  updated_at: string
}

export interface Login {
  chefDTO?: Chef
  eaterDTO?: Eater
  restaurantDTO?: Restaurant
  status?: string
  type?: string
}

export interface NewsletterSubscriptionRequest {
  email: string
  firstName?: string
  lastName?: string
}

export interface GeneralData {
  firstName?: string
  lastName?: string
  email?: string
  dob?: Date | any
  phone?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zipcode?: string
  country?: string
  incomeLevel?: number
  gender?: string
  occupation?: string
}

export interface OnboardSelectionMethods {
  getPayload: () => { likedItems: string[]; dislikedItems: string[] }
}
export interface PersonalFavoriteMethods {
  getPayload: () => {
    favoritesRestaurantItems: string[]
    foodReviewerItems: string[]
  }
}

export interface OnboardRestaurantPayloadType {
  restaurant: Restaurant | undefined
  website: string
}

export interface OnboardRestaurantMethods {
  getPayload: () => void
}

export interface OnboardRequestPayloadType {
  restaurantName: string
  contactName: string
  contactEmail: string
  phoneNumber: string
  websiteLink: string
}

export interface OnboardRequestMethods {
  getPayload: () => Promise<OnboardRequestPayloadType | {}>
}

export interface OnboardPersonalizeMethods {
  getPayload: () => Promise<GeneralData | undefined>
}

export interface OnboardSocialRefMethods {
  getPayload: () => SocialLinksState
}

export interface OnboardDiningFrequencyRefMethods {
  getPayload: () => DiningFrequencyState
}

export interface EaterPayload {
  page: OnboardPage
  eaterDTO?: any
  likes?: string[]
  dislikes?: string[]
  socialValue?: SocialLinksState
  diningFrequency?: DiningFrequencyState
  value?: string[]
  favorites?: OnboardFavorites
  restaurantLocation?: string
  onboardRequest?: string
}

export interface OnboardFavorites {
  reviewers: string[]
  restaurants: string[]
}

export interface SocialLinksState {
  facebook: string
  instagram: string
  x: string
  tiktok: string
}

export interface DiningFrequencyState {
  number: number
  frequency: string
}

export interface StepPayload {
  currentStep?: number
  nextStep?: number | null
  likedItems?: string[]
  dislikedItems?: string[]
  favoritesRestaurantItems?: string[]
  foodReviewerItems?: string[]
}

export interface StepsPayloadMap {
  [key: number]: StepPayload
}

export interface IStepper {
  step: number
  title: string
  subtitle: string
  path: string
  eventHandler: string
  canSkip: boolean
  nextStep: number | null
  prevStep: number | null
  page: string
}

export interface CountryType {
  code: string
  label: string
  phone: string
  suggested?: boolean
}

export interface onboardEaterPayload {
  page?: OnboardPage
  likes?: string[]
  dislikes?: string[]
  value?: string[]
}

export interface IFilterTabItems {
  key: number
  name: string
  value: string
}

export interface IFilterTabList {
  key: number
  name: string
  items: IFilterTabItems[]
  filterType?: string
  allowSearch?: boolean
  rangeStep?: number
  priceDetail?: boolean
  priceType?: 'dollar'
}

export interface IFilterItem {
  key: number
  name: string
  value: string
}

export interface IFilterResponseItem {
  distance?: number
  price?: number
  rating?: IFilterItem
  dietary?: IFilterItem[]
  cuisine?: IFilterItem[]
  ambience?: IFilterItem[]
  menu?: IFilterItem[]
  rangeStep?: number
}
export interface restaurantFilterRefMethods {
  getSelectedValues: () => IFilterResponseItem
}

export interface ITabList {
  label?: string
  value?: string
  icon?: React.ReactElement
}

export interface IRestaurantDishes {
  id: number
  image: string
  title: string
  description: string
  isEditable: boolean
  price?: number
  rating?: number
  tagChipData?: IChipData[]
}

export interface IChipData {
  percentage: string
  title?: string
}

export interface IGroupCard {
  imgList: string[]
  title: string
  subTitle: string
  distance: string
  tagChipData?: ITagChipData[]
  tagChipBgColor?: 'orange' | 'liteOrange' | 'white'
}

export interface ITagChipData {
  percentage: string
  title?: string
  matchData?: IMatchData[]
}

export interface IMatchData {
  name: string
  email: string
  image: string
  chipData?: ITagChipData[]
}
export interface IDiningReviewsByAlias {
  alias: string
  starCount: number
  value: string
  images?: Blob[]
  deleted?: string[]
}
export interface StoryRequest {
  eater?: Eater
}

export interface ILandingDetailsRequest {
  longitude?: number
  latitude?: number
  radius?: number
  searchRequest?: {
    term: string
    type: string
  }
  page?: number
  type?: string
  name?: string
  pageNumber?: number
}
export interface IRestaurantsCuisineRequest {
  longitude: number
  latitude: number
  radius: number
  page: number
  name: string
}
export interface IRestaurantsList {
  count?: number
  page?: number
  totalCount?: number
  result?: Restaurant[]
}

export interface ILandingResponse {
  lists: ILandingRestaurantList[]
  cuisines: ILandingCuisinesResponse[]
  restaurantCount: number
  cuisineCount: number
}
export enum LandingType {
  LOCAL = 'LOCAL',
  WALLET = 'WALLET',
  TOPRATED = 'TOPRATED',
  MUSTTRY = 'MUSTTRY',
}

export enum LandingTypeValue {
  LOCAL = 'local flavors',
  WALLET = 'Wallet Friendly Restaurants',
  TOPRATED = 'Top Rated Restaurants',
  MUSTTRY = 'Must Try Restaurants',
}

export enum ReviewImageStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export interface ILandingCuisinesResponse {
  key: string
  value: string
}

export interface ILandingRestaurantList {
  name: string
  type: LandingType
  restaurants: Restaurant[]
  predictedRestaurants: RestaurantResponse[]
}

export interface Dish {
  chef: string
  created: number
  description: string
  dishID: string
  ingredients: string
  name: string
  preparation: string
  restaurant: string
  status: string
  tags: string
  updated: number
}

export interface GroupMemberType {
  name: string
  email: string
  phone: string
  status?: string
  owner?: boolean
  eaterId?: string
  me?: boolean
}

export interface IRestaurantDishReviewRequest {
  alias: string
  dishId: string
  starCount: number
}

export interface IGenerateCustomEater {
  eaterId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipcode: string
  country: string
  instagram: string
  facebook: string
  x: string
  tiktok: string
  occupation: string
  imageUrl: string
  fullName: string
}
