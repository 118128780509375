import { Box, Popover, TextField, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'

export const ProfileMoreButtonCustomButton = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      backgroundColor: 'white',
      borderColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
      marginRight: '12px',
      paddingY: '12px',
      paddingX: '14px',
      minWidth: '44px !important',
    },
  }
})(() => ({}))

export const ProfileMoreButtonPopover = styled(Popover).attrs(() => ({
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  PaperProps: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: { xs: -6, md: 1 },
    },
  },
  sx: {
    '& .MuiPaper-root': {
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
      borderRadius: '10px',
      p: 1,
    },
  },
}))(() => ({}))

export const ProfileMoreButtonPopoverMenuContainer = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '8px',
      '&:hover': {
        color: theme.palette.error.dark,
      },
      color: theme.palette.grey[800],
      cursor: 'pointer',
      margin: '8px',
    },
  }
})(() => ({}))

export const ProfileMoreButtonPopoverMenuTypography = styled(Typography).attrs(
  () => ({ noWrap: true, component: 'p', variant: 'subtitle2' }),
)(() => ({}))

export const ProfileMoreButtonBasicModalTypography = styled(Typography).attrs(
  () => ({
    sx: {
      mt: '16px',
    },
    noWrap: true,
    component: 'p',
    variant: 'subtitle2',
  }),
)(() => ({}))

export const ProfileMoreButtonBasicModalTextField = styled(TextField).attrs(
  () => {
    const theme = useTheme()
    return {
      rows: 4,
      sx: {
        mb: 2,
        mt: '6px',
        width: '100%',
        maxWidth: 600,

        '& .MuiOutlinedInput-root': {
          fontSize: '14px',
          color: theme.palette.grey[800],
          fontWeight: 500,
          '& fieldset': {
            borderRadius: '8px',
          },
        },
      },
      fullWidth: true,
      multiline: true,
      variant: 'outlined',
    }
  },
)(() => ({}))
