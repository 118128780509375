import { Box, Grid, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

export const AIRevolutionEngineMainContainer = styled(Box).attrs(() => ({
  position: 'relative',
  sx: {
    margin: { xs: '40px 0 0px 0', md: '60px 0 40px 0px' },
  },
}))(({ theme }) => ({}))

export const AIRevolutionEngineContentGrid = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 5,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}))(({ theme }) => ({}))

export const AIRevolutionEngineContentContainer = styled(Box).attrs(() => ({
  sx: {
    mx: {
      xs: 'auto',
      md: 'none',
    },
    textAlign: {
      xs: 'center',
      md: 'start',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      xs: 'center',
      md: 'start',
    },
    mb: '32px',
  },
}))(({ theme }) => ({}))

export const AIRevolutionEngineTypographyChip = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    noWrap: true,
    component: 'span',
    variant: 'subtitle2',
    textTransform: 'uppercase',
    color: theme.palette.secondary.dark,
  }
})(() => {
  const theme = useTheme()
  return {
    letterSpacing: '0.09rm',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '15px',
    padding: '4px 16px',
  }
})

export const AIRevolutionEngineTypographyTitle = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      noWrap: true,
      component: 'p',
      variant: 'h2',
      color: theme.palette.grey[800],
    }
  },
)(() => ({}))

export const AIRevolutionEngineTypographyMainSubTitle = styled(
  Typography,
).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'body2',
    color: theme.palette.grey[700],
  }
})(() => {
  const theme = useTheme()
  return {
    paddingTop: '16px',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
    },
  }
})

export const AIRevolutionEngineTypographySubTitle = styled(Typography).attrs(
  () => {
    const theme = useTheme()
    return {
      component: 'p',
      variant: 'body2',
      color: theme.palette.grey[700],
    }
  },
)(() => {
  const theme = useTheme()
  return {
    paddingTop: '16px',
    paddingBottom: '24px',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
    },
  }
})

export const AIRevolutionEngineImgContainer = styled(Box).attrs(() => ({
  textAlign: 'center',
}))(() => ({ overflow: 'hidden', margin: 'auto' }))
