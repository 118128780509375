import { Box, Tabs } from '@mui/material'
import {
  IconAdjustments,
  IconBookmark,
  IconStar,
  IconUser,
} from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  selectEater,
  setCurrentTab,
  setIsEditCancelledData,
  setIsProfileEdit,
  setIsUpdateData,
} from '../../../../store/profileSlice'
import {
  ProfileTabInnerContainer,
  ProfileTabMainContainer,
  ProfileTabProfileBioContainer,
  ProfileTabTabComponent,
} from '../styled/ProfileTab.styled'
import Preferences from './Preferences'
import ProfileBio from './ProfileBio'
import ProfileReviewTab from './ProfileReviewTab'
import ProfileSavedRestaurants from './ProfileSavedRestaurants'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
)

const a11yProps = (index: number): { id: string; 'aria-controls': string } => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

const tabData = [
  { label: 'Bio', icon: <IconUser size={20} stroke={2} /> },
  { label: 'Preferences', icon: <IconAdjustments size={20} stroke={2} /> },
  { label: 'Saved Restaurants', icon: <IconBookmark size={20} stroke={2} /> },
  { label: 'Reviews', icon: <IconStar size={20} stroke={2} /> },
]

const ProfileTab: React.FC = () => {
  const dispatch = useAppDispatch()
  const userDetail = useAppSelector(selectEater)
  const [value, setValue] = useState(0)

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    updateTab(newValue)
  }

  useEffect(() => {
    updateTab(value)
  }, [])

  const updateTab = (value: number): void => {
    dispatch(setIsProfileEdit(false))
    dispatch(setIsUpdateData(false))
    dispatch(setIsEditCancelledData(false))
    dispatch(setCurrentTab(tabData[value]?.label || 'Reviews'))
    setValue(value)
  }

  return (
    <ProfileTabMainContainer>
      <ProfileTabInnerContainer>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="scrollable force basic tabs example"
        >
          {tabData.map((tab, index) => (
            <ProfileTabTabComponent
              key={index}
              icon={tab.icon}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </ProfileTabInnerContainer>
      <CustomTabPanel value={value} index={0}>
        <ProfileTabProfileBioContainer>
          <ProfileBio />
        </ProfileTabProfileBioContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Preferences />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ProfileSavedRestaurants
          cardList={userDetail?.predictedSavedRestaurants?.result ?? []}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ProfileReviewTab
          restaurantReviews={userDetail?.restaurantReviews ?? []}
        />
      </CustomTabPanel>
    </ProfileTabMainContainer>
  )
}

export default ProfileTab
