import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CustomButton from '../../../components/ui/CustomButton'
import { getActiveChipStyle } from './OnboardPreferenceOptions'
import OnboardSearchBox from './OnboardSearch'
import { OnboardChipList } from './styled/OnboardDialog.styled'
import { OnboardingItemListChip } from './styled/OnboardPreferenceOptions.styled'

interface OnboardDialogProps {
  isOpen: boolean
  title: string
  items: string[]
  selectedItems: string[]
  toggleDialog: () => void
  setSelectedItems: (items: string[]) => void
  oppositeItems: string[]
  chipType?: 'green' | 'red'
  searchIndexKey?: string
  onSuggestValue: (value: string) => void
  searchField: string
}

const OnboardDialog: React.FC<OnboardDialogProps> = ({
  isOpen,
  title,
  items,
  selectedItems,
  setSelectedItems,
  chipType = 'green',
  oppositeItems,
  toggleDialog,
  searchIndexKey,
  onSuggestValue,
  searchField,
}: OnboardDialogProps): JSX.Element => {
  const theme = useTheme()
  const activeChipStyle = getActiveChipStyle(chipType)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [suggestedWord, setSuggestedWord] = useState('')
  const [localSelectedItems, setLocalSelectedItems] =
    useState<string[]>(selectedItems)
  const dialogRef = useRef<HTMLElement>(null)

  useEffect(() => {
    setLocalSelectedItems(selectedItems)
  }, [selectedItems, isOpen])

  useEffect(() => {
    onSuggestValue(suggestedWord)
  }, [suggestedWord])

  const handleItemToggle = (item: string): void => {
    setLocalSelectedItems((prevState) =>
      prevState.includes(item)
        ? prevState.filter((i) => i !== item)
        : [...prevState, item],
    )
  }

  const isItemSelected = (item: string): boolean =>
    localSelectedItems.includes(item)

  const handleCancel = (): void => toggleDialog()

  const handleOk = (): void => {
    toggleDialog()
    setSelectedItems(localSelectedItems)
  }

  const handleEntering = (): void => {
    if (dialogRef.current) {
      dialogRef.current.focus()
    }
  }

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: isSmallScreen ? '100%' : '80%',
          maxHeight: 535,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '16px',
          p: '14px',
        },
        '& .MuiPaper-root': {
          mx: '10px',
        },
      }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={isOpen}
    >
      <DialogTitle style={{ fontFamily: 'Inter' }}>{title}</DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: {
            xs: '8px 12px',
            sm: '16px 24px',
          },
        }}
      >
        <OnboardSearchBox
          setWord={(e: string) => setSuggestedWord(e)}
          indexKey={searchIndexKey ?? ''}
          searchFieldName={searchField}
        />
        <OnboardChipList>
          {items.map((item) =>
            oppositeItems.includes(item) ? (
              <OnboardingItemListChip
                key={item}
                sx={{
                  paddingX: '8px',
                  color: isItemSelected(item)
                    ? activeChipStyle.color
                    : theme.palette.grey[800],
                  borderColor: isItemSelected(item)
                    ? activeChipStyle.borderColor
                    : theme.palette.grey[300],
                  backgroundColor: isItemSelected(item)
                    ? activeChipStyle.backgroundColor
                    : theme.palette.background.paper,
                  fontSize: '14px',
                }}
                disabled
                label={item}
                style={{ margin: 4, fontFamily: 'Inter' }}
              />
            ) : (
              <OnboardingItemListChip
                key={item}
                sx={{
                  paddingX: '8px',
                  color: isItemSelected(item)
                    ? activeChipStyle.color
                    : theme.palette.grey[800],
                  borderColor: isItemSelected(item)
                    ? activeChipStyle.borderColor
                    : theme.palette.grey[300],
                  backgroundColor: isItemSelected(item)
                    ? activeChipStyle.backgroundColor
                    : theme.palette.background.paper,
                  fontSize: '14px',
                }}
                onClick={() => handleItemToggle(item)}
                label={item}
                style={{ margin: 4, fontFamily: 'Inter' }}
              />
            ),
          )}
        </OnboardChipList>
      </DialogContent>
      <DialogActions>
        <CustomButton
          title={'Cancel'}
          onClick={handleCancel}
          fullWidth
          sx={{
            backgroundColor: 'white',
            borderColor: theme.palette.primary.light,
            color: theme.palette.primary.light,
            fontSize: '14px !important',
            paddingY: '8px',
          }}
        />
        <CustomButton
          title={'Save'}
          disabledTextColor="white"
          fullWidth
          onClick={handleOk}
          sx={{
            backgroundColor: theme.palette.primary.light,
            color: 'white',
            border: 'none',
            fontSize: '14px !important',
            paddingY: '9px',
          }}
          type="submit"
        />
      </DialogActions>
    </Dialog>
  )
}

export default OnboardDialog
