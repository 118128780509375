import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import {
  setMessage,
  setOpen,
  setSeverity,
  subscribeToNewsletter,
} from '../store/authSlice'
import { selectEater } from '../store/profileSlice'
import { NewsletterSubscriptionRequest } from '../types'
import {
  SubscribeFormInput,
  SubscribeFormInputSubscribeButton,
  SubscribeFormMainBox,
  SubscribeFormTypography,
} from './styled/SubscribeForm.styled'

const SubscribeForm: React.FC = () => {
  const dispatch = useAppDispatch()
  const eater = useAppSelector(selectEater)
  const [email, setEmail] = useState<string>('')

  const onNewsletterSubscribe = async (): Promise<void> => {
    if (email.length > 0) {
      if (!isValidEmail(email)) {
        dispatch(setOpen(true))
        dispatch(setMessage('Please Enter valid Email'))
        dispatch(setSeverity('error'))
      } else {
        const nsr: NewsletterSubscriptionRequest = {
          email,
          firstName: eater?.firstName ?? '',
          lastName: eater?.lastName ?? '',
        }
        const result = await dispatch(subscribeToNewsletter(nsr))
        if (subscribeToNewsletter.fulfilled.match(result)) {
          dispatch(setOpen(true))
          dispatch(setMessage('Thank you for subscribing!'))
          dispatch(setSeverity('success'))
          setEmail('')
        }
      }
    } else {
      dispatch(setOpen(true))
      dispatch(setMessage('Please Enter Email'))
      dispatch(setSeverity('error'))
    }
  }

  const isValidEmail = (email): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  return (
    <SubscribeFormMainBox>
      <SubscribeFormTypography>Join Our Newsletter</SubscribeFormTypography>
      <Box display="flex">
        <SubscribeFormInput value={email} setValue={setEmail} />
        <SubscribeFormInputSubscribeButton
          action={() => {
            void onNewsletterSubscribe()
          }}
        />
      </Box>
    </SubscribeFormMainBox>
  )
}

export default SubscribeForm
