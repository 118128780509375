export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  SOCIAL: '/social',
  PROFILE: '/profile',
  RESTAURANTS: '/restaurants',
  RESTAURANT_DETAILS: '/restaurant',
  SEARCH: '/search',
  ONBOARD: '/onboard',
  UNAUTHORIZED: '/unauthorized',
  DASHBOARD: '/dashboard',
  CHEF_DASHBOARD: '/chef-dashboard',
  RECOVER_ACCOUNT: '/recover-account',
  RECOVER_BLOCKED_ACCOUNT: '/recover-blocked-account',
  SITEMAP: '/sitemap',

  // Restaurant Admin Routes
  RESTAURANT_ADMIN_DASHBOARD: '/restaurant-dashboard',
  RESTAURANT_ADMIN_PENDING: '/restaurant-pending',
  // RESTAURANT_PROFILE_ROUTE: '/restaurant-profile',
  // RESTAURANT_DETAILS_ROUTE: '/restaurant-details',
  // RESTAURANT_REVIEWS_ROUTE: '/restaurant-reviews',
  // RESTAURANT_MENUS_ROUTE: '/restaurant-menus',
  // SEARCH_ROUTE: '/search',
  // VIEW_ALL_ROUTE: '/all',
  // PERSONALIZE_ROUTE: '/personalize',
  // ROLE_ROUTE: '/choose-your-role',
  // STORY_ROUTE: '/share-your-dining-story',
  // CHEF_BIO_ROUTE: '/share-your-bio',
  // MISSING_ROUTE: '/a-few-more-details',
  // FIND_RESTAURANT_ROUTE: '/find-restaurant',
  // REQUEST_ONBOARDING: '/request-onboarding',
  // GROUP: '/group',
  // ONBOARDING: '/onboarding',
}

export const CHILD_ROUTES = {
  BIO: 'bio',
  SAVED_RESTAURANTS: 'saved-restaurants',
  PREFERENCES: 'preferences',
  REVIEWS: 'reviews',
}

export const navBarRoutes = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    isPublic: true,
  },
  {
    name: 'About',
    path: ROUTES.ABOUT,
    isPublic: true,
  },
  {
    name: 'Social',
    path: ROUTES.SOCIAL,
    isPublic: false,
  },
]
